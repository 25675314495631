import { MDBTabs, MDBTabsItem, MDBTabsLink } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useAuth } from "../auth/AuthContext";
import { ApiHelper } from "../libs/api";
import { VictoryPie, VictoryLegend, VictoryContainer } from "victory";
import { useTranslation } from "react-i18next";

const RiskTypes = {
  life: "risk_category_life",
  sex: "risk_category_sex",
  property: "risk_category_property",
  drug: "risk_category_drug",
};

const RiskReport = ({
  date,
  time,
  provinceData,
  description,
  prob,
  level = 0,
  advices,
}) => {
  const { t } = useTranslation();
  const [pieData, setPieData] = useState([]);
  const [selectionType, setSelectionType] = useState(null);
  const [selectedTab, setSelectedTab] = useState("recommendations");
  const { birthday, gender } = useAuth();
  const [moreText, setMoreText] = useState("");

  useEffect(() => {
    const fetchCrimeRules = async () => {
      try {
        await crimeRulesPost();
      } catch (error) {
        console.error("Error fetching crime rules:", error);
      }
    };
    fetchCrimeRules();
    if (prob) {
      const total = Object.values(prob).reduce((a, b) => a + b, 0);
      const data = Object.keys(prob)
        .filter((key) => Math.round((prob[key] * 100) / total) > 1)
        .map((key) => ({
          x: key,
          y: prob[key],
          name: `${t(RiskTypes[key])} ${Math.round(
            (prob[key] * 100) / total
          )}%`,
        }));
      setPieData(data);
      if (data.length === 0) setSelectionType(null);
      else setSelectionType(data[0].x);
    }
  }, [prob, t]);

  const crimeRulesPost = async () => {
    try {
      const { success, data } = await ApiHelper.postCrimeRules({
        gender: gender || null,
        age: birthday || 30,
        province: provinceData,
        date: date,
        time: time,
      });
      if (success) {
        setMoreText(data);
      }
    } catch (error) {
      console.error("Error fetching crime rules:", error);
    }
  };

  return (
    <div className="w-100 d-flex flex-column align-items-center">
      <div
        className="d-flex mb-4 border border-black"
        style={{ width: "100%", borderWidth: "4px" }}
      >
        <button
          className={`btn ${
            selectedTab === "recommendations" ? "btn-secondary" : "btn-light"
          } w-bold fs-5`}
          onClick={() => setSelectedTab("recommendations")}
          style={{ flex: 1 }}
        >
          {t("general_tips")}
        </button>
        <button
          className={`btn ${
            selectedTab === "more" ? "btn-secondary" : "btn-light"
          } w-bold fs-5`}
          onClick={() => setSelectedTab("more")}
          style={{ flex: 1 }}
        >
          {t("emergency_tips")}
        </button>
      </div>

      {selectedTab === "recommendations" && (
        <div className="text-center">
          <p className="fs-5 mb-0">{description}</p>
        </div>
      )}
      {selectedTab === "more" && (
        <div className="text-center">
          {Object.keys(moreText)
            .filter((key) => key.startsWith("recommendations_"))
            .map((key, index) => (
              <p
                key={key}
                style={{
                  fontSize: 16,
                  textAlign: "center",
                  marginTop: 10,
                }}
              >
                {`${index + 1}. ${moreText[key] || t("no_data")}`}
              </p>
            ))}
        </div>
      )}

      {level > 1 && (
        <>
          <hr className="w-100 hr" />
          <p className="fw-bold fs-5">{t("risk_probability_info")}</p>
          <div className="alert alert-info mb-4" role="alert">
            {t("risk_probability_disclaimer")}
          </div>
          <VictoryContainer
            width={400}
            height={250 + 30 * Object.keys(pieData).length}
            style={{
              padding: 10,
            }}
          >
            <VictoryPie
              colorScale={[
                "#52489C",
                "#00798C",
                "#EDAE49",
                "#E63946",
                "#3376BD",
              ]}
              data={pieData}
              width={400}
              height={300}
              standalone={false}
              labels={() => null}
              padding={{
                top: 15 + 30 * Object.keys(pieData).length,
                bottom: 85 - 30 * Object.keys(pieData).length,
              }}
            />
            <VictoryLegend
              data={pieData}
              standalone={false}
              colorScale={[
                "#52489C",
                "#00798C",
                "#EDAE49",
                "#E63946",
                "#3376BD",
              ]}
            />
          </VictoryContainer>
          <hr className="w-100 hr" />
          <MDBTabs className="mb-4" fill justify style={{ width: "100%" }}>
            <div className="d-flex flex-row w-100">
              {pieData.map((item) => {
                const type = item.x;
                return (
                  <MDBTabsItem key={`tab${type}`}>
                    <MDBTabsLink
                      onClick={() => setSelectionType(type)}
                      active={selectionType === type}
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                      {t(RiskTypes[type])}
                    </MDBTabsLink>
                  </MDBTabsItem>
                );
              })}
            </div>
          </MDBTabs>
          <p className="fw-bold fs-5">{t("self_guidance")}</p>
          <p className="fs-5 mb-0">
            {selectionType
              ? advices?.[selectionType]?.general?.map((item, index) => (
                  <span key={index}>
                    {item}
                    <br />
                  </span>
                ))
              : null}
          </p>
          <hr className="w-100 hr" />
          <p className="fw-bold fs-5">{t("emergency_guidance")}</p>
          <p className="fs-5 mb-0">
            {selectionType
              ? advices?.[selectionType]?.emergency?.map((item, index) => (
                  <span key={index}>
                    {item}
                    <br />
                  </span>
                ))
              : null}
          </p>
        </>
      )}
    </div>
  );
};

export default RiskReport;
