import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";

import { Navbar, Footer } from ".";

const Layout = ({ children, admin = false }) => {
  const { t } = useTranslation();

  return (
    <div className="vh-100">
      <div className="d-flex flex-column vh-100">
        <header>
          <Navbar admin={admin} />
        </header>
        <div
          className="d-flex flex-column flex-grow-1"
          style={{
            background: admin ? "white" : "linear-gradient(white, #84BBFF)",
            fontFamily: "Kanit-Regular",
          }}
        >
          <main className="main-container flex-grow-1">{children}</main>
          <footer>
            <Footer />
          </footer>
        </div>
        <CookieConsent
          buttonText={t("cookie_consent_button")}
          cookieName="acceptedCookieConsent"
        >
          {t("cookie_consent_message")}
        </CookieConsent>
      </div>
    </div>
  );
};

export default Layout;
