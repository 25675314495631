import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const CustomVideoInput = ({ setSelectedFile }) => {
  const [preview, setPreview] = useState(null);
  const { t } = useTranslation();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("video/")) {
      setSelectedFile(file);
      const url = URL.createObjectURL(file);
      setPreview(url);
    } else {
      alert(t("invalid_video_file"));
    }
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <input
        type="file"
        accept="video/*"
        id="videoFileInput"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      <label
        htmlFor="videoFileInput"
        className={`custom-video-input ${preview ? "with-preview" : ""}`}
      >
        <img src="video.png" alt={t("video_icon")} className="video-icon" />
        {!preview && <span>{t("attach_video_prompt")}</span>}
      </label>
      {preview && (
        <video controls className="video-preview">
          <source src={preview} type="video/mp4" />
          {t("unsupported_video")}
        </video>
      )}
      <style jsx>{`
        .custom-video-input {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 40px;
          border: 1px solid #e3e3e3;
          border-radius: 0.25rem;
          color: #a0a0a0;
          cursor: pointer;
          position: relative;
          background: white;
          padding: 0.25rem 0.5rem;
        }
        .custom-video-input.with-preview {
          width: 20%;
          height: 150px;
        }
        .custom-video-input span {
          flex-grow: 1;
          text-align: right;
        }
        .video-icon {
          width: 30px;
          height: 30px;
        }
        .video-preview {
          width: 80%;
          max-width: 200px;
          height: 150px;
          border: 1px solid #e3e3e3;
          border-radius: 10px;
        }
        .custom-video-input.with-preview .video-icon {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      `}</style>
    </div>
  );
};

export default CustomVideoInput;
