import parse from "html-react-parser";
import { MDBContainer, MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { TERM_OF_USE, TERM_OF_USE_ENG } from "../../libs/constants";
import { Layout } from "../../components";
import { useTranslation } from "react-i18next";

const TermOfUse = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const termContent = currentLanguage === "th" ? TERM_OF_USE : TERM_OF_USE_ENG;

  return (
    <Layout>
      <MDBContainer>
        <MDBCard className="my-4">
          <MDBCardBody>
            <h2 className="text-uppercase text-center">{t("terms_of_use")}</h2>
            <h2 className="text-uppercase text-center mb-5">
              {t("terms_of_use_notice")}
            </h2>
            {parse(termContent)}
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </Layout>
  );
};

export default TermOfUse;
