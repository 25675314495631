import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiHelper } from "../../libs/api";
import { DEFAULT_ERROR_MESSAGE } from "../../libs/constants";
import { ModalAlert, Layout } from "../../components";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBValidation,
  MDBInput,
  MDBIcon,
} from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";

const ChangePassword = () => {
  const { t } = useTranslation();
  const [formValue, setFormValue] = useState({
    password: "",
    repeatPassword: "",
  });
  const navigate = useNavigate();

  const [modalValue, setModalValue] = useState({
    modalTitle: "",
    modalMessage: "",
    showModal: false,
    onModalClose: () => {},
  });

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, repeatPassword } = formValue;
    if (password && repeatPassword) {
      if (password !== repeatPassword) {
        setModalValue({
          modalTitle: t("error_title"),
          modalMessage: t("password_mismatch_error"),
          showModal: true,
          onModalClose: () =>
            setModalValue({ ...modalValue, showModal: false }),
        });
      } else if (password.length < 6) {
        setModalValue({
          modalTitle: t("error_title"),
          modalMessage: t("password_too_short_error"),
          showModal: true,
          onModalClose: () =>
            setModalValue({ ...modalValue, showModal: false }),
        });
      } else {
        const { success } = await ApiHelper.updatePassword({
          password: password,
        });
        if (success) {
          setModalValue({
            modalTitle: t("success_title"),
            modalMessage: t("password_change_success"),
            showModal: true,
            onModalClose: () => navigate("/"),
          });
        } else {
          setModalValue({
            modalTitle: t("error_title"),
            modalMessage: DEFAULT_ERROR_MESSAGE,
            showModal: true,
            onModalClose: () =>
              setModalValue({ ...modalValue, showModal: false }),
          });
        }
      }
    }
  };

  return (
    <Layout>
      <MDBContainer className="d-flex align-items-center justify-content-center">
        <MDBCard className="m-5" style={{ maxWidth: "400px" }}>
          <MDBCardBody className="px-5">
            <div className="d-flex align-items-center mb-1">
              <MDBBtn
                onClick={() => navigate(-1)}
                tag="a"
                color="none"
                className="m-2"
              >
                <MDBIcon fas icon="chevron-left" size="lg" />
              </MDBBtn>
              <h2 className="text-uppercase">{t("change_password_title")}</h2>
            </div>
            <h6 className="text-uppercase mb-3">
              {t("change_password_instructions")}
            </h6>
            <img
              src="/lock-password.png"
              alt="info"
              style={{
                width: "100%",
                height: "100%",
              }}
            />

            <MDBValidation onSubmit={handleSubmit}>
              <MDBInput
                wrapperClass="mb-4"
                label={t("new_password_label")}
                size="lg"
                id="input-password"
                type="password"
                name="password"
                value={formValue.password}
                onChange={onChange}
                required
              />
              <MDBInput
                wrapperClass="mb-4"
                label={t("repeat_password_label")}
                size="lg"
                id="input-repeat-password"
                type="password"
                name="repeatPassword"
                value={formValue.repeatPassword}
                onChange={onChange}
                required
              />
              <MDBBtn
                type="submit"
                color="danger"
                className="mb-4 w-100"
                size="lg"
              >
                {t("change_password_button")}
              </MDBBtn>
            </MDBValidation>
          </MDBCardBody>
        </MDBCard>
        <ModalAlert
          show={modalValue.showModal}
          setShow={(show) => (show ? null : modalValue.onModalClose())}
          title={modalValue.modalTitle}
          onClose={modalValue.onModalClose}
        >
          {modalValue.modalMessage}
        </ModalAlert>
      </MDBContainer>
    </Layout>
  );
};

export default ChangePassword;
