import { NavLink } from "react-router-dom";
import {
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRipple,
} from "mdb-react-ui-kit";
import { GooglePlayStore, AppStore } from "./StoreButton";
import { GOOGLE_PLAY_URL, APP_STORE_URL } from "../libs/constants";
import { useTranslation } from "react-i18next";

const RiskAds = ({ isAuthenticated }) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="p-5 text-center bg-image"
        style={{
          backgroundImage: "url('/bg.svg')",
          height: "600px",
        }}
      >
        <div className="d-flex justify-content-center align-items-center h-100">
          <img
            className="img-fluid d-none d-lg-block"
            src="/phone.svg"
            alt={t("phone_image_alt")}
            style={{
              maxHeight: "450px",
            }}
          />
          <div className="col-lg-6 col-md-10 align-self-center">
            <div className="mb-1 pt-5">
              <h1
                className="text-start my-0"
                style={{
                  color: "#FFE15C",
                  maxWidth: "550px",
                  fontFamily: "inherit",
                }}
              >
                {t("safe_points")}
              </h1>
              <h1
                className="text-start text-white fs-4"
                style={{
                  maxWidth: "550px",
                }}
              >
                {t("crime_forecasting_system")}
              </h1>
              <h4
                className="text-start text-white fw-normal"
                style={{
                  fontSize: "16px",
                  maxWidth: "550px",
                }}
              >
                {t("safe_points_description")}
              </h4>
              {!isAuthenticated && (
                <div className="my-4 d-flex flex-row pb-2">
                  <NavLink to="/login">
                    <MDBBtn
                      className="rounded-6"
                      color="secondary"
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      {t("login_button")}
                    </MDBBtn>
                  </NavLink>
                  <NavLink to="/register">
                    <MDBBtn
                      className="mx-2 text-decoration-underline"
                      color="tertiary"
                      rippleColor="light"
                      style={{
                        color: "#FFFFFF",
                        fontSize: "16px",
                      }}
                    >
                      {t("register_button")}
                    </MDBBtn>
                  </NavLink>
                </div>
              )}
            </div>
            <div
              className="d-flex flex-row mt-4 mb-5"
              style={{ minWidth: "200px", width: "100%" }}
            >
              <MDBCol style={{ maxWidth: "150px" }}>
                <a href={GOOGLE_PLAY_URL} target="_blank" rel="noreferrer">
                  <GooglePlayStore />
                </a>
              </MDBCol>
              <MDBCol className="mx-1 px-1" style={{ maxWidth: "150px" }}>
                <a href={APP_STORE_URL} target="_blank" rel="noreferrer">
                  <AppStore />
                </a>
              </MDBCol>
            </div>
            <img
              className="img-fluid d-none d-lg-block ms-5 my-2"
              src="/notebook.svg"
              alt={t("notebook_image_alt")}
              style={{
                maxHeight: "200px",
              }}
            />
          </div>
        </div>
      </div>
      {isAuthenticated ? null : (
        <div>
          <div className="d-flex flex-wrap justify-content-evenly align-items-center my-4">
            <MDBCard
              className="mx-2 my-3 rounded-6 overflow-hidden"
              style={{
                width: "26rem",
              }}
            >
              <MDBRipple
                rippleColor="light"
                rippleTag="div"
                className="p-3 hover-overlay d-flex justify-content-center align-items-center"
                style={{ backgroundColor: "#B6D0E2" }}
              >
                <img
                  src="/feature1.svg"
                  alt={t("feature1_image_alt")}
                  className="mx-3"
                />
                <div
                  className="mask"
                  style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                />
                <MDBCardTitle
                  className="text-center"
                  style={{
                    color: "#547AC1",
                  }}
                >
                  {t("feature1_title")}
                </MDBCardTitle>
              </MDBRipple>
              <MDBCardBody>
                <MDBCardText className="text-center">
                  {t("feature1_description")}
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
            <MDBCard
              className="mx-2 my-3 rounded-6 overflow-hidden"
              style={{
                width: "26rem",
              }}
            >
              <MDBRipple
                rippleColor="light"
                rippleTag="div"
                className="p-3 hover-overlay d-flex justify-content-center align-items-center"
                style={{ backgroundColor: "#B6D0E2" }}
              >
                <img
                  src="/feature2.svg"
                  alt={t("feature2_image_alt")}
                  className="mx-3"
                />
                <div
                  className="mask"
                  style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                />
                <MDBCardTitle
                  className="text-center"
                  style={{
                    color: "#547AC1",
                  }}
                >
                  {t("feature2_title")}
                </MDBCardTitle>
              </MDBRipple>
              <MDBCardBody>
                <MDBCardText className="text-center">
                  {t("feature2_description")}
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
            <MDBCard
              className="mx-2 my-3 rounded-6 overflow-hidden"
              style={{
                width: "26rem",
              }}
            >
              <MDBRipple
                rippleColor="light"
                rippleTag="div"
                className="p-3 hover-overlay d-flex justify-content-center align-items-center"
                style={{ backgroundColor: "#B6D0E2" }}
              >
                <img src="/feature3.svg" alt={t("feature3_image_alt")} />
                <div
                  className="mask"
                  style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                />
                <MDBCardTitle
                  className="text-center"
                  style={{
                    color: "#547AC1",
                  }}
                >
                  {t("feature3_title")}
                </MDBCardTitle>
              </MDBRipple>
              <MDBCardBody>
                <MDBCardText className="text-center">
                  {t("feature3_description")}
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </div>
        </div>
      )}
    </>
  );
};

export default RiskAds;
