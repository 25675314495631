export const regionMapping = {
  ภาคตะวันตก: "West",
  ภาคเหนือ: "North",
  ภาคตะวันออกเฉียงเหนือ: "Northeast",
  ภาคใต้: "South",
  ภาคกลาง: "Central",
  ภาคตะวันออก: "East",
};

export const provinceMapping = {
  เพชรบุรี: "Phetchaburi",
  นครปฐม: "Nakhon Pathom",
  นครพนม: "Nakhon Phanom",
  นครราชสีมา: "Nakhon Ratchasima",
  ร้อยเอ็ด: "Roi Et",
  เชียงใหม่: "Chiang Mai",
  บึงกาฬ: "Bueng Kan",
  ยะลา: "Yala",
  พระนครศรีอยุธยา: "Phra Nakhon Si Ayutthaya",
  พะเยา: "Phayao",
  กาฬสินธุ์: "Kalasin",
  ปราจีนบุรี: "Prachin Buri",
  ขอนแก่น: "Khon Kaen",
  อุทัยธานี: "Uthai Thani",
  ชัยนาท: "Chai Nat",
  ปทุมธานี: "Pathum Thani",
  สุพรรณบุรี: "Suphan Buri",
  กระบี่: "Krabi",
  พิษณุโลก: "Phitsanulok",
  ลำพูน: "Lamphun",
  จันทบุรี: "Chanthaburi",
  สกลนคร: "Sakon Nakhon",
  สุราษฎร์ธานี: "Surat Thani",
  นครนายก: "Nakhon Nayok",
  ปัตตานี: "Pattani",
  นครสวรรค์: "Nakhon Sawan",
  ตราด: "Trat",
  กาญจนบุรี: "Kanchanaburi",
  ลพบุรี: "Lop Buri",
  หนองบัวลำภู: "Nong Bua Lam Phu",
  ฉะเชิงเทรา: "Chachoengsao",
  ชุมพร: "Chumphon",
  บุรีรัมย์: "Buri Ram",
  อุบลราชธานี: "Ubon Ratchathani",
  น่าน: "Nan",
  สุรินทร์: "Surin",
  สมุทรปราการ: "Samut Prakan",
  สระบุรี: "Saraburi",
  ระยอง: "Rayong",
  ลำปาง: "Lampang",
  เชียงราย: "Chiang Rai",
  ชัยภูมิ: "Chaiyaphum",
  เลย: "Loei",
  สุโขทัย: "Sukhothai",
  อ่างทอง: "Ang Thong",
  สตูล: "Satun",
  อุตรดิตถ์: "Uttaradit",
  ตาก: "Tak",
  สระแก้ว: "Sa Kaeo",
  สงขลา: "Songkhla",
  ประจวบคีรีขันธ์: "Prachuap Khiri Khan",
  กรุงเทพมหานคร: "Bangkok",
  กำแพงเพชร: "Kamphaeng Phet",
  อุดรธานี: "Udon Thani",
  อำนาจเจริญ: "Amnat Charoen",
  นครศรีธรรมราช: "Nakhon Si Thammarat",
  ชลบุรี: "Chon Buri",
  มหาสารคาม: "Maha Sarakham",
  ยโสธร: "Yasothon",
  นราธิวาส: "Narathiwat",
  หนองคาย: "Nong Khai",
  ตรัง: "Trang",
  ศรีสะเกษ: "Si Sa Ket",
  นนทบุรี: "Nonthaburi",
  สมุทรสงคราม: "Samut Songkhram",
  แม่ฮ่องสอน: "Mae Hong Son",
  มุกดาหาร: "Mukdahan",
  สิงห์บุรี: "Sing Buri",
  เพชรบูรณ์: "Phetchabun",
  พังงา: "Phangnga",
  ระนอง: "Ranong",
  พิจิตร: "Phichit",
  ราชบุรี: "Ratchaburi",
  พัทลุง: "Phatthalung",
  ภูเก็ต: "Phuket",
  แพร่: "Phrae",
  สมุทรสาคร: "Samut Sakhon",
  สระบุรีก: "Saraburi",
};

export const districtMapping = {
  แก่งกระจาน: "Kaeng Krachan",
  นครชัยศรี: "Nakhon Chai Si",
  นาแก: "Na Kae",
  ครบุรี: "Khon Buri",
  เมืองร้อยเอ็ด: "Mueang Roi Et",
  ฝาง: "Fang",
  โซ่พิสัย: "So Phisai",
  กาบัง: "Kabang",
  นครหลวง: "Nakhon Luang",
  ดอกคำใต้: "Dok Khamtai",
  ห้วยเม็ก: "Huai Mek",
  ประจันตคาม: "Prachantakham",
  ชุมแพ: "Chum Phae",
  บ้านฝาง: "Ban Fang",
  ทัพทัน: "Thap Than",
  เนินขาม: "Noen Kham",
  เซกา: "Seka",
  ลาดหลุมแก้ว: "Lat Lum Kaeo",
  เดิมบางนางบวช: "Doem Bang Nang Buat",
  เมืองกระบี่: "Mueang Krabi",
  หนองหญ้าไซ: "Nong Ya Sai",
  วัดโบสถ์: "Wat Bot",
  ลี้: "Li",
  โป่งน้ำร้อน: "Pong Nam Ron",
  กุสุมาลย์: "Kusuman",
  ท่าคันโท: "Tha Khantho",
  ไชยา: "Chaiya",
  ปากพลี: "Pak Phli",
  ยะหริ่ง: "Yaring",
  ชุมตาบง: "Chum Ta Bong",
  เขาย้อย: "Khao Yoi",
  คลองใหญ่: "Khlong Yai",
  ท่าม่วง: "Tha Muang",
  เมืองลพบุรี: "Mueang Lop Buri",
  ไม้แก่น: "Mai Kaen",
  เหนือคลอง: "Nuea Khlong",
  นาวัง: "Na Wang",
  ยะหา: "Yaha",
  เมืองฉะเชิงเทรา: "Mueang Chachoengsao",
  โพนทอง: "Phon Thong",
  หลังสวน: "Lang Suan",
  บ้านกรวด: "Ban Kruat",
  เมืองอุบลราชธานี: "Mueang Ubon Ratchathani",
  กุดบาก: "Kut Bak",
  ท่าวังผา: "Tha Wang Pha",
  ปราสาท: "Prasat",
  ศรีสงคราม: "Si Songkhram",
  หนองกี่: "Nong Ki",
  เมืองสมุทรปราการ: "Mueang Samut Prakan",
  แก่งคอย: "Kaeng Khoi",
  บ้านสร้าง: "Ban Sang",
  บ้านหมี่: "Ban Mi",
  ธารโต: "Than To",
  กุฉินารายณ์: "Kuchinarai",
  แกลง: "Klaeng",
  แจ้ห่ม: "Chae Hom",
  แม่สาย: "Mae Sai",
  หนองบัวแดง: "Nong Bua Daeng",
  ภูเรือ: "Phu Ruea",
  จตุรพักตรพิมาน: "Chaturaphak Phiman",
  สวรรคโลก: "Sawankhalok",
  เมืองอ่างทอง: "Mueang Ang Thong",
  ควนกาหลง: "Khuan Kalong",
  รัตนบุรี: "Rattanaburi",
  พิชัย: "Phichai",
  บ้านตาก: "Ban Tak",
  ประโคนชัย: "Prakhon Chai",
  เมืองขอนแก่น: "Mueang Khon Kaen",
  โนนดินแดง: "Non Din Daeng",
  เมืองนครพนม: "Mueang Nakhon Phanom",
  เมืองเพชรบุรี: "Mueang Phetchaburi",
  โคกสูง: "Khok Sung",
  เดชอุดม: "Det Udom",
  พยุหะคีรี: "Phayuha Khiri",
  เนินสง่า: "Noen Sa-nga",
  สามโคก: "Sam Khok",
  สันป่าตอง: "San Pa Tong",
  ปักธงชัย: "Pak Thong Chai",
  โนนไทย: "Non Thai",
  จะนะ: "Chana",
  ปากชม: "Pak Chom",
  พระพุทธบาท: "Phra Phutthabat",
  แม่แตง: "Mae Taeng",
  บางสะพานน้อย: "Bang Saphan Noi",
  กระสัง: "Krasang",
  บางไทร: "Bang Sai",
  บางปลาม้า: "Bang Pla Ma",
  พรเจริญ: "Phon Charoen",
  พระนคร: "Phra Nakhon",
  ท่าลี่: "Tha Li",
  ภาษีเจริญ: "Phasi Charoen",
  สะเมิง: "Samoeng",
  คลองขลุง: "Khlong Khlung",
  บัวลาย: "Bua Lai",
  งาว: "Ngao",
  นาหม่อม: "Na Mom",
  โขงเจียม: "Khong Chiam",
  นางรอง: "Nang Rong",
  วังสามหมอ: "Wang Sam Mo",
  เมืองอำนาจเจริญ: "Mueang Amnat Charoen",
  วิหารแดง: "Wihan Daeng",
  ทุ่งใหญ่: "Thung Yai",
  เรณูนคร: "Renu Nakhon",
  ขามสะแกแสง: "Kham Sakaesaeng",
  เขื่องใน: "Khueang Nai",
  บ้านบึง: "Ban Bueng",
  แก้งคร้อ: "Kaeng Khro",
  ท่าวุ้ง: "Tha Wung",
  โกสุมพิสัย: "Kosum Phisai",
  นาทวี: "Na Thawi",
  สามพราน: "Sam Phran",
  ท่าใหม่: "Tha Mai",
  เสนา: "Sena",
  หนองนาคำ: "Nong Na Kham",
  กุดชุม: "Kut Chum",
  เมืองประจวบคีรีขันธ์: "Mueang Prachuap Khiri Khan",
  ยี่งอ: "Yi-ngo",
  ทุ่งยางแดง: "Thung Yang Daeng",
  พนมดงรัก: "Phanom Dong Rak",
  แม่ออน: "Mae On",
  ศรีเชียงใหม่: "Si Chiang Mai",
  ห้วยยอด: "Huai Yot",
  คลองหาด: "Khlong Hat",
  ยางชุมน้อย: "Yang Chum Noi",
  กาญจนดิษฐ์: "Kanchanadit",
  ค้อวัง: "Kho Wang",
  ปทุมราชวงศา: "Pathum Ratchawongsa",
  ปะนาเระ: "Panare",
  อุบลรัตน์: "Ubolratana",
  เมืองสุพรรณบุรี: "Mueang Suphan Buri",
  บางปะหัน: "Bang Pahan",
  สิชล: "Sichon",
  ลำปลายมาศ: "Lam Plai Mat",
  เชียงยืน: "Chiang Yuen",
  อุทัย: "Uthai",
  ปากเกร็ด: "Pak Kret",
  ชัยบาดาล: "Chai Badan",
  เมืองหนองคาย: "Mueang Nong Khai",
  คลองท่อม: "Khlong Thom",
  บ่อเกลือ: "Bo Kluea",
  ลาดพร้าว: "Lat Phrao",
  เมืองสมุทรสงคราม: "Mueang Samut Songkhram",
  นาโยง: "Na Yong",
  โชคชัย: "Chok Chai",
  บางละมุง: "Bang Lamung",
  ส่องดาว: "Song Dao",
  เมืองสุราษฎร์ธานี: "Mueang Surat Thani",
  รือเสาะ: "Rueso",
  เมืองยโสธร: "Mueang Yasothon",
  ท่ามะกา: "Tha Maka",
  สันทราย: "San Sai",
  บางรัก: "Bang Rak",
  เขมราฐ: "Khemarat",
  เมืองตาก: "Mueang Tak",
  หนองพอก: "Nong Phok",
  กงไกรลาศ: "Kong Krailat",
  กระนวน: "Kranuan",
  คำเขื่อนแก้ว: "Kham Khuean Kaeo",
  แม่เมาะ: "Mae Mo",
  เสาไห้: "Sao Hai",
  ห้วยขวาง: "Huai Khwang",
  เมืองอุดรธานี: "Mueang Udon Thani",
  ขุนยวม: "Khun Yuam",
  หนองจิก: "Nong Chik",
  เมืองมุกดาหาร: "Mueang Mukdahan",
  คลองหลวง: "Khlong Luang",
  ดอยเต่า: "Doi Tao",
  เมืองเชียงใหม่: "Mueang Chiang Mai",
  บ้านผือ: "Ban Phue",
  เมืองชลบุรี: "Mueang Chon Buri",
  ดงหลวง: "Dong Luang",
  เมืองนครสวรรค์: "Mueang Nakhon Sawan",
  สุวรรณภูมิ: "Suwannaphum",
  พนมไพร: "Phanom Phrai",
  วาปีปทุม: "Wapi Pathum",
  วานรนิวาส: "Wanon Niwat",
  หนองหิน: "Nong Hin",
  พระนครศรีอยุธยา: "Phra Nakhon Si Ayutthaya",
  อุทุมพรพิสัย: "Uthumphon Phisai",
  วาริชภูมิ: "Waritchaphum",
  เฉลิมพระเกียรติ: "Chaloem Phra Kiat",
  นาดูน: "Na Dun",
  โนนสุวรรณ: "Non Suwan",
  กันทรารมย์: "Kanthararom",
  บ้านค่าย: "Ban Khai",
  บางกล่ำ: "Bang Klam",
  เมืองตราด: "Mueang Trat",
  ปลวกแดง: "Pluak Daeng",
  ภูผาม่าน: "Phu Pha Man",
  หางดง: "Hang Dong",
  อู่ทอง: "U Thong",
  แม่จัน: "Mae Chan",
  บางระจัน: "Bang Rachan",
  หล่มสัก: "Lom Sak",
  เชียงดาว: "Chiang Dao",
  ขุขันธ์: "Khukhan",
  เมืองพิษณุโลก: "Mueang Phitsanulok",
  ท้ายเหมือง: "Thai Mueang",
  บ้านแหลม: "Ban Laem",
  มายอ: "Mayo",
  เวียงป่าเป้า: "Wiang Pa Pao",
  ละแม: "Lamae",
  ปัว: "Pua",
  เมืองพะเยา: "Mueang Phayao",
  บุณฑริก: "Buntharik",
  สุขสำราญ: "Suk Samran",
  เมืองนครศรีธรรมราช: "Mueang Nakhon Si Thammarat",
  แม่ทะ: "Mae Tha",
  กำแพงแสน: "Kamphaeng Saen",
  ทับคล้อ: "Thap Khlo",
  ลาดกระบัง: "Lat Krabang",
  สำโรงทาบ: "Samrong Thap",
  หนองวัวซอ: "Nong Wua So",
  ผักไห่: "Phak Hai",
  เมืองสิงห์บุรี: "Mueang Sing Buri",
  บ้านคา: "Ban Kha",
  ชาติตระการ: "Chat Trakan",
  ชุมแสง: "Chum Saeng",
  เลิงนกทา: "Loeng Nok Tha",
  ภักดีชุมพล: "Phakdi Chumphon",
  วังเหนือ: "Wang Nuea",
  ดำเนินสะดวก: "Damnoen Saduak",
  พนัสนิคม: "Phanat Nikhom",
  นากลาง: "Na Klang",
  นาด้วง: "Na Duang",
  สามเงา: "Sam Ngao",
  ฮอด: "Hot",
  ขุนหาญ: "Khun Han",
  วิเศษชัยชาญ: "Wiset Chai Chan",
  บ่อพลอย: "Bo Phloi",
  พิมาย: "Phimai",
  ศรีมหาโพธิ: "Si Maha Phot",
  พญาเม็งราย: "Phaya Mengrai",
  บางเสาธง: "Bang Sao Thong",
  เมืองน่าน: "Mueang Nan",
  ปากคาด: "Pak Khat",
  เมืองลำปาง: "Mueang Lampang",
  ปรางค์กู่: "Prang Ku",
  ดอนสัก: "Don Sak",
  เวียงชัย: "Wiang Chai",
  ห้วยผึ้ง: "Huai Phueng",
  รามัน: "Raman",
  ศรีวิไล: "Si Wilai",
  สัตหีบ: "Sattahip",
  บ่อไร่: "Bo Rai",
  ไทรงาม: "Sai Ngam",
  ตากใบ: "Tak Bai",
  เมืองมหาสารคาม: "Mueang Maha Sarakham",
  เมืองสตูล: "Mueang Satun",
  โกรกพระ: "Krok Phra",
  น้ำหนาว: "Nam Nao",
  เมืองหนองบัวลำภู: "Mueang Nong Bua Lam Phu",
  จักราช: "Chakkarat",
  ธัญบุรี: "Thanyaburi",
  แม่อาย: "Mae Ai",
  สัมพันธวงศ์: "Samphanthawong",
  แม่ฟ้าหลวง: "Mae Fa Luang",
  แม่วงก์: "Mae Wong",
  ลับแล: "Laplae",
  หนองโดน: "Nong Don",
  เมืองปทุมธานี: "Mueang Pathum Thani",
  มโนรมย์: "Manorom",
  แวงใหญ่: "Waeng Yai",
  บ้านแฮด: "Ban Haet",
  สบเมย: "Sop Moei",
  เกษตรวิสัย: "Kaset Wisai",
  เมืองนครนายก: "Mueang Nakhon Nayok",
  กุดจับ: "Kut Chap",
  ชื่นชม: "Chuen Chom",
  พรหมพิราม: "Phrom Phiram",
  แสวงหา: "Sawaeng Ha",
  ตะพานหิน: "Taphan Hin",
  หนองไผ่: "Nong Phai",
  พาน: "Phan",
  ดอนมดแดง: "Don Mot Daeng",
  ป่าบอน: "Pa Bon",
  ถลาง: "Thalang",
  อาจสามารถ: "At Samat",
  ตาคลี: "Takhli",
  ละงู: "La-ngu",
  สำโรง: "Samrong",
  เวียงสา: "Wiang Sa",
  หัวไทร: "Hua Sai",
  ตะกั่วทุ่ง: "Takua Thung",
  ท่าปลา: "Tha Pla",
  พิบูลมังสาหาร: "Phibun Mangsahan",
  หนองบุญมาก: "Nong Bunmak",
  พยัคฆภูมิพิสัย: "Phayakkhaphum Phisai",
  จัตุรัส: "Chatturat",
  กระแสสินธุ์: "Krasae Sin",
  เมืองนครราชสีมา: "Mueang Nakhon Ratchasima",
  เสนางคนิคม: "Senangkhanikhom",
  ชุมพวง: "Chum Phuang",
  เวียงแก่น: "Wiang Kaen",
  ห้วยกระเจา: "Huai Krachao",
  ฆ้องชัย: "Khong Chai",
  ห้วยแถลง: "Huai Thalaeng",
  ภูสิงห์: "Phu Sing",
  กันตัง: "Kantang",
  สุไหงปาดี: "Su-ngai Padi",
  ท่าแพ: "Tha Phae",
  โนนสูง: "Non Sung",
  ปากช่อง: "Pak Chong",
  เมืองศรีสะเกษ: "Mueang Si Sa Ket",
  ศีขรภูมิ: "Sikhoraphum",
  ท่าฉาง: "Tha Chang",
  เมืองระนอง: "Mueang Ranong",
  คลองหอยโข่ง: "Khlong Hoi Khong",
  วัดเพลง: "Wat Phleng",
  บึงกุ่ม: "Bueng Kum",
  แม่ทา: "Mae Tha",
  ศิลาลาด: "Sila Lat",
  เด่นชัย: "Den Chai",
  แม่ระมาด: "Mae Ramat",
  สรรพยา: "Sapphaya",
  ร้องกวาง: "Rong Kwang",
  บ้านไร่: "Ban Rai",
  ลานกระบือ: "Lan Krabue",
  หนองกุงศรี: "Nong Kung Si",
  หนองเรือ: "Nong Ruea",
  ดอยหลวง: "Doi Luang",
  โคกสำโรง: "Khok Samrong",
  เวียงหนองล่อง: "Wiang Nong Long",
  ศรีประจันต์: "Si Prachan",
  เมืองราชบุรี: "Mueang Ratchaburi",
  ยางตลาด: "Yang Talat",
  ไทรโยค: "Sai Yok",
  บำเหน็จณรงค์: "Bamnet Narong",
  ศรีสำโรง: "Si Samrong",
  วารินชำราบ: "Warin Chamrap",
  แม่สอด: "Mae Sot",
  เมืองสมุทรสาคร: "Mueang Samut Sakhon",
  สระโบสถ์: "Sa Bot",
  เมืองบุรีรัมย์: "Mueang Buri Ram",
  เบญจลักษ์: "Benchalak",
  ปราณบุรี: "Pran Buri",
  เกษตรสมบูรณ์: "Kaset Sombun",
  เมืองเชียงราย: "Mueang Chiang Rai",
  อมก๋อย: "Omkoi",
  ลอง: "Long",
  จอมทอง: "Chom Thong",
  บางกอกน้อย: "Bangkok Noi",
  นาบอน: "Na Bon",
  อัมพวา: "Amphawa",
  ชะอวด: "Cha-uat",
  คำม่วง: "Kham Muang",
  ราษีไศล: "Rasi Salai",
  สรรคบุรี: "Sankhaburi",
  คลองเตย: "Khlong Toei",
  ด่านช้าง: "Dan Chang",
  จอมพระ: "Chom Phra",
  คำตากล้า: "Kham Ta Kla",
  วังชิ้น: "Wang Chin",
  ท่าช้าง: "Tha Chang",
  สนม: "Sanom",
  เชียงของ: "Chiang Khong",
  เจาะไอร้อง: "Cho-airong",
  เมืองสุรินทร์: "Mueang Surin",
  ขลุง: "Khlung",
  ป่าโมก: "Pa Mok",
  เมืองเพชรบูรณ์: "Mueang Phetchabun",
  ควนเนียง: "Khuan Niang",
  คูเมือง: "Khu Mueang",
  หัวตะพาน: "Hua Taphan",
  เมืองกำแพงเพชร: "Mueang Kamphaeng Phet",
  เกาะพะงัน: "Ko Pha-ngan",
  ระโนด: "Ranot",
  โพธิ์ทอง: "Pho Thong",
  เมืองบึงกาฬ: "Mueang Bueng Kan",
  ด่านขุนทด: "Dan Khun Thot",
  พระประแดง: "Phra Pradaeng",
  ยางสีสุราช: "Yang Si Surat",
  จังหาร: "Changhan",
  ภูเขียว: "Phu Khiao",
  เทพา: "Thepha",
  สวี: "Sawi",
  พรรณานิคม: "Phanna Nikhom",
  บ้านดุง: "Ban Dung",
  บางบาล: "Bang Ban",
  ดอนตูม: "Don Tum",
  สร้างคอม: "Sang Khom",
  สอง: "Song",
  เขาสมิง: "Khao Saming",
  เมืองจันทบุรี: "Mueang Chanthaburi",
  เหล่าเสือโก้ก: "Lao Suea Kok",
  พนมสารคาม: "Phanom Sarakham",
  บุ่งคล้า: "Bung Khla",
  ขาณุวรลักษบุรี: "Khanu Woralaksaburi",
  กบินทร์บุรี: "Kabin Buri",
  แหลมสิงห์: "Laem Sing",
  แว้ง: "Waeng",
  สันติสุข: "Santi Suk",
  เมืองนราธิวาส: "Mueang Narathiwat",
  หาดสำราญ: "Hat Samran",
  บึงโขงหลง: "Bueng Khong Long",
  พล: "Phon",
  บางปะอิน: "Bang Pa-in",
  พุทธมณฑล: "Phutthamonthon",
  ศรีราชา: "Si Racha",
  ชนแดน: "Chon Daen",
  เมืองพังงา: "Mueang Phang-nga",
  สะบ้าย้อย: "Saba Yoi",
  เสลภูมิ: "Selaphum",
  รัตนวาปี: "Rattanawapi",
  พนม: "Phanom",
  หนองสองห้อง: "Nong Song Hong",
  โนนคูณ: "Non Khun",
  สุคิริน: "Sukhirin",
  น้ำยืน: "Nam Yuen",
  ลืออำนาจ: "Lue Amnat",
  พลับพลาชัย: "Phlapphla Chai",
  นาหว้า: "Na Wa",
  ชนบท: "Chonnabot",
  ท่าเรือ: "Tha Ruea",
  ปทุมรัตต์: "Pathum Rat",
  แม่วาง: "Mae Wang",
  เมืองพิจิตร: "Mueang Phichit",
  จุน: "Chun",
  เชียงคาน: "Chiang Khan",
  ไชยปราการ: "Chai Prakan",
  สายไหม: "Sai Mai",
  อุ้มผาง: "Umphang",
  จะแนะ: "Chanae",
  สูงเนิน: "Sung Noen",
  เกาะยาว: "Ko Yao",
  เถิน: "Thoen",
  สังขะ: "Sangkha",
  หนองจอก: "Nong Chok",
  กันทรลักษ์: "Kantharalak",
  สันกำแพง: "San Kamphaeng",
  บ้านแพรก: "Ban Phraek",
  โคกเจริญ: "Khok Charoen",
  บรบือ: "Borabue",
  ท่าแซะ: "Tha Sae",
  เก้าเลี้ยว: "Kao Liao",
  กะทู้: "Kathu",
  แหลมงอบ: "Laem Ngop",
  แม่ลาน: "Mae Lan",
  วังทอง: "Wang Thong",
  ไชยวาน: "Chai Wan",
  ภูเวียง: "Phu Wiang",
  โนนนารายณ์: "Non Narai",
  เชียงขวัญ: "Chiang Khwan",
  สทิงพระ: "Sathing Phra",
  ศรีธาตุ: "Si That",
  เมืองสงขลา: "Mueang Songkhla",
  มะขาม: "Makham",
  เมืองนนทบุรี: "Mueang Nonthaburi",
  บ้านโพธิ์: "Ban Pho",
  ทุ่งเสลี่ยม: "Thung Saliam",
  หล่มเก่า: "Lom Kao",
  ยะรัง: "Yarang",
  โพธิ์ชัย: "Pho Chai",
  แกดำ: "Kae Dam",
  ราชสาส์น: "Ratchasan",
  ชานุมาน: "Chanuman",
  ท่าหลวง: "Tha Luang",
  ดอยสะเก็ด: "Doi Saket",
  โพธิ์ตาก: "Pho Tak",
  เขาสวนกวาง: "Khao Suan Kwang",
  ลาดบัวหลวง: "Lat Bua Luang",
  เมืองยะลา: "Mueang Yala",
  เต่างอย: "Tao Ngoi",
  ปะเหลียน: "Palian",
  ท่ายาง: "Tha Yang",
  เปือยน้อย: "Pueai Noi",
  ประทาย: "Prathai",
  หนองขาหย่าง: "Nong Khayang",
  สีดา: "Sida",
  ปางมะผ้า: "Pang Mapha",
  เนินมะปราง: "Noen Maprang",
  เทิง: "Thoeng",
  พรหมบุรี: "Phrom Buri",
  ชะอำ: "Cha-am",
  เมืองลำพูน: "Mueang Lamphun",
  อินทร์บุรี: "In Buri",
  ลำทะเมนชัย: "Lam Thamenchai",
  บางคนที: "Bang Khonthi",
  เมืองสกลนคร: "Mueang Sakon Nakhon",
  บางน้ำเปรี้ยว: "Bang Nam Priao",
  แม่พริก: "Mae Phrik",
  สิเกา: "Sikao",
  หนองแค: "Nong Khae",
  คำชะอี: "Khamcha-i",
  ท่าตะโก: "Tha Tako",
  เพ็ญ: "Phen",
  ปากท่อ: "Pak Tho",
  บางสะพาน: "Bang Saphan",
  คง: "Khong",
  เชียงแสน: "Chiang Saen",
  นาน้อย: "Na Noi",
  วิภาวดี: "Vibhavadi",
  ละอุ่น: "La-un",
  หนองใหญ่: "Nong Yai",
  ทุ่งช้าง: "Thung Chang",
  แม่จริม: "Mae Charim",
  โพธิ์ประทับช้าง: "Pho Prathap Chang",
  เสิงสาง: "Soeng Sang",
  แม่ริม: "Mae Rim",
  เจริญศิลป์: "Charoen Sin",
  เขาคิชฌกูฏ: "Khao Khitchakut",
  นาจะหลวย: "Na Chaluai",
  ทองแสนขัน: "Thong Saen Khan",
  นาทม: "Na Thom",
  แม่ลาว: "Mae Lao",
  แม่สรวย: "Mae Suai",
  มหาราช: "Maha Rat",
  ศรีเทพ: "Si Thep",
  ทองผาภูมิ: "Thong Pha Phum",
  สิงหนคร: "Singhanakhon",
  เมืองสระแก้ว: "Mueang Sa Kaeo",
  ร่อนพิบูลย์: "Ron Phibun",
  หาดใหญ่: "Hat Yai",
  เมืองนครปฐม: "Mueang Nakhon Pathom",
  ป่าติ้ว: "Pa Tio",
  สว่างวีระวงศ์: "Sawang Wirawong",
  ถ้ำพรรณรา: "Tham Phannara",
  บ้านด่านลานหอย: "Ban Dan Lan Hoi",
  ปากพะยูน: "Pak Phayun",
  หนองหาน: "Nong Han",
  บ้านเหลื่อม: "Ban Lueam",
  แม่สะเรียง: "Mae Sariang",
  บางเลน: "Bang Len",
  สมเด็จ: "Somdet",
  บ้านแท่น: "Ban Thaen",
  เมืองแพร่: "Mueang Phrae",
  ธาตุพนม: "That Phanom",
  ประเวศ: "Prawet",
  พัฒนานิคม: "Phatthana Nikhom",
  ขนอม: "Khanom",
  ไทรน้อย: "Sai Noi",
  ตรอน: "Tron",
  อ่าวลึก: "Ao Luek",
  คอนสวรรค์: "Khon Sawan",
  บ้านเขว้า: "Ban Khwao",
  พระแสง: "Phrasaeng",
  สุวรรณคูหา: "Suwannakhuha",
  เวียงเชียงรุ้ง: "Wiang Chiang Rung",
  วังสะพุง: "Wang Saphung",
  สีชมพู: "Si Chomphu",
  ละหานทราย: "Lahan Sai",
  กาบเชิง: "Kap Choeng",
  กะเปอร์: "Kapoe",
  ฉวาง: "Chawang",
  คลองเขื่อน: "Khlong Khuean",
  ควนขนุน: "Khuan Khanun",
  พระโขนง: "Phra Khanong",
  กุมภวาปี: "Kumphawapi",
  ทุ่งสง: "Thung Song",
  แก่งหางแมว: "Kaeng Hang Maeo",
  เทพารักษ์: "Thepharak",
  ลาดยาว: "Lat Yao",
  เมืองเลย: "Mueang Loei",
  นิคมคำสร้อย: "Nikhom Kham Soi",
  สากเหล็ก: "Sak Lek",
  โนนสะอาด: "Non Sa-at",
  ดอยหล่อ: "Doi Lo",
  บันนังสตา: "Bannang Sata",
  ปากพนัง: "Pak Phanang",
  เมืองตรัง: "Mueang Trang",
  ธวัชบุรี: "Thawat Buri",
  วังน้อย: "Wang Noi",
  ห้างฉัตร: "Hang Chat",
  เมืองพัทลุง: "Mueang Phatthalung",
  เมืองกาญจนบุรี: "Mueang Kanchanaburi",
  แม่ลาน้อย: "Mae La Noi",
  โพนสวรรค์: "Phon Sawan",
  หนองเสือ: "Nong Suea",
  บ้านนาสาร: "Ban Na San",
  หนองมะโมง: "Nong Mamong",
  "สุไหงโก-ลก": "Su-ngai Kolok",
  เมืองภูเก็ต: "Mueang Phuket",
  ตะโหมด: "Tamot",
  ป่าซาง: "Pa Sang",
  อรัญประเทศ: "Aranyaprathet",
  พนา: "Phana",
  บางบ่อ: "Bang Bo",
  วังยาง: "Wang Yang",
  คอนสาร: "Khon San",
  พุนพิน: "Phunphin",
  ดอนจาน: "Don Chan",
  ภาชี: "Phachi",
  เมืองชัยภูมิ: "Mueang Chaiyaphum",
  สีคิ้ว: "Sikhio",
  หนองฉาง: "Nong Chang",
  สูงเม่น: "Sung Men",
  มหาชนะชัย: "Maha Chana Chai",
  ดอนพุด: "Don Phut",
  บาเจาะ: "Bacho",
  กรงปินัง: "Krong Pinang",
  ห้วยราช: "Huai Rat",
  สาทร: "Sathon",
  สามชุก: "Sam Chuk",
  บางระกำ: "Bang Rakam",
  น้ำขุ่น: "Nam Khun",
  บ้านโฮ่ง: "Ban Hong",
  ศรีรัตนะ: "Si Rattana",
  สองพี่น้อง: "Song Phi Nong",
  หนองหญ้าปล้อง: "Nong Ya Plong",
  ท่าอุเทน: "Tha Uthen",
  เชียงกลาง: "Chiang Klang",
  วังจันทร์: "Wang Chan",
  คลองสาน: "Khlong San",
  กระบุรี: "Kra Buri",
  เมืองอุตรดิตถ์: "Mueang Uttaradit",
  ป้อมปราบศัตรูพ่าย: "Pom Prap Sattru Phai",
  โพนพิสัย: "Phon Phisai",
  ศรีนครินทร์: "Srinagarindra",
  ลำดวน: "Lamduan",
  พยุห์: "Phayu",
  ศรีสมเด็จ: "Si Somdet",
  บ้านลาด: "Ban Lat",
  กะปง: "Kapong",
  บางบัวทอง: "Bang Bua Thong",
  บ้านไผ่: "Ban Phai",
  บ้านฉาง: "Ban Chang",
  วัฒนา: "Vadhana",
  เมืองปัตตานี: "Mueang Pattani",
  หนองแซง: "Nong Saeng",
  พรานกระต่าย: "Phran Kratai",
  หนองหงส์: "Nong Hong",
  เกาะคา: "Ko Kha",
  ดุสิต: "Dusit",
  สิรินธร: "Sirindhorn",
  โพทะเล: "Pho Thale",
  ซำสูง: "Sam Sung",
  สามชัย: "Sam Chai",
  เชียรใหญ่: "Chian Yai",
  สว่างแดนดิน: "Sawang Daen Din",
  ด่านซ้าย: "Dan Sai",
  คีรีมาศ: "Khiri Mat",
  วังโป่ง: "Wang Pong",
  เมืองยาง: "Mueang Yang",
  ท่าบ่อ: "Tha Bo",
  ระแงะ: "Ra-ngae",
  นิคมน้ำอูน: "Nikhom Nam Un",
  เมืองปราจีนบุรี: "Mueang Prachin Buri",
  ศรีบรรพต: "Si Banphot",
  หนองม่วง: "Nong Muang",
  บ้านแพ้ว: "Ban Phaeo",
  ลานสัก: "Lan Sak",
  วังม่วง: "Wang Muang",
  นายายอาม: "Na Yai Am",
  ปง: "Pong",
  สารภี: "Saraphi",
  โพนนาแก้ว: "Phon Na Kaeo",
  บ้านตาขุน: "Ban Ta Khun",
  บางคอแหลม: "Bang Kho Laem",
  ตะกั่วป่า: "Takua Pa",
  จตุจักร: "Chatuchak",
  กันทรวิชัย: "Kantharawichai",
  เมืองกาฬสินธุ์: "Mueang Kalasin",
  บางกะปิ: "Bang Kapi",
  น้ำโสม: "Nam Som",
  บางกระทุ่ม: "Bang Krathum",
  โนนสัง: "Non Sang",
  เขาค้อ: "Khao Kho",
  โกสัมพีนคร: "Kosamphi Nakhon",
  กงหรา: "Kong Ra",
  กระทุ่มแบน: "Krathum Baen",
  ไชโย: "Chaiyo",
  บางมูลนาก: "Bang Mun Nak",
  เลาขวัญ: "Lao Khwan",
  สายบุรี: "Sai Buri",
  ป่าพะยอม: "Pa Phayom",
  วัดสิงห์: "Wat Sing",
  อากาศอำนวย: "Akat Amnuai",
  หนองบัว: "Nong Bua",
  ศรีบุญเรือง: "Si Bun Rueang",
  บ้านนา: "Ban Na",
  น้ำปาด: "Nam Pat",
  ลำสนธิ: "Lam Sonthi",
  นาเชือก: "Na Chueak",
  ทุ่งฝน: "Thung Fon",
  ห้วยทับทัน: "Huai Thap Than",
  โพธาราม: "Photharam",
  ศรีมโหสถ: "Si Mahosot",
  ปะทิว: "Pathio",
  คุระบุรี: "Khura Buri",
  สามร้อยยอด: "Sam Roi Yot",
  เกาะช้าง: "Ko Chang",
  บ้านม่วง: "Ban Muang",
  สะเดา: "Sadao",
  ทับปุด: "Thap Put",
  เมืองอุทัยธานี: "Mueang Uthai Thani",
  พุทไธสง: "Phutthaisong",
  บึงสามพัน: "Bueng Sam Phan",
  วัฒนานคร: "Watthana Nakhon",
  บางกรวย: "Bang Kruai",
  บางซื่อ: "Bang Sue",
  ศรีสัชนาลัย: "Si Satchanalai",
  ศรีนคร: "Si Nakhon",
  บางบอน: "Bang Bon",
  เกาะสมุย: "Ko Samui",
  รัษฎา: "Ratsada",
  พระพรหม: "Phra Phrom",
  เมืองชุมพร: "Mueang Chumphon",
  กมลาไสย: "Kamalasai",
  บ้านหมอ: "Ban Mo",
  ป่าแดด: "Pa Daet",
  คีรีรัฐนิคม: "Khiri Rat Nikhom",
  มวกเหล็ก: "Muak Lek",
  ปาย: "Pai",
  เชียงคำ: "Chiang Kham",
  เขวาสินรินทร์: "Khwao Sinrin",
  ภูเพียง: "Phu Phiang",
  ม่วงสามสิบ: "Muang Sam Sip",
  เมืองสระบุรี: "Mueang Saraburi",
  บางปะกง: "Bang Pakong",
  หนองสูง: "Nong Sung",
  วังน้ำเขียว: "Wang Nam Khiao",
  วังทองหลาง: "Wang Thonglang",
  เขาพนม: "Khao Phanom",
  เมยวดี: "Moei Wadi",
  ตลิ่งชัน: "Taling Chan",
  สหัสขันธ์: "Sahatsakhan",
  บัวใหญ่: "Bua Yai",
  มัญจาคีรี: "Mancha Khiri",
  ดงเจริญ: "Dong Charoen",
  บ้านแพง: "Ban Phaeng",
  ตากฟ้า: "Tak Fa",
  วิเชียรบุรี: "Wichian Buri",
  พระสมุทรเจดีย์: "Phra Samut Chedi",
  ตระการพืชผล: "Trakan Phuet Phon",
  ทุ่งหัวช้าง: "Thung Hua Chang",
  กุยบุรี: "Kui Buri",
  ไพศาลี: "Phaisali",
  บางขัน: "Bang Khan",
  ศรีเมืองใหม่: "Si Mueang Mai",
  บางซ้าย: "Bang Sai",
  เกาะลันตา: "Ko Lanta",
  ท่าศาลา: "Tha Sala",
  ทรายมูล: "Sai Mun",
  นบพิตำ: "Nopphitam",
  วังน้ำเย็น: "Wang Nam Yen",
  ดอนเจดีย์: "Don Chedi",
  องครักษ์: "Ongkharak",
  วังวิเศษ: "Wang Wiset",
  คลองสามวา: "Khlong Sam Wa",
  ศรีสาคร: "Si Sakhon",
  เมืองสุโขทัย: "Mueang Sukhothai",
  ท่าตะเกียบ: "Tha Takiap",
  นครไทย: "Nakhon Thai",
  แก้งสนามนาง: "Kaeng Sanam Nang",
  หนองแขม: "Nong Khaem",
  พนมทวน: "Phanom Thuan",
  นายูง: "Na Yung",
  บึงนาราง: "Bueng Na Rang",
  ดอนตาล: "Don Tan",
  หนองม่วงไข่: "Nong Muang Khai",
  เกาะกูด: "Ko Kut",
  รัตภูมิ: "Rattaphum",
  หนองฮี: "Nong Hi",
  ชุมพลบุรี: "Chumphon Buri",
  ปลาปาก: "Pla Pak",
  หนองปรือ: "Nong Prue",
  นาเยีย: "Na Yia",
  ทุ่งตะโก: "Thung Tako",
  หันคา: "Hankha",
  โคกโพธิ์: "Khok Pho",
  นามน: "Na Mon",
  สว่างอารมณ์: "Sawang Arom",
  พร้าว: "Phrao",
  ค่ายบางระจัน: "Khai Bang Rachan",
  สบปราบ: "Sop Prap",
  เอราวัณ: "Erawan",
  ชัยบุรี: "Chai Buri",
  ปะคำ: "Pakham",
  โพธิ์ไทร: "Pho Sai",
  ทุ่งศรีอุดม: "Thung Si Udom",
  ขุนตาล: "Khun Tan",
  บางแค: "Bang Khae",
  นาแห้ว: "Na Haeo",
  โพธิ์ศรีสุวรรณ: "Pho Si Suwan",
  พระทองคำ: "Phra Thong Kham",
  บางแพ: "Bang Phae",
  บึงสามัคคี: "Bueng Samakkhi",
  ท่าตูม: "Tha Tum",
  วังทรายพูน: "Wang Sai Phun",
  หัวหิน: "Hua Hin",
  เมืองสรวง: "Mueang Suang",
  บางคล้า: "Bang Khla",
  ควนโดน: "Khuan Don",
  คลองลาน: "Khlong Lan",
  สวนผึ้ง: "Suan Phueng",
  นาตาล: "Na Tan",
  โนนแดง: "Non Daeng",
  วังสมบูรณ์: "Wang Sombun",
  ไทยเจริญ: "Thai Charoen",
  ฟากท่า: "Fak Tha",
  สามง่าม: "Sam Ngam",
  โคกโพธิ์ไชย: "Khok Pho Chai",
  เวียงเก่า: "Wiang Kao",
  สะพานสูง: "Saphan Sung",
  พญาไท: "Phaya Thai",
  ไพรบึง: "Phrai Bueng",
  บัวเชด: "Buachet",
  บ้านโป่ง: "Ban Pong",
  ภูหลวง: "Phu Luang",
  เบตง: "Betong",
  สอยดาว: "Soi Dao",
  วชิรบารมี: "Wachirabarami",
  กะพ้อ: "Kapho",
  นาดี: "Na Di",
  สังขละบุรี: "Sangkhla Buri",
  แม่แจ่ม: "Mae Chaem",
  น้ำเกลี้ยง: "Nam Kliang",
  บ้านใหม่ไชยพจน์: "Ban Mai Chaiyaphot",
  ปางศิลาทอง: "Pang Sila Thong",
  พานทอง: "Phan Thong",
  ประจักษ์ศิลปาคม: "Prachak Sinlapakhom",
  ท่าสองยาง: "Tha Song Yang",
  ซับใหญ่: "Sap Yai",
  กุดรัง: "Kut Rang",
  ตาพระยา: "Ta Phraya",
  ภูกามยาว: "Phu Kamyao",
  บางขุนเทียน: "Bang Khun Thian",
  วังหิน: "Wang Hin",
  สองแคว: "Song Khwae",
  ทุ่งเขาหลวง: "Thung Khao Luang",
  แม่เปิน: "Mae Poen",
  บางพลี: "Bang Phli",
  ย่านตาขาว: "Yan Ta Khao",
  พบพระ: "Phop Phra",
  พิปูน: "Phipun",
  เมืองแม่ฮ่องสอน: "Mueang Mae Hong Son",
  ภูพาน: "Phu Phan",
  จอมบึง: "Chom Bueng",
  ลำลูกกา: "Lam Luk Ka",
  เขาฉกรรจ์: "Khao Chakan",
  เขาชะเมา: "Khao Chamao",
  ทุ่งหว้า: "Thung Wa",
  ด่านมะขามเตี้ย: "Dan Makham Tia",
  แวงน้อย: "Waeng Noi",
  หว้านใหญ่: "Wan Yai",
  บ้านธิ: "Ban Thi",
  ธนบุรี: "Thon Buri",
  ลำทับ: "Lam Thap",
  ภูซาง: "Phu Sang",
  ห้วยคต: "Huai Khot",
  สามโก้: "Samko",
  บางใหญ่: "Bang Yai",
  สวนหลวง: "Suan Luang",
  ปลายพระยา: "Plai Phraya",
  นาคู: "Na Khu",
  พิบูลย์รักษ์: "Phibun Rak",
  ศรีสวัสดิ์: "Si Sawat",
  ราชเทวี: "Ratchathewi",
  ผาขาว: "Pha Khao",
  บึงบูรพ์: "Bueng Bun",
  ชำนิ: "Chamni",
  ขามทะเลสอ: "Kham Thale So",
  ทุ่งครุ: "Thung Khru",
  เสริมงาม: "Soem Ngam",
  เมืองชัยนาท: "Mueang Chai Nat",
  ท่าชนะ: "Tha Chana",
  นาโพธิ์: "Na Pho",
  นิคมพัฒนา: "Nikhom Phatthana",
  บางแก้ว: "Bang Kaeo",
  สังคม: "Sangkhom",
  บ่อทอง: "Bo Thong",
  ดอนเมือง: "Don Mueang",
  จุฬาภรณ์: "Chulabhorn",
  บรรพตพิสัย: "Banphot Phisai",
  กัลยาณิวัฒนา: "Galayani Vadhana",
  ลานสกา: "Lan Saka",
  ปทุมวัน: "Pathum Wan",
  สตึก: "Satuek",
  โพนทราย: "Phon Sai",
  หนองบัวระเหว: "Nong Bua Rawe",
  โคกศรีสุพรรณ: "Khok Si Suphan",
  เขาวง: "Khao Wong",
  พะโต๊ะ: "Phato",
  เมืองระยอง: "Mueang Rayong",
  แม่ใจ: "Mae Chai",
  พระยืน: "Phra Yuen",
  วังเจ้า: "Wang Chao",
  ทับสะแก: "Thap Sakae",
  สนามชัยเขต: "Sanam Chai Khet",
  ราษฎร์บูรณะ: "Rat Burana",
  น้ำพอง: "Nam Phong",
  เมืองปาน: "Mueang Pan",
  ช้างกลาง: "Chang Klang",
  มีนบุรี: "Min Buri",
  เชียงม่วน: "Chiang Muan",
  เคียนซา: "Khian Sa",
  สระใคร: "Sakhrai",
  นาหมื่น: "Na Muen",
  บางพลัด: "Bang Phlat",
  กู่แก้ว: "Ku Kaeo",
  เขาชัยสน: "Khao Chaison",
  หนองแสง: "Nong Saeng",
  บ้านด่าน: "Ban Dan",
  พังโคน: "Phang Khon",
  แปลงยาว: "Plaeng Yao",
  แคนดง: "Khaen Dong",
  ตาลสุม: "Tan Sum",
  ศรีณรงค์: "Si Narong",
  กุดข้าวปุ้น: "Kut Khaopun",
  เมืองจันทร์: "Mueang Chan",
  เทพสถิต: "Thep Sathit",
  ทวีวัฒนา: "Thawi Watthana",
  ร่องคำ: "Rong Kham",
  เฝ้าไร่: "Fao Rai",
  ทรายทองวัฒนา: "Sai Thong Watthana",
  บางกอกใหญ่: "Bangkok Yai",
  เวียงสระ: "Wiang Sa",
  บ้านนาเดิม: "Ban Na Doem",
  บ้านหลวง: "Ban Luang",
  ดินแดง: "Din Daeng",
  เวียงแหง: "Wiang Haeng",
  บางนา: "Bang Na",
  เกาะจันทร์: "Ko Chan",
  โนนศิลา: "Non Sila",
  บางเขน: "Bang Khen",
  หลักสี่: "Lak Si",
  พรหมคีรี: "Phrom Khiri",
  บ้านโคก: "Ban Khok",
  ภูกระดึง: "Phu Kradueng",
  ยานนาวา: "Yan Nawa",
  มะนัง: "Manang",
  คันนายาว: "Khan Na Yao",
  เกาะสีชัง: "Ko Sichang",
};

export const tambonMapping = {
  พุสวรรค์: "Phu Sawan",
  นครชัยศรี: "Nakhon Chai Si",
  ก้านเหลือง: "Kan Lueang",
  อรพิมพ์: "Oraphim",
  รอบเมือง: "Rop Mueang",
  แม่งอน: "Mae Ngon",
  เหล่าทอง: "Lao Thong",
  กาบัง: "Kabang",
  ปากจั่น: "Pak Chan",
  ดอกคำใต้: "Dok Kham Tai",
  ทรายทอง: "Sai Thong",
  หนองแก้ว: "Nong Kaeo",
  นาเพียง: "Na Phiang",
  โคกงาม: "Khok Ngam",
  ทัพทัน: "Thap Than",
  เนินขาม: "Noen Kham",
  น้ำจั้น: "Nam Chan",
  ลาดหลุมแก้ว: "Lat Lum Kaeo",
  ป่าสะแก: "Pa Sakae",
  เขาคราม: "Khao Khram",
  หนองขาม: "Nong Kham",
  ท่างาม: "Tha Ngam",
  ลี้: "Li",
  หนองตาคง: "Nong Ta Khong",
  นาตาล: "Na Tan",
  ตลาดไชยา: "Talat Chaiya",
  เกาะโพธิ์: "Ko Pho",
  ปุลากง: "Pulakong",
  ปางสวรรค์: "Pang Sawan",
  บางเค็ม: "Bang Khem",
  หาดเล็ก: "Hat Lek",
  วังขนาย: "Wang Khanai",
  โก่งธนู: "Kong Thanu",
  ตะโละไกรทอง: "Talo Kraithong",
  โคกยาง: "Khok Yang",
  วังปลาป้อม: "Wang Pla Pom",
  บาโร๊ะ: "Baro",
  บางกะไห: "Bang Kahai",
  โพธิ์ทอง: "Pho Thong",
  หาดยาย: "Hat yai",
  บึงเจริญ: "Bueng Charoen",
  หนองบ่อ: "Nong Bo",
  กุดไห: "Kut Hai",
  หนองทุ่ม: "Nong Thum",
  ยม: "Yom",
  ทมอ: "Thamo",
  ท่าบ่อสงคราม: "Tha Bo Songkhram",
  ไม้รูด: "Mai Rut",
  บุกระสัง: "Bu Krasang",
  บางด้วน: "Bang Duan",
  ชะอม: "Cha-om",
  บ้านสร้าง: "Ban Sang",
  บางกะพี้: "Bang Kaphi",
  บ้านแหร: "Ban Rae",
  เหล่าใหญ่: "Lao Yai",
  คลองปูน: "Khlong Pun",
  วิเชตนคร: "Wichet Nakhon",
  สนามแจง: "Sanam Chaeng",
  โป่งงาม: "Pong Ngam",
  วังชมภู: "Wang Chomphu",
  สานตม: "San Tom",
  น้ำใส: "Nam Sai",
  คลองยาง: "Khlong Yang",
  โพสะ: "Phosa",
  อุใดเจริญ: "Udai Charoen",
  ริม: "Rim",
  น้ำเขียว: "Nam Khiao",
  ไร่อ้อย: "Rai Oi",
  แม่สลิด: "Mae Salit",
  โคกตูม: "Khok Tum",
  ศิลา: "Sila",
  โนนดินแดง: "Non Din Daeng",
  วังตามัว: "Wang Ta Mua",
  หนองผือ: "Nong Phue",
  หนองโสน: "Nong Sano",
  หนองแวง: "Nong Waeng",
  ตบหู: "Top Hu",
  สระทะเล: "Sa Thale",
  กะฮาด: "Kahat",
  บ้านงิ้ว: "Ban Ngio",
  ทุ่งสะโตก: "Thung Satok",
  ธงชัยเหนือ: "Thong Chai Nuea",
  มะค่า: "Makha",
  บ้านนา: "Ban Na",
  ชมเจริญ: "Chom Charoen",
  เขาวง: "Khao Wong",
  สันมหาพน: "San Maha Phon",
  ปากแพรก: "Pak Phraek",
  โป่งน้ำร้อน: "Pong Nam Ron",
  ศรีภูมิ: "Si Phum",
  โคกช้าง: "Khok Chang",
  สองคอน: "Song Khon",
  บางใหญ่: "Bang Yai",
  บ้านพานถม: "Ban Phan Thom",
  ปากคลองภาษีเจริญ: "Pak Khlong Phasi Charoen",
  ยั้งเมิน: "Yang Moen",
  ช่อแล: "Cho Lae",
  คลองสมบูรณ์: "Khlong Sombun",
  หนองหว้า: "Nong Wa",
  บ้านแหง: "Ban Haeng",
  นครหลวง: "Nakhon Luang",
  พิจิตร: "Phichit",
  โขงเจียม: "Khong Chiam",
  หัวนา: "Hua Na",
  สะเดา: "Sadao",
  วังสามหมอ: "Wang Sam Mo",
  บ้านเอื้อง: "Ban Ueang",
  นายม: "Na Yom",
  เจริญธรรม: "Charoen Tham",
  ท่ายาง: "Tha Yang",
  ท่าลาด: "Tha Lat",
  ตากออก: "Tak Ok",
  เมืองนาท: "Mueang Nat",
  ท่าไห: "Tha Hai",
  หนองอิรุณ: "Nong Irun",
  หนองสังข์: "Nong Sang",
  มุจลินท์: "Mutchalin",
  หัวขวาง: "Hua Khwang",
  นาหมอศรี: "Na Mo Si",
  คลองจินดา: "Khlong Chinda",
  บ่อพุ: "Bo Phu",
  เสนา: "Sena",
  ขนวน: "Khanuan",
  นาโส่: "Na So",
  คลองวาฬ: "Khlong Wan",
  ลุโบะบือซา: "Lubo Buesa",
  คลองเขม้า: "Khlong Khamao",
  พิเทน: "Phithen",
  ตาเมียง: "Ta Miang",
  บ้านสหกรณ์: "Ban Sahakon",
  หนองปลาปาก: "Nong Pla Pak",
  เขากอบ: "Khao Kop",
  มะขามล้ม: "Makham Lom",
  คลองหาด: "Khlong Hat",
  บึงบอน: "Bueng Bon",
  ป่าร่อน: "Pa Ron",
  น้ำอ้อม: "Nam Om",
  นาหว้า: "Na Wa",
  ปะนาเระ: "Panare",
  โคกสูง: "Khok Sung",
  สระแก้ว: "Sa Kaeo",
  ทับน้ำ: "Thap Nam",
  เขาน้อย: "Khao Noi",
  หินโคน: "Hin Khon",
  หนองซอน: "Nong Son",
  บางพลับ: "Bang Phlap",
  ท่าดินดำ: "Tha Din Dam",
  กวนวัน: "Kuan Wan",
  เพหลา: "Phela",
  ดงพญา: "Dong Phaya",
  จรเข้บัว: "Chorakhe Bua",
  แม่กลอง: "Mae Klong",
  หนองหญ้าไซ: "Nong Ya Sai",
  ละมอ: "Lamo",
  ท่าจะหลุง: "Tha Chalung",
  เขาไม้แก้ว: "Khao Mai Kaeo",
  ส่องดาว: "Song Dao",
  บ้านโป่ง: "Ban Pong",
  แม่ลา: "Mae La",
  บางกุ้ง: "Bang Kung",
  ทุ่งกง: "Thung Kong",
  เรียง: "Riang",
  ขั้นไดใหญ่: "Khan Dai Yai",
  หวายเหนียว: "Wai Niao",
  ตลาดยอด: "Talat Yot",
  ป่าไผ่: "Pha Phai",
  สี่พระยา: "Si Phraya",
  นาแวง: "Na Waeng",
  บ้านแก้ง: "Ban Kaeng",
  น้ำรึม: "Nam Ruem",
  ภูเขาทอง: "Phukhao Thong",
  ไกรนอก: "Krai Nok",
  บ้านฝาง: "Ban Fang",
  เหล่าไฮ: "Lao Hai",
  จางเหนือ: "Chang Nuea",
  หัวปลวก: "Hua Pluak",
  ห้วยขวาง: "Huai Khwang",
  หมูม่น: "Mu Mon",
  แม่เงา: "Mae Ngao",
  ปุโละปุโย: "Pulo Puyo",
  บางทรายใหญ่: "Bang Sai Yai",
  คลองสาม: "Khlong Sam",
  มืดกา: "Muet Ka",
  ป่าตัน: "Pa Tan",
  คำด้วง: "Kham Duang",
  เสม็ด: "Samet",
  กกตูม: "Kok Tum",
  บางม่วง: "Bang Muang",
  สระคู: "Sa Khu",
  ชานุวรรณ: "Chanuwan",
  งัวบา: "Ngua Ba",
  หนองแวงใต้: "Nong Waeng Tai",
  ตาดข่า: "Tat Kha",
  ท่าวาสุกรี: "Tha Wasukri",
  ท้ายตลาด: "Thai Talat",
  แต้: "Tae",
  ปลาโหล: "Pla Lo",
  ห้วยบง: "Huai Bong",
  โนนเปือย: "Non Pueai",
  ดงยาง: "Dong Yang",
  โนนสุวรรณ: "Non Suwan",
  เกาะหลัก: "Ko Lak",
  โนนสัง: "Non Sang",
  ตาขัน: "Ta Khan",
  แม่ทอม: "Mae Thom",
  ท่ากุ่ม: "Tha Kum",
  ตาสิทธิ์: "Ta Sit",
  ผึ้งรวง: "Phueng Ruang",
  พ้อแดง: "Pho Daeng",
  ห้วยม่วง: "huai Muang",
  นาเลียง: "Na Liang",
  โคกสะอาด: "Khok Sa-at",
  แม่อูคอ: "Mae Ukho",
  น้ำแพร่: "Nam Phrae",
  ยุ้งทะลาย: "Yung Thalai",
  จันจว้า: "Chan Chwa",
  สิงห์: "Sing",
  สักหลง: "Sak Long",
  ทุ่งข้าวพวง: "Thung Khao Phuang",
  โสน: "Sano",
  จอมทอง: "Chom Thong",
  ประโคนชัย: "Prakhon Chai",
  นาเตย: "Na Toei",
  หนองนาคำ: "Nong Na Kham",
  บางครก: "Bang Khrok",
  ถนน: "Thanon",
  ทุ่งคาวัด: "Thung Kha Wat",
  สนามชัย: "Sanam Chai",
  อวน: "Uan",
  บ้านต๋อม: "Ban Tom",
  บ้านแมด: "Ban Maet",
  กำพวน: "Kamphuan",
  ท่าซัก: "Tha Sak",
  บ้านบอม: "Ban Bom",
  สำราญ: "Samran",
  สระสี่มุม: "Sa Si Mum",
  เขาทราย: "Khao Sai",
  โนนรัง: "Non Rang",
  ขุมทอง: "Khum Thong",
  หนองป่าครั่ง: "Nong Pa Khrang",
  แคตก: "Khae Tok",
  กระออม: "Kra-om",
  น้ำพ่น: "Nam Phon",
  ผักไห่: "Phak Hai",
  บางกระบือ: "Bang Krabue",
  บ้านคา: "Ban Kha",
  บ่อภาค: "Bo Phak",
  ไผ่สิงห์: "Phai Sing",
  สร้างมิ่ง: "Sang Ming",
  ท้ายทุ่ง: "Thai Thung",
  แห่ใต้: "Hae Tai",
  สวนดอกไม้: "Suan Dok Mai",
  สายตะกู: "Sai Taku",
  แหลมทอง: "Laem Thong",
  วังแก้ว: "Wang Kaeo",
  บางระกำ: "Bang Rakam",
  ศรีสุราษฎร์: "Si Surat",
  วัดโบสถ์: "Wat Bot",
  เวียง: "Wiang",
  ดงสวรรค์: "Dong Sawan",
  ท่าสวรรค์: "Tha Sawan",
  บ้านกรวด: "Ban Kruat",
  ท่าค้อ: "Tha Kho",
  ยกกระบัตร: "Yokkrabat",
  บ้านตาล: "Ban Tan",
  ท้ายบ้านใหม่: "Thai Ban Mai",
  พราน: "Phran",
  ตลาดใหม่: "Talat Mai",
  หลุมรัง: "Lum Rang",
  ท่าหลวง: "Tha Luang",
  แม่นะ: "Mae Na",
  บ้านโคก: "Ban Khok",
  ดงกระทงยาม: "Dong Krathong Yam",
  ไม้ยา: "Mai Ya",
  ศีรษะจรเข้ใหญ่: "Sisa Chorakhe Yai",
  ดู่ใต้: "Du Tai",
  โนนศิลา: "Non Sila",
  ต้นธงชัย: "Ton Thong Chai",
  กู่: "Ku",
  บ้านบึง: "Ban Bueng",
  สันดอนแก้ว: "San Don Kaeo",
  หนองพลับ: "Nong Phlap",
  ไชยคราม: "Chaiyakhram",
  แม่ยวมน้อย: "Mae Yuam Noi",
  ดอนศิลา: "Don Sila",
  คำบง: "Kham Bong",
  เนินงาม: "Noen Ngam",
  นาแสง: "Na Saeng",
  นาจอมเทียน: "Na Chom Thian",
  กุดเมืองฮาม: "Kut Mueang Ham",
  บ้านกอก: "Ban Kok",
  บ่อพลอย: "Bo Phloi",
  หนองพอก: "Nong Phok",
  บ่อ: "Bo",
  ตะเคียนเลื่อน: "Takhian Luean",
  จันจว้าใต้: "Chan Chwa Tai",
  พานทอง: "Phan Thong",
  โฆษิต: "Khosit",
  แก่งเลิงจาน: "Kaeng Loeng Chan",
  พิมาน: "Phiman",
  ศาลาแดง: "Sala Daeng",
  วังกวาง: "Wang Kwang",
  บ้านขาม: "Ban Kham",
  กุดแข้: "Kut Khae",
  บึงยี่โถ: "Bueng Yitho",
  ประสาทสิทธิ์: "Prasat Sit",
  ห้วยยาง: "Huai Yang",
  ท่าทอง: "Tha Thong",
  บ้านดง: "Ban Dong",
  ทับคล้อ: "Thap Khlo",
  แม่นาวาง: "Mae Na Wang",
  จักรวรรดิ: "Chakkrawat",
  แม่ฟ้าหลวง: "Mae Fa Luang",
  เขาชนกัน: "Khao Chon Kan",
  ไผ่ล้อม: "Phai Lom",
  ดอนทอง: "Don Thong",
  บ้านกระแชง: "Ban Krachaeng",
  ท่าฉนวน: "Tha Chanuan",
  กำปัง: "Kampang",
  ฮอด: "Hot",
  โนนสะอาด: "Non Sa-at",
  เขากะลา: "Khao Kala",
  โคกสำราญ: "Khok Samran",
  กองก๋อย: "Kong Koi",
  คลองน้อย: "Khlong Noi",
  เจดีย์ชัย: "Chedi Chai",
  วัดดาว: "Wat Dao",
  หลักด่าน: "Lak Dan",
  บ้านรุน: "Ban Run",
  เหล่าหลวง: "Lao Luang",
  วังกระโจม: "Wang Krachom",
  ท่าน้ำอ้อย: "Tha Nam Oi",
  กุดจับ: "Kut Chap",
  เหล่าดอกไม้: "Lao Dok Mai",
  ร่องจิก: "Rong Chik",
  ภูหลวง: "Phu Luang",
  หอกลอง: "Ho Klong",
  ลิ้นฟ้า: "Lin Fa",
  สีบัวทอง: "Si Bua Thong",
  วังหลุม: "Wang Lum",
  วังท่าดี: "Wang Tha Di",
  ทุ่งเบญจา: "Thung Bencha",
  ทานตะวัน: "Than Tawan",
  คำไฮใหญ่: "Kham Hai Yai",
  หนองธง: "Nong Thong",
  ศรีสุนทร: "Si Sunthon",
  โพนเมือง: "Phon Mueang",
  บ้านกลาง: "Ban Klang",
  หนองโพ: "Nong Pho",
  ละงู: "La-ngu",
  หนองไฮ: "Nong Hai",
  แม่ขะนิง: "Mae Khaning",
  ควนชะลิก: "Khuan Chalik",
  ถ้ำ: "Tham",
  ร่วมจิต: "Ruam Chit",
  ระแหง: "Rahaeng",
  ดู่น้อย: "Du Noi",
  โนนกลาง: "Non Klang",
  ไทยเจริญ: "Thai Charoen",
  หนองบัวแก้ว: "Nong Bua Kaeo",
  หนองโดน: "Nong Don",
  เกาะใหญ่: "Ko Yai",
  สีมุม: "Si Mum",
  ไร่สีสุก: "Rai Si Suk",
  ชุมพวง: "Chum Phuang",
  หล่ายงาว: "Lai Ngao",
  สระลงเรือ: "Sa Long Ruea",
  งิ้ว: "Ngio",
  โพธิ์งาม: "Pho Ngam",
  ไพรพัฒนา: "Phrai Phatthana",
  เกาะลิบง: "Ko Libong",
  โต๊ะเด็ง: "Todeng",
  สาคร: "Sakhon",
  บางมัญ: "Bang Man",
  โนนสูง: "Non Sung",
  หนองสาหร่าย: "Nong Sarai",
  น้ำคำ: "Nam Kham",
  คาละแมะ: "Khalamae",
  สำโรง: "Samrong",
  สุวารี: "Suwari",
  หัวรอ: "Hua Ro",
  คลองไทร: "Khlong Sai",
  เกาะพยาม: "Ko Phayam",
  พระแท่น: "Phra Thaen",
  ขนงพระ: "Khanong Phra",
  ขามป้อม: "Kham Pom",
  โคกม่วง: "Khok Muang",
  ศิลาเพชร: "Sila Phet",
  เกาะศาลพระ: "Ko San Phra",
  คลองกุ่ม: "Khlong Kum",
  ทาสบเส้า: "Tha Sop Sao",
  กุง: "Kung",
  เด่นชัย: "Den Chai",
  ขะเนจื้อ: "Khane Chue",
  สรรพยา: "Sapphaya",
  แม่ทราย: "Mae Sai",
  ห้วยแห้ง: "Huai Haeng",
  บางแก้ว: "Bang Kaeo",
  บัวชุม: "Bua Chum",
  คูขวาง: "Khu Khwang",
  จันทิมา: "Chanthima",
  ปากู: "Paku",
  หนองกุงศรี: "Nong Kung Si",
  ช่างปี่: "Chang Pi",
  บ้านเม็ง: "Ban Meng",
  โชคชัย: "Chok Chai",
  คลองเกตุ: "Khlong Ket",
  หนองยวง: "Nong Yuang",
  บ้านกร่าง: "Ban Krang",
  พงสวาย: "Phong Sawai",
  หัวนาคำ: "Hua Na Kham",
  ปทุมวาปี: "Pathum Wapi",
  ทุ่งต่อ: "Thung To",
  บ้องตี้: "Bongti",
  ม่วงเตี้ย: "Muang Tia",
  โคกเริงรมย์: "Khok Roeng Rom",
  เกาะหวาย: "Ko Wai",
  นาขุนไกร: "Na Khun Krai",
  บุ่งหวาย: "Bung Wai",
  แม่ตาว: "Mae Tao",
  น้ำผุด: "Nam Phut",
  ขามสะแกแสง: "Kham Sakaesaeng",
  หาดแพง: "Hat Phaeng",
  กาหลง: "Kalong",
  บางพลวง: "Bang Phluang",
  นิยมชัย: "Niyom Chai",
  ถลุงเหล็ก: "Thalung Lek",
  โนนหมากมุ่น: "Non Mak Mun",
  หน้าพระธาตุ: "Na Phra That",
  โพนางดำตก: "Pho Nang Dam Tok",
  ปราณบุรี: "Pran Buri",
  โนนทอง: "Non Thong",
  แสนสุข: "Saen Suk",
  ห้วยชมภู: "Huai Chomphu",
  นาเกียน: "Na Kian",
  บ่อเหล็กลอง: "Bo Lek Long",
  ลาดค่าง: "Lat Khang",
  บ้านแปะ: "Ban Pae",
  ดอนหวาย: "Don Wai",
  บางขุนนนท์: "Bang Khun Non",
  ทุ่งสง: "Thung Song",
  ย่านยาว: "Yan Yao",
  จำลอง: "Chamlong",
  วังสามัคคี: "Wang Samakkhi",
  บางนางลี่: "Bang Nang Li",
  บางเมือง: "Bang Mueang",
  เคร็ง: "Khreng",
  ศาลารีไทย: "Sala Ri Thai",
  นาบอน: "Na Bon",
  จักราช: "Chakkarat",
  หว้านคำ: "Wan Kham",
  แพรกศรีราชา: "Phraek Si Racha",
  ทุ่งท่าช้าง: "Thung Tha Chang",
  คลองเตย: "Khlong Toei",
  นิคมกระเสียว: "Nikhom Krasiao",
  บ้านผือ: "Ban Phue",
  หนองบัวสิม: "Nong Bua Sim",
  แม่เกิ๋ง: "Mae Koeng",
  โพประจักษ์: "Pho Prachak",
  หนองสว่าง: "Nong Sawang",
  ดงบัง: "Dong Bang",
  แคน: "Khaen",
  บางขุนไทร: "Bang Khun Sai",
  บุญเรือง: "Bun Rueang",
  หนองบัว: "Nong Bua",
  ยุหว่า: "Yu Wa",
  จวบ: "Chuap",
  หนองน้ำแดง: "Nong Nam Daeng",
  มาบไพ: "Map Phai",
  โผงเผง: "Phongpheng",
  ในเมือง: "Nai Mueang",
  ห้วยลึก: "Huai Luek",
  ปะเคียบ: "Pakhiap",
  สวนเมี่ยง: "Suan Miang",
  หัวตะพาน: "Hua Taphan",
  นครชุม: "Nakhon Chum",
  เกาะพะงัน: "Ko Pha-ngan",
  ระโนด: "Ranot",
  อ่างแก้ว: "Ang Kaeo",
  โคกก่อง: "Khok Kong",
  สระจรเข้: "Sa Chorakhe",
  บางจาก: "Bang Chak",
  สร้างแซ่ง: "Sang Saeng",
  ดินดำ: "Din Dam",
  กุดยม: "Kut Yom",
  คูบัว: "Khu Bua",
  หนองอึ่ง: "Nong Ueng",
  สัมปทวน: "Sampathuan",
  สบป้าด: "Sop Pat",
  วังใหญ่: "Wang Yai",
  ท่าหิน: "Tha Hin",
  ไร่: "Rai",
  ไผ่พระ: "Phai Phra",
  บ้านม่วง: "Ban Muang",
  บางชะนี: "Bang Chani",
  บัวบาน: "Bua Ban",
  ห้วยด้วน: "Huai Duan",
  บ้านยวด: "Ban Yuat",
  นามะเฟือง: "Na Mafueang",
  หัวเมือง: "Hua Mueang",
  ปะหลาน: "Palan",
  วังไม้ขอน: "Wang Mai Khon",
  จันทบเพชร: "Chanthop Phet",
  ท่าโสม: "Tha Som",
  แสลง: "Salaeng",
  หนองบก: "Nong Bok",
  พนมสารคาม: "Phanom Sarakham",
  หนองเดิ่น: "Nong Doen",
  โค้งไผ่: "Khong Phai",
  หนองกุ่ม: "Nong Kum",
  วังท่าช้าง: "Wang Tha Chang",
  พลิ้ว: "Phlio",
  แว้ง: "Waeng",
  บ้านดอน: "Ban Don",
  ป่าแลวหลวง: "Pa Laeo Luang",
  ลำภู: "Lumphu",
  ทับกวาง: "Thap Kwang",
  ตะเสะ: "Tase",
  ประจันตคาม: "Prachantakham",
  กุดประทาย: "Kut Prathai",
  ท่าดอกคำ: "Tha Dok Kham",
  โจดหนองแก: "Chot Nong Kae",
  บ้านแป้ง: "Ban Paeng",
  ศาลายา: "Salaya",
  บึง: "Bueng",
  ลาดแค: "Lat Khae",
  ทางกลาง: "Thang Klang",
  สันนาเม็ง: "San Na Meng",
  ถ้ำน้ำผุด: "Tham Nam Phut",
  สะบ้าย้อย: "Saba Yoi",
  แคออก: "Khae Ok",
  ท่าม่วง: "Tha Muang",
  คลองขลุง: "Khlong Khlung",
  โพนงาม: "Phon Ngam",
  นาทับไฮ: "Na Thap Hai",
  ต้นยวน: "Ton Yuan",
  บ้านดุง: "Ban Dung",
  คลองใหม่: "Khlong Mai",
  โนนค้อ: "Non Kho",
  ท่ามะเฟือง: "Tha Mafueang",
  บางนาค: "Bang Nak",
  สุคิริน: "Sukhirin",
  ยางใหญ่: "Yang Yai",
  หนองกระเบียน: "Nong Krabian",
  ซับมะกรูด: "Sap Makrut",
  ไร่ขี: "Rai Khi",
  เหล่าพัฒนา: "Lao Phatthana",
  ห้วยสำราญ: "Huai Samran",
  ทางเกวียน: "Thang Kwian",
  แม่สาย: "Mae Sai",
  สงเปือย: "Song Pueai",
  บ้านแท่น: "Ban Thaen",
  วังแดง: "Wang Daeng",
  อินทขิล: "Inthakhin",
  แควใหญ่: "Khwae Yai",
  เขาจ้าว: "Khao Chao",
  โนนสง่า: "Non Sa-nga",
  คำโตนด: "Kham Tanot",
  ถ่อนนาลับ: "Thon Na Lap",
  กำแพงแสน: "Kamphaeng Saen",
  หนองคอนไทย: "Nong Khon Thai",
  บางหลวง: "Bang Luang",
  ทุ่งปี๊: "Thung Pi",
  เมืองเก่า: "Mueang Kao",
  ทุ่งรวงทอง: "Thung Ruang Thong",
  หาดทรายขาว: "Hat Sai Khao",
  ศรีดงเย็น: "Si Dong Yen",
  คลองถนน: "Khlong Thanon",
  อุ้มผาง: "Umphang",
  ช้างเผือก: "Chang Phueak",
  บ้านม้า: "Ban Ma",
  เสมา: "Sema",
  นาโยงเหนือ: "Na Yong Nuea",
  หนองสรวง: "Nong Suang",
  เกาะยาวน้อย: "Ko Yao Noi",
  แหลมใหญ่: "Laem Yai",
  แม่ปะ: "Mae Pa",
  หนองขมาร: "Nong Khaman",
  โพธิไพศาล: "Phothiphaisan",
  ชำผักแพว: "Cham Phak Phaeo",
  ด่านคล้า: "Dan Khla",
  สัมพันธ์: "Samphan",
  สองชั้น: "Song Chan",
  บ้านชบ: "Ban Chop",
  วังทรายคำ: "Wang Sai Kham",
  กระทุ่มราย: "Krathum Rai",
  สังเม็ก: "Sang Mek",
  แม่ปูคา: "Mae Pu Kha",
  สองห้อง: "Song Hong",
  ยางราก: "Yang Rak",
  หนองโก: "Nong Ko",
  นากระตาม: "Na Kratam",
  ธารคีรี: "Than Khiri",
  มหาโพธิ: "Maha Phot",
  ป่าตอง: "Pa Tong",
  เขาพังไกร: "Khao Phang Krai",
  ท่าแค: "Tha Kae",
  โพไร่หวาน: "Pho Rai Wan",
  ลาดพัฒนา: "Lat Phatthana",
  คลองใหญ่: "Khlong Yai",
  แม่ลาน: "Mae Lan",
  โคกหม้อ: "Khok Mo",
  วังพิกุล: "Wang Phikun",
  ไชยวาน: "Chai Wan",
  หว้าทอง: "Wa Thong",
  เชิงกลัด: "Choeng Klat",
  หัวดอน: "Hua Don",
  หนองเทพ: "Nong Thep",
  บ้านเขือง: "Ban Khueang",
  ปงแสนทอง: "Pong Saen Thong",
  บ้านกล้วย: "Ban Kuai",
  หลุมดิน: "Lum Din",
  กรอกสมบูรณ์: "Krok Sombun",
  บางเตย: "Bang Toei",
  ชุมพล: "Chumphon",
  ศรีธาตุ: "Si That",
  สามัคคี: "Samakkhi",
  บ้านเพชร: "Ban Phet",
  เขารูปช้าง: "Khao Rup Chang",
  หินแก้ว: "Hin Kaeo",
  คู้ฝั่งเหนือ: "Khu Fang Nuea",
  บ้านแม: "Ban Mae",
  ปัถวี: "Patthawi",
  สวนใหญ่: "Suan Yai",
  บ้านโพธิ์: "Ban Pho",
  ไทยชนะศึก: "Thai Chana Suek",
  จระเข้: "Chorakhe",
  บ้านเอื้อม: "Ban Ueam",
  วังบาล: "Wang Ban",
  แวงดง: "Waeng Dong",
  ยะรัง: "Yarang",
  พระธาตุ: "Phra That",
  บัวคำ: "Bua Kham",
  หนองกระทุ่ม: "Nong Krathum",
  วังแสง: "Wang Saeng",
  หนองพยอม: "Nong Phayom",
  ดงน้อย: "Dong Noi",
  โคกสาร: "Khok San",
  บ้านบุ่ง: "Ban Bung",
  จุมจัง: "Chum Chang",
  ซับจำปา: "Sap Champa",
  เบิด: "Boet",
  แม่ฮ้อยเงิน: "Mae Hoi Ngoen",
  เสาชิงช้า: "Sao Chingcha",
  โพธิ์ตาก: "Pho Tak",
  คำม่วง: "Kham Muang",
  ท่าพลับ: "Tha Phlap",
  นาแซง: "Na Saeng",
  คลองพระยาบันลือ: "Khlong Phraya Banlue",
  ลำใหม่: "Lam Mai",
  บึงทวาย: "Bueng Thawai",
  บ้านหนุน: "Ban Nun",
  ลิพัง: "Liphang",
  ยางหย่อง: "Yang Yong",
  บางเสร่: "Bang Sare",
  ดอนมัน: "Don Man",
  คมบาง: "Khom Bang",
  ห้วยรอบ: "Huai Rop",
  หนองตาดใหญ่: "Nong Tat Yai",
  เขื่องใน: "Khueang Nai",
  โพธิ์กระสังข์: "Pho Krasang",
  พ่อมิ่ง: "Pho Ming",
  ปางมะผ้า: "Pang Mapha",
  บ้านมุง: "Ban Mung",
  บ้านเกาะ: "Ban Ko",
  บ้านใหม่: "Ban Mai",
  บางกะเจ้า: "Bang Kachao",
  บางตีนเป็ด: "Bang Tin Pet",
  ทุ่งเสลี่ยม: "Thung Saliam",
  ห้วยโจด: "Huai Chot",
  ศาลาลอย: "Sala Loi",
  หนองแรต: "Nong Raet",
  ดำเนินสะดวก: "Damnoen Saduak",
  สันทรายงาม: "San Sai Ngam",
  โรงช้าง: "Rong Chang",
  ทาเหนือ: "Tha Nuea",
  ไทรโยค: "Sai Yok",
  โสกนกเต็น: "Sok Nok Ten",
  ดอนขุนห้วย: "Don Khun Huai",
  เหมืองง่า: "Mueang Nga",
  เหล่ากวาง: "Lao Kwang",
  ประศุก: "Prasuk",
  โคกหล่าม: "Khok Lam",
  ช่องแมว: "Chong Maeo",
  บางคนที: "Bang Khonthi",
  ท่าคา: "Tha Kha",
  เชียงขวัญ: "Chiang Khwan",
  เมืองก๋าย: "Mueang Kai",
  ฮางโฮง: "Hang Hong",
  หนองตอกแป้น: "Nong Tok Paen",
  ดอนรวก: "Don Ruak",
  ศรีค้ำ: "Si Kham",
  ผาปัง: "Pha Pang",
  นาเมืองเพชร: "Na Mueang Phet",
  หนองปลาหมอ: "Nong Pla Mo",
  วัดจันทร์: "Wat Chan",
  คำบก: "Kham Bok",
  ชากบก: "Chak Bok",
  ทำนบ: "Thamnop",
  โคกตาล: "Khok Tan",
  ดอนโพธิ์: "Don Pho",
  สร้างแป้น: "Sang Paen",
  บ่อกระดาน: "Bo Kradan",
  ชัยเกษม: "Chai Kasem",
  หนองกระโดน: "Nong Kradon",
  โนนเต็ง: "Non Teng",
  แม่พุง: "Mae Phung",
  หมื่นไวย: "Muen Wai",
  พระธาตุผาแดง: "Phra That Pha Daeng",
  ศรีดอนมูล: "Si Don Mun",
  กุดปลาดุก: "Kut Pla Duk",
  ฉลอง: "Chalong",
  ศาลาลาย: "Sala Lai",
  คลองขาม: "Khlong Kham",
  น้ำทรง: "Nam Song",
  น้ำตก: "Nam Tok",
  ดอนทราย: "Don Sai",
  ทุ่งโพธิ์: "Thung Pho",
  ตะกุกใต้: "Takuk Tai",
  ทองมงคล: "Thong Mongkhon",
  ในวงใต้: "Nai Wong Tai",
  คลองพลู: "Khlong Phlu",
  ปอน: "Pon",
  น้ำพาง: "Nam Phang",
  ไผ่รอบ: "Phai Rop",
  วังใต้: "Wang Tai",
  ตาเซะ: "Tase",
  สระตะเคียน: "Sa Takhian",
  โชคนาสาม: "Chok Na Sam",
  นาผือ: "Na Phue",
  แม่สา: "Mae Sa",
  เขาแก้ว: "Khao Kaeo",
  ธงชัย: "Thong Chai",
  บ้านเดื่อ: "Ban Duea",
  บ้านเหล่า: "Ban Lao",
  ขวัญเมือง: "Khwan Mueang",
  ชากไทย: "Chak Thai",
  โนนสมบูรณ์: "Non Sombun",
  น้ำพี้: "Nam Phi",
  นาทม: "Na Thom",
  แม่กา: "Mae Ka",
  โคกสะตอ: "Khok Sato",
  หนองมะแซว: "Nong Masaeo",
  ป่าก่อดำ: "Pa Ko Dam",
  โนนกาเล็น: "Non Ka Len",
  ศรีถ้อย: "Si Thoi",
  บางนา: "Bang Na",
  หมูสี: "Mu Si",
  ตาเกษ: "Ta Ket",
  นาสนุ่น: "Na Sanun",
  ลิ่นถิ่น: "Linthin",
  ห้องแซง: "Hong Saeng",
  หนองขอน: "Nong Khon",
  พระงาม: "Phra Ngam",
  ปากน้ำ: "Pak Nam",
  หัวเขา: "Hua Khao",
  บ้านแห: "Ban Hae",
  สระขวัญ: "Sa Khwan",
  หินตก: "Hin Tok",
  บ้านแถว: "Ban Thaeo",
  น้ำน้อย: "Nam Noi",
  แก่งโสภา: "Kaeng Sopha",
  วังเย็น: "Wang Yen",
  โพธิ์ไทร: "Pho Sai",
  ท่าช้าง: "Tha Chang",
  ปงสนุก: "Pong Sanuk",
  วังมหากร: "Wang Mahakon",
  คลองเส: "Khlong Se",
  สันต้นหมื้อ: "San Ton Mue",
  ตลิ่งชัน: "Taling Chan",
  บัวงาม: "Bua Ngam",
  หนองกุงธนสาร: "Nong Kung Thanasan",
  ฝาละมี: "Falami",
  ฆ้องชัยพัฒนา: "Khong Chai Phatthana",
  บ้านเหลื่อม: "Ban Lueam",
  เสาหิน: "Sao Hin",
  ขอนยูง: "Khon Yung",
  ท่าข้าม: "Tha Kham",
  ลำพญา: "Lam Phaya",
  ป่ามะคาบ: "Pa Makhap",
  ลำห้วยหลัว: "Lam Huai Lua",
  ควนกาหลง: "Khuan Kalong",
  สระแจง: "Sa Chaeng",
  เมืองคง: "Mueang Khong",
  ไพศาล: "Phaisan",
  ดอนเงิน: "Don Ngoen",
  หันห้วยทราย: "Han Huai Sai",
  ดอนกลาง: "Don Klang",
  สามสวน: "Sam Suan",
  หน้าพระลาน: "Na Phra Lan",
  หนองรี: "Nong Ri",
  บ้านน้ำบ่อ: "Ban Nam Bo",
  ห้วยอ้อ: "Huai O",
  ทุ่งกวาว: "Thung Kwao",
  เต่างอย: "Tao Ngoi",
  ลาดพร้าว: "Lat Phrao",
  แสนพัน: "Saen Phan",
  ไผ่หลวง: "Phai Luang",
  วัฒนา: "Watthana",
  สร้างนกทา: "Sang Nok Tha",
  เรณู: "Renu",
  ประเวศ: "Prawet",
  ท่าอยู่: "Tha Yu",
  ทุ่งสุขลา: "Thung Sukhla",
  สามพร้าว: "Sam Phrao",
  ท่าลาดขาว: "Tha Lat Khao",
  สร้อยทอง: "Soi Thong",
  บางโทรัด: "Bang Tho Rat",
  ดอยแก้ว: "Doi Kaeo",
  คูเมือง: "Khu Mueang",
  ช่องสาริกา: "Chong Sarika",
  ควนทอง: "Khuan Thong",
  คลองขวาง: "Khlong Khwang",
  ท่ามะพลา: "Tha Maphla",
  บ้านแก่ง: "Ban Kaeng",
  อ่าวลึกเหนือ: "Ao Luek Nuea",
  ดอนงัว: "Don Ngua",
  ปลวกแดง: "Pluak Daeng",
  วังน้ำคู้: "Wang Nam Khu",
  หนองหม้อ: "Nong Mo",
  ราชคราม: "Ratchakhram",
  ลุ่มลำชี: "Lum Lam Chi",
  คุ้งน้ำวน: "Khung Nam Won",
  สาคู: "Sakhu",
  นาสี: "Na Si",
  โกรกพระ: "Krok Phra",
  ป่าซาง: "Pa Sang",
  เกาะยอ: "Ko Yo",
  ตลุกกลางทุ่ง: "Taluk Klang Thung",
  เกาะปันหยี: "Ko Panyi",
  กง: "Kong",
  ผาบิ้ง: "Pha Bing",
  หนองแดง: "Nong Daeng",
  ชื่นชม: "Chuen Chom",
  ห้วยตึ๊กชู: "Huai Tuekchu",
  สำโรงใหม่: "Samrong Mai",
  แพงใหญ่: "Phaeng Yai",
  ห้วยแร้ง: "Huai Raeng",
  กาบเชิง: "Kap Choeng",
  บ้านกลึง: "Ban Klueng",
  เชี่ยวเหลียง: "Chiao Liang",
  จันดี: "Chan Di",
  ป่าชัน: "Pa Chan",
  ก้อนแก้ว: "Kon Kaeo",
  สามหมื่น: "Sam Muen",
  ทุ่งพอ: "Thung Pho",
  คลองพระอุดม: "Khlong Phra Udom",
  ต้นโพธิ์: "Ton Pho",
  พะตง: "Phatong",
  คำเลาะ: "Kham Lo",
  ดงใหญ่: "Dong Yai",
  แชแล: "Chaelae",
  ยี่ล้น: "Yi Lon",
  ที่วัง: "Thi Wang",
  พวา: "Phawa",
  ตลาดเกรียบ: "Talat Kriap",
  พระธาตุบังพวน: "Phra That Bang Phuan",
  วังยายทอง: "Wang Yai Thong",
  หนองมะนาว: "Nong Manao",
  บ้านไร่: "Ban Rai",
  มาบแก: "Map Kae",
  ละหานทราย: "Lahan Sai",
  ศรีสองรัก: "Si Song Rak",
  หนองหญ้าไทร: "Nong Ya Sai",
  โตนดด้วน: "Tanot Duan",
  ห้วยกระเจา: "Huai Krachao",
  ช่องด่าน: "Chong Dan",
  ทมนางาม: "Thom Na Ngam",
  สันติสุข: "Santi Suk",
  อ่างศิลา: "Ang Sila",
  ศรีมงคล: "Si Mongkhon",
  นาบ่อคำ: "Na Bo Kham",
  ตาเนาะปูเต๊ะ: "Tanao Pute",
  ท่าสะอาด: "Tha Sa-at",
  สันโป่ง: "San Pong",
  หนองแคน: "Nong Khaen",
  โซ่: "So",
  บ้านเพิง: "Ban Phoeng",
  นราภิรมย์: "Naraphirom",
  ดอนแรด: "Don Raet",
  บางปลาร้า: "Bang Pla Ra",
  คลองตาล: "Khlong Tan",
  เมืองลีง: "Mueang Ling",
  บ้านควน: "Ban Khuan",
  นาซาว: "Na Sao",
  หนองไผ่: "Nong Phai",
  ชะแมบ: "Chamaep",
  กลางใหญ่: "Klang Yai",
  ร้องกวาง: "Rong Kwang",
  วอแก้ว: "Wo Kaeo",
  โอโล: "Olo",
  วังแซ้ม: "Wang Saem",
  บ้านใต้: "Ban Tai",
  ห้างฉัตร: "Hang Chat",
  ยาบี: "Yabi",
  แม่ลาน้อย: "Mae La Noi",
  ป่าระกำ: "Pa Rakam",
  กุรุคุ: "Kurukhu",
  บ้านไทร: "Ban Sai",
  บ้านพราน: "Ban Phran",
  กระทุ่มล้ม: "Krathum Lom",
  หนองแม่แตง: "Nong Mae Taeng",
  หัวไทร: "Hua Sai",
  สระพังลาน: "Sa Phang Lan",
  โพนบก: "Phon Bok",
  วัดยม: "Wat Yom",
  ควนศรี: "Khuan Si",
  สมุด: "Samut",
  วังตะเคียน: "Wang Takhian",
  ระวิง: "Rawing",
  เวียงห้าว: "Wiang Hao",
  ปาเสมัส: "Pasemat",
  บางหญ้าแพรก: "Bang Ya Phraek",
  ลำผักชี: "Lam Phak Chi",
  หนองคันทรง: "Nong Khan Song",
  ทุ่ง: "Thung",
  แม่จั๊วะ: "Mae Chua",
  หนองเรือ: "Nong Ruea",
  รัษฎา: "Ratsada",
  เวียงสะอาด: "Wiang Sa-at",
  บ้านซ่อง: "Ban Song",
  สันปูเลย: "San Pu Loei",
  แม่คำ: "Mae Kham",
  บ้านแค: "Ban Khae",
  นาคำ: "Na Kham",
  แม่ขรี: "Mae Khari",
  มะกอก: "Makok",
  แม่สลองนอก: "Mae Salong Nok",
  บ่อหลวง: "Bo Luang",
  ผ่านศึก: "Phan Suek",
  พนา: "Phana",
  บางพลีน้อย: "Bang Phli Noi",
  บ้านยาง: "Ban Yang",
  พุคา: "Phu Kha",
  ธารทอง: "Than Thong",
  ยอดชาด: "Yot Chat",
  นาโพธิ์: "Na Pho",
  ทุ่งนาเลา: "Thung Na Lao",
  บาตง: "Batong",
  นาถ่อน: "Na Thon",
  วังจั่น: "Wang Chan",
  สันพระเนตร: "San Phra Net",
  ท่าฉาง: "Tha Chang",
  บางเดือน: "Bang Duean",
  นาจำปา: "Na Champa",
  ระโสม: "Rasom",
  กุดตุ้ม: "Kut Tum",
  ดอนแร่: "Don Rae",
  กุดแห่: "Kut Hae",
  วังโรงใหญ่: "Wang Rong Yai",
  แม่สรวย: "Mae Suai",
  สถาน: "Sathan",
  ตาลชุม: "Tan Chum",
  เขากวางทอง: "Khao Kwang Thong",
  บ้านปง: "Ban Pong",
  หาดสำราญ: "Hat Samran",
  ท่าราบ: "Tha Rap",
  วังหว้า: "Wang Wa",
  เมืองบางขลัง: "Mueang Bang Khlang",
  บางพระ: "Bang Phra",
  แมด: "Maet",
  ไผ่หลิ่ว: "Phai Lio",
  บาเระใต้: "Bare Tai",
  เจาทอง: "Chao Thong",
  ดงเย็น: "Dong Yen",
  ผาทอง: "Pha Thong",
  ห้วยกระทิง: "Huai Krathing",
  หนองจอก: "Nong Chok",
  แม่คะตวน: "Mae Khatuan",
  หนองช้างแล่น: "Nong Chang Laen",
  ขัวก่าย: "Khua Kai",
  เมืองโพธิ์: "Mueang Pho",
  ทุ่งมหาเมฆ: "Thung Maha Mek",
  หนองผักนาก: "Nong Phak Nak",
  แม่วิน: "Mae Win",
  งิ้วงาม: "Ngio Ngam",
  บ้านซ่าน: "Ban San",
  อีง่อง: "I Ngong",
  ท่านางงาม: "Tha Nang Ngam",
  นาจาน: "Na Chan",
  ขี้เหล็ก: "Khilek",
  ป่าพลู: "Pa Phlu",
  ท่าเมือง: "Tha Muaeng",
  เสื่องข้าว: "Sueang Khao",
  ดอนมะนาว: "Don Manao",
  ช้างตะลูด: "Chang Talut",
  เมืองไผ่: "Mueang Phai",
  ยางน้ำกลัดใต้: "Yang Nam Klat Tai",
  โคกสว่าง: "Khok Sawang",
  บ้านแอ่น: "Ban Aen",
  ทุ่งนารี: "Thung Nari",
  หนองชิ่ม: "Nong Chim",
  หนองตาไก้: "Nong Ta Kai",
  สาลี: "Sali",
  ท่าอุเทน: "Tha Uthen",
  สนามคลี: "Sanam Khli",
  ยะต๊ะ: "Yata",
  พญาแก้ว: "Phaya Kaeo",
  ห่อหมก: "Homok",
  วังจันทร์: "Wang Chan",
  พรุดินนา: "Phru Din Na",
  พรมสวรรค์: "Phrom Sawan",
  สมเด็จเจ้าพระยา: "Somdet Chao Phraya",
  บางพุทรา: "Bang Phutsa",
  ท่าเดื่อ: "Tha Duea",
  น้ำจืด: "Nam Chuet",
  บางเล่า: "Bang Lao",
  หนองลาน: "Nong lan",
  บ้านโภชน์: "Ban Phot",
  โคกว่าน: "Khok Wan",
  บ้านใหม่หนองไทร: "Ban Mai Nong Sai",
  ท่าเสา: "Tha Sao",
  เทพรักษา: "Thep Raksa",
  บ้านบาตร: "Ban Bat",
  อ่างทอง: "Ang Thong",
  ตระเปียงเตีย: "Tra Priang Tia",
  หินดาด: "Hin Dat",
  บ้านแจ้ง: "Ban Chaeng",
  แม่เจดีย์: "Mae Chedi",
  โพธิ์ศรี: "Pho Si",
  ป่าโปง: "Pa Pong",
  พรหมสวัสดิ์: "Phrom Sawat",
  หนองใหญ่: "Nong Yai",
  หนองกระเจ็ด: "Nong Krachet",
  กะปง: "Kapong",
  โสนลอย: "Sano Loi",
  ช้างน้อย: "Chang Noi",
  หัวเวียง: "Hua Wiang",
  บึงนาเรียง: "Bueng Na Riang",
  หนองน้ำใส: "Nong Nam Sai",
  ห้วยยางโทน: "Huai Yang Thon",
  พลา: "Phala",
  คลองเตยเหนือ: "Khlong Toei Nuea",
  ตะลุโบะ: "Talubo",
  ดอนปรู: "Don Pru",
  น้ำเต้า: "Nam Tao",
  เถินบุรี: "Thoen Buri",
  ม่วงหวาน: "Muang Wan",
  ห้วยแถลง: "Huai Thalaeng",
  สระบัว: "Sa Bua",
  ลำหนองแสน: "Lam Nong Saen",
  ม่อนจอง: "Mon Chong",
  เจ้าเจ็ด: "Chao Chet",
  ขุนน่าน: "Khun Nan",
  นาหมอม้า: "Na Mo Ma",
  ดอนกลอย: "Don Kloi",
  คำโพน: "Kham Phon",
  พระนอน: "Phra Non",
  ละหาน: "Lahan",
  ม่วงงาม: "Muang Ngam",
  ควนโส: "Khuan So",
  โคกนาโก: "Khok Na Ko",
  โพนข่า: "Phon Kha",
  คุ้งสำเภา: "Khung Samphao",
  ถ้ำกระต่ายทอง: "Tham Kratai Thong",
  เมืองฝ้าย: "Mueang Fai",
  ช้างคลาน: "Chang Khlan",
  ใหม่พัฒนา: "Mai Phatthana",
  ดุสิต: "Dusit",
  คันไร่: "Khan Rai",
  แม่จัน: "Mae Chan",
  ท่านั่ง: "Tha Nang",
  กระนวน: "Kranuan",
  ดงมูล: "Dong Mun",
  ชัยนารายณ์: "Chai Narai",
  เนินฆ้อ: "Noen Kho",
  สำราญใต้: "Samran Tai",
  โป่งตาลอง: "Pong Talong",
  หน้าเมือง: "Na Mueang",
  หลังสวน: "Lang Suan",
  ท่าขนาน: "Tha Khanan",
  นาบัว: "Na Bua",
  บงใต้: "Bong Tai",
  หนองบัวละคร: "Nong Bua Lakhon",
  ห้วยตามอญ: "Huai Ta Mon",
  นาคา: "Nakha",
  ป่าชิง: "Pa Ching",
  คำครั่ง: "Kham Khrang",
  ป่าบอน: "Pa Bon",
  ทุ่งหลวง: "Thung Luang",
  หัวโพธิ์: "Hua Pho",
  ปงน้อย: "Pong Noi",
  ราษฎร์นิยม: "Rat Niyom",
  วังหิน: "Wang Hin",
  โนนอุดม: "Non Udom",
  บัวขาว: "Bua Khao",
  สีแก้ว: "Si Kaeo",
  ชุมแสง: "Chum Saeng",
  คำชะอี: "Kham Cha-i",
  ผาจุก: "Pha Chuk",
  แม่กิ๊: "Mae Ki",
  นาท่ามใต้: "Na Tham Tai",
  ตันหยงลิมอ: "Tanyong Limo",
  สุวรรณคาม: "Suwannakham",
  ตะแพน: "Taphaen",
  ชอนสมบูรณ์: "Chon Sombun",
  หลักสอง: "Lak Song",
  แม่ระมาด: "Mae Ramat",
  ดอนหว่าน: "Don Wan",
  เขมราฐ: "Khemarat",
  ไพบูลย์: "Phaibun",
  ระบำ: "Rabam",
  บุดี: "Budi",
  ตรมไพร: "Trom Phrai",
  ชัยพร: "Chaiyaphon",
  ปะโค: "Pa Kho",
  แสลงพัน: "Salaeng Phan",
  ยางขี้นก: "Yang Khi Nok",
  กุมภวาปี: "Kumphawapi",
  กะเปียด: "Kapiat",
  วังคีรี: "Wang Khiri",
  โคกไทย: "Khok Thai",
  บ้านเนิน: "Ban Noen",
  หนองข้างคอก: "Nong Khang Khok",
  ลิดล: "Lidon",
  วังโตนด: "Wang Tanot",
  ปากกราน: "Pak Kran",
  ทรายมูล: "Sai Mun",
  นาอิน: "Na In",
  ส้าน: "San",
  ไตรตรึงษ์: "Trai Trueng",
  ดงอีจาน: "Dong I Chan",
  นางพญา: "Nang Phaya",
  ไทยสามัคคี: "Thai Samakkhi",
  ลุโบะบายะ: "Lubo Baya",
  ออย: "Oi",
  ท่าเรือ: "Tha Ruea",
  บ้านค้อ: "Ban Kho",
  โนนสำราญ: "Non Samran",
  ฝั่งแดง: "Fang Daeng",
  ดอนแก้ว: "Don Kaeo",
  ไพล: "Phlai",
  นาแก้ว: "Na Kaeo",
  วัดพระยาไกร: "Wat Phraya Krai",
  หนองกุงใหญ่: "Nong Kung Yai",
  ธงธานี: "Thong Thani",
  ยางงาม: "Yang Ngam",
  เชิงดอย: "Choeng Doi",
  ทับไทร: "Thap Sai",
  หนองสนิท: "Nong Sanit",
  สวนกล้วย: "Suan Kluai",
  หนองควาย: "Nong Khwai",
  บ้านขวาง: "Ban Khwang",
  โคกกระเบื้อง: "Khok Krabueang",
  สวาท: "Sawat",
  ทาปลาดุก: "Tha Pla Duk",
  เซิม: "Soem",
  โคกเคียน: "Khok Khian",
  จอมพล: "Chom Phon",
  โพพระ: "Pho Phra",
  เป็นสุข: "Pen Suk",
  กระหวะ: "Krawa",
  เกาะสำโรง: "Ko Samrong",
  ดอนกรวย: "Don Kruai",
  แดนชุมพล: "Daen Chumphon",
  ห้วยข่า: "Huai Kha",
  แก่นมะกรูด: "Kaen Makrut",
  ทุ่งเทิง: "Thung Thoeng",
  ปิงโค้ง: "Ping Khong",
  แคนเหนือ: "Khaen Nuea",
  พนมวังก์: "Phanom Wang",
  ท่าอิบุญ: "Tha Ibun",
  ภูฟ้า: "Phu Fa",
  นาชุมแสง: "Na Chum Saeng",
  ท่าขอนยาง: "Tha Khon Yang",
  ธรรมศาลา: "Thammasala",
  นาโยงใต้: "Na Yong Tai",
  เวียงมอก: "Wiang Mok",
  นาภู: "Na Phu",
  ลำพาน: "Lam Phan",
  ยามู: "Yamu",
  ห้วยเม็ก: "Huai Mek",
  ทะเลวังวัด: "Thale Wang Wat",
  หนองหญ้าปล้อง: "Nong Ya Plong",
  วัดตูม: "Wat Tum",
  บางชนะ: "Bang Chana",
  ยางโทน: "Yang Thon",
  ท่าบอน: "Tha Bon",
  โพนทอง: "Phon Thong",
  วัดสำโรง: "Wat Samrong",
  หินกอง: "Hin Kong",
  ปรุใหญ่: "Pru Yai",
  คุยม่วง: "Khui Muang",
  หัวไผ่: "Hua Phai",
  บ้านใหม่สามัคคี: "Ban Mai Samakkhi",
  คลองจั่น: "Khlong Chan",
  ผาเลือด: "Pha Lueat",
  โสมเยี่ยม: "Som Yiam",
  บ้านกุ่ม: "Ban Kum",
  ถ้ำเจริญ: "Tham Charoen",
  ขุนโขลน: "Khun Khlon",
  ม่วงคำ: "Muang Kham",
  บองอ: "Bo-ngo",
  ท่าใหม่: "Tha Mai",
  ภูคา: "Phu Kha",
  หนองแม่นา: "Nong Mae Na",
  เขาโร: "Khao Ro",
  บ้านไทย: "Ban Thai",
  อิตื้อ: "Itue",
  ลานดอกไม้ตก: "Lan Dokmai Tok",
  วรนคร: "Woranakhon",
  คลองทรายขาว: "Khlong Sai Khao",
  ปากพูน: "Pak Phun",
  ข้าวงาม: "Khao Ngam",
  คลองทราย: "Khlong Sai",
  ดอนกำ: "Don Kam",
  วารีสวัสดิ์: "Wari Sawat",
  ไชยภูมิ: "Chaiyaphum",
  หอไกร: "Ho Krai",
  ทุ่งกระบ่ำ: "Thung Krabam",
  ปากแจ่ม: "Pak Chaem",
  ชุมแพ: "Chum Phae",
  ตะลุบัน: "Taluban",
  ธารปราสาท: "Than Prasat",
  บ้านพร้าว: "Ban Phrao",
  ยางชุมใหญ่: "Yang Chum Yai",
  กุดเพียขอม: "Kut Phia Khom",
  โพแตง: "Pho Taeng",
  ดอยเต่า: "Doi Tao",
  แสนภูดาษ: "Saen Phu Dat",
  รั้วใหญ่: "Rua Yai",
  รมณีย์: "Rommani",
  บ่อแร่: "Bo Rae",
  โคกกว้าง: "Khok Kwang",
  โนนโหนน: "Non Non",
  มหาพราหมณ์: "Maha Phram",
  ทุ่งทอง: "Thung Thong",
  สะกำ: "Sakam",
  หนองนกเขียน: "Nong Nok Khian",
  น้ำรอบ: "Nam Rop",
  คลองปราบ: "Khlong Prap",
  นายูง: "Na Yung",
  โคกสลุด: "Khok Salut",
  ขะยูง: "Khayung",
  กุดน้อย: "Kut Noi",
  ชะไว: "Chawai",
  ศรีบุญเรือง: "Si Bun Rueang",
  อาษา: "Asa",
  บางสะแก: "Bang Sakae",
  บ้านฝาย: "Ban Fai",
  ควนขนุน: "Khuan Khanun",
  โซง: "Song",
  หนองเต่า: "Nong Tao",
  ตลาด: "Talat",
  กะรน: "Karon",
  โพธิ์: "Pho",
  วังทอง: "Wang Thong",
  ท่าด้วง: "Tha Duang",
  มะขามเตี้ย: "Makham Tia",
  วังพร้าว: "Wang Phrao",
  สายห้วยแก้ว: "Sai Huai Kaeo",
  ลำสนธิ: "Lam Sonthi",
  หนองกลับ: "Nong Klap",
  เกาะขวาง: "Ko Khwang",
  แม่คะ: "Mae Kha",
  พะเนียด: "Phaniat",
  ท่าศิลา: "Tha Sila",
  สำมะโรง: "Sam Marong",
  ปุยุด: "Puyut",
  จักรสีห์: "Chaksi",
  วัดประดู่: "Wat Pradu",
  หนองโพธิ์: "Nong Pho",
  คลองเขื่อน: "Khlong Khuean",
  วังโป่ง: "Wang Pong",
  ท่าวังพร้าว: "Tha Wang Phrao",
  คึกคัก: "Khuekkhak",
  เขาทอง: "Khao Thong",
  น้ำขาว: "Nam Khao",
  โนนภิบาล: "Non Phiban",
  หนองทรายขาว: "Nong Sai Khao",
  ทุ่งใหญ่: "Thung Yai",
  ต้นเปา: "Ton Pao",
  ดีลัง: "Di Lang",
  เวียงชัย: "Wiang Chai",
  เมืองหลวง: "Mueang Luang",
  ตาคง: "Ta Khong",
  คลองข่อย: "Khlong Khoi",
  ตำมะลัง: "Tam Malang",
  นิคมเขาบ่อแก้ว: "Nikhom Khao Bo Kaeo",
  ป่าแดง: "Pa Daeng",
  นางแดด: "Nang Daet",
  สะพานหิน: "Saphan Hin",
  บางแค: "Bang Khae",
  ถนนนครไชยศรี: "Thanon Nakhon Chai Si",
  กุดโบสถ์: "Kut Bot",
  โคกปีบ: "Khok Pip",
  พลงตาเอี่ยม: "Phlong Ta Iam",
  ลำชี: "Lam Chi",
  ทะเลทรัพย์: "Thale Sap",
  ห้วยจันทร์: "Huai Chan",
  บ้านดู่: "Ban Du",
  เกาะพระทอง: "Ko Phra Thong",
  ไร่ใหม่: "Rai Mai",
  ค่ายบกหวาน: "Khai Bok Wan",
  บางน้ำจืด: "Bang Nam Chuet",
  โพธิ์ประทับช้าง: "Pho Prathap Chang",
  บ้านราษฎร์: "Ban Rat",
  วันยาว: "Wan Yao",
  สี่ร้อย: "Si Roi",
  บางขวัญ: "Bang Khwan",
  เกาะช้าง: "Ko Chang",
  สวนพริก: "Suan Phrik",
  บ้านโข้ง: "Ban Khong",
  บ้านโนน: "Ban Non",
  ท่าศาลา: "Tha Sala",
  โคกกกม่วง: "Khok Kok Muang",
  บางหว้า: "Bang Wa",
  ม่วง: "Muang",
  เดื่อศรีคันไชย: "Duea Si Khan Chai",
  ประจัน: "Prachan",
  ชัยบุรี: "Chai Buri",
  ทุ่งหมอ: "Thung Mo",
  เกาะสุกร: "Ko Sukon",
  นาใน: "Na Nai",
  ม่วงหมู่: "Muang Mu",
  วังไคร้: "Wang Khrai",
  บางเหรียง: "Bang Riang",
  อุ่มเม่า: "Um Mao",
  หนองขนาน: "Nong Khanan",
  หนองแก: "Nong Kae",
  ป่าตาล: "Pa Tan",
  นาหม่อม: "Na Mom",
  ท่าผาปุ้ม: "Tha Pha Pum",
  พุทไธสง: "Phutthaisong",
  หนองตาแต้ม: "Nong Ta Taem",
  ทุ่งอรุณ: "Thung Arun",
  ลาดใหญ่: "Lat Yai",
  กองดิน: "Kong Din",
  บวกค้าง: "Buak Khang",
  สามเมือง: "Sam Mueang",
  ห้วยซ้อ: "Huai So",
  กุดสะเทียน: "Kut Sathian",
  ซับสมอทอด: "Sap Samo Thot",
  จีกแดก: "Chik Daek",
  หนองหมากฝ้าย: "Nong Mak Fai",
  บางขนุน: "Bang Khanun",
  เนินมะกอก: "Noen Makok",
  คอแลน: "Kho Laen",
  คลองไผ่: "Khlong Phai",
  พระโขนง: "Phra Khanong",
  บัวมาศ: "Bua Mat",
  บางซื่อ: "Bang Sue",
  หาดเสี้ยว: "Hat Siao",
  พระบาทวังตวง: "Phra Bat Wang Tuang",
  ห้วยปริก: "Huai Prik",
  หนองราชวัตร: "Nong Ratchawat",
  คำเหมือดแก้ว: "Kham Mueat Kaeo",
  หนองหิน: "Nong Hin",
  ชีน้ำร้าย: "Chi Nam Rai",
  คลองบางบอน: "Khlong Bang Bon",
  หนองลาด: "Nong Lat",
  บ้านจาน: "Ban Chan",
  บ้านชัย: "Ban Chai",
  ค้างพลู: "Khang Phu",
  ลำเพียก: "Lam Phiak",
  ถนนขาด: "Thanon Khat",
  ควนเมา: "Khuan Mao",
  ช้างซ้าย: "Chang Sai",
  บ้านคลัง: "Ban Khlang",
  ตากแดด: "Tak Daet",
  ช้างข้าม: "Chang Kham",
  ชุมภูพร: "Chumphu Phon",
  บือเระ: "Bue Re",
  นาครัว: "Na Khrua",
  วังสรรพรส: "Wang Sappharot",
  โคกตะเคียน: "Khok Takhian",
  ธัญญา: "Thanya",
  น้ำปาย: "Nam Pai",
  กระโด: "Krado",
  ท่ากว้าง: "Tha Kwang",
  มะขุนหวาน: "Makhun Wan",
  ศรีสุข: "Si Suk",
  สร่างโศก: "Sang Sok",
  นาเชือก: "Na Chueak",
  เมืองสวรรคโลก: "Mueang Sawankhalok",
  แคมป์สน: "Khaem Son",
  สำนักขาม: "Samnak Kham",
  แม่ข้าวต้ม: "Mae Khao Tom",
  ตาจั่น: "Ta Chan",
  ท้ายบ้าน: "Thai Ban",
  กะดุนง: "Kadunong",
  หนองแหน: "Nong Nae",
  ถ้ำสิงขร: "Tham Singkhon",
  หนองย่างเสือ: "Nong Yang Suea",
  เวียงใต้: "Wiang Tai",
  อำแพง: "Amphaeng",
  ศรีสำราญ: "Si Samran",
  เก่างิ้ว: "Kao Ngio",
  เขวาสินรินทร์: "Khwao Sinrin",
  เอราวัณ: "Erawan",
  สีกาย: "Si Kai",
  ดูน: "Dun",
  น้ำแก่น: "Nam Kaen",
  ทุ่งหวัง: "Thung Wang",
  ลำปลายมาศ: "Lam Plai Mat",
  ยางสักกระโพหลุ่ม: "Yang Sak Krapho Lum",
  หนองโน: "Nong No",
  สบสาย: "Sop Sai",
  มะมุ: "Mamu",
  ช่องแค: "Chong Khae",
  ป่าคลอก: "Pa Khlok",
  โนนตูม: "Non Tum",
  หนองสระ: "Nong Sa",
  หนองหาน: "Nong Han",
  แม่กุ: "Mae Ku",
  ผักไหม: "Phak Mai",
  บางน้ำผึ้ง: "Bang Nam Phueng",
  ทางพูน: "Thang Phun",
  บางวัว: "Bang Wua",
  หนองสูง: "Nong Sung",
  บางบ่อ: "Bang Bo",
  ลุโบะสาวอ: "Lubo Sawo",
  ระเริง: "Raroeng",
  ลำไทร: "Lam Sai",
  หอคำ: "Ho Kham",
  บึงทับแรต: "Bueng Thap Raet",
  วังทองหลาง: "Wang Thonglang",
  งิ้วราย: "Ngio Rai",
  เขาพนม: "Khao Phanom",
  ประตูชัย: "Pratu Chai",
  ไก่คำ: "Kai Kham",
  บึงเกลือ: "Bueng Kluea",
  ชมสะอาด: "Chom Sa-at",
  คลองชักพระ: "Khlong Chak Phra",
  คลองสวนพลู: "Khlong Suan Phlu",
  โนนแหลมทอง: "Non Laem Thong",
  ตลาดกระทุ่มแบน: "Talad Krathum Baen",
  เสาเล้า: "Sao Lao",
  โนนประดู่: "Non Pladu",
  ค้อทอง: "Kho Thong",
  เสมาใหญ่: "Sema Yai",
  บางพึ่ง: "Bang Phueng",
  บ้านพระ: "Ban Phra",
  ยายชา: "Yai Cha",
  ร้องเข็ม: "Rong Khem",
  หินโงม: "Hin Ngom",
  ท่ามะขาม: "Tha Makham",
  ลาดยาว: "Lat Yao",
  กลัดหลวง: "Klat Luang",
  โพนเพ็ก: "Phon Phek",
  ท่าลี่: "Tha Li",
  หนองกวั่ง: "Nong Kwang",
  วังงิ้วใต้: "Wang Ngio Tai",
  อู่ตะเภา: "U Taphao",
  บางกระเบา: "Bang Krabao",
  พิมูล: "Phimun",
  สะกาด: "Sakat",
  หนองพิกุล: "Nong Phikun",
  ชะแล: "Chalae",
  โคกปรง: "Khok Prong",
  บ้านคลองสวน: "Ban Khlong Suan",
  ท่าชัย: "Tha Chai",
  ช่องสะเดา: "Chong Sadao",
  คลีกลิ้ง: "Khli Kling",
  ศรีสุทโธ: "Si Suttho",
  บ้านแดง: "Ban Daeng",
  หนองเสม็ด: "Nong Samet",
  ทุ่งหัวช้าง: "Thung Hua Chang",
  บ้านทาน: "Ban Than",
  หนองงูเหลือม: "Nong Ngu Lueam",
  หนองเม็ก: "Nong Mek",
  ดอนยายหนู: "Don Yai Nu",
  โพธิ์ประสาท: "Pho Prasat",
  บ้านนิคม: "Ban Nikhom",
  จะทิ้งพระ: "Chathing Phra",
  ผักตบ: "Phak Top",
  ลาดหญ้า: "Lat Ya",
  สงยาง: "Song Yang",
  ลำผักกูด: "Lam Phak Kut",
  โพธิ์สัย: "Pho Sai",
  เทพมงคล: "Thep Mongkhon",
  รำพัน: "Ramphan",
  โกสัมพี: "Kosamphi",
  นาเข: "Na Khe",
  นาสิงห์: "Na Sing",
  หนองไร่: "Nong Rai",
  บ้านหาร: "Ban Han",
  เกาะกลาง: "Ko Klang",
  แสนชาติ: "Saen Chat",
  เดิด: "Doet",
  บางเลน: "Bang Len",
  ท่าขึ้น: "Tha Khuen",
  ห้วยขะยุง: "Huai Khayung",
  บางขาม: "Bang Kham",
  ตับเต่า: "Tap Tao",
  สามง่าม: "Sam Ngam",
  อุทัยใหม่: "Uthai Mai",
  ดงขวาง: "Dong Khwang",
  กะลาเส: "Kalase",
  บ่อพลับ: "Bo Phlap",
  บ้านน้อยซุ้มขี้เหล็ก: "Ban Noi Sum Khilek",
  ปรือใหญ่: "Prue Yai",
  บ้านบาก: "Ban Bak",
  ทุ่งน้อย: "Thung Noi",
  ท่าขมิ้น: "Tha Khamin",
  เขาแดง: "Khao Daeng",
  ท่าทองใหม่: "Tha Thong Mai",
  สำโรงกลาง: "Samrong Klang",
  กะหรอ: "Kalo",
  บ่อทอง: "Bo Thong",
  นาทราย: "Na Sai",
  ห้วยหินลาด: "Huai Hin Lat",
  คำผง: "Kham Phong",
  ลำไพล: "Lam Phlai",
  ขามเปี้ย: "Kham Pia",
  บันนังสาเรง: "Bannang Sareng",
  หนองหาร: "Nong Han",
  ทุ่งมหาเจริญ: "Thung Maha Charoen",
  เมืองเตา: "Mueang Tao",
  บ้านใหม่ไชยมงคล: "Ban Mai Chai Mongkhon",
  ศีรษะกระบือ: "Sisa Krabue",
  สนามไชย: "Sanam Chai",
  จิกสังข์ทอง: "Chik Sang Thong",
  พระเจ้า: "Phra Chao",
  วังหินลาด: "Wang Hin Lat",
  นางิ้ว: "Na Ngio",
  ตาหลังใน: "Ta Lang Nai",
  ห้วยมุ่น: "Huai Mun",
  สลกบาตร: "Salokbat",
  จะโหนง: "Chanong",
  กุดจิก: "Kut Chik",
  หนองฮาง: "Nong Hang",
  โคกสี: "Khok Si",
  ทับทัน: "Thap Than",
  โคกโคเฒ่า: "Khok Kho Thao",
  วังมะปรางเหนือ: "Wang Maprang Nuea",
  สีดา: "Sida",
  ป่าตึง: "Pa Tueng",
  เตราะบอน: "Tro Bon",
  ป่าสัก: "Pa Sak",
  สันป่าม่วง: "San Pa Muang",
  คลองโคน: "Khlong Khon",
  บางชัน: "Bang Chan",
  ศรีสาคร: "Si Sakhon",
  ป้อมปราบ: "Pom Prap",
  เกาะสะบ้า: "Ko Saba",
  สะดาวา: "Sadawa",
  บางลำภูล่าง: "Bang Lamphu Lang",
  กระเทียม: "Krathiam",
  นาหอ: "Na Ho",
  บางริ้น: "Bang Rin",
  รือเสาะ: "Rueso",
  เขาพระ: "Khao Phra",
  สีวิเชียร: "Si Wichian",
  ศรีเมืองชุม: "Si Mueang Chum",
  สระว่านพระยา: "Sa Wan Phraya",
  นาสะแบง: "Na Sabaeng",
  บ้านสวน: "Ban Suan",
  ท่าตะเกียบ: "Tha Takiap",
  บ้านครัว: "Ban Khrua",
  วังเหนือ: "Wang Nuea",
  ยางโกลน: "Yang Klon",
  ไทรน้อย: "Sai Noi",
  ชำแระ: "Chamrae",
  สะแกซำ: "Sakae Sam",
  เมืองพลับพลา: "Mueang Phlapphla",
  บ้านบัว: "Ban Bua",
  ไผ่ท่าโพ: "Phai Tha Pho",
  หมูม้น: "Mu Mon",
  บาเระเหนือ: "Bare Nuea",
  พุกร่าง: "Phu Krang",
  ตอหลัง: "Tolang",
  ลำเหย: "Lam Hoei",
  บางมด: "Bang Mot",
  เชิงแส: "Choeng Sae",
  พันท้ายนรสิงห์: "Phan Thai Norasing",
  แก้งสนามนาง: "Kaeng Sanam Nang",
  ทุ่งศรี: "Thung Si",
  หาดพันไกร: "Hat Phan Krai",
  ธาตุ: "That",
  กะบกเตี้ย: "Kabok Tia",
  เกาะเรียน: "Ko Rian",
  ร้องวัวแดง: "Rong Wua Daeng",
  หนองแขม: "Nong Khaem",
  บางนกแขวก: "Bang Nok Khwaek",
  ศรีสงคราม: "Si Songkhram",
  คีรีเขต: "Khiri Khet",
  ห้วยไผ่: "Huai Phai",
  หนองโรง: "Nong Rong",
  แจระแม: "Chaeramae",
  คลองนิยมยาตรา: "Khlong Niyom Yattra",
  ไร่น้อย: "Rai Noi",
  ไผ่ขวาง: "Phai Khwang",
  อีปาด: "I Pat",
  ห้วยแคน: "Huai Khaen",
  ทะเมนชัย: "Thamen Chai",
  วัดสามพระยา: "Wat Sam Phraya",
  คลองสามประเวศ: "Khlong Sam Prawet",
  สกาด: "Sakat",
  ทุ่งแต้: "Thung Tae",
  ภารแอ่น: "Phan Aen",
  ตาลเตี้ย: "Tan Tia",
  บางเสาธง: "Bang Sao Thong",
  นาป่า: "Na Pa",
  ทุ่งนางาม: "Thung Na Ngam",
  ดงกลาง: "Dong Klang",
  ตลาดใหญ่: "Talat Yai",
  วัดป่า: "Wat Pa",
  แหลมรัง: "Laem Rang",
  มหาดไทย: "Mahat Thai",
  บ้านหวด: "Ban Huat",
  เขาบางแกรก: "Khao Bang Kraek",
  แก้งแก: "Kaeng Kae",
  ท่าตาล: "Tha Tan",
  ดงเดือย: "Dong Dueai",
  หนองแสงใหญ่: "Nong Saeng Yai",
  ศิลาดาน: "Sila Dan",
  สีชมพู: "Si Chomphu",
  บ้านเต่า: "Ban Tao",
  กอตอตือร๊ะ: "Koto Tuera",
  นาสะเม็ง: "Na Sameng",
  บางปรอก: "Bang Prok",
  นวลจันทร์: "Nuan Chan",
  บ้านต้าย: "Ban Tai",
  แม่แตง: "Mae Taeng",
  หนองม่วงไข่: "Nong Muang Khai",
  พิมายเหนือ: "Phimai Nuea",
  บ่อเกลือใต้: "Bo Kluea Tai",
  เกาะหมาก: "Ko Mak",
  ภูมิ: "Phum",
  นากลาง: "Na Klang",
  ละหาร: "Lahan",
  กำแพงเพชร: "Kamphaeng Phet",
  สาวแห: "Sao Hae",
  หนองบัวดง: "Nong Bua Dong",
  บาละ: "Bala",
  สารจิตร: "San Chit",
  ทุ่งกระเชาะ: "Thung Kracho",
  สวนจิก: "Suan Chik",
  หาดนางแก้ว: "Hat Nang Kaeo",
  หนองแซง: "Nong Saeng",
  ชุมพลบุรี: "Chumphon Buri",
  บางมะฝ่อ: "Bang Mafo",
  หนองฮี: "Nong Hi",
  ห้วยพุก: "Huai Phuk",
  หัวเรือ: "Hua Ruea",
  แสมสาร: "Samaesan",
  หนองปรือ: "Nong Prue",
  หนองกุงทับม้า: "Nong Kung Thap Ma",
  หนองนาแซง: "Nong Na Saeng",
  นาดี: "Na Di",
  ปงเตา: "Pong Tao",
  ปวนพุ: "Puan Phu",
  ปากตะโก: "Pak Tako",
  นาเขลียง: "Na Khliang",
  หนองระฆัง: "Nong Rakhang",
  วังพิณพาทย์: "Wang Phinphat",
  คลองมะพลับ: "Khlong Maphlap",
  คำแก้ว: "Kham Kaeo",
  ยอดแกง: "Yot Kaeng",
  บางครุ: "Bang Khru",
  ไร่ใต้: "Rai Tai",
  ตะโละแมะนา: "Talo Maena",
  น้ำสวย: "Nam Suai",
  บ้านถ้ำ: "Ban Tham",
  รัตนบุรี: "Rattanaburi",
  ไผ่เขียว: "Phai Khiao",
  คลองคูณ: "Khlong Khun",
  คลองท่อมเหนือ: "Khlong Thom Nuea",
  กู่ทอง: "Ku Thong",
  ตูม: "Tum",
  แม่ปั๋ง: "Mae Pang",
  แม่แฝกใหม่: "Mae Faek Mai",
  บัวค้อ: "Bua Kho",
  ปล้อง: "Plong",
  บางเขน: "Bang Khen",
  ท้ายเกาะ: "Thai Ko",
  ท่าไม้: "Tha Mai",
  ท่าแลง: "Tha Laeng",
  กระแชง: "Krachaeng",
  โพสังโฆ: "Pho Sangkho",
  นางาม: "Na Ngam",
  เจริญศิลป์: "Charoen Sin",
  แม่กัวะ: "Mae Kua",
  ศรีจุฬา: "Si Chula",
  สาวะถี: "Sawathi",
  โคกก่ง: "Khok Kong",
  ฟ้าหยาด: "Fa Yat",
  พังลา: "Phang La",
  จอมพระ: "Chom Phra",
  กุดหวาย: "Kut Wai",
  แม่ระกา: "Mae Raka",
  วชิรพยาบาล: "Wachira Phayaban",
  สุวรรณคูหา: "Suwannakhuha",
  ค้อใหญ่: "Kho Yai",
  แม่น้ำคู้: "Maenam Khu",
  ท่าแพ: "Tha Phae",
  หนองทะเล: "Nong Thale",
  โพงาม: "Pho Ngam",
  ตำแย: "Tamyae",
  เขาเพิ่ม: "Khao Phoem",
  ท่าเยี่ยม: "Tha Yiam",
  ตาแกะ: "Takae",
  หล่มสัก: "Lom Sak",
  ศรีวิชัย: "Si Wichai",
  ฆอเลาะ: "Kholo",
  บ้านแฮด: "Ban Haet",
  บ้านหลวง: "Ban Luang",
  พะทาย: "Phathai",
  หนองผึ้ง: "Nong Phueng",
  ผาอินทร์แปลง: "Pha In Plaeng",
  ยานนาวา: "Yan Nawa",
  กุดป่อง: "Kut Pong",
  หางน้ำสาคร: "Hang Nam Sakhon",
  ไทรขึง: "Sai Khueng",
  สะพานสอง: "Saphan Song",
  กาลิซา: "Kalisa",
  หนองแม่ไก่: "Nong Mae Kai",
  นาทวี: "Na Thawi",
  ม่วงยาย: "Muang Yai",
  แม่ปาน: "Mae Pan",
  คุระ: "Khura",
  ไทรทอง: "Sai Thong",
  โคกมะม่วง: "Khok Mamuang",
  ศรีสัชนาลัย: "Si Satchanalai",
  โพนเมืองน้อย: "Phon Mueang Noi",
  หนองญาติ: "Nong Yat",
  ทุ่งผาสุข: "Thung Pha Suk",
  ไกรใน: "Krai Nai",
  บางคลาน: "Bang Khlan",
  หนองภัยศูนย์: "Nong Phai Sun",
  นาป่าแซง: "Na Pa Saeng",
  ดอนพุทรา: "Don Phutsa",
  กุดเรือ: "Kut Ruea",
  ขุนกระทิง: "Khun Krathing",
  ป่างิ้ว: "Pa Ngio",
  ทาขุมเงิน: "Tha Khum Ngoen",
  เขาขาว: "Khao Khao",
  ร่อนทอง: "Ron Thong",
  ยางฮอม: "Yang Hom",
  ปากพนังฝั่งตะวันตก: "Pak Phanang Fang Tawan Tok",
  โคกกุง: "Khok Kung",
  หันทราย: "Han Sai",
  แสนตอ: "Saen To",
  บางสวรรค์: "Bang Sawan",
  มะกรูด: "Makrut",
  ภูสิงห์: "Phu Sing",
  ลานข่อย: "Lan Khoi",
  มะเกลือใหม่: "Makluea Mai",
  สามควายเผือก: "Sam Khwai Phueak",
  สบเตี๊ยะ: "Sop Tia",
  คลัง: "Khlang",
  นามาลา: "Na Ma La",
  หน้าถ้ำ: "Na Tham",
  บ้านฉาง: "Ban Chang",
  วังข่อย: "Wang Khoi",
  หนองปลาไหล: "Nong Pla Lai",
  กุดใส้จ่อ: "Kut Sai Cho",
  หรเทพ: "Horathep",
  โชคเหนือ: "Chok Nuea",
  ทุ่งเตา: "Thung Tao",
  จานแสนไชย: "Chan Saen Chai",
  หนองม้า: "Nong Ma",
  ยาง: "Yang",
  โนนค่า: "Non Kha",
  แม่นาจาง: "Mae Na Chang",
  ทัพรั้ง: "Thap Rang",
  บุญทัน: "Bun Than",
  หนองช้างคืน: "Nong Chang Khuen",
  บ้านเบิก: "Ban Boek",
  วังควง: "Wang Khuang",
  วังด้ง: "Wang Dong",
  โพรงมะเดื่อ: "Phrong Maduea",
  ทุ่งศรีเมือง: "Thung Si Mueang",
  ลอมคอม: "Lom Khom",
  สุขเกษม: "Suk Kasem",
  สมเด็จ: "Somdet",
  โพหัก: "Pho Hak",
  ป่ามะม่วง: "Pa Mamuang",
  ระหาน: "Rahan",
  บะ: "Ba",
  ขามเฒ่า: "Kham Thao",
  พญาวัง: "Phaya Wang",
  คอโค: "Kho Kho",
  เมืองสรวง: "Mueang Suang",
  หาดล้า: "Hat La",
  ดงพระราม: "Dong Phra Ram",
  หนองบัวโคก: "Nong Bua Khok",
  กรูด: "Krut",
  คลองขนาก: "Khlong Khanak",
  พระธาตุขิงแกง: "Phra That Khing Kaeng",
  ไร่ขิง: "Rai Khing",
  ท่าทองหลาง: "Tha Thonglang",
  บ้านวัง: "Ban Wang",
  เมืองปราสาท: "Mueang Prasat",
  สะลวง: "Saluang",
  บางงาม: "Bang Ngam",
  บางปูใหม่: "Bang Pu Mai",
  วังประจัน: "Wang Prachan",
  ทรงคนอง: "Song Khanong",
  โนนราษี: "Non Rasi",
  วังสำโรง: "Wang Samrong",
  บุฝ้าย: "Bu Fai",
  สว่าง: "Sawang",
  คลองน้ำไหล: "Khlong Nam Lai",
  ฆะมัง: "Khamang",
  สวนผึ้ง: "Suan Phueng",
  หนองนาก: "Nong Nak",
  วังหมัน: "Wang Man",
  ทับตีเหล็ก: "Thap Ti Lek",
  ออนเหนือ: "On Nuea",
  พรุเตียว: "Phru Tiao",
  นครเจดีย์: "Nakhon Chedi",
  บางทราย: "Bang Sai",
  ตั้งใจ: "Tang Chai",
  กองโพน: "Kong Phon",
  หินเหล็กไฟ: "Hin Lek Fai",
  ดอนยาวใหญ่: "Don Yao Yai",
  คู: "Khu",
  ปึกเตียน: "Puek Tian",
  เชียงเพ็ง: "Chiang Pheng",
  ก้ามปู: "Kham Pu",
  พญาขัน: "Phaya Khan",
  เมืองบัว: "Mueang Bua",
  หนองหัวแรต: "Nong Hua Raet",
  โนนสวรรค์: "Non Sawan",
  ศรีโนนงาม: "Si Non Ngam",
  ประชาธิปัตย์: "Prachathipat",
  ไชยสถาน: "Chai Sathan",
  บุ่งคล้า: "Bung Khla",
  เชียงเครือ: "Chiang Khruea",
  คำเตย: "Kham Toei",
  คอนสวรรค์: "Khon Sawan",
  โพธิ์ชัย: "Pho Chai",
  ห้วยเหนือ: "Huai Nuea",
  บ้านช้าง: "Ban Chang",
  วังตะแบก: "Wang Tabaek",
  ฟากท่า: "Fak Tha",
  เมืองยาว: "Mueang Yao",
  สามบัณฑิต: "Sam Bandit",
  ธำมรงค์: "Thammarong",
  หนองบัวสันตุ: "Nong Bua Santu",
  ทุ่งคลี: "Thung Khli",
  โนนม่วง: "Non Muang",
  สันทราย: "San Sai",
  มิตรภาพ: "Mittraphap",
  ท่าสัก: "Tha Sak",
  คำพอุง: "Kham Pha-ung",
  ข่วงเปา: "Khuang Pao",
  สองคลอง: "Song Khlong",
  มหาชัย: "Maha Chai",
  โคกมะขาม: "Khok Makham",
  บ้านระกาศ: "Ban Rakat",
  คลองชีล้อม: "Khlong Chi Lom",
  ผึ่งแดด: "Phueng Daet",
  สมอพลือ: "Samo Phlue",
  ควนกรด: "Khuan Krot",
  น้ำทูน: "Nam Thun",
  ไม้แก่น: "Mai Kaen",
  ดอนไฟ: "Don Fai",
  ซับสมบูรณ์: "Sap Sombun",
  ทุ่งตะไคร: "Thung Takhrai",
  เมืองเก่าพัฒนา: "Mueang Kao Phatthana",
  ไม้งาม: "Mai Ngam",
  สบป่อง: "Sop Pong",
  ไกรกลาง: "Krai Klang",
  ท่าตอน: "Tha Ton",
  กกโพธิ์: "Kok Pho",
  คลองทับจันทร์: "Khlong Thap Chan",
  สาวอ: "Sawo",
  หันคา: "Hankha",
  บ้านทาม: "Ban Tham",
  สะพานสูง: "Saphan Sung",
  ดาโต๊ะ: "Dato",
  ตะเคียนเตี้ย: "Takhian Tia",
  นาอาน: "Na An",
  นายายอาม: "Na Yai Am",
  หนองขาว: "Nong Khao",
  โคกกรวด: "Khok Kruat",
  บางลี่: "Bang Li",
  การะเกด: "Karaket",
  เขื่อนผาก: "Khuean Phak",
  วังบ่อ: "Wang Bo",
  บางคอแหลม: "Bang Kho Laem",
  ดงมหาวัน: "Dong Maha Wan",
  บางกล่ำ: "Bang Klam",
  เริงราง: "Roeng Rang",
  อ่ายนาไลย: "Ai Na Lai",
  พญาไท: "Phaya Thai",
  อ้อมน้อย: "Om Noi",
  สำโรงพลัน: "Samrong Phlan",
  กุดเชียงหมี: "Kut Chiang Mi",
  โนนคอม: "Non Khom",
  จรัส: "Charat",
  วัดตายม: "Wat Ta Yom",
  เกาะแก้ว: "Ko Kaeo",
  จระเข้หิน: "Chorakhe Hin",
  สะเดาะพง: "Sado Phong",
  ทับสวาย: "Thap Sawai",
  แม่สาว: "Mae Sao",
  วังม่วง: "Wang Muang",
  สีสุก: "Si Suk",
  สะนอ: "Sano",
  ทับเที่ยง: "Thap Thiang",
  หัวง้ม: "Hua Ngom",
  เค็งใหญ่: "Kheng Yai",
  เขาไพร: "Khao Phrai",
  มหาไชย: "Maha Chai",
  เสาธง: "Sao Thong",
  โป่งแดง: "Pong Daeng",
  คลองหอยโข่ง: "Khlong Hoi Khong",
  สามพราน: "Sam Phran",
  ดงมะดะ: "Dong Mada",
  หน้าเขา: "Na Khao",
  ฟ้าฮ่าม: "Fa Ham",
  เลยวังไสย์: "Loei Wang Sai",
  บ้านหว้า: "Ban Wa",
  บางสมบูรณ์: "Bang Sombun",
  อู่ทอง: "U Thong",
  บางตะบูนออก: "Bang Tabun Ok",
  เขาดิน: "Khao Din",
  บ้านสระ: "Ban Sa",
  ลำตาเสา: "Lam Ta Sao",
  กุยเหนือ: "Kui Nuea",
  อัยเยอร์เวง: "Aiyoeweng",
  ห้วยโรง: "Huai Rong",
  นานกกก: "Na Nok Kok",
  ศรีดอนไชย: "Si Don Chai",
  สันป่าตอง: "San Pa Tong",
  ทุ่งขนาน: "Thung Khanan",
  หนองหลุม: "Nong Lum",
  มหาราช: "Maha Rat",
  กะรุบี: "Karubi",
  ดอนโพธิ์ทอง: "Don Pho Thong",
  นิคมห้วยผึ้ง: "Nikhom Huai Phueng",
  ป่าแมต: "Pa Maet",
  ดอนเมือง: "Don Mueang",
  บางแวก: "Bang Waek",
  พรุไทย: "Phru Thai",
  สามเรือน: "Sam Ruean",
  คันโช้ง: "Khan Chong",
  บุพราหมณ์: "Bu Phram",
  ร่องกาศ: "Rong Kat",
  เขาไชยราช: "Khao Chai Rat",
  วังเพิ่ม: "Wang Phoem",
  ตาลเลียน: "Tan Lian",
  ป่าพะยอม: "Pa Phayom",
  เขวาใหญ่: "Khwao Yai",
  วังยาง: "Wang Yang",
  หนองอ้ม: "Nong Om",
  ด่านช้าง: "Dan Chang",
  ลำนารายณ์: "Lam Narai",
  คลองเกลือ: "Khlong Kluea",
  ถ้ำลอด: "Tham Lot",
  สามพวง: "Sam Phuang",
  โพนเขวา: "Phon Khwao",
  หนองบอน: "Nong Bon",
  โนนแดง: "Non Daeng",
  ครน: "Khron",
  ลีเล็ด: "Lilet",
  ตะกุดไร: "Takut Rai",
  ดอนแฝก: "Don Faek",
  ปรังเผล: "Prang Phle",
  บางพรม: "Bang Phrom",
  นาหลวงเสน: "Na Luang Sen",
  ไทยาวาส: "Thaiyawat",
  ศรีชมภู: "Si Chomphu",
  สำนักท้อน: "Samnak Thon",
  แนงมุด: "Naeng Mut",
  ลานหอย: "Lan Hoi",
  แม่ศึก: "Mae Suek",
  หนองตากยา: "Nong Tak Ya",
  ตองปิด: "Tong Pit",
  เมืองเล็น: "Mueang Len",
  แดงใหญ่: "Daeng Yai",
  ศิริราช: "Sirirat",
  ขุย: "Khui",
  บัวโคก: "Bua Khok",
  นาด้วง: "Na Duang",
  ทุ่งแก: "Thung Kae",
  คำพราน: "Kham Phran",
  เหล่ายาว: "Lao Yao",
  ชะมาย: "Chamai",
  หัวหว้า: "Hua Wa",
  บ้านหอย: "Ban Hoi",
  เมาะมาวี: "Mo Mawi",
  ค้อเหนือ: "Kho Nuea",
  บึงเสนาท: "Bueng Senat",
  ดู่ลาด: "Du Lat",
  หนองเหียง: "Nong Hiang",
  สระกระโจม: "Sa Krachom",
  ห้วยข้าวก่ำ: "Huai Khao Kam",
  บางปลากด: "Bang Pla Kot",
  นาขา: "Na Kha",
  พังตรุ: "Phang Tru",
  ส้มผ่อ: "Som Pho",
  โพนสูง: "Phon Sung",
  กุดขาคีม: "Kut Kha Khim",
  ทับยายเชียง: "Thap Yai Chiang",
  หินดาต: "Hin Dat",
  หนองขนาก: "Nong Khanak",
  หนองตำลึง: "Nong Tamlueng",
  วังบัว: "Wang Bua",
  นาม่วง: "Na Muang",
  อุ่มเม้า: "Um Mao",
  แม่เงิน: "Mae Ngoen",
  หินซ้อน: "Hin Son",
  เขาพัง: "Khao Phang",
  แม่หละ: "Mae La",
  ไพรวัน: "Phraiwan",
  บ้านใหญ่: "Ban Yai",
  ตะโกทอง: "Tako Thong",
  น้ำชำ: "Nam Cham",
  บ้านค่า: "Ban Kha",
  เลิงแฝก: "Loeng Faek",
  โคคลาน: "Kho Khlan",
  หนองพระ: "Nong Phra",
  ดอนกระเบื้อง: "Don Krabueang",
  บ้านเสี้ยว: "Ban Siao",
  โพธิ์แทน: "Pho Thaen",
  ทุ่งคล้า: "Thung Khla",
  ค้อน้อย: "Kho Noi",
  พนม: "Phanom",
  แม่อิง: "Mae Ing",
  พงศ์ประศาสน์: "Phong Prasat",
  ยางตลาด: "Yang Talat",
  จันอัด: "Chan At",
  โพทะเล: "Pho Thale",
  นาข้าวเสีย: "Na Khao Sia",
  ทับคาง: "Thap Khang",
  หนองเสาเล้า: "Nong Sao Lao",
  ดู่: "Du",
  หลักเหลี่ยม: "Lak Liam",
  จอเบาะ: "Cho Bo",
  วังมะนาว: "Wang Manao",
  บ้านลาด: "Ban Lat",
  ท่าหินโงม: "Tha Hin Ngom",
  หัวเสือ: "Hua Suea",
  ดอนมะโนรา: "Don Manora",
  เตย: "Toei",
  บางตะพง: "Bang Taphong",
  นาแต้: "Na Tae",
  เสอเพลอ: "Soephloe",
  หนองอีบุตร: "Nong I But",
  สระกรวด: "Sa Kruat",
  แวง: "Waeng",
  ดงสมบูรณ์: "Dong Sombun",
  แม่ลาหลวง: "Mae La Luang",
  นาขมิ้น: "Na Khamin",
  สำราญราษฎร์: "Samran Rat",
  โนนพะยอม: "Non Phayom",
  นาไร่หลวง: "Na Rai Luang",
  บึงงาม: "Bueng Ngam",
  เหล: "Le",
  ไผ่หูช้าง: "Phai Hu Chang",
  ลำสมพุง: "Lam Somphung",
  อุทัยสวรรค์: "Uthai Sawan",
  แม่เปิน: "Mae Poen",
  เข็กน้อย: "Khek Noi",
  ม่วงกลวง: "Muang Kluang",
  ราชาเทวะ: "Racha Thewa",
  บ้านขาว: "Ban Khao",
  นาชุมเห็ด: "Na Chum Het",
  นาเลิน: "Na Loen",
  ปากเพรียว: "Pak Phriao",
  กระบี่น้อย: "Krabi Noi",
  แม่จะเรา: "Mae Charao",
  ปุโรง: "Purong",
  เจดีย์คำ: "Chedi Kham",
  ท่าคอย: "Tha Khoi",
  ตลาดขวัญ: "Talat Khwan",
  ผาสิงห์: "Pha Sing",
  โนนเมือง: "Non Mueang",
  เสียว: "Siao",
  ห้วยเขน: "Huai Khen",
  หนองยาว: "Nong Yao",
  หนองเพรางาย: "Nong Phrao Ngai",
  สากเหล็ก: "Sak Lek",
  บางปู: "Bang Pu",
  หัวเดียด: "Hua Diat",
  โพธิ์วงศ์: "Pho Wong",
  ตะคร้ำเอน: "Takhram En",
  บ้านโปร่ง: "Ban Prong",
  พบพระ: "Phop Phra",
  สามวาตะวันออก: "Sam Wa Tawan Ok",
  ยางค้อม: "Yang Khom",
  ปากแตระ: "Pak Trae",
  หนองงิ้ว: "Nong Ngio",
  คลองกระบือ: "Khlong Krabue",
  โคกเหล็ก: "Khok Lek",
  ท่าล้อ: "Tha Lo",
  ปางหมู: "Pang Mu",
  พะงาด: "Pha-ngat",
  สระเยาว์: "Sa Yao",
  ดอนมูล: "Don Mun",
  ป่าแดด: "Pa Daet",
  หลุบเลา: "Lup Lao",
  นาดง: "Na Dong",
  ดงดำ: "Dong Dam",
  เทพนิมิต: "Thep Nimit",
  บ้านปรก: "Ban Prok",
  สะกอม: "Sakom",
  อาฮี: "Ahi",
  ละทาย: "Lathai",
  บางยอ: "Bang Yo",
  รางบัว: "Rang Bua",
  หนองช้าง: "Nong Chang",
  นนทรี: "Nonsi",
  ประกอบ: "Prakop",
  บึงทองหลาง: "Bueng Thonglang",
  ฉลุง: "Chalung",
  เหล่าต่างคำ: "Lao Tang Kham",
  หนองโบสถ์: "Nong Bot",
  ตากตก: "Tak Tok",
  เนินศาลา: "Noen Sala",
  แม่เปา: "Mae Pao",
  ศรีละกอ: "Si Lako",
  กำแพง: "Kamphaeng",
  นาเกษม: "Na Kasem",
  ไหล่ทุ่ง: "Lai Thung",
  ทุ่งกระพังโหม: "Thung Kraphang Hom",
  แก่งศรีภูมิ: "Kaeng Si Phum",
  บ้านลาน: "Ban Lan",
  ไม้เค็ด: "Mai Khet",
  ฉวาง: "Chawang",
  ป่าอ้อ: "Pa O",
  น้ำเป็น: "Nam Pen",
  ขอนคลาน: "Khon Khlan",
  ตะกั่วป่า: "Takua Pa",
  เขาสามยอด: "Khao Sam Yot",
  กลอนโด: "Klon Do",
  คลองสะแก: "Khlong Sakae",
  เชียงบาน: "Chiang Ban",
  บ่อยาง: "Bo Yang",
  ศรีแก้ว: "Si Kaeo",
  ท่าแร่: "Tha Rae",
  กุดดินจี่: "Kut Din Chi",
  ดงหมู: "Dong Mu",
  ท่าแร้ง: "Tha Raeng",
  ชนแดน: "Chon Daen",
  กุดเลาะ: "Kut Lo",
  ทุ่ม: "Thum",
  ห้วยยาบ: "Huai Yap",
  วงฆ้อง: "Wong Khong",
  โพธิ์หมากแข้ง: "Pho Mak Khaeng",
  ดาวคะนอง: "Dao Khanong",
  ลำทับ: "Lam Thap",
  ลาดขวาง: "Lat Khwang",
  โคกพระ: "Khok Phra",
  ดม: "Dom",
  บางยี่โท: "Bang Yi Tho",
  ชอนน้อย: "Chon Noi",
  หนองเชียงทูน: "Nong Chiang Thun",
  บางพูด: "Bang Phut",
  วังใหม่: "Wang Mai",
  เรือง: "Rueang",
  พันนา: "Phan Na",
  ตะนาวศรี: "Tanao Si",
  โพนางดำออก: "Pho Nang Dam Ok",
  หัวช้าง: "Hua Chang",
  หมอนทอง: "Mon Thong",
  ท่าวังทอง: "Tha Wang Thong",
  ตลาดไทร: "Talat Sai",
  สายคำโห้: "Sai Kham Ho",
  เอกราช: "Ekkarat",
  เจ้าเสด็จ: "Chao Sadet",
  ตลาดหลวง: "Talat Luang",
  ปากหมาก: "Pak Mak",
  นาตงวัฒนา: "Na Tong Watthana",
  วังจุฬา: "Wang Chula",
  ควร: "Khuan",
  ห้วยแก้ว: "Huai Kaeo",
  ขุนควร: "Khun Khuan",
  งอบ: "Ngop",
  เกาะแต้ว: "Ko Taeo",
  โพชนไก่: "Pho Chon Kai",
  รังกาใหญ่: "Rang Ka Yai",
  เจ้าวัด: "Chao Wat",
  ห้วยงู: "Huai Ngu",
  สะเตงนอก: "Sateng Nok",
  ทองหลาง: "Thonglang",
  ปาฝา: "Pa Fa",
  เขื่อน: "Khuean",
  ละหานนา: "Lahan Na",
  บึงนคร: "Bueng Nakhon",
  กื้ดช้าง: "Kuet Chang",
  ลาดตะเคียน: "Lat Takhian",
  มงคลธรรมนิมิต: "Mongkhon Tham Nimit",
  คลองพิไกร: "Khlong Phikrai",
  ทางพระ: "Thang Phra",
  ปางกู่: "Pang Ku",
  ทุ่งแล้ง: "Thung Laeng",
  คูสะคาม: "Khu Sakham",
  ห้วยทราย: "Huai Sai",
  สีพยา: "Si Phaya",
  เบญจขร: "Benchakhon",
  ปากเกร็ด: "Pak Kret",
  กระจาย: "Krachai",
  คึมใหญ่: "Khuem Yai",
  กระดังงา: "Kradangnga",
  กลางเวียง: "Klang Wiang",
  เสาธงชัย: "Sao Thong Chai",
  มะนาวหวาน: "Manao Wan",
  เสือเฒ่า: "Suea Thao",
  ยางหัก: "Yang Hak",
  หาดกรวด: "Hat Kruat",
  โพธิ์รังนก: "Pho Rang Nok",
  โคกกลาง: "Khok Klang",
  บางโกระ: "Bang Kro",
  สระสี่เหลี่ยม: "Sa Si Liam",
  พัฒนาการ: "Phatthanakan",
  พลับพลา: "Phlapphla",
  เมืองแคน: "Mueang Khaen",
  แวงน้อย: "Waeng Noi",
  ราไวย์: "Rawai",
  ปางตาไว: "Pang Ta Wai",
  บึงมะลู: "Bueng Malu",
  บางรักใหญ่: "Bang Rak Yai",
  ตาเกา: "Ta Kao",
  สำเหร่: "Samre",
  บ้านติ้ว: "Ban Tio",
  คีรีวง: "Khiri Wong",
  คลองเขิน: "Khlong Khoen",
  บางหิน: "Bang Hin",
  เหล่าบัวบาน: "Lao Bua Ban",
  วังผาง: "Wang Phang",
  บ่อแก้ว: "Bo Kaeo",
  โนนสว่าง: "Non Sawang",
  โพธิ์ศรีสว่าง: "Pho Si Sawang",
  สิงโตทอง: "Singto Thong",
  เก่ากลอย: "Kao Kloi",
  สองพี่น้อง: "Song Phi Nong",
  ปะโด: "Pado",
  หนองม่วง: "Nong Muang",
  ทุ่งนุ้ย: "Thung Nui",
  โป่งแยง: "Pong Yaeng",
  บางดี: "Bang Di",
  ตะโละกาโปร์: "Talo Kapo",
  โป่ง: "Pong",
  อุโลกสี่หมื่น: "Ulok Si Muen",
  หนองบัวบาน: "Nong Bua Ban",
  ละอุ่นใต้: "La-un Tai",
  ออนใต้: "On Tai",
  ตันหยงดาลอ: "Tanyong Dalo",
  นาข่า: "Na Kha",
  ส้มป่อย: "Som Poi",
  วัดโสมนัส: "Wat Sommanat",
  แดงหม้อ: "Daeng Mo",
  บ้านธิ: "Ban Thi",
  บางกะดี: "Bang Kadi",
  ท่ากระดาน: "Tha Kradan",
  นาจารย์: "Na Chan",
  พรมเทพ: "Phrom Thep",
  บ้านอ้อน: "Ban On",
  หนองน้ำส้ม: "Nong Nam Som",
  เมืองคอง: "Mueang Khong",
  ปากชม: "Pak Chom",
  หันตะเภา: "Han Taphao",
  หนองแสง: "Nong Saeng",
  ท่านัด: "Tha Nat",
  ถนนพญาไท: "Thanon Phaya Thai",
  จองคำ: "Chong Kham",
  สระกำแพงใหญ่: "Sa Kamphaeng Yai",
  ดงมอน: "Dong Mon",
  คลองมหานาค: "Khlong Maha Nak",
  บ้านฆ้อง: "Ban Khong",
  โคกภู: "Khok Phu",
  ผาขาว: "Pha Khao",
  บ่อเวฬุ: "Bo Weru",
  บึงบูรพ์: "Bueng Bun",
  โคกไม้ลาย: "Khok Mai Lai",
  หนองขวาว: "Nong Khwao",
  โคกเครือ: "Khok Khruea",
  กกสะทอน: "Kok Sathon",
  เวียงตาล: "Wiang Tan",
  นาสาร: "Na San",
  โคกสนวน: "Khok Sanuan",
  ศรีนคร: "Si Nakhon",
  โคกหินแฮ่: "Khok Hin Hae",
  ทรัพย์ไพวัลย์: "Sap Phaiwan",
  ธาตุทอง: "That Thong",
  ขามทะเลสอ: "Kham Thale So",
  หนองไทร: "Nong Sai",
  แม่นาจร: "Mae Na Chon",
  กุดธาตุ: "Kut That",
  หนองตอง: "Nong Tong",
  เขาพระทอง: "Khao Phra Thong",
  ลำพะยา: "Lam Phaya",
  กระเดียน: "Kradian",
  วังหมี: "Wang Mi",
  เชิงทะเล: "Choeng Thale",
  มาบไผ่: "Map Phai",
  จรเข้สามพัน: "Chorakhe Sam Phan",
  บางหัก: "Bang Hak",
  ตรีณรงค์: "Tri Narong",
  หินลาด: "Hin Lat",
  ทุ่งครุ: "Thung Khru",
  รังนก: "Rang Nok",
  ยี่งอ: "Yi-ngo",
  ปากน้ำปราณ: "Pak Nam Pran",
  โคกคราม: "Khok Khram",
  ไร่เก่า: "Rai Kao",
  ท่ามะนาว: "Tha Manao",
  หอมเกร็ด: "Hom Kret",
  ดงชน: "Dong Chon",
  จตุจักร: "Chatuchak",
  คูคำ: "Khu Kham",
  เขามีเกียรติ: "Khao Mi Kiat",
  บัวสลี: "Bua Sali",
  พุทเลา: "Phut Lao",
  เสริมขวา: "Soem Khwa",
  ท่าโพธิ์ศรี: "Tha Pho Si",
  สะเอะ: "Sa-e",
  ถ้ำวัวแดง: "Tham Wua Daeng",
  เขาท่าพระ: "Khao Tha Phra",
  ท่าอ่าง: "Tha Ang",
  มหาสอน: "Maha Son",
  แกใหญ่: "Kae Yai",
  ประสงค์: "Prasong",
  สร้อยละคร: "Soi Lakhon",
  บ้านสา: "Ban Sa",
  บ้านยา: "Ban Ya",
  หนองปากโลง: "Nong Pak Long",
  ห้วยราช: "Huai Rat",
  โนนกอก: "Non Kok",
  บ้านคู: "Ban Khu",
  มะขามคู่: "Makham Khu",
  ด่านมะขามเตี้ย: "Dan Makham Tia",
  จะบังติกอ: "Chabang Tiko",
  นาซอ: "Na So",
  ราตาปันยัง: "Rata Panyang",
  วังน้ำเย็น: "Wang Nam Yen",
  ลำนางแก้ว: "Lam Nang Kaeo",
  ห้วยยอด: "Huai Yot",
  วังอ่าง: "Wang Ang",
  บางทรายน้อย: "Bang Sai Noi",
  ห้วยเกตุ: "Huai Ket",
  พุทธบาท: "Phutthabat",
  ดงละคร: "Dong Lakhon",
  ดินจี่: "Din Chi",
  มาบกราด: "Map Krat",
  แม่แวน: "Mae Waen",
  ท่ากำชำ: "Tha Kamcham",
  ตะเคียนทอง: "Takhian Thong",
  หูทำนบ: "Hu Thamnop",
  กระโพ: "Krapho",
  น้ำพุ: "Nam Phu",
  หนองไผ่ล้อม: "Nong Phai Lom",
  ผักขวง: "Phak Khuang",
  ป่าไหน่: "Pa Nai",
  ทุ่งตำเสา: "Thung Tam Sao",
  หนองป่าก่อ: "Nong Pa Ko",
  ละเวี้ย: "Lawia",
  โคกสัก: "Khok Sak",
  โนนไทย: "Non Thai",
  ผาบ่อง: "Pha Bong",
  โสกก่าม: "Sok Kam",
  เมืองพาน: "Mueang Phan",
  หนองขยาด: "Nong Khayat",
  โพธิ์ม่วงพันธ์: "Pho Muang Phan",
  หนองพันจันทร์: "Nong Phan Chan",
  ท่าแฝก: "Tha Faek",
  แม่ดง: "Mae Dong",
  ม่วงสามสิบ: "Muang Sam Sip",
  โพนแพง: "Phon Phaeng",
  ทุ่งยาว: "Thung Yao",
  นาราชควาย: "Na Rat Khwai",
  บ้านด้าย: "Ban Dai",
  ตะบ่าย: "Tabai",
  หนองนาง: "Nong Nang",
  กระแสสินธุ์: "Krasae Sin",
  คลองหรัง: "Khlong Rang",
  ด่านแม่ละเมา: "Dan Mae Lamao",
  หนองหลวง: "Nong Luang",
  สะเนียน: "Sanian",
  ทรายขาว: "Sai Khao",
  โนนปอแดง: "Non Po Daeng",
  หนองแปน: "Nong Paen",
  สะพุง: "Saphung",
  วัดสุวรรณ: "Wat Suwan",
  พัฒนานิคม: "Phatthana Nikhom",
  สีกัน: "Si Kan",
  พันดุง: "Phan Dung",
  บ้าหวี: "Ba Wi",
  หาดส้มแป้น: "Hat Som Paen",
  ดงเมือง: "Dong Mueang",
  ธาตุนาเวง: "That Na Weng",
  สระโบสถ์: "Sa Bot",
  ป่าเลา: "Pa Lao",
  ปางมะค่า: "Pang Makha",
  พระยาทด: "Phraya Thot",
  กู่สวนแตง: "Ku Suan Taeng",
  ไผ่ดำพัฒนา: "Phai Dam Phatthana",
  ดงตะงาว: "Dong Ta-ngao",
  คอกกระบือ: "Khok Krabue",
  ภูเงิน: "Phu Ngoen",
  ดอนกำยาน: "Don Kamyan",
  วังกะทะ: "Wang Katha",
  สายไหม: "Sai Mai",
  ลาดควาย: "Lat Khwai",
  สันทรายหลวง: "San Sai Luang",
  สามง่ามท่าโบสถ์: "Sam Ngam Tha Bot",
  วัดยางงาม: "Wat Yang Ngam",
  แม่นาเรือ: "Mae Na Ruea",
  ทุ่งศรีทอง: "Thung Si Thong",
  ยางสาว: "Yang Sao",
  สามพระยา: "Sam Phraya",
  โคกคอน: "Khok Khon",
  เสาเดียว: "Sao Diao",
  บางเคียน: "Bang Khian",
  เกาะเพชร: "Ko Phet",
  บางป่า: "Bang Pa",
  ปากแคว: "Pak Khwae",
  เขาปู่: "Khao Pu",
  คลองยา: "Khlong Ya",
  นาหมอบุญ: "Na Mo Bun",
  หนองวัวซอ: "Nong Wua So",
  หงษ์เจริญ: "Hong Charoen",
  ตาสัง: "Ta Sang",
  เชียงยืน: "Chiang Yuen",
  บางโตนด: "Bang Tanot",
  ม่วงลาย: "Muang Lai",
  บ่อตาโล่: "Bo ta Lo",
  เกาะตาล: "Ko Tan",
  วังน้ำเขียว: "Wang Nam Khiao",
  เก่าขาม: "Kao Kham",
  โนนขมิ้น: "Non Khamin",
  บางรัก: "Bang Rak",
  บางขุนทอง: "Bang Khun Thong",
  นากอก: "Na Kok",
  น้ำร้อน: "Nam Ron",
  สง่าบ้าน: "Sa-nga Ban",
  คลองน้ำใส: "Khlong Nam Sai",
  แม่สำ: "Mae Sam",
  หนองหงษ์: "Nong Hong",
  ท่าตะคร้อ: "Tha Ta Khro",
  ท่างิ้ว: "Tha Ngio",
  บางปลาม้า: "Bang Pla Ma",
  ถาวร: "Thawon",
  เหมืองจี้: "Mueang Chi",
  ท่าวุ้ง: "Tha Wung",
  คำนาดี: "Kham Na Di",
  คูขาด: "Khu Khat",
  คำไผ่: "Kham Phai",
  ไม้ดัด: "Mai Dat",
  แม่แดด: "Mae Daet",
  โนนหัน: "Non Han",
  หนองบัวใต้: "Nong Bua Tai",
  ดูนสาด: "Dun Sat",
  หนองสามวัง: "Nong Sam Wang",
  หนองกบ: "Nong Kop",
  ชีบน: "Chi Bon",
  วัฒนานคร: "Watthana Nakhon",
  กำโลน: "Kamlon",
  ศรีเตี้ย: "Si Tia",
  ควนปริง: "Khuan Pring",
  ศาลากลาง: "Sala Klang",
  แม่สอด: "Mae Sot",
  แก่งโดม: "Kaeng Dom",
  หัวหวาย: "Hua Wai",
  ปะแต: "Patae",
  เลม็ด: "Lamet",
  ธารโต: "Than To",
  สันกลาง: "San Klang",
  บะฮี: "Ba Hi",
  ทุ่งคา: "Thung Kha",
  มะรือโบออก: "Maruebo Ok",
  บ้านค่าย: "Ban Khai",
  นาเกลือ: "Na Kluea",
  วัง: "Wang",
  ไค้นุ่น: "Khai Nun",
  ทุ่งพง: "Thung Phong",
  บ้านปิน: "Ban Pin",
  หนองกะทิง: "Nong Kathing",
  ศาลเจ้าพ่อเสือ: "San Chao Pho Suea",
  เกาะลอย: "Ko Loi",
  หนองปลิง: "Nong Pling",
  บางแพ: "Bang Phae",
  ศิลาลอย: "Sila Loi",
  บ่อใหญ่: "Bo Yai",
  ชะอำ: "Cha-am",
  เชียงเคี่ยน: "Chiang Khian",
  มะตูม: "Matum",
  แจ้ห่ม: "Chae Hom",
  ลุมพินี: "Lumphini",
  ดอนหัวฬ่อ: "Don Hua Lo",
  สำเภาลูน: "Samphao Lun",
  ป่าไร่: "Pa Rai",
  มาบตะโกเอน: "Map Tako En",
  จันทนิมิต: "Chanthanimit",
  ตานี: "Tani",
  โกรกแก้ว: "Krok Kaeo",
  ป่าไก่: "Pa Kai",
  ไทรย้อย: "Sai Yoi",
  ตะโล๊ะหะลอ: "Talo Halo",
  สว่างอารมณ์: "Sawang Arom",
  บ้านหัน: "Ban Han",
  ขนาบนาก: "Khanap Nak",
  ปิล๊อก: "Pilok",
  นิคม: "Nikhom",
  มดแดง: "Mot Daeng",
  เกาะเปาะ: "Ko Po",
  แม่แฝก: "Mae Faek",
  ไชยบุรี: "Chaiburi",
  สามขา: "Sam Kha",
  หนองสิม: "Nong Sim",
  ขมิ้น: "Khamin",
  โคกสะบ้า: "Khok Saba",
  ศาลาครุ: "Sala Khru",
  ตลาดพลู: "Talat Phlu",
  ทุ่งค่าย: "Thung Khai",
  น้ำซึม: "Nam Suem",
  คลองสวน: "Khlong Suan",
  ไทรงาม: "Sai Ngam",
  สวนป่าน: "Suan Pan",
  สัมพันธวงศ์: "Samphanthawong",
  ศรีบรรพต: "Si Banphot",
  ดู่พงษ์: "Du Phong",
  เซเป็ด: "Se Pet",
  ปราสาททนง: "Prasat Thanong",
  แม่ถอด: "Mae Thot",
  บ้านหม้อ: "Ban Mo",
  โสกปลาดุก: "Sok Pla Duk",
  ห้วยพลู: "Huai Phlu",
  วันดาว: "Wan Dao",
  นาฮี: "Na Hi",
  หลักแก้ว: "Lak Kaeo",
  ด่านม่วงคำ: "Dan Muang Kham",
  โพธาราม: "Photharam",
  เบตง: "Betong",
  บางประแดง: "Bang Pradaeng",
  คลองด่าน: "Khlong Dan",
  บางศรีเมือง: "Bang Si Mueang",
  กระเบื้อง: "Krabueang",
  ควน: "Khuan",
  ท่าชุมพล: "Tha Chumphon",
  ทุ่งต้อม: "Thung Tom",
  สงเปลือย: "Song Plueai",
  ท่าชะมวง: "Tha Chamuang",
  บ้านเลื่อม: "Ban Lueam",
  มายอ: "Mayo",
  ไทรม้า: "Sai Ma",
  บ้านเป้า: "Ban Pao",
  กาวะ: "Kawa",
  อำนาจ: "amnat",
  คลองหลา: "Khlong La",
  แพรกหนามแดง: "Phraek Nam Daeng",
  น้ำก้อ: "Nam Ko",
  หนองเมธี: "Nong Methi",
  ศาลเจ้าไก่ต่อ: "San Chao Kai to",
  หนองกะปุ: "Nong Kapu",
  พระรักษ์: "Phra Rak",
  ป่าคาย: "Pa Khai",
  สนามแย้: "Sanam Yae",
  นายาง: "Na Yang",
  ห้วยสะแก: "Huai Sakae",
  หนองมะเขือ: "Nong Makhuea",
  บ้านเหนือ: "Ban Nuea",
  เวียงกาหลง: "Wiang Ka Long",
  น้ำคอก: "Nam Khok",
  บางเขา: "Bang Khao",
  ท่าสาป: "Tha Sap",
  ดงลิง: "Dong Ling",
  ห้วยกะปิ: "Huai Kapi",
  เจริญราษฎร์: "Charoen Rat",
  บ้านผึ้ง: "Ban Phueng",
  นาคู: "Na Khu",
  เกาะลันตาใหญ่: "Ko Lanta Yai",
  ชอนไพร: "Chon Phrai",
  ม่วงตึ๊ด: "Muang Tuet",
  เมืองน้อย: "Mueang Noi",
  วังขอนขว้าง: "Wang Khon Khwang",
  คลองหิน: "Khlong Hin",
  ทุ่งระยะ: "Thung Raya",
  ทัพไทย: "Thap Thai",
  ชะเมา: "Chamao",
  อุทัยเก่า: "Utai Kao",
  ไพรนกยูง: "Phrai Nok Yung",
  สวาย: "Sawai",
  ช่อแฮ: "Cho Hae",
  บึงปรือ: "Bueng Prue",
  แม่สาบ: "Mae Sap",
  แพรกษา: "Phraek Sa",
  บ่อโพง: "Bo Phong",
  บุฮม: "Bu Hom",
  น้ำเชี่ยว: "Nam Chiao",
  สะแกราช: "Sakae Rat",
  ด่านสวี: "Dan Sawi",
  ปลักหนู: "Plak Nu",
  ลุมพลี: "Lumphli",
  ลานสกา: "Lan Saka",
  หันสัง: "Han Sang",
  จอมบึง: "Chom Bueng",
  บ้านจันทน์: "Ban Chan",
  พระยืน: "Phra Yuen",
  เขาบายศรี: "Khao Baisi",
  ท่าตอ: "Tha To",
  นาเรือง: "Na Rueang",
  บ้านเชียง: "Ban Chiang",
  บุคคโล: "Bukkhalo",
  ท่าสีดา: "Tha Sida",
  ด่านทับตะโก: "Dan Thap Tako",
  ท่าสายลวด: "Tha Sai Luat",
  มะลวน: "Maluan",
  น้ำหนาว: "Nam Nao",
  คลองแม่ลาย: "Khlong Mae Lai",
  นาม่อง: "Na Mong",
  รำมะสัก: "Ram Masak",
  เชียงทอง: "Chiang Thong",
  ผไทรินทร์: "Phathairin",
  เขาล้าน: "Khao Lan",
  เกษตรสุวรรณ: "Kaset Suwan",
  บางขันหมาก: "Bang Khan Mak",
  งิ้วด่อน: "Ngio Don",
  เบิกไพร: "Boek Phrai",
  โปงทุ่ง: "Pong Thung",
  บ่อกวางทอง: "Bo Kwang Thong",
  เกาะเทโพ: "Ko Thepho",
  มะเริง: "Maroeng",
  สนามบิน: "Sanam Bin",
  ดินทอง: "Din Thong",
  สูงเนิน: "Sung Noen",
  ซับพุทรา: "Sap Phutsa",
  ย่านซื่อ: "Yan Sue",
  ตาลเดี่ยว: "Tan Diao",
  คู้ยายหมี: "Ku Yai Mi",
  กุดน้ำใส: "Kut Nam Sai",
  พิมาย: "Phimai",
  ตลุกดู่: "Taluk Du",
  โตนด: "Tanot",
  ดอนฉิมพลี: "Don Chimphli",
  เสนานิคม: "Sena Nikhom",
  อาเนาะรู: "Anoru",
  จรเข้ใหญ่: "Chorakhe Yai",
  หูกวาง: "Hu Kwang",
  ธนู: "Thanu",
  เชิงเนิน: "Choeng Noen",
  เมืองเหนือ: "Mueang Nuea",
  ตำหรุ: "Tamru",
  ตากฟ้า: "Tak Fa",
  ธารทหาร: "Than Thahan",
  เกาะยาวใหญ่: "Ko yao Yai",
  ราษฎร์บูรณะ: "Rat Burana",
  ห้วยร่วม: "Huai Ruam",
  พยุหะ: "Phayuha",
  เขาหัวควาย: "Khao Hua Khwai",
  วังเงิน: "Wang Ngoen",
  วังหามแห: "Wang Ham Hae",
  หนองตางู: "Nong Ta Ngu",
  ดงสิงห์: "Dong Sing",
  บ้านแลง: "Ban Laeng",
  นิคมน้ำอูน: "Nikhom Nam Un",
  หนองกร่าง: "Nong Krang",
  ห้วยเตย: "Huai Toei",
  แม่ลอย: "Mae Loi",
  บาโลย: "Baloi",
  เหมืองหม้อ: "Mueang Mo",
  มะขามหย่ง: "Makham Yong",
  บัวเชด: "Buachet",
  แจ้ซ้อน: "Chae Son",
  ไผ่: "Phai",
  เพชรชมภู: "Phet Chomphu",
  หนองสองห้อง: "Nong Song Hong",
  แสนตุ้ง: "Saen Tung",
  เด่นเหล็ก: "Den Lek",
  สร้างค้อ: "Sang Kho",
  บัวปากท่า: "Bua Pak Tha",
  หนองนมวัว: "Nong Nom Wua",
  ลำต้อยติ่ง: "Lam Toiting",
  ป่ากลาง: "Pa Klang",
  คลองต้นไทร: "Khlong Ton Sai",
  ทุ่งพญาไท: "Thung Phaya Thai",
  บ้านไผ่: "Ban Phai",
  โพน: "Phon",
  ทับปุด: "Thap Put",
  บางระจัน: "Bang Rachan",
  ทุ่งพระยา: "Thung Phraya",
  ซับเปิบ: "Sap Poep",
  สวนส้ม: "Suan Som",
  โขมง: "Khamong",
  นาขยาด: "Na Khayat",
  ไทยอุดม: "Thai Udom",
  ราษฎรพัฒนา: "Ratsadon Phatthana",
  เมืองแปง: "Mueang Paeng",
  วังเมือง: "Wang Mueang",
  หนองแจง: "Nong Chaeng",
  แม่ใส: "Mae Sai",
  กำพี้: "Kam Phi",
  ท่าจำปี: "Tha Champi",
  ท่าตะเภา: "Tha Taphao",
  จานใหญ่: "Chan Yai",
  แหลมประดู่: "Laem Pradu",
  หนองหล่ม: "Nong Lom",
  นาแขม: "Na Khaem",
  บุแกรง: "Bu Kraeng",
  หนองบัวน้อย: "Nong Bua Noi",
  รุ่งระวี: "Rung Rawi",
  คำเขื่อนแก้ว: "Kham Khuean Kaeo",
  บึงพระ: "Bueng Phra",
  น้ำหัก: "Nam Hak",
  วังตะกู: "Wang Taku",
  โนนเมืองพัฒนา: "Non Mueang Phatthana",
  เชียงดาว: "Chiang Dao",
  ข้าวสาร: "Khao San",
  สบปราบ: "Sop Prap",
  พนานิคม: "Phana Nikhom",
  บ้านตาด: "Ban Tat",
  สันกำแพง: "San Kamphaeng",
  องครักษ์: "Ongkharak",
  คลองอุดมชลจร: "Khlong Udom Chonlachon",
  ศรีสว่าง: "Si Sawang",
  สายทอง: "Sai Thong",
  แม่ทะ: "Mae Tha",
  บางปะกอก: "Bang Pa Kok",
  หน้าประดู่: "Na Pradu",
  ช้างกลาง: "Chang Klang",
  พรเจริญ: "Phon Charoen",
  โคกชะงาย: "Khok Cha-ngai",
  สิชล: "Sichon",
  ดงแดง: "Dong Daeng",
  เม็งราย: "Mengrai",
  บาเจาะ: "Bacho",
  มะอึ: "Ma-ue",
  หาดสูง: "Hat Sung",
  "สุไหงโก-ลก": "Su-ngai Kolok",
  ตาดทอง: "Tat Thong",
  ตลาดน้อย: "Talat Noi",
  ท่ามิหรำ: "Tha Miram",
  บอน: "Bon",
  เขื่องคำ: "Khueang Kham",
  หนองปล้อง: "Nong Plong",
  หนองจิก: "Nong Chik",
  บ้านร่อม: "Ban Rom",
  ร่มเมือง: "Rom Mueang",
  ทัพหลวง: "Thap Luang",
  ปากช่อง: "Pak Chong",
  ห้วยแม่เพรียง: "Huai Mae Phriang",
  โคกกะเทียม: "Khok Kathiam",
  นาขาม: "Na Kham",
  หมอเมือง: "Mo Mueang",
  แสนแสบ: "Saen Saep",
  เตาปูน: "Tao Pun",
  ศรีนาวา: "Si Nawa",
  นครเดิฐ: "Nakhon Doet",
  โสกแสง: "Sok Saeng",
  สันมะค่า: "San Makha",
  บึงพะไล: "Bueng Phalai",
  มะต้อง: "Matong",
  ขุมเงิน: "Khum Ngoen",
  ลำมูล: "Lam Mun",
  ชากพง: "Chak Phong",
  หัวลำ: "Hua Lam",
  ห้วยถั่วเหนือ: "Huai Thua Nuea",
  บึงแก: "Bueng Kae",
  ปอพาน: "Po Phan",
  พะเนา: "Phanao",
  วัดเกต: "Wat Ket",
  แม่คำมี: "Mae Kham Mi",
  นางแก้ว: "Nang Kaeo",
  ไชยวัฒนา: "Chai Watthana",
  บ้านแร่: "Ban Rae",
  คำน้ำแซบ: "Kham Nam Saep",
  ทุ่งยางเมือง: "Thung Yang Mueang",
  ช้างให้ตก: "Chang Hai Tok",
  แม่ไร่: "Mae Rai",
  ละแอ: "La-ae",
  ขุนทะเล: "Khun Thale",
  เขาสวนกวาง: "Khao Suan Kwang",
  วังน้ำลัด: "Wang Nam Lat",
  เมืองหมี: "Mueang Mi",
  บ้านมาง: "Ban Mang",
  พยุห์: "Phayu",
  ดินแดง: "Din Daeng",
  วังยาว: "Wang Yao",
  ห้วยน้ำขาว: "Huai Nam Khao",
  อรัญคามวารี: "Aranyakham Wari",
  หนองซ้ำซาก: "Nong Samsak",
  ดอนสมบูรณ์: "Don Sombun",
  ตำหนักธรรม: "Tamnak Tham",
  มะขามสูง: "Makham Sung",
  สระใคร: "Sakhrai",
  ช้างม่อย: "Chang Moi",
  กองควาย: "Kong Khwai",
  บางยี่ขัน: "Bang Yi Khan",
  ล้อมแรด: "Lom Raet",
  นาจักร: "Na Chak",
  ลำปำ: "Lampam",
  หนองตะเคียนบอน: "Nong Takian Bon",
  พร่อน: "Phron",
  ห้วยม้า: "Huai Ma",
  สวนดอก: "Suan Dok",
  ถ้ำทองหลาง: "Tham Thonglang",
  ตะดอบ: "Tadop",
  ลำปลาทิว: "Lam Pla Thio",
  นาแว: "Na Wae",
  บางขุนเทียน: "Bang Khun Thian",
  นาโป่ง: "Na Pong",
  หนองค่าย: "Nong Khai",
  บ้านหมอ: "Ban Mo",
  กกดู่: "Kok Du",
  ปอแดง: "Po Daeng",
  กระบี่ใหญ่: "Krabi Yai",
  วัดสน: "Wat Son",
  หนองนกแก้ว: "Nong Nok Kaeo",
  พยอม: "Phayom",
  พระโขนงเหนือ: "Phra Khanong Nuea",
  ดอนชะเอม: "Don Cha-em",
  หลุ่งประดู่: "Lung Pradu",
  เสาธงหิน: "Sao Thong Hin",
  วาใหญ่: "Wa Yai",
  คอนสาย: "Khon Sai",
  เกาะกูด: "Ko Kut",
  ใหม่นาเพียง: "Mai Na Phiang",
  กวางโจน: "Kwang Chon",
  บางกรูด: "Bang Krut",
  ท่าวังตาล: "Tha Wang Tan",
  สะเตง: "Sateng",
  ชอนม่วง: "Chon Muang",
  สนับทึบ: "Sanap Thuep",
  คูเต่า: "Khu Tao",
  เขาชัยสน: "Khao Chaison",
  บางไผ่: "Bang Phai",
  หัวถนน: "Hua Thanon",
  คลองตันเหนือ: "Khlong Tan Nuea",
  ยางคำ: "Yang Kham",
  เหล่า: "Lao",
  หนองหลัก: "Nong Lak",
  วัดใหม่: "Wat Mai",
  ปริก: "Prik",
  หนองยอง: "Nong Yong",
  หว้านใหญ่: "Wan Yai",
  บัววัฒนา: "Bua Watthana",
  หนองแวงโสกพระ: "Nong Waeng Sok Phra",
  บ้านขอ: "Ban Kho",
  ปากท่อ: "Pak Tho",
  พังเคน: "Phang Khen",
  ทับกุง: "Thap Kung",
  นางตะเคียน: "Nang Takian",
  บ้านว่าน: "Ban Wan",
  บางยาง: "Bang Yang",
  วัดขนุน: "Wat Khanun",
  ไผ่กองดิน: "Phai Kong Din",
  นางลือ: "Nang Lue",
  สมอทอง: "Samo Thong",
  คลองขนาน: "Khlong Khanan",
  ตะเคียน: "Takhian",
  หนองเต็ง: "Nong Teng",
  แม่คง: "Mae Khong",
  โพนสว่าง: "Phon Sawang",
  หนองเป็ด: "Nong Pet",
  แม่รำพึง: "Mae Ramphueng",
  บ้านพลวง: "Ban Phluang",
  เกาะขนุน: "Ko Khanun",
  คลองนา: "Khlong Na",
  โพนทราย: "Phon Sai",
  คำน้ำสร้าง: "Kham Nam Sang",
  แกลง: "Klaeng",
  ปราสาท: "Prasat",
  สันป่ายาง: "San Pa Yang",
  เว่อ: "Woe",
  ดอนหายโศก: "Don Hai Sok",
  เกาะขันธ์: "Ko Khan",
  สากอ: "Sako",
  ตะคร้อ: "Takhro",
  หนองนกทา: "Nong Nok Tha",
  ทุ่งพึ่ง: "Thung Phueng",
  บึงกอก: "Bueng Kok",
  พรสวรรค์: "Phon Sawan",
  บางไทร: "Bang Sai",
  ฉาง: "Chang",
  บุ่งมะแลง: "Bung Malaeng",
  กุแหระ: "Kurae",
  โคกก่อ: "Khok Ko",
  ป่าลาน: "Pa Lan",
  โนนตาเถร: "Non Ta Then",
  หมอกจำแป่: "Mok Champae",
  เมืองเกษตร: "Mueang Kaset",
  ตระการ: "Trakan",
  บ้านจ่า: "Ban Cha",
  ดอนนางหงส์: "Don Nang Hong",
  ป่ายุบใน: "Pa Yup Nai",
  บางกะจะ: "Bang Kacha",
  ศาลาขาว: "Sala Khao",
  คำเจริญ: "Kham Charoen",
  คลองลานพัฒนา: "Khlong Lan Phatthana",
  ลาดชิด: "Lat Chit",
  คอนกาม: "Khon Kam",
  วังประจบ: "Wang Prachop",
  น้ำเลา: "Nam Lao",
  หนองหงส์: "Nong Hong",
  กะเฉด: "Kachet",
  โคกขี้หนอน: "Khok Khi Non",
  ผาเสวย: "Pha Sawoei",
  วังนกแอ่น: "Wang Nok Aen",
  แก่งคอย: "Kaeng Khoi",
  ระวะ: "Rawa",
  แก: "Kae",
  สมอโคน: "Samo Khon",
  บางช้าง: "Bang Chang",
  สามเสนใน: "Sam Sen Nai",
  สุรศักดิ์: "Surasak",
  ท่าไข่: "Tha Khai",
  ระเวียง: "Rawiang",
  ต้นผึ้ง: "Ton Phueng",
  ผือใหญ่: "Phue Yai",
  ครบุรีใต้: "Khon Buri Tai",
  โนนทัน: "Non Than",
  คลองวัว: "Khlong Wua",
  ตะเบาะ: "Tabo",
  แม่พริก: "Mae Phrik",
  ท่าหมื่นราม: "Tha Muen Ram",
  วังเพลิง: "Wang Phloeng",
  บ้านหาด: "Ban Hat",
  หนองไม้แก่น: "Nong Mai Kaen",
  ฝายแก้ว: "Fai Kaeo",
  เพ: "Phe",
  กะเปา: "Kapao",
  ปากนคร: "Pak Nakhon",
  หนองไม้แดง: "Nong Mai Daeng",
  มะเร็ต: "Maret",
  รุง: "Rung",
  หนองตูม: "Nong Tum",
  หนองแรด: "Nong Raet",
  พลอยแหวน: "Phloi Waen",
  อ่าวใหญ่: "Ao Yai",
  บ้านข่อย: "Ban Khoi",
  พระอาจารย์: "Phra Achan",
  สระพัฒนา: "Sa Phatthana",
  นาแส่ง: "Na Saeng",
  สาคอใต้: "Sakho Tai",
  ชีลอง: "Chi Long",
  ลำปาว: "Lam Pao",
  แม่ยางตาล: "Mae Yang Tan",
  ตลาดเหนือ: "Talat Nuea",
  นาเลิง: "Na Loeng",
  หนองกุงเซิน: "Nong Kung Soen",
  เชียงหวาง: "Chiang Wang",
  ป่าเด็ง: "Pa Deng",
  ชัยพฤกษ์: "Chaiyaphruek",
  เปือ: "Puea",
  บ้านแปรง: "Ban Praeng",
  บางภาษี: "Bang Phasi",
  โคกจาน: "Khok Chan",
  เปาะเส้ง: "Po Seng",
  บ้านร้อง: "Ban Rong",
  หนองหัวฟาน: "Nong Hua Fan",
  สมสะอาด: "Som Sa-at",
  สร้างก่อ: "Sang Ko",
  บ้านโขด: "Ban Khot",
  มักกะสัน: "Makkasan",
  กฤษณา: "Kritsana",
  ควนลัง: "Khuan Lang",
  ประทัดบุ: "Prathat Bu",
  เมืองแฝก: "Mueang Faek",
  นาดอกคำ: "Na Dok Kham",
  สะบารัง: "Sabarang",
  ผาสุก: "Pha Suk",
  ท่าอุแท: "Tha U-thae",
  สะแกกรัง: "Sakae Krang",
  บ้านด่าน: "Ban Dan",
  หัวฝาย: "Hua Fai",
  เมือง: "Mueang",
  หนองเทาใหญ่: "Nong Thao Yai",
  คูตัน: "Khu Tan",
  ปลักแรด: "Plak Raet",
  นาวัง: "Na Wang",
  สวนพริกไทย: "Suan Phrik Thai",
  จิกเทิง: "Chik Thoeng",
  พระบาทนาสิงห์: "Phra Bat Na Sing",
  เหนือคลอง: "Nuea Khlong",
  หนองจ๊อม: "Nong Chom",
  นครนายก: "Nakhon Nayok",
  โนนขวาง: "Non Khwang",
  ไพรขลา: "Phrai Khla",
  ตะมะยูง: "Tamayung",
  ทุ่งผึ้ง: "Thung Phueng",
  ช่อง: "Chong",
  กันทรอม: "Kanthrom",
  ชำนิ: "Chamni",
  ดอกไม้: "Dok Mai",
  ภูแล่นช้าง: "Phu Laen Chang",
  เกาะสาหร่าย: "Ko Sarai",
  บางพระเหนือ: "Bang Phra Nuea",
  ช้างขวา: "Chang Khwa",
  น้ำไผ่: "Nam Phai",
  บึงวิชัย: "Bueng Wichai",
  ลุมพุก: "Lumphuk",
  บ่อน้ำร้อน: "Bo Nam Ron",
  ดอนข่อย: "Don Khoi",
  บาโงย: "Ba-ngoi",
  โคกปี่ฆ้อง: "Khok Pi Khong",
  สิ: "Si",
  ชะรัด: "Charat",
  ดอนยอ: "Don Yo",
  จรเข้เผือก: "Chorakhe Phueak",
  หนองโอ่ง: "Nong Ong",
  บางกระเจ้า: "Bang Krachao",
  ชุมพร: "Chumphon",
  มาบตาพุด: "Map Ta Phut",
  กาบิน: "Ka Bin",
  โพธิ์ไชย: "Pho Chai",
  จำปา: "Champa",
  สี่ขีด: "Si Khit",
  ละลาย: "Lalai",
  ลำภี: "Lam Phi",
  อ่าวลึกน้อย: "Ao Luek Noi",
  นาน้อย: "Na Noi",
  บางสะพาน: "Bang Saphan",
  แม่นาเติง: "Mae Na Toeng",
  หาดทรายรี: "Hat Sai Ri",
  แม่ปืม: "Mae Puem",
  ปิยามุมัง: "Piya Mumang",
  ปลาบ่า: "Pla Ba",
  หนองเลิง: "Nong Loeng",
  คลองหินปูน: "Khlong Hin Pun",
  นาโหนด: "Na Not",
  ดอนใหญ่: "Don Yai",
  เขาขี้ฝอย: "Khao Khi Foi",
  กุศกร: "Kutsakon",
  ทุ่งรัง: "Thung Rang",
  โคกขาม: "Khok Kham",
  กุฎี: "Kudi",
  เพ็กใหญ่: "Phek Yai",
  บ้านกาศ: "Ban Kat",
  คลองคะเชนทร์: "Khlong Khachen",
  ห้วยไร่: "Huai Rai",
  กุดสระ: "Kut Sa",
  บวรนิเวศ: "Bowon Niwet",
  ห้วยยั้ง: "Huai Yang",
  ท่าแร้งออก: "Tha Raeng Ok",
  ทุ่งโพ: "Thung Pho",
  บ้านเป็ด: "Ban Pet",
  น้ำโจ้: "Nam Cho",
  มะม่วงสองต้น: "Mamuang Song Ton",
  คลองขุด: "Khlong Khut",
  โนนท่อน: "Non Thon",
  ทุ่งขวาง: "Thung Khwang",
  ศรีดอนชัย: "Si Don Chai",
  โพกรวม: "Phok Ruam",
  สมัย: "Samai",
  วังไทร: "Wang Sai",
  วัดหลวง: "Wat Luang",
  หนองหญ้าลาด: "Nong Ya Lat",
  เมืองจันทร์: "Mueang Chan",
  สะเดาใหญ่: "Sadao Yai",
  คำสะอาด: "Kham Sa-at",
  พลวง: "Phluang",
  ท่าแดง: "Tha Daeng",
  เมืองพะไล: "Mueang Phalai",
  บ้านกู่: "Ban Ku",
  เจ๊ะบิลัง: "Che Bilang",
  อ่าวนาง: "Ao Nang",
  หนองหมี: "Nong Mi",
  พระเสาร์: "Phra Sao",
  จันทรเกษม: "Chan Kasem",
  บางหัวเสือ: "Bang Hua Suea",
  บางตาเถร: "Bang Ta Then",
  ดอนคา: "Don Kha",
  จังหาร: "Changhan",
  ดอยลาน: "Doi Lan",
  ทางช้าง: "Thang Chang",
  ซึ้ง: "Sueng",
  กระสัง: "Krasang",
  หนองหัวคู: "Nong Hua Khu",
  สร้างถ่อน้อย: "Sang Tho Noi",
  ปากน้ำกระแส: "Pak Nam Krasae",
  ซับไม้แดง: "Sap Mai Daeng",
  วังโบสถ์: "Wang Bot",
  เกรียงไกร: "Kriangkrai",
  หนองเทา: "Nong Thao",
  พงษ์: "Phong",
  ทุ่งใส: "Thung Sai",
  ตะโกตาพิ: "Tako Taphi",
  บางมูลนาก: "Bang Mun Nak",
  ห้วยโก๋น: "Huai Kon",
  ยางอู้ม: "Yang Um",
  โพรงจระเข้: "Phrong Chorakhe",
  ดอนสัก: "Don Sak",
  บางพลีใหญ่: "Blang Phli Yai",
  ท่าประดู่: "Tha Pradu",
  เขาหลวง: "Khao Luang",
  สันทรายน้อย: "San Sai Noi",
  ห้วยท่าช้าง: "Huai Tha Chang",
  ริมสีม่วง: "Rim Si Muang",
  ทุ่งสว่าง: "Thung Sawang",
  คูหาใต้: "Khuha Tai",
  น้ำดิบ: "Nam Dip",
  ท้ายช้าง: "Thai Chang",
  รวมไทยพัฒนา: "Ruam Thai Phatthana",
  ไผ่โทน: "Phai Thon",
  หนองปรง: "Nong Prong",
  หนองตะไก้: "Nong Takai",
  วะตะแบก: "Wa Tabaek",
  ตะเคียนปม: "Takhian Pom",
  เซกา: "Seka",
  กันตวจระมวล: "Kantuat Ramuan",
  สุขไพบูลย์: "Suk Phaibun",
  ป่าแฝก: "Pa Faek",
  กุดค้าว: "Kut Khao",
  ยางคราม: "Yang Khram",
  คลองตะเกรา: "Khlong Takrao",
  นอกเมือง: "Nok Mueang",
  วัดเกาะ: "Wat Ko",
  แม่ละมุ้ง: "Mae Lamung",
  คลองเปียะ: "Khlong Pia",
  บ้านแวง: "Ban Waeng",
  เขาเจ็ดลูก: "Khao Chet Luk",
  ฉิมพลี: "Chimphli",
  ในเวียง: "Nai Wiang",
  กระโสบ: "Krasop",
  วัดเพลง: "Wat Phleng",
  ห้วยโป่ง: "Huai Pong",
  สามโก้: "Samko",
  บ้านตูล: "Ban Tun",
  หมกแถว: "Mok Thaeo",
  โพนยาง: "Phon Yang",
  หนองไม้งาม: "Nong Mai Ngam",
  ศรีคีรีมาศ: "Si Khiri Mat",
  สบโขง: "Sop Khong",
  โพธิ์ตรุ: "Pho Tru",
  ท่าหมอไทร: "Tha Mo Sai",
  มะนังตายอ: "Manang Tayo",
  ทวีวัฒนา: "Thawi Watthana",
  หลวงเหนือ: "Luang Nuea",
  กุดกว้าง: "Kut Kwang",
  ถ้ำสิงห์: "Tham Sing",
  ท่าตูม: "Tha Tum",
  เหล่าอ้อย: "Lao Oi",
  นาเหนือ: "Na Nuea",
  วังไก่เถื่อน: "Wang Kai Thuean",
  หนองอียอ: "Nong I Yo",
  ปิงหลวง: "Ping Luang",
  ขุนศรี: "Khun Si",
  เหล่าไฮงาม: "Lao Hai Ngam",
  หนองกรด: "Nong Krot",
  บางไทรป่า: "Bang Sai Pa",
  จุมพล: "Chumphon",
  หอรัตนไชย: "Ho Rattanachai",
  แม่ตืน: "Mae Tuen",
  สระพัง: "Sa Phang",
  บึงศาล: "Bueng San",
  พรหมพิราม: "Phrom Phiram",
  ตะพง: "Taphong",
  สะแกโพรง: "Sakae Phrong",
  จาน: "Chan",
  นาพรุ: "Na Phru",
  ตาหลวง: "Ta Luang",
  ถ้ำแข้: "Tham Khae",
  คลองห้า: "Khlong Ha",
  ไชโย: "Chaiyo",
  บ้านหลุม: "Ban Lum",
  มีนบุรี: "Min Buri",
  บงตัน: "Bong Tan",
  หนองสวรรค์: "Nong Sawan",
  เนินพระ: "Noen Phra",
  คลองอู่ตะเภา: "Khlong U Taphao",
  คำใหญ่: "Kham Yai",
  เกาะเกร็ด: "Ko Kret",
  ถ้ำใหญ่: "Tham Yai",
  ทุ่งสมอ: "Thung Samo",
  ตะโก: "Tako",
  ศรีสมเด็จ: "Si Somdet",
  วังชะโอน: "Wang Cha-on",
  ปากบาง: "Pak Bang",
  คลองสิบ: "Khlong Sip",
  ดอนตาล: "Don Tan",
  เมืองพล: "Mueang Phon",
  หูช้าง: "Hu Chang",
  เชียรใหญ่: "Chian Yai",
  ห้วยยูง: "Huai Yung",
  ตะพานหิน: "Taphan Hin",
  บ้านตะโก: "Ban Tako",
  นรสิงห์: "Norasing",
  โคกเพชร: "Khok Phet",
  ตระแสง: "Trasaeng",
  ผาน้อย: "Pha Noi",
  คอนฉิม: "Khon Chim",
  หนองเสือช้าง: "Nong Suea Chang",
  ขัวเรียง: "Khua Riang",
  บ้านป่า: "Ban Pa",
  พิมพา: "Phimpha",
  สะเมิงเหนือ: "Samoeng Nuea",
  รังแร้ง: "Rang Raeng",
  บ้านแพรก: "Ban Phraek",
  ห้วยสัก: "Huai Sak",
  หัวหนอง: "Hua Nong",
  บุโพธิ์: "Bu Pho",
  น้ำหมัน: "Nam Man",
  สองสลึง: "Song Salueng",
  ราชกรูด: "Ratchakrut",
  เขาใหญ่: "Khao Yai",
  ทับยา: "Thap Ya",
  ประจวบคีรีขันธ์: "Prachuap Khiri Khan",
  ขัวมุง: "Khua Mung",
  จอมประทัด: "Chom Prathat",
  ประดาง: "Pradang",
  แม่ฮี้: "Mae Hi",
  สวนหลวง: "Suan Luang",
  หนองชัยศรี: "Nong Chai Si",
  ศรีราชา: "Si Racha",
  เกิ้ง: "Koeng",
  บางเสด็จ: "Bang Sadet",
  เชื้อเพลิง: "Chuea Phloeng",
  เหล่ากอหก: "Lao Ko Hok",
  ฉมัน: "Chaman",
  ทองเอน: "Thong En",
  เมืองเพีย: "Mueang Phia",
  อุดมพร: "Udom Phon",
  คึมชาด: "Khuemchat",
  กรงปินัง: "Krong Pinang",
  ชัยนาม: "Chai Nam",
  ท้องเนียน: "Thong Nian",
  จอมปลวก: "Chom Pluak",
  ปะลุกาสาเมาะ: "Paluka Samo",
  ยางตาล: "Yang Tan",
  นบพิตำ: "Nopphitam",
  บางบริบูรณ์: "Bang Boribun",
  นาโสก: "Na Sok",
  โนนทองอินทร์: "Non Thong In",
  นาทุ่ง: "Na Thung",
  สร้อยพร้าว: "Soi Phrao",
  ตรำดม: "Tram Dom",
  สามไถ: "Sam Thai",
  ตันหยงลุโละ: "Tanyong Lulo",
  หนองไข่น้ำ: "Nong Khai Nam",
  น้ำก่ำ: "Nam Kam",
  บางนาง: "Bang Nang",
  หัวสำโรง: "Hua Samrong",
  กะมิยอ: "Kamiyo",
  ทับยาว: "Thap Yao",
  เทนมีย์: "Thenmi",
  ลำพยา: "Lam Phaya",
  คลองสี่: "Khlong Si",
  เขาคอก: "Khao Khok",
  บ้านปรือ: "Ban Prue",
  แม่พูล: "Mae Phun",
  สตึก: "Satuek",
  พระบาท: "Phra Bat",
  ทับช้าง: "Thap Chang",
  เวียงคำ: "Wiang Kham",
  ช่องไม้แก้ว: "Chong Mai Kaeo",
  ไม้ฝาด: "Mai Fat",
  ประดู่ยืน: "Pradu Yuen",
  เหนือ: "Nuea",
  เกะรอ: "Kero",
  ท่าวังผา: "Tha Wang Pha",
  ฝายหลวง: "Fai Luang",
  ปลายนา: "Plai Na",
  โยธะกา: "Yothaka",
  บางหมาก: "Bang Mak",
  ทุ่งหว้า: "Thung Wa",
  ศรีพนมมาศ: "Si Phanom Mat",
  เชียงรากน้อย: "Chiang Rak Noi",
  ป่าพุทรา: "Pa Phutsa",
  บันนังสตา: "Bannang Sata",
  ตำนาน: "Tamnan",
  เด่นราษฎร์: "Den Rat",
  สุรนารี: "Suranari",
  ชุมเห็ด: "Chum Het",
  นาไหม: "Na Mai",
  คลองสระ: "Khlong Sa",
  แม่หล่าย: "Mae Lai",
  มาบปลาเค้า: "Map Pla Khao",
  สมหวัง: "Som Wang",
  เขาตูม: "Khao Tum",
  จรเข้ร้อง: "Chorakhe Rong",
  แม่สูน: "Mae Sun",
  ลาดบัวขาว: "Lat Bua Khao",
  ช่องสามหมอ: "Chong Sam Mo",
  ท่าคล้อ: "Tha Khlo",
  ควนสุบรรณ: "Khuan Suban",
  เมืองปัก: "Mueang Pak",
  มารวิชัย: "Manwichai",
  สุขสวัสดิ์: "Suk Sawat",
  วัดแก้ว: "Wat Kaeo",
  หนองแค: "Nong Khae",
  ในคลองบางปลากด: "Nai Khlong Bang Pla Kot",
  โคกสลุง: "Khok Salung",
  สินปุน: "Sin Pun",
  วัดละมุด: "Wat Lamut",
  แคนใหญ่: "Khaen Yai",
  บุ่งแก้ว: "Bung Kaeo",
  ก่อเอ้: "Ko E",
  สบตุ๋ย: "Sop Tui",
  โพธิ์กลาง: "Pho Klang",
  ทุ่งทราย: "Thung Sai",
  แคนน้อย: "Khaen Noi",
  ยางสีสุราช: "Yang Si Surat",
  ตะกาง: "Takang",
  พุ่มแก: "Phum Kae",
  กร่ำ: "Kram",
  หนองฉลอง: "Nong Chalong",
  พระพุทธบาท: "Phra Phutthabat",
  บ้านหยวก: "Ban Yuak",
  ดอยฮาง: "Doi Hang",
  ดอนคลัง: "Don Khlang",
  กระแจะ: "Krachae",
  คือเวียง: "Khue Wiang",
  ทุ่งคาโงก: "Thung Kha Ngok",
  พิปูน: "Phipun",
  บางพระใต้: "Bang Phra Tai",
  คำโคกสูง: "Kham Khok Sung",
  นาหนองไผ่: "Na Nong Phai",
  กุงเก่า: "Kung Kao",
  หินปัก: "Hin Pak",
  บ้านใหม่คลองเคียน: "Ban Mai Khlong Khian",
  ดงคู่: "Dong Khu",
  บางโรง: "Bang Rong",
  นนทรีย์: "Nonsi",
  กองแขก: "Kong Khaek",
  หนองทัพไทย: "Nong Thap Thai",
  วังลึก: "Wang Luek",
  หนองบัวศาลา: "Nong Bua Sala",
  วัดอรุณ: "Wat Arun",
  เทพราช: "Theppharat",
  ยางสูง: "Yang Sung",
  เมืองแก: "Mueang Kae",
  เขาสมอคอน: "Khao Samo Khon",
  แม่เหียะ: "Mae Hia",
  วังศรีราช: "Wang Si Rat",
  ช้างแรก: "Chang Raek",
  จอมจันทร์: "Chom Chan",
  สีออ: "Si O",
  ใจดี: "Chai Di",
  ห้วยแอ่ง: "Huai Aeng",
  พังราด: "Phang Rat",
  ท่าทราย: "Tha Sai",
  สังขะ: "Sangkha",
  จรเข้มาก: "Chorakhe Mak",
  บ้านโฮ่ง: "Ban Hong",
  หนองกุง: "Nong Kung",
  รังงาม: "Rang Ngam",
  นครชุมน์: "Nakhon Chum",
  เกาะตะเภา: "Ko Taphao",
  นาปะขอ: "Na Pakho",
  บัลลังก์: "Banlang",
  เขาซก: "Khao Sok",
  โคกหาร: "Khok Han",
  คำบ่อ: "Kham Bo",
  โคกลำพาน: "Khok Lam Phan",
  บือมัง: "Buemang",
  แหลมโพธิ์: "Laem Pho",
  ควนขัน: "Khuan Khan",
  ทุ่งมะพร้าว: "Thung Maphrao",
  กะเปอร์: "Kapoe",
  นาหนาด: "Na Nat",
  ไผ่ใหญ่: "Phai Yai",
  เขานิพันธ์: "Khao Niphan",
  คอลอตันหยง: "Kholo Tanyong",
  ศรีณรงค์: "Si Narong",
  บางเก่า: "Bang Kao",
  หนองบัวฮี: "Nong Bua Hi",
  โคกเดื่อ: "Khok Duea",
  ปงดอน: "Pong Don",
  เจดีย์หัก: "Chedi Hak",
  ตะปอน: "Tapon",
  ทุ่งลาน: "Thung Lan",
  ดวนใหญ่: "Duan Yai",
  สะเอียบ: "Sa-iap",
  บ้านหมี่: "Ban Mi",
  บางบุตร: "Bang But",
  นาเกาะ: "Na Ko",
  หนองหอย: "Nong Hoi",
  ม่วงน้อย: "Muang Noi",
  นาพันสาม: "Na Phan Sam",
  ดุซงญอ: "Dusongyo",
  ย่านรี: "Yan Ri",
  ดอนพุด: "Don Phut",
  บางตลาด: "Bang Talat",
  ชัยฤทธิ์: "Chai Rit",
  ห้วยปูลิง: "Huai Pu Ling",
  ศรีษะทอง: "Sisa Thong",
  ยางม่วง: "Yang Muang",
  นิคมพัฒนา: "Nikhom Phatthana",
  แม่เย็น: "Mae Yen",
  ท่าใหญ่: "Tha Yai",
  หนองค้างพลู: "Nong Khang Phlu",
  ท่าถ่าน: "Tha Than",
  ขุนคง: "Khun Khong",
  กระทุ่มแพ้ว: "Krathum Phaeo",
  "จ.ป.ร.": "Choporo",
  แม่ลาว: "Mae Lao",
  ตลิ่งงาม: "Taling Ngam",
  แขม: "Khaem",
  เวียงคุก: "Wiang Khuk",
  วังทับไทร: "Wang Thap Sai",
  ศรีวิลัย: "Si Wilai",
  กุดจอก: "Kut Chok",
  ทุ่งจังหัน: "Thung Changhan",
  บ่อตรุ: "Bo Tru",
  ธานี: "Thani",
  จอมศรี: "Chom Si",
  พุขาม: "Phu Kham",
  คลองเฉลิม: "Khlong Chaloem",
  หัวหิน: "Hua Hin",
  วัดชลอ: "Wat Chalo",
  ห้วยกรด: "Huai Krot",
  สะพลี: "Saphli",
  เจ้าปลุก: "Chao Pluk",
  นารุ่ง: "Na Rung",
  ข้าวเม่า: "Khao Mao",
  บางเมืองใหม่: "Bang Mueang Mai",
  บ่อกรุ: "Bo Kru",
  กบเจา: "Kop Chao",
  บางโคล่: "Bang Khlo",
  จันทึก: "Chanthuek",
  พลายชุมพล: "Phlai Chumphon",
  เขาโจด: "Khao Chot",
  กะลุวอเหนือ: "Kaluwo Nuea",
  น้ำแคม: "Nam Khaem",
  หนองมะค่า: "Nong Makha",
  ไทยบุรี: "Thai Buri",
  นางบวช: "Nang Buat",
  เนินทราย: "Noen Sai",
  หนองสูงเหนือ: "Nong Sung Nuea",
  ห้วยใต้: "Huai Tai",
  เขารวก: "Khao Ruak",
  ทาทุ่งหลวง: "Tha Thung Luang",
  บางปอ: "Bang Po",
  วังกระแจะ: "Wang Krachae",
  ชะแล้: "Chalae",
  หนองตาด: "Nong Tat",
  สบบง: "Sop Bong",
  ยางซ้าย: "Yang Sai",
  เกาะพลับพลา: "Ko Phlapphla",
  โพตลาดแก้ว: "Pho Talat Kaeo",
  กุดเรือคำ: "Kut Ruea Kham",
  อาจสามารถ: "At Samat",
  ลมศักดิ์: "Lom Sak",
  เสม็ดเหนือ: "Samet Nuea",
  ห้วยสามพาด: "Huai Sam Phat",
  สระพระ: "Sa Phra",
  โนนป่าซาง: "Non Pa Sang",
  สามแยก: "Sam Yaek",
  กุดบง: "Kut Bong",
  ยี่สาร: "Yi San",
  เขาทะลุ: "Khao Thalu",
  ขุนซ่อง: "Khun Song",
  ราชสถิตย์: "Rat Sathit",
  เวียงต้า: "Wiang Ta",
  เกาะลันตาน้อย: "Ko Lanta Noi",
  บ้านชะอวด: "Ban Cha-uat",
  ตาลเอน: "Tan En",
  นาหนองทุ่ม: "Na Nong Thum",
  บ้านสิงห์: "Ban Sing",
  บ้านในดง: "Ban Nai Dong",
  หล่มเก่า: "Lom Kao",
  บ้านช่างหล่อ: "Ban Chang Lo",
  เวียงทอง: "Wiang Thong",
  ดอนขวาง: "Don Khwang",
  หนองสนม: "Nong Sanom",
  แหลมโตนด: "Laem Tanot",
  ตำตัว: "Tam Tua",
  โนนข่า: "Non Kha",
  เมืองทุ่ง: "Mueang Thung",
  ท่าแยก: "Tha Yaek",
  พอกน้อย: "Phok Noi",
  ปรางหมู่: "Prang Mu",
  ขุนทอง: "Khun Thong",
  โพธิ์ศรีสำราญ: "Pho Si Samran",
  เขาวงกต: "Khao Wongkot",
  กู่กาสิงห์: "Ku Ka Sing",
  ต้นธง: "Ton Thong",
  ป่าคา: "Pa Kha",
  เทพกระษัตรี: "Thep Krasatti",
  ดอน: "Don",
  ปังหวาน: "Pang Wan",
  เขาแร้ง: "Khao Raeng",
  หนองหัวช้าง: "Nong Hua Chang",
  ช้างทูน: "Chang Thun",
  ทุ่งงาม: "Thung Ngam",
  น้ำแวน: "Nam Waen",
  บักดอง: "Bak Dong",
  หลุมข้าว: "Lum Khao",
  นิคมทุ่งโพธิ์ทะเล: "Nikhom Thung Pho Thale",
  โคกพุทรา: "Khok Phutsa",
  สระประดู่: "Sa Pradu",
  คุ้งลาน: "Khung Lan",
  กาตอง: "Ka Tong",
  "แป-ระ": "Pae-ra",
  ท้ายดง: "Thai Dong",
  แค: "Khae",
  พันชนะ: "Phan Chana",
  จะกว๊ะ: "Cha-kwa",
  เมืองมาย: "Mueang Mai",
  นาท่ามเหนือ: "Na Tham Nuea",
  หัวป่า: "Hua Pa",
  เมืองโดน: "Mueang Don",
  เสาเภา: "Sao Phao",
  วังทรายพูน: "Wang Sai Phun",
  ท่าซุง: "Tha Sung",
  ทุ่งเตาใหม่: "Thung Tao Mai",
  ม่วงลาด: "Muang Lat",
  สวด: "Suat",
  แม่เหาะ: "Mae Ho",
  หัวสะพาน: "Hua Saphan",
  กองนาง: "Kong Nang",
  กระเสียว: "Krasiao",
  บ้านควนมุด: "Ban Khuan Mut",
  เกาะเต่า: "Ko Tao",
  ตาคลี: "Takhli",
  แม่สามแลบ: "Mae Sam Laep",
  พระครู: "Phra Khru",
  นาขอม: "Na Khom",
  เขาสามสิบหาบ: "Khao Sam Sip Hap",
  สันติคีรี: "Santi Khiri",
  เขาชะงุ้ม: "Khao Cha-ngum",
  วังม้า: "Wang Ma",
  โนนชัยศรี: "Non Chai Si",
  นาโฉง: "Na Chong",
  วังศาล: "Wang San",
  ตาเบา: "Ta Bao",
  แหลมสอม: "Laem Som",
  บ้านเก่า: "Ban Kao",
  ริมปิง: "Rim Ping",
  เหล่าแดง: "Lao Daeng",
  สามกระทาย: "Sam Krathai",
  แช่ช้าง: "Chae Chang",
  แหลมกลัด: "Laem Klat",
  หนองกราด: "Nong Krat",
  โหรา: "Hora",
  มวกเหล็ก: "Muak Lek",
  วังวน: "Wang Won",
  นกออก: "Nok Ok",
  ท่าแซะ: "Tha Sae",
  ท่ามะปราง: "Tha Maprang",
  ปากล่อ: "Pak Lo",
  ตลุกเทียม: "Taluk Thiam",
  หนองยายพิมพ์: "Nong Yai Phim",
  ทุ่งกระเต็น: "Thung Kraten",
  อมก๋อย: "Omkoi",
  คุ้งกระถิน: "Khung Krathin",
  รองเมือง: "Rong Mueang",
  เขวาไร่: "Khwao Rai",
  แม่สัน: "Mae San",
  ห้วยทับมอญ: "Huai Thap Mon",
  นาส่วง: "Na Suang",
  ควนธานี: "Khuan Thani",
  โนนกุง: "Non Kung",
  ปากปวน: "Pak Puan",
  คลองโยง: "Khlong Yong",
  คลองกระจัง: "Khlong Krachang",
  บางหลวงโดด: "Bang Luang Dot",
  นาท่อม: "Na Thom",
  กลันทา: "Kalantha",
  หนองทันน้ำ: "Nong Than Nam",
  หนองคู: "Nong Khu",
  คุ้มเก่า: "Khum Kao",
  ฝายนาแซง: "Fai Na Saeng",
  แหลมงอบ: "Laem Ngop",
  สระยายโสม: "Sa Yai Som",
  อูบมุง: "Up Mung",
  กังแอน: "Kang Aen",
  สันผีเสื้อ: "San Phi Suea",
  นวมินทร์: "Nawamin",
  สาวร้องไห้: "Sao Rong Hai",
  น้ำไคร้: "Nam Khrai",
  ห้วยหลัว: "Huai Lua",
  แหลม: "Laem",
  มาบแค: "Map Khae",
  บ้านเชี่ยน: "Ban Chian",
  ด่านขุนทด: "Dan Khun Thot",
  ประดู่งาม: "Pradu Ngam",
  เก่าย่าดี: "Kao Ya Di",
  สะอาดไชยศรี: "Sa-at Chai Si",
  เกาะเปริด: "Ko Proet",
  พรหมมาสตร์: "Phrommat",
  พังทุย: "Phang Thui",
  โพสาวหาญ: "Pho Sao Han",
  ทับมา: "Thap Ma",
  นาแห้ว: "Na Haeo",
  ปากตม: "Pak Tom",
  หนองหญ้าขาว: "Nong Ya Khao",
  สร้างนางขาว: "Sang Nang Khao",
  วิหารแดง: "Wihan Daeng",
  บ้านกวาง: "Ban Kwang",
  กาเยาะมาตี: "Kayo Mati",
  อบทม: "Op Thom",
  ปากทรง: "Pak Song",
  ผดุงมาตร: "Phadung Mat",
  หนองพลวง: "Nong Phluang",
  กุดปลาค้าว: "Kut Pla Khao",
  เหมือง: "Mueang",
  หนองสูงใต้: "Nong Sung Tai",
  ศาลาลัย: "Salalai",
  จารพัต: "Charaphat",
  พิบูล: "Phibun",
  ยางเนิ้ง: "Yang Noeng",
  เปียงหลวง: "Piang Luang",
  อรุณอมรินทร์: "Arun Ammarin",
  คำหยาด: "Kham Yat",
  สำนักแต้ว: "Samnak Taeo",
  ลาดกระบัง: "Lat Krabang",
  ห้างสูง: "Hang Sung",
  ก้อ: "Ko",
  อุ่มเหม้า: "Um Mao",
  ตันหยงมัส: "Tanyong Mat",
  แม่กรณ์: "Mae Kon",
  หาดทนง: "Hat Thanong",
  บ้านแหลม: "Ban Laem",
  ขุนยวม: "Khun Yuam",
  ด่านแม่แฉลบ: "Dan Mae Chalaep",
  บ้านเลน: "Ban Len",
  ทัพเสด็จ: "Thap Sadet",
  นาพู่: "Na Phu",
  โจดม่วง: "Chot Muang",
  ทะเลชุบศร: "Thale Chup Son",
  เชียงสือ: "Chiang Sue",
  หนองกระดิ่ง: "Nong Krading",
  มุกดาหาร: "Mukdahan",
  ปาดังเบซาร์: "Padang Besa",
  ป่าเว: "Pa We",
  พนมเศษ: "Phanom Set",
  พุเตย: "Phu Toei",
  ท่ากกแดง: "Tha Kok Daeng",
  สามเสนนอก: "Sam Sen Nok",
  พิหารแดง: "Phihan Daeng",
  เวียงเหนือ: "Wiang Nuea",
  บ่อปลาทอง: "Bo Pla Thong",
  ขึ่ง: "Khueng",
  นาพึง: "Na Phueng",
  บางโปรง: "Bang Prong",
  ขวาว: "Khwao",
  พรหมนิมิต: "Phrom Nimit",
  โคกชำแระ: "Khok Chamrae",
  ดอนมะสังข์: "Don Masang",
  ห้วยหิน: "Huai Hin",
  บางตะเคียน: "Bang Takhian",
  พันลาน: "Phan Lan",
  ตะกาดเง้า: "Takat Ngao",
  ไสหร้า: "Sai Ra",
  ปากคม: "Pak Khom",
  แม่ข่า: "Mae Kha",
  เขิน: "Khoen",
  ตาพระยา: "Ta Phraya",
  ย่านตาขาว: "Yan Ta Khao",
  ทุ่งฮั้ว: "Thung Hua",
  ทับปริก: "Thap Prik",
  บัวตูม: "Bua Tum",
  นาใหญ่: "Na Yai",
  กายูคละ: "Kayu Khla",
  แซร์ออ: "Sae-o",
  กมลาไสย: "Kamalasai",
  ตันหยงโป: "Tanyong Po",
  บ้านน้ำพุ: "Ban Nam Phu",
  แคราย: "Khae Rai",
  หัวทุ่ง: "Hua Thung",
  ซับน้อย: "Sap Noi",
  เกาะสะท้อน: "Ko Sathon",
  ทะเลบก: "Thale Bok",
  เปือยน้อย: "Pueai Noi",
  หนามแท่ง: "Nam Thaeng",
  ตะโละดือรามัน: "Talo Due Raman",
  ค้อใต้: "Kho Tai",
  แม่ทะลบ: "Mae Thalop",
  สามโคก: "Sam Khok",
  ห้วยป่าหวาย: "Huai Pa Wai",
  เกาะคา: "Ko Kha",
  เชียงคาน: "Chiang Khan",
  ชีทวน: "Chi Thuan",
  ปากทะเล: "Pak Thale",
  ตลุก: "Taluk",
  หนองยายดา: "Nong Yai Da",
  ทุ่งไทรทอง: "Thung Sai Thong",
  ห้วย: "Huai",
  ปัว: "Pua",
  วิสัยใต้: "Wisai Tai",
  บ้านหีบ: "Ban Hip",
  ตะกรบ: "Takrop",
  ทรัพย์พระยา: "Sap Phraya",
  คลองตำหรุ: "Khlong Tamru",
  แม่วะ: "Mae Wa",
  ทุ่งโป่ง: "Thung Pong",
  ดงดินแดง: "Dong Din Daeng",
  หญ้าปล้อง: "Ya Plong",
  นาตาขวัญ: "Na Ta Khwan",
  กระชอน: "Krachon",
  ศาลา: "Sala",
  ปากหมัน: "Pak Man",
  นาดินดำ: "Na Din Dam",
  ไสหมาก: "Sai Mak",
  หัวดง: "Hua Dong",
  เนินพระปรางค์: "Noen Phra Prang",
  เสม็จ: "Samet",
  ยางสว่าง: "Yang Sawang",
  ห้วยข้อง: "Huai Khong",
  บ้านกระทุ่ม: "Ban Krathum",
  โพธิ์ไทรงาม: "Pho Sai Ngam",
  ท่าชี: "Tha Chi",
  โพนครก: "Phon Khrok",
  อาโพน: "A Phon",
  กระจิว: "Krachio",
  คลองตาคต: "Khlong Ta Khot",
  เชียงรากใหญ่: "Chiang Rak Yai",
  วังอิทก: "Wang Ithok",
  บางโพธิ์เหนือ: "Bang Pho Nuea",
  แตล: "Taen",
  ละเอาะ: "La-o",
  พะโต๊ะ: "Phato",
  นิลเพชร: "Nin Phet",
  ลำนางรอง: "Lam Nang Rong",
  ทะเลน้อย: "Thale Noi",
  เพิ่มพูนทรัพย์: "Phoem Phun Sap",
  ละแม: "Lamae",
  บ้านแพง: "Ban Phaeng",
  ทุ่งพระ: "Thung Phra",
  จอมหมอกแก้ว: "Chom Mok Kaeo",
  ดงขุย: "Dong Khui",
  โนนบุรี: "Non Buri",
  หนองนกไข่: "Nong Nok Khai",
  ดอนหญ้านาง: "Don Ya Nang",
  เชียงพิณ: "Chiang Phin",
  แจนแลน: "Chaen Laen",
  บ้านชวน: "Ban Chuan",
  นาเหรง: "Na Reng",
  หัวหมาก: "Hua Mak",
  แม่ต๋ำ: "Mae Tam",
  กะทูน: "Kathun",
  โพธิ์เสด็จ: "Pho Sadet",
  เขาขลุง: "Khao Khlung",
  พลกรัง: "Phon Krang",
  เนินยาง: "Noen Yang",
  สะอาด: "Sa-at",
  ปากบ่อง: "Pak Bong",
  สำนักทอง: "Samnak Thong",
  วังชะพลู: "Wang Chaphlu",
  นางัว: "Na Ngua",
  ตะลุง: "Talung",
  เหล่าเสือโก้ก: "Lao Suea Kok",
  อิสาณ: "Isan",
  ทุ่งนนทรี: "Thung Nonsi",
  ดงเสือเหลือง: "Dong Suea Lueang",
  นาเจริญ: "Na Charoen",
  บุ่งเลิศ: "Bung Loet",
  ธวัชบุรี: "Thawat Buri",
  ค้อเขียว: "Kho Khiao",
  ลวงเหนือ: "Luang Nuea",
  ด่านซ้าย: "Dan Sai",
  นาแพง: "Na Phaeng",
  มะบ้า: "Maba",
  ท่าผา: "Tha Pha",
  ขนอนหลวง: "Khanon Luang",
  บ้านชุ้ง: "Ban Chung",
  ลำพญากลาง: "Lam Phaya Klang",
  โคกสง่า: "Khok Sa-nga",
  คลองสองต้นนุ่น: "Khlong Song Ton Nun",
  หนามแดง: "Nam Daeng",
  ฝายกวาง: "Fai Kwang",
  หนองขอนกว้าง: "Nong Khon Kwang",
  บางตาหงาย: "Bang Ta Ngai",
  ทรายกองดิน: "Sai Kong Din",
  น้ำโมง: "Nam Mong",
  เหล่าโพนค้อ: "Lao Phon Kho",
  ตาลาน: "Ta Lan",
  ราชธานี: "Ratchathani",
  เตาไห: "Tao Hai",
  บางขัน: "Bang Khan",
  ท่าเสน: "Tha Sen",
  ลาดน้ำเค็ม: "Lat Nam Khem",
  หนองจะบก: "Nong Chabok",
  ท่าสุด: "Tha Sut",
  น้ำเกลี้ยง: "Nam Kliang",
  แม่ก๊า: "Mae Ka",
  เขาสามสิบ: "Khao Sam Sip",
  หย่วน: "Yuan",
  คูบางหลวง: "Khu Bang Luang",
  ไทรโรงโขน: "Sai Rong Khon",
  สามผง: "sam Phong",
  โคกใหญ่: "Khok Yai",
  สัมฤทธิ์: "Samrit",
  ชำฆ้อ: "Cham Kho",
  นาแก: "Na Kae",
  มะเฟือง: "Mafueang",
  กระหวัน: "Krawan",
  สุมเส้า: "Sum Sao",
  สวนเขื่อน: "Suan Khuean",
  ลานดอกไม้: "Lan Dokmai",
  พะลาน: "Phalan",
  บ้านถิ่น: "Ban Thin",
  แหลมผักเบี้ย: "Laem Phak Bia",
  คลองควาย: "Khlong Khwai",
  สะพานไกร: "Saphan Krai",
  ขามเรียง: "Kham Riang",
  ยายร้า: "Yai Ra",
  อรัญญิก: "Aranyik",
  ท่าเคย: "Tha Khoei",
  บ้านเวียง: "Ban Wiang",
  กุดพิมาน: "Kut Phiman",
  ถนนใหญ่: "Thanon Yai",
  เทอดไทย: "Thoet Thai",
  เขาถ่าน: "Khao Than",
  นิคมสร้างตนเอง: "Nikhom Sang Ton Eng",
  กุดตาเพชร: "Kut Ta Phet",
  ปากพะยูน: "Pak Phayun",
  อัคคะคำ: "Akkha Kham",
  ดอนลาน: "Don Lan",
  บางนาใต้: "Bang Na Tai",
  คูซอด: "Khu Sot",
  สหัสขันธ์: "Sahatsakhan",
  หอมศีล: "Hom Sin",
  บุ่ง: "Bung",
  บ้านป้อม: "Ban Pom",
  บานา: "Bana",
  บ้านเจียง: "Ban Chiang",
  ตาอ็อง: "Ta Ong",
  บางงา: "Bang Nga",
  บ่อรัง: "Bo Rang",
  หนองเขียด: "Nong Khiat",
  คานหาม: "Khan Ham",
  พลวงสองนาง: "Phluang Song Nang",
  ลานสัก: "Lan Sak",
  พะแสง: "Phasaeng",
  วัดเทพศิรินทร์: "Wat Thep Sirin",
  หาดท่าเสา: "Hat Tha Sao",
  รางสาลี่: "Rang Sali",
  ห้วยห้อม: "Huai Hom",
  ตลาดจินดา: "Talat Chinda",
  กุดนกเปล้า: "Kut Nok Plao",
  เจ๊ะเห: "Chehe",
  สิงหนาท: "Singhanat",
  ชัยบาดาล: "Chai Badan",
  บางกระทุ่ม: "Bang Krathum",
  นาเริก: "Na Roek",
  วังตะกอ: "Wang Tako",
  น้ำรัด: "Nam Rat",
  คูหาสวรรค์: "Khuha Sawan",
  โคกทราย: "Khok Sai",
  ขวาวใหญ่: "Khwao Yai",
  คันธารราษฎร์: "Khanthararat",
  สะเมิงใต้: "Samoeng Tai",
  ธรรมเสน: "Thammasen",
  พ่วงพรมคร: "Phuang Phrom Khon",
  ลำลูกกา: "Lum Luk Ka",
  กันทรารมย์: "Kanthararom",
  ดงมะไฟ: "Dong Mafai",
  ท่าโพธิ์: "Tha Pho",
  ปราสาทเยอ: "Prasat Yoe",
  ปงป่าหวาย: "Pong Pa Wai",
  หนองไม้กอง: "Nong Mai Kong",
  สระกะเทียม: "Sra Kathiam",
  บ้านปวง: "Ban Puang",
  พนัสนิคม: "Phanat Nikhom",
  สว่างแดนดิน: "Sawang Daen Din",
  กระจัน: "Krachan",
  ปากพลี: "Pak Phli",
  หนองข่า: "Nong Kha",
  ท่าอิฐ: "Tha It",
  ดงลาน: "Dong Lan",
  หนองชาก: "Nong Chak",
  ในวงเหนือ: "Nai Wong Nuea",
  ยางชุมน้อย: "Yang Chum Noi",
  แข้: "Khae",
  เทพารักษ์: "Thepharak",
  ซับสีทอง: "Sap Si Thong",
  เกาะจันทร์: "Ko Chan",
  กะทู้: "Kathu",
  คลองนครเนื่องเขต: "Khlong Nakhon Nueang Khet",
  ริมกก: "Rim Kok",
  ราษฎร์พัฒนา: "Rat Phatthana",
  ห้วยคต: "Huai Khot",
  หาดใหญ่: "Hat yai",
  ตาเป๊ก: "Ta Pek",
  ไพศาลี: "Phaisali",
  รางพิกุล: "Rang Phikun",
  บ่อแดง: "Bo Daeng",
  และ: "Lae",
  สะเดียง: "Sadiang",
  บึงคอไห: "Bueng Kho Hai",
  ดอนเกาะกา: "Don Ko Ka",
  โพนจาน: "Phon Chan",
  มาโมง: "Mamong",
  สารภี: "Saraphi",
  หนองปลาสะวาย: "Nong Pla Sawai",
  พญาเย็น: "Phaya Yen",
  แหลมฟ้าผ่า: "Laem Fa Pha",
  บางกะปิ: "Bang Kapi",
  หาดเจ้าสำราญ: "Hat Chao Samran",
  ปิตูมุดี: "Pitu Mudi",
  ท้ายสำเภา: "Thai Samphao",
  ละลวด: "Laluat",
  นาจิก: "Na Chik",
  เมืองชุม: "Mueang Chum",
  บ้านจารย์: "Ban Chan",
  หลักสาม: "Lak Sam",
  โคกม้า: "Khok Ma",
  บาโหย: "Ba Hoi",
  ท่าพยา: "Tha Phaya",
  บ้านโสก: "Ban Sok",
  มะขามหลวง: "Makham Luang",
  บ่อแสน: "Bo Saen",
  ทุ่งวัง: "Thung Wang",
  สาริกา: "Sarika",
  กุดรัง: "Kut Rang",
  คอหงส์: "Kho Hong",
  บางพระหลวง: "Bang Phra Luang",
  กงรถ: "Kong Rot",
  ธาตุเชิงชุม: "That Choeng Chum",
  บรบือ: "Borabue",
  ตรวจ: "Truat",
  ไผ่จำศีล: "Phai Cham Sin",
  ช่อระกา: "Cho Raka",
  หนองกินเพล: "Nong Kin Phen",
  ศรีสะอาด: "Si Sa-at",
  พระพุทธ: "Phra Phut",
  โนนฆ้อง: "Non Khong",
  ควนโดน: "Khuan Don",
  ภูผาม่าน: "Phu Pha Man",
  หนองเมือง: "Nong Mueang",
  บริบูรณ์: "Boribun",
  หินตั้ง: "Hin Tang",
  ซำ: "Sam",
  นาคำใหญ่: "Na Kham Yai",
  ดงประคำ: "Dong Prakham",
  ปากพนัง: "Pak Phanang",
  โนนเพ็ด: "Non Phet",
  หนองละลอก: "Nong Lalok",
  สำเภาล่ม: "Samphao Lom",
  เสือโฮก: "Suea Hok",
  ศาลาธรรมสพน์: "Sala Thammasop",
  ด่านนอก: "Dan Nok",
  ดงครั่งน้อย: "Dong Khrang Noi",
  พระกลางทุ่ง: "Phra Klang Thung",
  บางประมุง: "Bang Pramung",
  ตาเสา: "Ta Sao",
  ถ้ำทะลุ: "Tham Thalu",
  บางคา: "Bang Kha",
  ตาลโกน: "Tan Kon",
  ชุมแสงสงคราม: "Chum Saeng Songkhram",
  บ้านแหวน: "Ban Waen",
  หนองฝ้าย: "Nong Fai",
  ทับสะแก: "Thap Sakae",
  คลองตัน: "Khlong Tan",
  แสลงโทน: "Salaeng Thon",
  ลำประดา: "Lam Prada",
  ลุมปุ๊ก: "Lumpuk",
  บางผึ้ง: "Bang Phueng",
  สวนจิตรลดา: "Suan Chitlada",
  หนองกะท้าว: "Nong Kathao",
  ลาดโพธิ์: "Lat Pho",
  หนองบุนนาก: "Nong Bunnak",
  ร่มไทร: "Rom Sai",
  วิหารขาว: "Wihan Khao",
  บ้านพี้: "Ban Phi",
  นาทอง: "Na Thong",
  ช่างเหล็ก: "Chang Lek",
  ดงเค็ง: "Dong Kheng",
  บางขุนพรหม: "Bang Khun Phrom",
  สาคอบน: "Sakho Bon",
  เมืองยาง: "Mueang Yang",
  จำป่าหวาย: "Cham Pa Wai",
  วาวี: "Wawi",
  วังมะปราง: "Wang Maprang",
  ดงเจริญ: "Dong Charoen",
  ยาบหัวนา: "Yap Hua Na",
  บ้านโตก: "Ban Tok",
  นิคมคำสร้อย: "Nikhom Kham Soi",
  โนนเหล็ก: "Non Lek",
  ปากคาด: "Pak Khat",
  ทรายกองดินใต้: "Sai Kong Din Tai",
  บ้านแพ: "Ban Phae",
  วังสะพุง: "Wang Saphung",
  วัดไทร: "Wat Sai",
  นาสัก: "Na Sak",
  คลองไก่เถื่อน: "Khlogn Kai Thuean",
  บ้านส้อง: "Ban Song",
  ปลายบาง: "Plai Bang",
  พระประโทน: "Phra Prathon",
  นาห่อม: "Na Hom",
  หนองฉาง: "Nong Chang",
  ด่านแม่คำมัน: "Dan Mae Kham Man",
  คลองสระบัว: "Khlong Sa Bua",
  ปากดุก: "Pak Duk",
  กลางดง: "Klang Dong",
  สหธาตุ: "Sahathat",
  เจริญสุข: "Charoen Suk",
  ศาลเจ้าโรงทอง: "San Chao Rong Thong",
  เทพาลัย: "Thephalai",
  ช้างทอง: "Chang Thong",
  คันธุลี: "Khan Thuli",
  ปะอาว: "Pa Ao",
  ยะรม: "Yarom",
  ทุ่งกว๋าว: "Thung Kwao",
  วาริชภูมิ: "Waritchaphum",
  หิรัญรูจี: "Hiran Ruchi",
  ท่าคันโท: "Tha Khantho",
  สำพันตา: "Sam Phan Ta",
  แก่งหางแมว: "Kaeng Hang Maeo",
  คลองประเวศ: "Khlong Prawet",
  ควนหนองหงษ์: "Khuan Nong Hong",
  เปือยใหญ่: "Pueai Yai",
  ตาตุม: "Ta Tum",
  โพธิ์เก้าต้น: "Pho Kao Ton",
  ปากแรต: "Pak Raet",
  สามชุก: "Sam Chuk",
  เสริมกลาง: "Soem Klang",
  เสิงสาง: "Soeng Sang",
  หนองคล้า: "Nong Khla",
  วังแขม: "Wang Khaem",
  ไสไทย: "Sai Thai",
  ผือฮี: "Phue Hi",
  บึงสามพัน: "Bueng Sam Phan",
  ประดู่: "Pradu",
  โคกพระเจดีย์: "Khok Phra Chedi",
  บ้านปทุม: "Ban Pathum",
  นาฝาย: "Na Fai",
  วังสมบูรณ์: "Wang Sombun",
  มะกอกเหนือ: "Makok Nuea",
  แคนดง: "Khaen Dong",
  โนนปูน: "Non Pun",
  อนุสาวรีย์: "Anusawari",
  ธรรมามูล: "Thammamun",
  บัวเงิน: "Bua Ngoen",
  ตะขบ: "Takhop",
  ภูดิน: "Phu Din",
  วังพัฒนา: "Wang Phatthana",
  หนองไม้ซุง: "Nong Mai Sung",
  ทุ่งฝาย: "Thung Fai",
  สันทะ: "Santha",
  คอนสาร: "Khon San",
  แม่ทา: "Mae Tha",
  ภูผาหมอก: "Phu Pha Mok",
  บางน้ำเปรี้ยว: "Bang Nam Priao",
  หัวทะเล: "Hua Thale",
  บางโฉลง: "Bang Chalong",
  บางยี่เรือ: "Bang Yi Ruea",
  เอือดใหญ่: "Ueat Yai",
  วังกรด: "Wang Krot",
  ท่าไม้รวก: "Tha Mai Ruak",
  มหาพฤฒาราม: "Maha Phruettharam",
  บ้านจันทร์: "Ban Chan",
  ซากอ: "Sako",
  พังโคน: "Phang Khon",
  โนนยอ: "Non Yo",
  เมืองทอง: "Mueang Thong",
  เขาหินซ้อน: "Khao Hin Son",
  แก้งกอก: "Kaeng Kok",
  คำสร้างเที่ยง: "Kham Sang Thiang",
  ปทุม: "Pathum",
  แม่มอก: "Mae Mok",
  โคกขมิ้น: "Khok Khamin",
  เชียงของ: "Chiang Khong",
  เชียงแหว: "Chiang Wae",
  บางคู้: "Bang Khu",
  รามราช: "Ram Rat",
  ดอนยาง: "Don Yang",
  ผาช้างน้อย: "Pha Chang Noi",
  สันสลี: "San Sali",
  บ้านเรือน: "Ban Ruean",
  โคกตะบอง: "Khok Tabong",
  ท่าน้าว: "Tha Nao",
  หนองประดู่: "Nong Pradu",
  บ้านขล้อ: "Ban Khlo",
  หารเทา: "Han Thao",
  ไผ่ขอดอน: "Phai Kho Don",
  ดอนมะเกลือ: "Don Makluea",
  นาเกตุ: "Na Ket",
  แม่สุก: "Mae Suk",
  หาดคำ: "Hat Kham",
  แงง: "Ngaeng",
  นครปฐม: "Nakhon Pathom",
  ท้ายเหมือง: "Thai Mueang",
  ขุหลุ: "Khulu",
  บาราเฮาะ: "Bara Ho",
  โมคลาน: "Mokkhalan",
  ท่าขนอน: "Tha Khanon",
  คลองเจ็ด: "Khlong Chet",
  สายออ: "Sai O",
  ขุนฝาง: "Khun Fang",
  คลองบางพราน: "Khlong Bang Phran",
  หนองชุมพล: "Nong Chumphon",
  บ้านน้อย: "Ban Noi",
  บางโขมด: "Bang Khamot",
  บัวใหญ่: "Bua Yai",
  บางขุนศรี: "Bang Khun Si",
  บักได: "Bak Dai",
  บ้านตึก: "Ban Tuek",
  บางเดื่อ: "Bang Duea",
  สายลำโพง: "Sai Lamphong",
  เจ็ดริ้ว: "Chet Rio",
  ป่าหุ่ง: "Pa Hung",
  ไม้เรียง: "Mai Riang",
  มะนังดาลำ: "Manang Dalam",
  เมืองใหม่: "Mueang Mai",
  หนองหมื่นถ่าน: "Nong Muen Than",
  เนินเพิ่ม: "Noen Phoem",
  บางปิด: "Bang Pit",
  เป้า: "Pao",
  ตะปาน: "Tapan",
  บ้านราม: "Ban Ram",
  น้ำกุ่ม: "Nam Kum",
  กะแดะ: "Kadae",
  โพรงอากาศ: "Phrong Akat",
  รือเสาะออก: "Rueso Ok",
  อีเซ: "I Se",
  หงาว: "Ngao",
  คู้ลำพัน: "Khu Lam Phan",
  ดงดวน: "Dong Duan",
  ปากฉลุย: "Pak Chalui",
  นางั่ว: "Na Ngua",
  หลุบ: "Lup",
  อุทัย: "Uthai",
  จำปี: "Champi",
  ห้วยจรเข้: "Huai Chorakhe",
  ร่มเกล้า: "Rom Klao",
  วังบูรพาภิรมย์: "Wang Burapha Phirom",
  กุดหมากไฟ: "Kut Mak Fai",
  สำโรงใต้: "Samrong Tai",
  หนองผักแว่น: "Nong Phak Waen",
  ตลาดบางเขน: "Talat Bang Khen",
  ห้วยชัน: "Huai Chan",
  โพนสา: "Phon Sa",
  แสงภา: "Saeng Pha",
  กุดฉิม: "Kut Chim",
  ดงแคนใหญ่: "Dong Khaen Yai",
  สะโน: "Sano",
  บางสวน: "Bang Suan",
  บ้านเพิ่ม: "Ban Phoem",
  เหล่าหมี: "Lao Mi",
  หลักหก: "Lak Hok",
  เกาะทวด: "Ko Thuat",
  บ้านตุ่น: "Ban Tun",
  ลำดวน: "Lamduan",
  เนินกว้าว: "Noen Kwao",
  บางแขม: "Bang Khaem",
  เขาแก้วศรีสมบูรณ์: "Khao Kaeo Si Sombun",
  แหลมสน: "Laem Son",
  แม่สะเรียง: "Mae Sariang",
  นานาค: "Na Nak",
  แม่ลาด: "Mae Lat",
  ป่าอ้อดอนชัย: "Pa O Don Chai",
  กุดเสลา: "Kut Salao",
  หนองคูขาด: "Nong Khu Khat",
  ด่านชุมพล: "Dan Chumphon",
  แม่วะหลวง: "Mae Wa Luang",
  วัดแค: "Wat Khae",
  พิงพวย: "Phing Phuai",
  หาดยาง: "Hat Yang",
  นาเรียง: "Na Riang",
  บางทอง: "Bang Thong",
  ทรัพย์ทวี: "Sap Thawi",
  ท่าฬ่อ: "Tha Lo",
  น้ำอ่าง: "Nam Ang",
  โนน: "Non",
  ลำภูรา: "Lamphu Ra",
  สี่แยกมหานาค: "Si Yaek Mahanak",
  มาบยางพร: "Map Yang Phon",
  ทรงธรรม: "Song Tham",
  ลานกระบือ: "Lan Krabue",
  เวียงสระ: "Wiang Sa",
  ภูหอ: "Phu Ho",
  น้ำหมาน: "Nam Man",
  พุนพิน: "Phunphin",
  คูบ: "Khup",
  โคราช: "Khorat",
  กอลำ: "Kolam",
  ตาก้อง: "Ta Kong",
  หินฮาว: "Hin Hao",
  น้ำคำใหญ่: "Nam Kham Yai",
  เมืองเดช: "Mueang Det",
  เขาต่อ: "Khao To",
  ต้นตาล: "Ton Tan",
  กายูบอเกาะ: "Kayu Boko",
  โนนพลวง: "Non Phluang",
  แม่กระบุง: "Mae Krabung",
  ท่าข้าวเปลือก: "Tha Khao Plueak",
  ดงเจน: "Dong Chen",
  สุขสำราญ: "Suk Samran",
  วัดราชบพิธ: "Wat Ratchabophit",
  ดงคอน: "Dong Khon",
  บ้านเรือ: "Ban Ruea",
  หนองอิเฒ่า: "Nong I Thao",
  เสือโก้ก: "Suea Kok",
  ทับพริก: "Thap Phrik",
  ดงป่าคำ: "Dong Pa Kham",
  ป่าขะ: "Pa Kha",
  นาโก: "Na Ko",
  ท่าจีน: "Tha Chin",
  สี่คลอง: "Si Khlong",
  หนองตรุด: "Nong Trut",
  ห้วยส้ม: "Huai Som",
  ดงหม้อทอง: "Dong Mo Thong",
  ไพร: "Phrai",
  สะตอน: "Saton",
  ประชาพัฒนา: "Pracha Phatthana",
  สระพังทอง: "Sa Phang Thong",
  ชุมตาบง: "Chum Ta Bong",
  จันทเขลม: "Chanthakhlem",
  โคกมั่งงอย: "Khok Mang Ngoi",
  บุเปือย: "Bu Pueai",
  กระเบื้องนอก: "Krabueang Nok",
  อินทประมูล: "Inthapramun",
  ครบุรี: "Khon Buri",
  ม่วงใหญ่: "Muang Yai",
  บ้านเสียว: "Ban Siao",
  สระทอง: "Sa Thong",
  โป่งแพร่: "Pong Phrae",
  หนองดินแดง: "Nong Din Daeng",
  เขาวิเศษ: "Khao Wiset",
  ภูแลนคา: "Phu Laen Kha",
  หานโพธิ์: "Han Pho",
  บะยาว: "Ba Yao",
  บางสระเก้า: "Bang Sa Kao",
  ชัยมงคล: "Chai Mongkon",
  ตาดควัน: "Tat Khwan",
  นากระแซง: "Na Krasaeng",
  เจดีย์หลวง: "Chedi Luang",
  บ้านปราโมทย์: "Ban Pramot",
  บ้านแซว: "Ban Saeo",
  ปลายโพงพาง: "Plai Phongphang",
  พังขว้าง: "Phang Khwang",
  นาใต้: "Na Tai",
  ไฮหย่อง: "Hai Yong",
  ดงมะยาง: "Dong Mayang",
  หนองไม้ไผ่: "Nong Mai Phai",
  ท่าดินแดง: "Tha Din Daeng",
  โคกโพธิ์: "Khok Pho",
  กำแมด: "Kammaet",
  ท่าตะโก: "Tha Tako",
  อ่าวน้อย: "Ao Noi",
  โนนหอม: "Non Hom",
  โนนคูณ: "Non Khun",
  โพนสวรรค์: "Phon Sawan",
  ในควน: "Nai Khuan",
  ไพรบึง: "Phrai Bueng",
  พระแก้ว: "Phra Kaeo",
  นาคำไฮ: "Na Kham Hai",
  ริมโขง: "Rim Khong",
  แม่สอง: "Mae Song",
  ละลมใหม่พัฒนา: "Lalom Mai Phatthana",
  นามน: "Na Mon",
  บ้านมะเกลือ: "Ban Makluea",
  ยุโป: "Yupo",
  กกแรต: "Kok Raet",
  ทุ่งน้าว: "Thung Nao",
  โนนทองหลาง: "Non Thonglang",
  คำป่าหลาย: "Kham Pa Lai",
  ไร่ใหม่พัฒนา: "Rai Mai Phatthana",
  ดอนประดู่: "Don Pradu",
  บ้านอิฐ: "Ban It",
  เจียด: "Chiat",
  บุ่งค้า: "Bung Kha",
  แก่งเค็ง: "Kaeng Kheng",
  ป่าโมก: "Pa Mok",
  หนองพังค่า: "Nong Phang Kha",
  หนองฉิม: "Nong Chim",
  วังซ่าน: "Wang San",
  บิง: "Bing",
  สองแพรก: "Song Phraek",
  บางโพงพาง: "Bang Phongphang",
  รังสิต: "Rangsit",
  บะหว้า: "Ba Wa",
  ตระกาจ: "Trakat",
  ห้วยเขย่ง: "Huai Khayeng",
  ยางเปียง: "Yang Piang",
  โคกมน: "Khok Mon",
  เขาพระงาม: "Khao Phra Ngam",
  หายโศก: "Hai Sok",
  ลือ: "Lue",
  พนางตุง: "Phanang Tung",
  ป่งขาม: "Pong Kham",
  โยนก: "Yonok",
  รูสะมิแล: "Ru Samilae",
  อุดมธัญญา: "Udom Thanya",
  บางเจ้าฉ่า: "Bang Chao Cha",
  บางเพรียง: "Bang Phriang",
  พลูตาหลวง: "Phlu Ta Luang",
  แม่นางขาว: "Mae Nang Khao",
  วังชัย: "Wang Chai",
  หาดสองแคว: "Hat Song Khwae",
  โมถ่าย: "Mo Thai",
  ไก่เส่า: "Kai Sao",
  บาโงยซิแน: "Ba-ngoi Sinae",
  ห้วยน้ำหอม: "Huai Nam Hom",
  นาสีนวน: "Na Si Nuan",
  เทวราช: "Thewarat",
  พิมลราช: "Phimon Rat",
  แพงพวย: "Phaengphuai",
  หนองโพรง: "Nong Phrong",
  สามแวง: "Sam Waeng",
  นางแล: "Nang Lae",
  ตาลสุม: "Tan Sum",
  เจ็ดเสมียน: "Chet Samian",
  นาโสม: "Na Som",
  ดอนเจดีย์: "Don Chedi",
  บางรักพัฒนา: "Bang Rak Phatthana",
  พญาแมน: "Phaya Maen",
  ลาดสวาย: "Lat Sawai",
  ดู่ทุ่ง: "Du Thung",
  แวงน่าง: "Waeng Nang",
  จันเสน: "Chan Sen",
  โมโกร: "Mokro",
  บ่อเงิน: "Bo Ngoen",
  ลาดงา: "Lat Nga",
  น้ำชุน: "Nam Chun",
  บ่อเบี้ย: "Bo Bia",
  บางเกลือ: "Bang Kluea",
  เขวาทุ่ง: "Khwao Thung",
  ภูกระดึง: "Phu Kradueng",
  หลุมเข้า: "Lum Khao",
  ห้วยไคร้: "Huai Khrai",
  คลองชะอุ่น: "Khlong Cha-un",
  มาบโป่ง: "Map Pong",
  วังโพธิ์: "Wang Pho",
  ดองกำเม็ด: "Dong Kam Met",
  ห้วยใหญ่: "Huai Yai",
  สุเทพ: "Suthep",
  ใหม่: "Mai",
  ศรีโพธิ์เงิน: "Si Pho Ngoen",
  หันตรา: "Hantra",
  แพรกษาใหม่: "Phraek Sa Mai",
  เกาะเปียะ: "Ko Pia",
  โหล่งขอด: "Long Khot",
  บางกระสอบ: "Bang Krasop",
  ถ้ำพรรณรา: "Tham Phannara",
  กู่สันตรัตน์: "Ku Santarat",
  หนองช้างใหญ่: "Nong Chang Yai",
  สร้างคอม: "Sang Khom",
  บางกรวย: "Bang Kruai",
  คำแมด: "Kham Maet",
  กะลุวอ: "Kaluwo",
  ห้วยราชา: "Huai Racha",
  ป่าบง: "Pa Bong",
  หมากเขียบ: "Mak Khiap",
  ดอนเมย: "Don Moei",
  เขานิเวศน์: "Khao Niwet",
  ทุ่งไชย: "Thung Chai",
  พนมทวน: "Phanom Thuan",
  โพนค้อ: "Phon Kho",
  แม่สิน: "Mae Sin",
  สีคิ้ว: "Sikhio",
  ทุ่งคลอง: "Thung Khlong",
  เนินแจง: "Noen Chaeng",
  จริม: "Chalim",
  บางจาน: "Bang Chan",
  ปลายกลัด: "Plai Klat",
  ผาสามยอด: "Pha Sam Yot",
  สหกรณ์นิคม: "Sahakon Nikhom",
  ทากาศ: "Tha Kat",
  มะกอกหวาน: "Makok Wan",
  บ้านเลือก: "Ban Lueak",
  คลองจุกกระเฌอ: "Khlong Chuk Krachoe",
  แม่ป้าก: "Mae Pak",
  หนองมะค่าโมง: "Nong Makha Mong",
  บาลอ: "Balo",
  จะแหน: "Chanae",
  วัดกัลยาณ์: "Wat Kanlaya",
  นาอุดม: "Na Udom",
  เขากระปุก: "Khao Krapuk",
  แม่ยางร้อง: "Mae Yang Rong",
  สวี: "Sawi",
  แม่สวด: "Mae Suat",
  ฝางคำ: "Fang Kham",
  สะแก: "Sakae",
  บางพูน: "Bang Phun",
  แม่เล่ย์: "Mae Le",
  สาวชะโงก: "Sao Cha-ngok",
  ไร่พัฒนา: "Rai Phatthana",
  ธารเกษม: "Than Kasem",
  บางขนาก: "Bang Khanak",
  นาจะหลวย: "Na Chaluai",
  พิกุลออก: "Phikun Ok",
  นครสวรรค์ออก: "Nakhon Sawan Ok",
  ภูเหล็ก: "Phu Lek",
  นิคมสร้างตนเองลำโดมน้อย: "Nikhom Sang Ton-eng Lam Dom Noi",
  ตะค่า: "Takha",
  นาตาล่วง: "Na Ta Luang",
  สระ: "Sa",
  นาเฉลียง: "Na Chaliang",
  เจดีย์: "Chedi",
  นากะชะ: "Na Kacha",
  วังสวาบ: "Wang Sawap",
  ตูมใหญ่: "Tum Yai",
  หนองกุลา: "Nong Kula",
  คำเนียม: "Kham Niam",
  นาบินหลา: "Na Bin La",
  เชียงกลม: "Chiang Klom",
  แม่สลองใน: "Mae Salong Nai",
  ห้วยพิชัย: "Huai Phichai",
  อรัญประเทศ: "Aranyaprathet",
  ตลาดแร้ง: "Talat Raeng",
  ท่ากระเสริม: "Tha Krasoem",
  ปากทาง: "Pak Thang",
  ทับใต้: "Thap Tai",
  วัดโคก: "Wat Khok",
  หนองล่อง: "Nong Long",
  ท่าหาดยาว: "Tha Hat Yao",
  โคกแฝด: "Khok Faet",
  พรานกระต่าย: "Phran Kratai",
  บ่อไทย: "Bo Thai",
  ทุ่งนางโอก: "Thung Nang Ok",
  น้ำเกี๋ยน: "Nam Kian",
  ท่าตุ้ม: "Tha Tum",
  ดอนขมิ้น: "Don Khamin",
  บางตะไนย์: "Bang Tanai",
  บุ่งน้ำเต้า: "Bung Namtao",
  หมากแข้ง: "Mak Khaeng",
  โนนโพธิ์: "Non Pho",
  ตกพรม: "Tok Phom",
  หนองแฝก: "Nong Faek",
  โนนเพ็ก: "Nong Phek",
  น้ำปั้ว: "Nam Pua",
  หนองหัวโพ: "Nong Hua Pho",
  เหล่าสร้างถ่อ: "Lao Sang Tho",
  หนองซน: "Nong Son",
  แสงสว่าง: "Saeng Sawang",
  หนองกุงแก้ว: "Nong Kung Kaeo",
  หนองสามสี: "Nong Sam Si",
  ไร่หลักทอง: "Rai Lak Thong",
  คลองจิก: "Khlong Chik",
  หนองขาหย่าง: "Nong Khayang",
  ขุนแม่ลาน้อย: "Khun Mae La Noi",
  บึงนาราง: "Bueng Na Rang",
  นางหลง: "Nong Long",
  นครไทย: "Nakhon Thai",
  ดอนไก่ดี: "Don Kai Di",
  หลักเขต: "Lak Khet",
  กล้วยแพะ: "Kluai Phae",
  ผาตั้ง: "Pha Tang",
  หนองนางนวล: "Nong Nang Nuan",
  เทพเสด็จ: "Thep Sadet",
  บางวัน: "Bang Wan",
  แป้น: "Paen",
  หนองกลางดง: "Nong Klang Dong",
  กกกุง: "Kok Kung",
  โพนทัน: "Phon Than",
  บางคูรัด: "Bang Khu Rat",
  ควนหนองคว้า: "Khuan Nong Khwa",
  หนองเหล็ก: "Nong Lek",
  ทรัพย์อนันต์: "Sap Anan",
  หนองครก: "Nong Khrok",
  น้ำสุด: "Nam Sut",
  สมอ: "Samo",
  ช่องลม: "Chong Lom",
  เกษม: "Kasem",
  โคกแย้: "Khok Yae",
  แก่งดินสอ: "Kaeng Din So",
  ด่าน: "Dan",
  หมื่นศรี: "Muen Si",
  มาย: "Mai",
  ดอนอะราง: "Don Arang",
  ลำไทรโยง: "Lam Sai Yong",
  ปะกาฮะรัง: "Paka Harang",
  ขุนหาญ: "Khun Han",
  บ้านปรางค์: "Ban Prang",
  หนองกอมเกาะ: "Nong Kom Ko",
  ไชยราช: "Chai Rat",
  โคกหล่อ: "Khok Lo",
  สบแม่ข่า: "Sop Mae Kha",
  บึงชำอ้อ: "Bueng Cham O",
  เหล่าพรวน: "Lao Phruan",
  โดด: "Dot",
  คู้สลอด: "Khu Salot",
  หนองระเวียง: "Nong Rawiang",
  สามตุ่ม: "Sam Tum",
  ห้วยขมิ้น: "Huai Khamin",
  ดงพลับ: "Dong Phlap",
  พรุใน: "Phru Nai",
  ศรีฐาน: "Si Than",
  บ้านเสด็จ: "Ban Sadet",
  งิม: "Ngim",
  ท่าโพ: "Tha Pho",
  บางนมโค: "Bang Nom Kho",
  ลำคลอง: "Lam Khlong",
  หนองค้า: "Nong Kha",
  ปาล์มพัฒนา: "Pam Phatthana",
  จำปาขัน: "Champa Khan",
  ช่องกุ่ม: "Chong Kum",
  เมืองใต้: "Mueang Tai",
  สังคม: "Sangkhom",
  ห้วยโพธิ์: "Huai Pho",
  กองทูล: "Kong Thun",
  บาราโหม: "Bara Hom",
  สำพะเนียง: "Lam Phaniang",
  จันทร์เพ็ญ: "Chan Phen",
  คลองกิ่ว: "Khlong Kio",
  เชียงใหม่: "Chiang Mai",
  คลองหนึ่ง: "Khlong Nueng",
  ธารละหลอด: "Than Lalot",
  เชียงกลาง: "Chiang Klang",
  ปากรอ: "Pak Ro",
  โนนผึ้ง: "Non Phueng",
  กุดขอนแก่น: "Kut Khon Kaen",
  สองแคว: "Song Khwae",
  หนองกะขะ: "Nong Kakha",
  ปลาปาก: "Pla Pak",
  ซับใหญ่: "Sap Yai",
  สะพานไม้แก่น: "Saphan Mai Kaen",
  สุขฤทัย: "Suk Ruethai",
  ชายนา: "Chai Na",
  โป่งสา: "Pong Sa",
  แม่อ้อ: "Mae O",
  คลองเมือง: "Khlong Mueang",
  ตะโละ: "Talo",
  เขาคีริส: "Khao Khirit",
  ทุ่งนาไทย: "Thung Na Thai",
  โรงหีบ: "Rong Hip",
  คลองนกกระทุง: "Khlong Nok Krathung",
  สุโสะ: "Suso",
  ป่าเมี่ยง: "Pa Miang",
  ท่าพระยา: "Tha Phraya",
  เปลี่ยน: "Plian",
  ทุ่งมน: "Thung Mon",
  บัวทอง: "Bua Thong",
  บ้านโคน: "Ban Khon",
  พนมไพร: "Phanom Phrai",
  โนนงาม: "Non Ngam",
  พังแดง: "Phang Daeng",
  เฉลิม: "Chaloem",
  พระสิงห์: "Phra Sing",
  บางกอบัว: "Bang Ko Bua",
  เพี้ยราม: "Phia Ram",
  คลองมะเดื่อ: "Khlong Maduea",
  ศรีสุขสำราญ: "Si Suk Samran",
  สะแบง: "Sabaeng",
  ห้วยเฮี้ย: "Huai Hia",
  บางลาย: "Bang Lai",
  น้ำปลีก: "Nam Plik",
  วัดขวาง: "Wat Khwang",
  ปากน้ำโพ: "Pak Nam Pho",
  โคกล่าม: "Khok Lam",
  แพด: "Phaet",
  ศิลาทิพย์: "Sila Thip",
  ท่าเกษม: "Tha Kasem",
  หนองย่างทอย: "Nong Yang Thoi",
  ดอนตะโก: "Don Tako",
  วังไชย: "Wang Chai",
  บ้านกิ่ว: "Ban Kio",
  จุน: "Chun",
  วัดพริก: "Wat Phrik",
  ห้วยหอม: "Huai Hom",
  ปูยู: "Puyu",
  ศรีกะอาง: "Si Ka-ang",
  ออเงิน: "O Ngoen",
  ท่ามะไฟหวาน: "Tha Mafai Wan",
  ท่าสองยาง: "Tha Song Yang",
  ลุงเขว้า: "Lung Khwao",
  ท่าน้ำ: "Tha Nam",
  แร่: "Rae",
  หนองบัวเหนือ: "Nong Bua Nuea",
  ทุ่งพลา: "Thung Phala",
  วังจิก: "Wang Chik",
  หนองแหย่ง: "Nong Yaeng",
  แก้วแสน: "Kaeo Saen",
  เสาไห้: "Sao Hai",
  อากาศ: "Akat",
  เนินขี้เหล็ก: "Noen Khilek",
  มหาสวัสดิ์: "Maha Sawat",
  จานลาน: "Chan Lan",
  จะกง: "Chakong",
  ทุ่งบุหลัง: "Thung Bu Lang",
  บางระมาด: "Bang Ramat",
  หงส์หิน: "Hong Hin",
  คลองศก: "Khlong Sok",
  ดอนดู่: "Don Du",
  เวียงคอย: "Wiang Khoi",
  ตองโขบ: "Tong Khop",
  ทุ่งเขาหลวง: "Thung Khao Luang",
  บึงน้ำรักษ์: "Bueng Nam Rak",
  ราษฎร์เจริญ: "Rat Charoen",
  รางจรเข้: "Rang Chorakhe",
  ทุ่งยั้ง: "Thung Yang",
  ท่าปลา: "Tha Pla",
  ลำคอหงษ์: "Lam Kho Hong",
  คันนายาว: "Khan Na Yao",
  มะขามเฒ่า: "Makham Thao",
  ท่ามะกา: "Tha Maka",
  ยอด: "Yot",
  เขวา: "Khwao",
  หูล่อง: "Hu Long",
  หนองชุมพลเหนือ: "Nong Chumphon Nuea",
  รัตภูมิ: "Rattaphum",
  คำหว้า: "Kham Wa",
  กาฬสินธุ์: "Kalasin",
  ศรีวิไล: "Si Wilai",
  สำนักขุนเณร: "Samnak Khun Nen",
  บางปลา: "Bang Pla",
  หนองไข่นก: "Nong Khai Nok",
  บงเหนือ: "Bong Nuea",
  ป่าแป๋: "Pa Pae",
  หนองบัวทอง: "Nong Bua Thong",
  หนองยายโต๊ะ: "Nong Yai To",
  สะท้อน: "Sathon",
  พันชาลี: "Phan Chali",
  ห้วยหมอนทอง: "Huai Mon Thong",
  ช่องสะแก: "Chong Sakae",
  บ้านจั่น: "Ban Chan",
  หนองกี่: "Nong Ki",
  วาเล่ย์: "Wale",
  ไร่โคก: "Rai Khok",
  บ้านถ่อน: "Ban Thon",
  ริมเหนือ: "Rim Nuea",
  บางเขียด: "Bang Khiat",
  คุริง: "Khuring",
  ทุ่งกุลา: "Thung Kula",
  คำไฮ: "Kham Hai",
  ดูกอึ่ง: "Duk Ueng",
  บางจัก: "Bang Chak",
  ป่าเซ่า: "Pa Sao",
  บ่อถ้ำ: "Bo Tham",
  ศรีพราน: "Si Phran",
  ชมพู: "Chomphu",
  นาเสียว: "Na Siao",
  พังกาญจน์: "Phang Kan",
  เกวียนหัก: "Kwian Hak",
  แม่เจดีย์ใหม่: "Mae Chedi Mai",
  บึงโขงหลง: "Bueng Khong Long",
  นาพิน: "Na Phin",
  ควนเกย: "Khuan Koei",
  บึงคำพร้อย: "Bueng Kham Phroi",
  โรงเข้: "Rong Khe",
  บางพลี: "Bang Phli",
  พระโขนงใต้: "Phra Khanong Tai",
  ปังกู: "Pang Ku",
  ครึ่ง: "Khrueng",
  กกแดง: "Kok Daeng",
  ศรีษะเกษ: "Sisa Ket",
  บ้านข่า: "Ban Kha",
  โรง: "Rong",
  สนามจันทร์: "Sanam Chan",
  ห้วยเกิ้ง: "Huai Koeng",
  บางกร่าง: "Bang Krang",
  เมืองไพร: "Mueang Phrai",
  บ้านลำ: "Ban Lam",
  กรุงหยัน: "Krung Yan",
  เพ็ญ: "Phen",
  ชนะสงคราม: "Chana Songkhram",
  บางศาลา: "Bang Sala",
  บางใบไม้: "Bang Bai Mai",
  ปากข้าวสาร: "Pak Khao San",
  ฟากห้วย: "Fak Huai",
  จอมสวรรค์: "Chom Sawan",
  ทุ่งลุยลาย: "Thung Luilai",
  หนองตีนนก: "Nong Tin Nok",
  เมยวดี: "Moei Wadi",
  บ้านชี: "Ban Chi",
  หนองบัวตะเกียด: "Nong Bua Takiat",
  แม่ใจ: "Mae Chai",
  หนองปล่อง: "Nong Plong",
  แมดนาท่ม: "Maet Na Thom",
  บัวน้อย: "Bua Noi",
  คอกช้าง: "Khok Chang",
  บางเชือกหนัง: "Bang Chueak Nang",
  คลองรี: "Khlong Ri",
  ช่องแคบ: "Chong Khaep",
  ท่าฉลอม: "Tha Chalom",
  หลวงใต้: "Luang Tai",
  รามแก้ว: "Ram Kaeo",
  กกปลาซิว: "Kok Pla Sio",
  ถนนหัก: "Thanon Hak",
  ขุนแก้ว: "Khun Kaeo",
  เปร็ง: "Preng",
  ช่องเม็ก: "Chong Mek",
  แสวงหา: "Sawaeng Ha",
  สร้อยฟ้า: "Soi Fa",
  เฝ้าไร่: "Fao Rai",
  วงศ์สว่าง: "Wong Sawang",
  ดอนไผ่: "Don Phai",
  ปะเหลียน: "Palian",
  ทุ่งควายกิน: "Thung Khwai Kin",
  คอรุม: "Kho Rum",
  โสธร: "Sothon",
  นาโคก: "Na Khok",
  โพธิ์ใหญ่: "Pho Yai",
  วังงิ้ว: "Wang Ngio",
  บึงกาฬ: "Bueng Kan",
  ป่าแก่บ่อหิน: "Pa Kae Bo Hin",
  หนองน้อย: "Nong Noi",
  ร่องฟอง: "Rong Fong",
  โกตาบารู: "Kota Baru",
  วารินชำราบ: "Warin Chamrap",
  ทุ่งกล้วย: "Thung Kluai",
  อ้อมกอ: "Om Ko",
  สำโรงปราสาท: "Samrong Prasat",
  ตาเนิน: "Ta Noen",
  ศรีมหาโพธิ: "Si Maha Phot",
  ตากูก: "Ta Kuk",
  เนินมะปราง: "Noen Maprang",
  ฟ้าห่วน: "Fa Huan",
  บางโพธิ์: "Bang Pho",
  ตลาดกรวด: "Talat Kruat",
  หนองบัวแดง: "Nong Bua Daeng",
  ทุ่งช้าง: "Thung Chang",
  ลำแก่น: "Lam Kaen",
  ทับกฤชใต้: "Thap Krit Tai",
  คลองนารายณ์: "Khlong Narai",
  พังยาง: "Phang Yang",
  กะปาง: "Kapang",
  ชะโนด: "Chanot",
  บางแตน: "Bang Taen",
  ควนสตอ: "Khuan Sato",
  สินเจริญ: "Sin Charoen",
  แกดำ: "Kae Dam",
  คลองหก: "Khlong Hok",
  บางนอน: "Bang Non",
  พักทัน: "Phak Than",
  หนองควายโซ: "Nong Khwai So",
  วังชิ้น: "Wang Chin",
  ดงสุวรรณ: "Dong Suwan",
  ตาอุด: "Ta Ut",
  นาปรัง: "Na Prang",
  ขุนพิทักษ์: "Khun Phithak",
  วังหงส์: "Wang Hong",
  ทุ่งปรัง: "Thung Prang",
  แม่โถ: "Mae Tho",
  ปล่องหอย: "Plong Hoi",
  ชำ: "Cham",
  ทุ่งสองห้อง: "Thung Song Hong",
  หันนางาม: "Han Na Ngam",
  สวนหม่อน: "Suan Mon",
  นาเชิงคีรี: "Na Choeng Khiri",
  ท่ากระชับ: "Tha Krachap",
  ขอนหาด: "Khon Hat",
  โนนห้อม: "Non Hom",
  บางสีทอง: "Bang Si Thong",
  อุดมทรัพย์: "Udom Sap",
  แควอ้อม: "Khwaae Om",
  เขาย้อย: "Khao Yoi",
  อ้อมใหญ่: "Om Yai",
  อุโมงค์: "Umong",
  นาเวียง: "Na Wiang",
  บ้านโพน: "Ban Phon",
  วัดธาตุ: "Wat That",
  ชำราก: "Chamrak",
  กุยบุรี: "Kui Buri",
  บ้านแขม: "Ban Khaem",
  กระเบื้องใหญ่: "Krabueang Yai",
  สะพือ: "Saphue",
  หนองบ่มกล้วย: "Nong Bom Kluai",
  แหลมตะลุมพุก: "Laem Talumphuk",
  เหล่าปอแดง: "Lao Po Daeng",
  ท่าสะอ้าน: "Tha Sa-an",
  บึงกาสาม: "Bueng Ka Sam",
  ไชยสอ: "Chai So",
  บ้านตูม: "Ban Tum",
  วาริน: "Warin",
  ไผ่ลิง: "Phai Ling",
  ท่าสว่าง: "Tha Sawang",
  ยะลา: "Yala",
  ปง: "Pong",
  หนองยาง: "Nong Yang",
  หัวงัว: "Hua Ngua",
  สบเปิง: "Sop Poeng",
  นาประดู่: "Na Pradu",
  นิคมสงเคราะห์: "Nikhom Songkhro",
  ป่าแงะ: "Pa Ngae",
  ดอนโอง: "Don Ong",
  ขอนแตก: "Khon Taek",
  บ้านหวาย: "Ban Wai",
  ลุ่มสุ่ม: "Lum Sum",
  เฉลียง: "Chaliang",
  บางอ้อ: "Bang O",
  เมืองจัง: "Mueang Chang",
  เขาย่า: "Khao Ya",
  ทับกฤช: "Thap Krit",
  เวียงยอง: "Wiang Yong",
  คูคต: "Khu Khot",
  บ้านนอก: "Ban Nok",
  กุดบาก: "Kut Bak",
  ช่างเคิ่ง: "Chang Khoeng",
  ไทรเดี่ยว: "Sai Diao",
  รัตนวาปี: "Rattanawapi",
  โนนจาน: "Non Chan",
  สำโรงทาบ: "Samrong Thap",
  สี่หมื่น: "Si Muen",
  นามะตูม: "Na Matum",
  ชานุมาน: "Chanuman",
  เนินกุ่ม: "Noen Kum",
  บางละมุง: "Bang Lamung",
  ป่าขาด: "Pa Khat",
  หนองพันทา: "Nong Phan Tha",
  ศีรษะจรเข้น้อย: "Sisa Chorakhe Noi",
  แก้มอ้น: "Kaem On",
  บางขุด: "Bang Khut",
  ห้วงน้ำขาว: "Huang Nam Khao",
  ทาแม่ลอบ: "Tha Mae Lop",
  นาสะไม: "Na Samai",
  แพรกหา: "Phraek Ha",
  หนองสะโน: "Nong Sano",
  ห้วยลาน: "Huai Lan",
  คลองประสงค์: "Khlong Prasong",
  ท่าสะแก: "Tha Sakae",
  เมืองที: "Mueang Thi",
  ทุ่งโฮ้ง: "Thung Hong",
  ตาชี: "Tashi",
  คลองแห: "Khlong Hae",
  คณฑี: "Khonthi",
  ขามเฒ่าพัฒนา: "Kham Thao Phatthana",
  ไผ่ชะเลือด: "Phai Cha Lueat",
  นาวุ้ง: "Na Wung",
  น้ำมวบ: "Nam Muap",
  โละจูด: "Lochut",
  ทับผึ้ง: "Thap Phueng",
  สะแกราบ: "Sakae Rap",
  หนองบัวใหญ่: "Nong Bua Yai",
  วังตะโก: "Wang Tako",
  เกียร์: "Kia",
  พระลับ: "Phra Lap",
  นาพญา: "Na Phaya",
  กะไหล: "Kalai",
  ลำโพ: "Lam Pho",
  หนองเยือง: "Nong Yueang",
  เสนางคนิคม: "Senangkhanikhom",
  บ้านดารา: "Ban Dara",
  ร่มเย็น: "Rom Yen",
  หนองมะโมง: "Nong Mamong",
  ไหล่น่าน: "Lai Nan",
  หัวโพ: "Hua Pho",
  ประณีต: "Pranit",
  คอกควาย: "Khok Khwai",
  หนองบัวสะอาด: "Nong Bua Sa-at",
  ศรีมหาโพธิ์: "Si Maha Pho",
  กลางแดด: "Klang Daet",
  ท้อแท้: "Thothae",
  ห้วยสัตว์ใหญ่: "Huai Sat Yai",
  มะขาม: "Makham",
  เขื่อนอุบลรัตน์: "Khuean Ubolratana",
  โพธิ์สามต้น: "Pho Sam Ton",
  ม่วงไข่: "Muang Khai",
  บางปลาสร้อย: "Bang Pla Soi",
  ซาง: "Sang",
  โคกเจริญ: "Khok Charoen",
  ตะเครียะ: "Takhria",
  บางเป้า: "Bang Pao",
  หนองหัววัว: "Nong Hua Wua",
  หนองฮะ: "Nong Ha",
  วังซ้าย: "Wang Sai",
  ขุนตัดหวาย: "Khun Tat Wai",
  รอบเวียง: "Rop Wiang",
  นาเหล่า: "Na Lao",
  สนม: "Sanom",
  ลานสะแก: "Lan Sakae",
  รางหวาย: "Rang Wai",
  เทพา: "Thepha",
  ธารน้ำทิพย์: "Than Nam Thip",
  ขอนแก่น: "Khon Kaen",
  ม่วงหัก: "Muang Hak",
  บ้านแป้น: "Ban Paen",
  แซงบาดาล: "Saeng Badan",
  ท่าวัง: "Tha Wang",
  โคกย่าง: "Khok Yang",
  ม่วงนา: "Muang Na",
  เขาตอก: "Khao Tok",
  หนองไขว่: "Nong Khwai",
  อมฤต: "Ammarit",
  จิกดู่: "Chik Du",
  กำเนิดนพคุณ: "Kamnoet Nopphakhun",
  ธาตุน้อย: "That Noi",
  ปากท่า: "Pak Tha",
  ดงรัก: "Dong Rak",
  จะรัง: "Charang",
  ม่อนปิ่น: "Mon Pin",
  ท่าบัว: "Tha Bua",
  ปราสาททอง: "Prasat Thong",
  ห้วยคันแหลน: "Huai Khan Laen",
  ยางน้อย: "Yang Noi",
  ตลาดโพธิ์: "Talat Pho",
  ทุ่งกระตาดพัฒนา: "Thung Kratat Phatthana",
  เกาะช้างใต้: "Ko Chang Tai",
  มูโนะ: "Muno",
  ดงครั่งใหญ่: "Dong Khrang Yai",
  พรรณา: "Phanna",
  ป่ากุมเกาะ: "Pa Kum Ko",
  บางนาเหนือ: "Bang Na Nuea",
  สามร้อยยอด: "Sam Roi Yot",
  โนนตาล: "Non Tan",
  ควนรู: "Khuan Ru",
  ดอนดั่ง: "Don Dang",
  โนนนาจาน: "Non Na Chan",
  ปากคลอง: "Pak Khlong",
  โนนน้ำเกลี้ยง: "Non Nam Kliang",
  สำโรงตาเจ็น: "Samrong Ta Chen",
  หนองโพนงาม: "Nong Phon Ngam",
  คลองบ้านโพธิ์: "Khlong Ban Pho",
  พรสำราญ: "Phon Samran",
  จองถนน: "Chong Thanon",
  ป่าสังข์: "Pa Sang",
  ถ้ำฉลอง: "Tham Chalong",
  แก้วฟ้า: "Kaeo Fa",
  บ้านซ่ง: "Ban Song",
  ภาชี: "Phachi",
  วังตะคร้อ: "Wang Takhro",
  พะวง: "Phawong",
  ดอนตรอ: "Don Tro",
  แม่ปุ: "Mae Pu",
  ด่านใน: "Dan Nai",
  ทรายแดง: "Sai Daeng",
  นาคูณใหญ่: "Na Khun Yai",
  บางพระครู: "Bang Phra Khru",
  พลับพลาไชย: "Phlapphla Chai",
  ดินอุดม: "Din Udom",
  หันโจด: "Han Chot",
  ปากคลองบางปลากด: "Pak Khlong Bang Pla Kot",
  บ้านแพน: "Ban Phaen",
  ย่านมัทรี: "Yan Matsi",
  คำพี้: "Ham Phi",
  กกโก: "Kok Ko",
  หน้าโคก: "Na Khok",
  บางค้อ: "Bang Kho",
  เจริญผล: "Charoen Phon",
  บ่อดาน: "Bo Dan",
  แม่ยวม: "Mae Yuam",
  ท่าสะบ้า: "Tha Saba",
  หนองบอนแดง: "Nong Bon Daeng",
  เชิงชุม: "Choeng Chum",
  ตาเนาะแมเราะ: "Tano Maero",
  พระเพลิง: "Phra Phloeng",
  ระแว้ง: "Rawaeng",
  สามพี่น้อง: "Sam Phi Nong",
  ห้วยทรายเหนือ: "Huai Sai Nuea",
  หนองแวงนางเบ้า: "Nong Waeng Nang Bao",
  คลองมานิง: "Khlong Maning",
  เกาะไร่: "Ko Rai",
  อินคีรี: "In Khiri",
  ปลายพระยา: "Plai Phraya",
  นาชะอัง: "Na Cha-ang",
  พืชอุดม: "Phuet Udom",
  บางคล้า: "Bang Khla",
  ลำสินธุ์: "Lam Sin",
  บุ่งไหม: "Bung Mai",
  ชุมช้าง: "Chum Chang",
  ปอ: "Po",
  เมืองฝาง: "Mueang Fang",
  ท่าเทววงษ์: "Tha Thewawong",
  หนองอ้อ: "Nong O",
  แจงงาม: "Chaeng Ngam",
  ภูฝ้าย: "Phu Fai",
  เปือย: "Pueai",
  บัวแดง: "Bua Daeng",
  ลำปางหลวง: "Lampang Luang",
  นาสะไมย์: "Na Samai",
  คลองสอง: "Khlong Song",
  โพธิ์เอน: "Pho En",
  แม่หวาด: "Mae Wat",
  วังน้ำขาว: "Wang Nam Khao",
  แดนสงวน: "Daen Sa-nguan",
  หนองแสน: "Nong Saen",
  สุริยวงศ์: "Suriyawong",
  บางยี่รงค์: "Bang Yi Rong",
  บางกระเจ็ด: "Bang Krachet",
  ด่านศรีสุข: "Dan Si Suk",
  ตะคุ: "Takhu",
  วัด: "Wat",
  นาหินลาด: "Na Hin Lat",
  กะทุ่ม: "Kathum",
  ห้วยทับทัน: "Huai Thap Than",
  ช่องนนทรี: "Chong Nonsi",
  ผักแว่น: "Phak Waen",
  กงหรา: "Kong Ra",
  บึงเนียม: "Bueng Niam",
  ห้วยผา: "Huai Pha",
  โนนกาหลง: "Non Kalong",
  บางเพลิง: "Bang Phloeng",
  บางพลัด: "Bang Phlat",
  สมเด็จเจริญ: "Somdet Charoen",
  กุดชุม: "Kut Chum",
  ชากโดน: "Chak Don",
  เหล่าน้อย: "Lao Noi",
  รำแดง: "Ram Daeng",
  ท่าตลาด: "Tha Talat",
  ตาลเนิ้ง: "Tan Noeng",
  น้ำบ่อหลวง: "Nam Bo Luang",
  บางรักน้อย: "Bang Rak Noi",
  ไม้ขาว: "Mai Khao",
  ทุ่งกระบือ: "Thung Krabue",
  บางกระสั้น: "Bang Krasan",
  เขาเขน: "Khao Khen",
  ท่าพุทรา: "Tha Phutsa",
  เกาะเกิด: "Ko Koet",
  ป่าตุ้ม: "Pa Tum",
  นาสีนวล: "Na Si Nuan",
  ห้วยหม้าย: "Huai Mai",
  เกาะศรีบอยา: "Ko Siboya",
  วังกะพี้: "Wang Kaphi",
  พุดซา: "Phutsa",
  พระยาบันลือ: "Phraya Banlue",
  ประชาสุขสันต์: "Pracha Suk San",
  บางขุนกอง: "Bang Khun Kong",
  วังไม้แดง: "Wang Mai Daeng",
  นาซ่าว: "Na Sao",
  เทพนคร: "Thep Nakhon",
  ไทรใหญ่: "Sai Yai",
  เชียงม่วน: "Chiang Muan",
  บางลึก: "Bang Luek",
  ขนุน: "Khanun",
  ท่าพระ: "Tha Phra",
  เมืองบางยม: "Mueang Bang Yom",
  เหล่างาม: "Lao Ngam",
  เจ้าท่า: "Chao Tha",
  สายนาวัง: "Sai Na Wang",
  สี่เหลี่ยม: "Si Liam",
  เป๊าะ: "Po",
  อินทร์บุรี: "In Buri",
  สร้างถ่อ: "Sang Tho",
  บางกระทึก: "Bang Krathuek",
  ท่าจำปา: "Tha Champa",
  เกษตรวิสัย: "Kaset Wisai",
  ภูน้ำหยด: "Phu Nam Yot",
  บางมะเดื่อ: "Bang Maduea",
  ตะบิ้ง: "Tabing",
  วังดาล: "Wang Dan",
  คลองสาน: "Khlong San",
  สูงเม่น: "Sung Men",
  บุ่งขี้เหล็ก: "Bung Khilek",
  นานวน: "Na Nuan",
  ชัยจุมพล: "Chai Chumphon",
  นาอ้อ: "Na O",
  โคกแสมสาร: "Khok Samae San",
  ลาดสาลี่: "Lat Sali",
  คลองกวาง: "Khlong Kwang",
  นิคมลำนารายณ์: "Nikhom Lam Narai",
  ศิลาแลง: "Sila Laeng",
  ท่าชนะ: "Tha Chana",
  ท่าโรง: "Tha Rong",
  น้ำขุม: "Nam Khum",
  ดอนยายหอม: "Don Yai Hom",
  เดิมบาง: "Doem Bang",
  ปะตง: "Patong",
  พนมรอก: "Phanom Rok",
  บ้านคลอง: "Ban Khlong",
  น้ำริด: "Nam Rit",
  ลาโละ: "Lalo",
  ท่ามะเขือ: "Tha Makhuea",
  สระขุด: "Sa Khut",
  ดอนจิก: "Don Chik",
  ปากโทก: "Pak Thok",
  ละลม: "Lalom",
  ถนนเพชรบุรี: "Thanon Phetchaburi",
  กกแก้วบูรพา: "Kok Kaeo Burapha",
  หนองกุงสวรรค์: "Nong Kung Sawan",
  บ้านทราย: "Ban Sai",
  แม่สาคร: "Mae Sakhon",
  ลุ่มระวี: "Lum Rawi",
  คำพระ: "Kham Phra",
  เขาดินเหนือ: "Khao Din Nuea",
  ลำพยนต์: "Lam Phayon",
  คุยบ้านโอง: "Khui Ban Ong",
  ร่อนพิบูลย์: "Ron Phibun",
  พันเสา: "Phan Sao",
  ทอนหงส์: "Thon Hong",
  สามเงา: "Sam Ngao",
  ไร่มะขาม: "Rai Makham",
  คลองหลวงแพ่ง: "Khlong Luang Phaeng",
  บ้านโพ: "Ban Pho",
  ท่านา: "Tha Na",
  มะเกลือเก่า: "Makluea Kao",
  หน้าไม้: "Na Mai",
  ยางท่าแจ้ง: "Yang Tha Chaeng",
  หลักช้าง: "Lak Chang",
  ดอนศรีชุม: "Don Si Chum",
  กุดกุง: "Kut Kung",
  บางลูกเสือ: "Bang Luk Suea",
  โป่งนก: "Pong Nok",
  เที่ยงแท้: "Thiang Thae",
  บ้านฟ้า: "Ban Fa",
  ป่าปอ: "Pa Po",
  บางเดชะ: "Bang Decha",
  นาคอเรือ: "Na Kho Ruea",
  ทาม: "Tham",
  กันตังใต้: "Kantang Tai",
  ป่ากอ: "Pa Ko",
  หมากหญ้า: "Mak Ya",
  กุดดู่: "Kut Du",
  วังไผ่: "Wang Phai",
  คลองกระจง: "Khlong Krachong",
  กำแพงดิน: "Kamphaeng Din",
  ดอนหัน: "Don Han",
  โป่งผา: "Pong Pha",
  เกษมทรัพย์: "Kasem Sap",
  บางสน: "Bang Son",
  อ่างคีรี: "Ang Khiri",
  นาพูน: "Na Phun",
  ในเตา: "Nai Tao",
  หนองกง: "Nong Kong",
  ดาวเรือง: "Dao Rueang",
  บางปะหัน: "Bang Pahan",
  กุดโดน: "Kut Don",
  บางปะกง: "Bang Pakong",
  ท่าธง: "Tha Thong",
  ท่าขุนราม: "Tha Khun Ram",
  ยะหา: "Yaha",
  เขาพระบาท: "Khao Phra Bat",
  แม่โป่ง: "Mae Pong",
  แสมดำ: "Samae Dam",
  ดงเมืองแอม: "Dong Mueang Aem",
  สามกอ: "Sam Ko",
  ยะวึก: "Yawuek",
  หนองลู: "Nong Lu",
  ตะกุกเหนือ: "Takuk Nuea",
  ท่าพล: "Tha Phon",
  วังหลวง: "wang Luang",
  บางบอนเหนือ: "Bang Bon Nuea",
  พังเทียม: "Phang Thiam",
  บ่อพันขัน: "Bo Phan Khan",
  บ่อโพธิ์: "Bo Pho",
  ตะเคียนราม: "Takhian Ram",
  เนินหอม: "Noen Hom",
  ป่าคาหลวง: "Pa Kha Luang",
  ชะโนดน้อย: "Chanot Noi",
  พรหมบุรี: "Phrom Buri",
  สำโรงเหนือ: "Samrong Nuea",
  น้ำเฮี้ย: "Nam Hia",
  หนองหญ้า: "Nong Ya",
  บางกะไชย: "Bang Kachai",
  นาทับ: "Na Thap",
  แจ่มหลวง: "Chaem Luang",
  บัวหุ่ง: "Bua Hung",
  ห้วยพระ: "Huai Phra",
  คลองน้ำเค็ม: "Khlong Nam Khem",
  บ่อวิน: "Bo Win",
  ขยาย: "Khayai",
  กู่จาน: "Ku Chan",
  แม่ตื่น: "Mae Tuen",
  บางซ้าย: "Bang Sai",
  ถ้ำรงค์: "Tham Rong",
  จำปาโมง: "Champa Mong",
  สามตำบล: "Sam Tambon",
  ช่อผกา: "Cho Phaka",
  เขาคันทรง: "Khao Khansong",
  กุตาไก้: "Kutakai",
  ศรีโคตร: "Si Khot",
  นาเยีย: "Na Yia",
  บ่อนอก: "Bo Nok",
  เรณูใต้: "Renu Tai",
  แสงอรุณ: "Saeng Arun",
  ทุ่งบัว: "Thung Bua",
  ดอนตาเพชร: "Don Ta Phet",
  หนองห้าง: "Nong Hang",
  เกาะจัน: "Ko Chan",
  ด่านจาก: "Dan Chak",
  เนินปอ: "Noen Po",
  เกาะรัง: "Ko Rang",
  บุฤาษี: "Bu Ruesi",
  ประสุข: "Prasuk",
  คลองฉนวน: "Khlong Chanuan",
  บางสัก: "Bang Sak",
  โคกเพลาะ: "Khok Phlo",
  สันป่าเปา: "San Pa Pao",
  ปันแต: "Pan Tae",
  บางคูวัด: "Bang Khu Wat",
  นานวล: "Na Nuan",
  ทุ่งแค้ว: "Thung Khaeo",
  ทุ่งคอก: "Thung Khok",
  ลิปะสะโง: "Lipa Sa-ngo",
  อีสานเขต: "Isan Khet",
  บากเรือ: "Bak Ruea",
  ธาตุพนมเหนือ: "That Phanom Nuea",
  หาดอาษา: "Hat Asa",
  พระบุ: "Phra Bu",
  หารแก้ว: "Han Kaeo",
  ทับใหญ่: "Thap Yai",
  ห้วยขุนราม: "Huai Khun Ram",
  วังธง: "Wang Thong",
  บก: "Bok",
  หนองสะเดา: "Nong Sadao",
  พรหมโลก: "Phrommalok",
  บ้านบ่อ: "Ban Bo",
  ข่อยสูง: "Khoi Sung",
  บ้านพริก: "Ban Phrik",
  พระปฐมเจดีย์: "Phra Pathom Chedi",
  กระโทก: "Krathok",
  หนองคัน: "Nong Khan",
  ยางขาว: "Yang Khao",
  อู่โลก: "U Lok",
  ท่าโพธิ์ชัย: "Tha Pho Chai",
  เลาขวัญ: "Lao Khwan",
  ไชยมงคล: "Chai Mongkhon",
  แม่วงก์: "Mae Wong",
  พังงู: "Phang Ngu",
  ลาดบัวหลวง: "Lat Bua Luang",
  ชะอวด: "Cha-uat",
  ท่าวัด: "Tha Wat",
  นาสวน: "Na Suan",
  วังตะเฆ่: "Wang Takhe",
  บึงกระจับ: "Bueng Krachap",
  ท่านางแนว: "Tha Nang Naeo",
  คุ้งตะเภา: "Khung Taphao",
  ย่อ: "Yo",
  ไชยมนตรี: "Chai Montri",
  หนองสระปลา: "Nong Sa Pla",
  ทับหมัน: "Thap Man",
  บางแม่นาง: "Bang Mae Nang",
  ตะกุด: "Takut",
  เก้าเลี้ยว: "Kao Liao",
  ศรีตระกูล: "Si Trakun",
  ควนโนรี: "Khuan Nori",
  บางบอนใต้: "Bang Bon Tai",
  บ้านใหม่สุขเกษม: "Ban Mai Suk Kasem",
  หนองตะครอง: "Nong Takhrong",
  นครป่าหมาก: "Nakhon Pa Mak",
  นามะเขือ: "Na Makhuea",
  วังโพรง: "Wang Phrong",
  หนองกระเจา: "Nong Krachao",
  วิสัยเหนือ: "Wisai Nuea",
  ผางาม: "Pha Ngam",
  ท่าสะท้อน: "Tha Sathon",
  ท่าสาย: "Tha Sai",
  สร้างปี่: "Sang Pi",
  นาโต๊ะหมิง: "Na To Ming",
  กาลูปัง: "Kalupang",
  พงตึก: "Phong Tuek",
  ห้วยนาง: "Huai Nang",
  บางแคเหนือ: "Bang Khae Nuea",
  แม่สอย: "Mae Soi",
  ตรัง: "Trang",
  บึงสามัคคี: "Bueng Samakkhi",
  ดุมใหญ่: "Dum Yai",
  ผานกเค้า: "Pha Nok Khao",
  แหลมบัว: "Laem Bua",
  ณรงค์: "Narong",
  หมอนนาง: "Mon Nang",
  โนนหนามแท่ง: "Non Nam Thaeng",
  รามอินทรา: "Ram Inthra",
  พิกุลทอง: "Phikun Thong",
  บ้านกง: "Ban Kong",
  กระหาด: "Krahat",
  วังน้ำซับ: "Wang Nam Sap",
  ยางหวาย: "Yang Wai",
  นาปัง: "Na Pang",
  ห้วยแย้: "Huai Yae",
  ขามสมบูรณ์: "Kham Sombun",
  คลองแดน: "Khlong Daen",
  ลาดกระทิง: "Lat Krathing",
  ปงตำ: "Pong Tam",
  เหนือเมือง: "Nuea Mueang",
  เพชรละคร: "Phet Lakhon",
  เขาเจียก: "Khao Chiak",
  คอทราย: "Kho Sai",
  นาเหลือง: "Na Lueang",
  คลองเคียน: "Khlong Khian",
  ต้นมะพร้าว: "Ton Maphrao",
  วิศิษฐ์: "Wisit",
  เม็กดำ: "Mek Dam",
  ดงขี้เหล็ก: "Dong Khilek",
  สระนกแก้ว: "Sa Nok Kaeo",
  กะมัง: "Kamang",
  ควนชุม: "Khuan Chum",
  บ่อเกลือเหนือ: "Bo Kluea Nuea",
  ดอนช้าง: "Don Chang",
  เหล่ากลาง: "Lao Klang",
  นาโพธิ์กลาง: "Na Pho Klang",
  ราม: "Ram",
  กุดยาลวน: "Kut Ya Luan",
  แม่อาย: "Mae Ai",
  ท่ากูบ: "Tha Kup",
  ทุ่งวัดดอน: "Thung Wat Don",
  เชียงเงิน: "Chiang Ngoen",
  ป่ามะนาว: "Pa Manao",
  เมืองการุ้ง: "Muang Ka Rung",
  แก้ง: "Kaeng",
  อิปุ่ม: "Ipum",
  ท่าก้อน: "Tha Kon",
  ดอนเตย: "Don Toei",
  สบเมย: "Sop Moei",
  ยางหล่อ: "Yang Lo",
  เจริญเมือง: "Charoen Mueang",
  ตะโหมด: "Tamot",
  แก้งไก่: "Kaeng Kai",
  นาเคียน: "Na Khian",
  วังก์พง: "Wang Phong",
  ศรีเทพ: "Si Thep",
  สลุย: "Salui",
  วิชิต: "Wichit",
  ท่าช้างคล้อง: "Tha Chang Khlong",
  ห้วยต้อน: "Huai Ton",
  กลาง: "Klang",
  หนองเอี่ยน: "Nong Ian",
  บ้านก้อง: "Ban Kong",
  ดงมูลเหล็ก: "Dong Mun Lek",
  ภูวง: "Phu Wong",
  บางขะแยง: "Bang Khayaeng",
  เชิงคีรี: "Choeng Khiri",
  ริมใต้: "Rim Tai",
  เสี้ยว: "Siao",
  หนองหนาม: "Nong Nam",
  นาปู่ป้อม: "Na Pu Pom",
  พระบรมมหาราชวัง: "Phra Borom Maha Ratchawang",
  กุ่มหัก: "Kum Hak",
  ห้วยกรดพัฒนา: "Huai Krot Phatthana",
  ท้องลำเจียก: "Thong Lamchiak",
  ตันหยงจึงงา: "Tanyong Chueng-nga",
  เกตรี: "Ket Ri",
  ปลาค้าว: "Pla Khao",
  ทุ่งชมพู: "Thung Chomphu",
  แม่หอพระ: "Mae Ho Phra",
  นาสวรรค์: "Na Sawan",
  แหลมทราย: "Laem Sai",
  ขนอม: "Khanom",
  เมืองแหง: "Mueang Haeng",
  ซำยาง: "Sam Yang",
  ซับตะเคียน: "Sap Takhian",
  มหาวัน: "Maha Wan",
  หาดงิ้ว: "Hat Ngio",
  บ้านแพ้ว: "Ban Phaeo",
  ปากน้ำแหลมสิงห์: "Pak Nam Laem Sing",
  รับร่อ: "Rap Ro",
  สำโรงชัย: "Samrong Chai",
  โคกกระชาย: "Khok Krachai",
  หนองไผ่แก้ว: "Nong Phai Kaeo",
  เกาะนางคำ: "Ko Nang Kham",
  วังศาลา: "Wang Sala",
  ท้ายหาด: "Thai Hat",
  พระเหลา: "Phra Lao",
  ตะแบกบาน: "Tabaek Ban",
  กระแสบน: "Krasae Bon",
  ขามใหญ่: "Kham Yai",
  บัวลอย: "Bua Loi",
  ห้วยถั่วใต้: "Huai Thua Tai",
  นาแค: "Na Khae",
  มะเขือแจ้: "Makhuea Chae",
  มะรุ่ย: "Marui",
  ไหล่หิน: "Lai Hin",
  หาดขาม: "Hat Kham",
  โป่งเปือย: "Pong Pueai",
  แปลงยาว: "Plaeng Yao",
  หนองเหล่า: "Nong Lao",
  ดอนกอก: "Don Kok",
  โคกสมบูรณ์: "Khok Sombun",
  สันมะเค็ด: "San Makhet",
  สามัคคีพัฒนา: "Samakkhi Phatthana",
  นาหนัง: "Na Nang",
  ถาวรวัฒนา: "Thawon Watthana",
  แม่ท้อ: "Mae Tho",
  ดอนดึง: "Don Dueng",
  แม่เมาะ: "Mae Mo",
  แม่เจ้าอยู่หัว: "Mae chao Yu Hua",
  บ้านต้อน: "Ban Ton",
  อาซ่อง: "A-song",
  อุ่มจาน: "Um Chan",
  คำแคน: "Kham Khaen",
  กรับใหญ่: "Krap Yai",
  เสือหึง: "Suea Hueng",
  เขาค้อ: "Khao Kho",
  ท่าดี: "Tha Di",
  โนนหวาย: "Non Wai",
  เมืองปอน: "Mueang Pon",
  ดอนรัก: "Don Rak",
  ซับสนุ่น: "Sap Sanun",
  บ่อสุพรรณ: "Bo Suphan",
  นาทะนุง: "Na Thanung",
  เขาชายธง: "Khao Chai Thong",
  ชลคราม: "Chonlakhram",
  ถนนโพธิ์: "Thanon Pho",
  ไคสี: "Khai Si",
  เลิงใต้: "Loeng Tai",
  ท่าพี่เลี้ยง: "Tha Phi Liang",
  ท่าขนุน: "Tha Khanun",
  เขาดินพัฒนา: "Khao Din Phatthana",
  ไผ่ต่ำ: "Phai Tam",
  กุดชุมแสง: "Kut Chum Saeng",
  โนนศิลาเลิง: "Non Sila Loeng",
  บ้านทุ่ม: "Ban Thum",
  ราวต้นจันทร์: "Rao Ton Chan",
  บางตาวา: "Bang Tawa",
  หายยา: "Hai Ya",
  นายาว: "Na Yao",
  คำตากล้า: "Kham Ta Kla",
  ไม้กลอน: "Mai Klon",
  นาด่าน: "Na Dan",
  ระเว: "Rawe",
  มะลิกา: "Malika",
  คลองพน: "Khlong Phon",
  บ้านด่านนาขาม: "Ban Dan Na Kham",
  บุญเกิด: "Bun Koet",
  โนนธาตุ: "Non That",
  โกรกกราก: "Krokkrak",
  สักงาม: "Sak Ngam",
  แวงใหญ่: "Waeng Yai",
  ยางโยภาพ: "Yang Yo Phap",
  ท่าไร่: "Tha Rai",
  พลูเถื่อน: "Phlu Thuean",
  โนนหมากเค็ง: "Non Mak Kheng",
  อินทร์แปลง: "In Plaeng",
  เสม็ดใต้: "Samet Tai",
  บ้านธาตุ: "Ban That",
  ตาขีด: "Ta Khit",
  โพนโก: "Phon Ko",
  อิปัน: "I-pan",
  บ่อผุด: "Bo Phut",
  วัดท่าพระ: "Wat Tha Phra",
  นิเวศน์: "Niwet",
  ดงตะขบ: "Dong Takhop",
  นางรอง: "Nang Rong",
  วังพญา: "Wang Phaya",
  เนินสว่าง: "Noen Sawang",
  นากั้ง: "Na Kang",
  เกษตรพัฒนา: "Kaset Phatthana",
  ยางนอน: "Yang Non",
  บ้านโต้น: "Ban Ton",
  ตาลีอายร์: "Tali-ai",
  ท่าก๊อ: "Tha Ko",
  บ้านพรุ: "Ban Phru",
  แม่อุสุ: "Mae U-su",
  มาบข่า: "Map Kha",
  กุดสิมคุ้มใหม่: "Kut Sim Khum Mai",
  ลำลูกบัว: "Lam Luk Bua",
  ทุ่งก่อ: "Thung Ko",
  ดอนตะหนิน: "Don Tanin",
  สันผักหวาน: "San Phak Wan",
  ชมภู: "Chomphu",
  วังน้อย: "Wang Noi",
  โนนก่อ: "Non Ko",
  หนองทอง: "Nong Thong",
  ละอาย: "La-ai",
  ตรึม: "Truem",
  โคกกลอย: "Khok Kloi",
  เชียงงา: "Chiang Nga",
  ชีวาน: "Chiwan",
  ท่าพญา: "Tha Phaya",
  บางรูป: "Bang Rup",
  ต้าผามอก: "Ta Pha Mok",
  บางคูเวียง: "Bang Khu Wiang",
  ท่าตำหนัก: "Tha Tamnak",
  วังคัน: "Wang Khan",
  ห้วยฝ้ายพัฒนา: "Huai Fai Phatthana",
  เชียงดา: "Chiang Da",
  เวียงพางคำ: "Wiang Phang Kham",
  ท่าบ่อ: "Tha Bo",
  เมืองศรีไค: "Mueang Si Khai",
  เกยไชย: "Koei Chai",
  ทุ่งสัง: "Thung Sang",
  ดงพยุง: "Dong Phayung",
  ชาติตระการ: "Chat Trakan",
  ปากพนังฝั่งตะวันออก: "Pak Phanang Fang Tawan Ok",
  ดงเหนือ: "Dong Nuea",
  ป่าไม้งาม: "Pa Mai Ngam",
  สะอาดสมบูรณ์: "Sa-at Sombun",
  เกาะมะนาว: "Ko Manao",
  หัวโทน: "Hua Thon",
  ไม้ตรา: "Mai Tra",
  ควนพัง: "Khuan Phang",
  ปูโยะ: "Puyo",
  ไร่สะท้อน: "Rai Sathon",
  นาเมือง: "Na Mueang",
  โนนสวาง: "Non Sawang",
  สมอแข: "Samo Khae",
  ยางช้าย: "Yang Chai",
  ประตูป่า: "Pratu Pa",
  ดอยงาม: "Doi Ngam",
  แสลงพันธ์: "Salaeng Phan",
  สระโพนทอง: "Sa Phon Thong",
  ดีหลวง: "Di Luang",
  บางน้ำเชี่ยว: "Bang Nam Chiao",
  กุดลาด: "Kut Lat",
  ปทุมวัน: "Pathum Wan",
  บ่อสลี: "Bo Sali",
  พนอม: "Phanom",
  เขาปูน: "Khao Pun",
  พะวอ: "Phawo",
  ท่าเจ้าสนุก: "Tha Chao Sanuk",
  บ้านปึก: "Ban Puek",
  ปรือ: "Prue",
  เหมาะ: "Mo",
  เคียนซา: "Khian Sa",
  ท่ามะเดื่อ: "Tha Maduea",
  เต่าเล่า: "Tao Lao",
  โนนยาง: "Non Yang",
  โนนเจริญ: "Non Charoen",
  สามวาตะวันตก: "Sam Wa Tawan Tok",
  บางบัวทอง: "Bang Bua Thong",
  สำนักบก: "Samnak Bok",
  คำขวาง: "Kham Khwang",
  เด่นใหญ่: "Den Yai",
  หลักชัย: "Lak Chai",
  ห้วยยางขาม: "Huai Yang Kham",
  เพนียด: "Phaniat",
  พระขาว: "Phra Khao",
  รัตนวารี: "Rattanawari",
  ลำพูน: "Lamphun",
  หาดคัมภีร์: "Hat Khamphi",
  บ้านต๊ำ: "Ban Tam",
  พรหมณี: "Phrommani",
  ห้วยแก้ง: "Huai Kaeng",
  กาเกาะ: "Ka Ko",
  แม่ตีบ: "Mae Tip",
  นาพละ: "Na Phala",
  ดงพลอง: "Dong Phlong",
  สัตหีบ: "Sattahip",
  ท่าพริก: "Tha Phrik",
  โค้งยาง: "Khong Yang",
  เกาะคอเขา: "Ko Kho Khao",
  หลุบคา: "Lup Kha",
  บ่อสวก: "Bo Suak",
  ผักแพว: "Phak Phaeo",
  คูหา: "Khuha",
  กระเสาะ: "Kraso",
  บางมะพร้าว: "Bang Maphrao",
  ค้อวัง: "Kho Wang",
  ศาลาลำดวน: "Sala Lamduan",
  แม่จริม: "Mae Charim",
  ไผ่วง: "Phai Wong",
  บึงปลาทู: "Bueng Pla Thu",
  ช้างใหญ่: "Chang Yai",
  ห้วยยายจิ๋ว: "Huai Yai Chio",
  อ่าวลึกใต้: "Ao Luek Tai",
  ปะเสยะวอ: "Pase Yawo",
  หินมูล: "Hin Mun",
  แม่คือ: "Mae Khue",
  ทางขวาง: "Thang Khwang",
  บ่อแฮ้ว: "Bo Haeo",
  นาสะอาด: "Na Sa-at",
  ปะลุรู: "Paluru",
  ปากพระ: "Pak Phra",
  พุแค: "Phu Khae",
  จอหอ: "Cho Ho",
  อี่หล่ำ: "I Lam",
  กระโสม: "Krasom",
  พลวงทอง: "Phluang Thong",
  ลำเลียง: "Lam Liang",
  บูกิต: "Bukit",
  ม่วงชุม: "Muang Chum",
  เหมืองแก้ว: "Mueang Kaeo",
  ดอนตูม: "Don Tum",
  โนนทราย: "Non Sai",
  กมลา: "Kamala",
  สาบัน: "Saban",
  ป่าหวาย: "Pa Wai",
  นาหมื่นศรี: "Na Muen Si",
  คลองฉนาก: "Khlong Chanak",
  กกทอง: "Kok Thong",
  ท่าบุญมี: "Tha Bun Mi",
  พลายวาส: "Phlai Wat",
  นาคู่: "Na Khu",
  นากว้าง: "Na Kwang",
  น้ำจืดน้อย: "Nam Chuet Noi",
  ป่าหวายนั่ง: "Pa Wai Nang",
  ตรอกนอง: "Trok Nong",
  ม่วงเจ็ดต้น: "Muang Chet Ton",
  ชีวึก: "Chiwuek",
  บ้านกรด: "Ban Krot",
  ผักปัง: "Phak Pang",
  เขาแหลม: "Khao Laem",
  ยายแย้มวัฒนา: "Yai Yaem Watthana",
  แม่ยม: "Mae Yom",
  หน้าสตน: "Na Saton",
  บ้านแดน: "Ban Daen",
  กันตัง: "Kantang",
  ทุ่งฝน: "Thung Fon",
  กลาย: "Klai",
  บางบาล: "Bang Ban",
  ข้าวปุ้น: "Khao Pun",
  ลานบ่า: "Lan Ba",
  สิบเอ็ดศอก: "Sip Et Sok",
  ท่าประจะ: "Tha Pracha",
  ทุ่งแสงทอง: "Thung Saeng Thong",
  อ้อมเกร็ด: "Om Kret",
  แม่ยาว: "Mae Yao",
  พิกุล: "Phikun",
  ยางน้ำกลัดเหนือ: "Yang Nam Klat Nuea",
  กบินทร์: "Kabin",
  สวนแตง: "Suan Taeng",
  กุดหว้า: "Kut Wa",
  แม่แรง: "Mae Raeng",
  เมืองเปลือย: "Mueang Plueai",
  บ้านหินโงม: "Bang Hin Ngom",
  หนองแก๋ว: "Nong Kaeo",
  ไทรโสภา: "Sai Sopha",
  ท่าเกวียน: "Tha Kwian",
  บ้านต้อง: "Ban Tong",
  นบปริง: "Nop Pring",
  บางสมัคร: "Bang Samak",
  วังดิน: "Wang Din",
  ดอนแสลบ: "Don Salaep",
  น้ำพอง: "Nam Phong",
  โคกสีทองหลาง: "Khok Si Thonglang",
  คลองตะเคียน: "Khlong Takhian",
  หนองกวาง: "Nong Kwang",
  เขื่อนบางลาง: "Khuean Bang Lang",
  ห้วยแก: "Huai Kae",
  กุสุมาลย์: "Kusuman",
  บางซ่อน: "Bang Son",
  นพรัตน์: "Noppharat",
  แม่กาษา: "Mae Kasa",
  โคกสำโรง: "Khok Samrong",
  เมืองนะ: "Mueang Na",
  แสนไห: "Saen Hai",
  ดอนชมพู: "Don Chomphu",
  บ่อหิน: "Bo Hin",
  ภูห่าน: "Phu Han",
  แม่ต้าน: "Mae Tan",
  บ้านรี: "Ban Ri",
  คลองสิบสอง: "Khlong Sip Song",
  ปะคำ: "Pakham",
  สุไหงปาดี: "Su-ngai Padi",
  บึงสำโรง: "Bueng Samrong",
  คีรีราษฎร์: "Khiri Rat",
  แก่งเสี้ยน: "Kaeng Sian",
  โดมประดิษฐ์: "Dom Pradit",
  สำนักตะคร้อ: "Samnak Takhro",
  ปอภาร: "Po Phan",
  บางตะบูน: "Bang Tabun",
  บางขันแตก: "Bang Khan Taek",
  ลิปะน้อย: "Lipa Noi",
  สรอย: "Soi",
  แก้งเหนือ: "Kaeng Nuea",
  คลองม่วง: "Khlong Muang",
  ภูปอ: "Phu Po",
  บ้านเซิด: "Ban Soet",
  หนองสีดา: "Nong Sida",
  กำแพงเซา: "Kamphaeng Sao",
  บึงบา: "Bueng Ba",
  นางรำ: "Nang Ram",
  คลองกระแชง: "Khlong Krachaeng",
  บางกระสอ: "Bang Kraso",
  ระแงง: "Ra-ngaeng",
  ตะปอเยาะ: "Tapoyo",
  ชอนสารเดช: "Chon Saradet",
  นาหัวบ่อ: "Na Hua Bo",
  ถอนสมอ: "Thon Samo",
  เขาฉกรรจ์: "Khao Chakan",
  เขือน้ำ: "Khuea Nam",
  นาไม้ไผ่: "Na Mai Phai",
  แชะ: "Chae",
  คลองเปรง: "Khlong Preng",
  เวินพระบาท: "Woen Phrabat",
  สลักได: "Salakdai",
  กรุงชิง: "Krung Ching",
  นาซำ: "Na Sam",
  บุสูง: "Bu Sung",
  ประทาย: "Prathai",
  แม่ยางฮ่อ: "Mae Yang Ho",
  นาทอน: "Na Thon",
  โคกเพชรพัฒนา: "Khok Phet Phatthana",
  ทุ่งลูกนก: "Thung Luk Nok",
  กุดเค้า: "Kut Khao",
  ตานิม: "Ta Nim",
  กล้วยกว้าง: "Kluai Kwang",
  สวายจีก: "Sawai Chik",
  นาเดื่อ: "Na Duea",
  บ้านสาง: "Ban Sang",
  หางดง: "Hang Dong",
  วานรนิวาส: "Wanon Niwat",
  นาคาย: "Na Khai",
  เมืองลี: "Mueang Li",
  พลสงคราม: "Phon Songkhram",
  ห้วยบ่อซืน: "Huai Bo Suen",
  ต้นมะม่วง: "Ton Mamuang",
  ปางหินฝน: "Pang Hin Fon",
  ท้ายน้ำ: "Thai Nam",
  บ้านโหนด: "Ban Not",
  คูขุด: "Khu Khut",
  ป่าป้อง: "Pa Pong",
  น้ำดำ: "Nam Dam",
  โพธิ์พระยา: "Pho Phraya",
  เสริมซ้าย: "Soem Sai",
  เมืองปาน: "Mueang Pan",
  ลอ: "Lo",
  คลองเรือ: "Khlong Ruea",
  ป่งไฮ: "Pong Hai",
  ริโก๋: "Riko",
  ดอยหล่อ: "Doi Lo",
  สมสนุก: "Som Sanuk",
  บ้านจีต: "Ban Chit",
  นาโบสถ์: "Na Bot",
  บึงสนั่น: "Bueng Sanan",
  วัดตะกู: "Wat Taku",
  นาหูกวาง: "Na Hu Kwang",
  นาดูน: "Na Dun",
  นาวังหิน: "Na Wang Hin",
  ศรีประจันต์: "Si Prachan",
  คุ้งพยอม: "Khung Phayom",
  ตาโกน: "Ta Kon",
  ชุมโค: "Chum Kho",
  กันจุ: "Kanchu",
  ดอนจาน: "Don Chan",
  ท้องฟ้า: "Thong Fa",
  ท่าซอม: "Tha Som",
  ไร่ส้ม: "Rai Som",
  พุคำจาน: "Phu Kham Chan",
  ละหานปลาค้าว: "Lahan Pla Khao",
  นายางกลัก: "Na yang Klak",
  หล่อยูง: "Lo Yung",
  ดงหลวง: "Dong Luang",
  ลำตะเคียน: "Lam Takhian",
  ลุโบะยิไร: "Lubo yilai",
  คลองลุ: "Khlong Lu",
  หนองบัวระเหว: "Nong Bua Rawe",
  เมืองงาย: "Mueang Ngai",
  หนองศาลา: "Nong Sala",
  เหล่าบก: "Lao Bok",
  หนองไผ่แบน: "Nong Phai Baen",
  ช้างมิ่ง: "Chang Ming",
  กาญจนา: "Kanchana",
  คำไหล: "Kham Lai",
  ผักขะ: "Phak Kha",
  น้ำตาล: "Nam Tan",
  อัมพวา: "Amphawa",
  นครสวรรค์ตก: "Nakhon Sawan Tok",
  หนองหมู: "Nong Mu",
  ลางา: "La-nga",
  บุญนาคพัฒนา: "Bunnak Phatthana",
  หนองแวงควง: "Nong Waeng Khuang",
  ลาดทิพรส: "Lat Thippharot",
  เหมืองใหม่: "Mueang Mai",
  เขาค่าย: "Khao Khai",
  สุขเดือนห้า: "Suk Duean Ha",
  หนองจรเข้: "Nong Chorakhe",
  โคกศิลา: "Khok Sila",
  วัดสิงห์: "Wat Sing",
  ตาจง: "Ta Chong",
  พระหลวง: "Phra Luang",
  ตาดกลอย: "Tat Kloi",
  สาหร่าย: "Sarai",
  นาวง: "Na Wong",
  หลุ่งตะเคียน: "Lung Takhian",
  พระซอง: "Phra Song",
  เขาสมิง: "Khao Saming",
  สทิงหม้อ: "Sathing Mo",
  บึงบัว: "Bueng Bua",
  ดอนมดแดง: "Don Mot Daeng",
  เชียงแรง: "Chiang Raeng",
  ต้า: "Ta",
  ควนมะพร้าว: "Khuan Maphrao",
  ท่าระหัด: "Tha Rahat",
  ละอุ่นเหนือ: "La-un Nuea",
  กุดชมภู: "Kut Chomphu",
  หนองขุ่นใหญ่: "Nong Khun Yai",
  เฉนียง: "Chaniang",
  เขาวัว: "Khao Wua",
  บึงอ้อ: "Bueng O",
  กระตีบ: "Kratip",
  เมืองหงส์: "Mueang Hong",
  ส้านนาหนองใหม่: "San Na Nong Mai",
  สันโค้ง: "San Khong",
  หนองย่างชิ้น: "Nong Yang Chin",
  ม่วงค่อม: "Muang Khom",
  ตูมใต้: "Tum Tai",
  น้ำโสม: "Nam Som",
  มะนังยง: "Manang Yong",
  บ้านทำเนียบ: "Ban Thamniap",
  ทัพราช: "Thap Rat",
  คลองพา: "Khlong Pha",
  หลักเมือง: "Lak Mueang",
  อ่างหิน: "Ang Hin",
  แก่งผักกูด: "Kaeng Phak Kut",
  ถืมตอง: "Thuem Tong",
  บางนางร้า: "Bang Nang Ra",
  จำปาหล่อ: "Champa Lo",
  ป่าโมง: "Pa Mong",
  คลองเจ้าคุณสิงห์: "Khlong Chao Khun Sing",
  ปกาสัย: "Pakasai",
  ดอนมนต์: "Don Mon",
  ท่าโรงช้าง: "Tha Rong Chang",
  ศรีบัวบาน: "Si Bua Ban",
  สนวน: "Sanuan",
  ท่าสองคอน: "Tha Song Khon",
  หนองแจ้งใหญ่: "Nong Chaeng Yai",
  บางนายสี: "Bang Nai Si",
  คลองท่อมใต้: "Khlong Thom Tai",
  หลักฟ้า: "Lak Fa",
  ด่านเกวียน: "Dan Kwian",
  ป่าก่อ: "Pa Ko",
  ทะนง: "Thanong",
  ลานตากฟ้า: "Lan Tak Fa",
  สะพานไทย: "Saphan Thai",
  จันดุม: "Chan Dum",
  ชนบท: "Chonnabot",
  แหลมสัก: "Laem Sak",
  เสวียด: "Sawiat",
  ธาตุพนม: "That Phanom",
  บางบำหรุ: "Bang Bamru",
  แก่งกระจาน: "Kaeng Krachan",
  สีลม: "Si Lom",
  มีชัย: "Mi Chai",
  นาขุม: "Na Khum",
  ภูซาง: "Phu Sang",
  สวนขัน: "Suan Khan",
  เทพคีรี: "Thep Khiri",
  ศาลาด่าน: "Sala Dan",
  ทุ่งขมิ้น: "Thung Khamin",
  ชัยนาท: "Chai Nat",
  คชสิทธิ์: "Khotchasit",
  ดอนแตง: "Don Taeng",
  พรุพี: "Phru Phi",
  บ้านเขว้า: "Ban Khwao",
  แสนทอง: "Saen Thong",
  แม่น้ำ: "Mae Nam",
  เขาพระนอน: "Khao Phra Non",
  ผาน้ำย้อย: "Pha Nam Yoi",
  ท่าเสม็ด: "Tha Samet",
  บางงอน: "Bang Ngon",
  คลองปาง: "Khlong Pang",
  ควนกลาง: "Khuan Klang",
  ตุยง: "Tuyong",
  มะรือโบตก: "Marue Botok",
  บ้านกลับ: "Ban Klap",
  ขันเงิน: "Khan Ngoen",
  ดงหม้อทองใต้: "Dong Mo Thong Tai",
  แจนแวน: "Chaenwaen",
  บ้านทับ: "Ban Thap",
  สิงห์โคก: "Sing Khok",
  เมืองเสือ: "Mueang Suea",
  พุมเรียง: "Phum Riang",
  นาทัน: "Na Than",
  บัวลาย: "Bua Lai",
  น้ำเที่ยง: "Nam Thiang",
  ดอนเปา: "Don Pao",
  ปานัน: "Panan",
  บ้านแยง: "Ban Yaeng",
  หนองขุ่น: "Nong Khun",
  รัชดาภิเษก: "Ratchadaphisek",
  สระสมิง: "Sa Saming",
  บ้านลำนาว: "Ban Lam Nao",
  พานพร้าว: "Phan Phrao",
  บ้านเล่า: "Ban Lao",
  ชะมวง: "Chamuang",
  บางแก้วฟ้า: "Bang Kaeo Fa",
  บาโงสะโต: "Ba-ngo Sato",
  พุนกยูง: "Phu Nok Yung",
  ร่องคำ: "Rong Kham",
  แพง: "Phaeng",
  หนองน้ำใหญ่: "Nong Nam Yai",
  ควนโพธิ์: "Khuan Pho",
  องค์พระ: "Ong Phra",
  พันดอน: "Phan Don",
  ดอกล้ำ: "Dok Lam",
  ไร่รถ: "Rai Rot",
  ห้วยสีเสียด: "Huai Sisiat",
  ผาตอ: "Pha To",
  หน่อม: "Nom",
  ชิงโค: "Ching Kho",
  คำอาฮวน: "Kham Ahuan",
  อ่อนนุช: "On Nut",
  ไล่โว่: "Laiwo",
  หนองกลางนา: "Nong Klang Na",
  ตาวัง: "Ta Wang",
  บ้านพลับ: "Ban Phlap",
  กุดผึ้ง: "Kut Phueng",
  บางนบ: "Bang Nop",
  อ่าวตง: "Ao Tong",
  ยายแพง: "Yai Phaeng",
  ศาลาใหม่: "Sala Mai",
  กุฎโง้ง: "Kut Ngong",
  พิชัย: "Phichai",
  ร่องเคาะ: "Rong Kho",
  เกาะตาเลี้ยง: "Ko Ta Liang",
  เปียน: "Pian",
  บ้านกาด: "Ban Kat",
  บางจะเกร็ง: "Bang Chakreng",
  สะตอ: "Sato",
  ปากกาง: "Pak Kang",
  เย้ยปราสาท: "Yoei Prasat",
  จะแนะ: "Chanae",
  กลางหมื่น: "Klang Muen",
  เชียรเขา: "Chian Khao",
  กาลอ: "Kalo",
  บ้านลี่: "Ban Li",
  หนองตะพาน: "Nong Taphan",
  ดงมะรุม: "Dong Marum",
  ขลุง: "Khlung",
  โบสถ์: "Bot",
  หัวเตย: "Hua Toei",
  ปงยางคก: "Pong Yang Khok",
  แม่แรม: "Mae Raem",
  ภูเวียง: "Phu Wiang",
  ศรีภิรมย์: "Si Phirom",
  วังโมกข์: "Wang Mok",
  วังทองแดง: "Wang Thong Daeng",
  ออนกลาง: "On Klang",
  พิตเพียน: "Phit Phian",
};
