import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, ModalAlert } from "../../components";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBValidation,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import { useAuth } from "../../auth/AuthContext";
import { useTranslation } from "react-i18next";

const DeleteAccount = () => {
  const { t } = useTranslation();
  const { deleteAccount, checkAuth } = useAuth();
  const [formValue, setFormValue] = useState({ confirm: false });
  const navigate = useNavigate();
  const [modalValue, setModalValue] = useState({
    modalTitle: "",
    modalMessage: "",
    showModal: false,
    onModalClose: () => {},
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formValue?.confirm) {
      const isSuccess = await deleteAccount();
      if (isSuccess) {
        setModalValue({
          modalTitle: t("delete_account_success_title"),
          modalMessage: t("delete_account_success_message"),
          showModal: true,
          onModalClose: async () => {
            setModalValue({ ...modalValue, showModal: false });
            await checkAuth();
            navigate("/");
          },
        });
      }
    }
  };

  return (
    <Layout>
      <MDBContainer className="d-flex align-items-center justify-content-center">
        <MDBCard
          className="m-5"
          style={{ maxWidth: "600px", minWidth: "400px" }}
        >
          <MDBCardBody className="px-5">
            <h2 className="text-uppercase text-center text-danger">
              {t("delete_account_title")}
            </h2>
            <div
              className="overflow-auto mt-4 mb-5 bg-secondary bg-opacity-25"
              style={{ maxHeight: "200px" }}
            >
              {t("delete_account_description")}
            </div>
            <MDBValidation onSubmit={handleSubmit}>
              <MDBCheckbox
                label={t("delete_account_checkbox")}
                id="invalidCheck"
                required
                name="confirm"
                onChange={(e) => setFormValue({ confirm: e.target.checked })}
              />
              <MDBBtn type="submit" className="my-4 w-100 bg-danger" size="lg">
                {t("delete_account_confirm")}
              </MDBBtn>
            </MDBValidation>
          </MDBCardBody>
        </MDBCard>
        <ModalAlert
          show={modalValue.showModal}
          setShow={(show) => (show ? null : modalValue.onModalClose())}
          title={modalValue.modalTitle}
          onClose={modalValue.onModalClose}
        >
          {modalValue.modalMessage}
        </ModalAlert>
      </MDBContainer>
    </Layout>
  );
};

export default DeleteAccount;
