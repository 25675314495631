import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon,
} from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { convertToThaiDate } from "../libs/function";
import { API_URL } from "../libs/constants";
import { mappingLevel, mappingTypeValue } from "../libs/mappingValue";

const ModalDetail = ({
  openModal,
  toggleOpen,
  title,
  type,
  description,
  image,
  location_title,
  date_created,
  damage_level,
}) => {
  const { t } = useTranslation();
  const dateCreate = convertToThaiDate(date_created);

  return (
    <MDBModal show={openModal} backdrop={true} staticBackdrop={true}>
      <MDBModalDialog centered={true}>
        <MDBModalContent>
          <MDBModalBody>
            <div
              className="w-100 d-flex align-items-center justify-content-center border border-gray-300 rounded"
              style={{
                height: "250px",
                width: "210px",
              }}
            >
              {image ? (
                <img
                  src={`${API_URL}/assets/${image}`}
                  className="img-fluid w-100 h-100"
                  alt={title}
                />
              ) : (
                <MDBIcon fas icon="image" size="4x" />
              )}
            </div>
            <div className="d-flex justify-content-between my-3">
              <h4 className="mb-1">{title}</h4>
              <div className="d-flex flex-row">
                {type?.map((accident, index) => {
                  const mapValue = mappingTypeValue(accident);
                  return (
                    <div
                      key={index}
                      className="bg-black text-white px-2 py-1 me-2 rounded-5 text-truncate"
                    >
                      {mapValue}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="d-flex gap-1">
              <img
                src={`warning-level-${damage_level}.png`}
                alt="damage-level"
                style={{ width: "25px", height: "25px" }}
              />
              <p>
                <b>{t("modal_detail_damage_level")}: </b>
                {mappingLevel(damage_level)}
              </p>
            </div>
            <p className="mb-0">
              <b>{t("modal_detail_location")}: </b>
              {location_title}
            </p>
            <p className="mb-1 text-secondary">
              <b>{t("modal_detail_description")}: </b>
              {description}
            </p>
            <p className="mb-0 text-end">
              {t("modal_detail_reported_at")} {dateCreate}
            </p>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn className="w-100" color="danger" onClick={toggleOpen}>
              {t("modal_detail_acknowledge")}
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default ModalDetail;
