export const API_URL =
  process.env.REACT_APP_API_URL ||
  window.env.API_URL ||
  "http://localhost:8055";

export const REDIRECT_URI_APPLICATION_APK =
  process.env.REACT_APP_APP_SCHEMA_URI ||
  window.env.APP_SCHEMA_URI ||
  "safe-points://";
export const GOOGLE_PLAY_URL =
  process.env.REACT_APP_GOOGLE_PLAY_URL ||
  window.env.GOOGLE_PLAY_URL ||
  "https://play.google.com/store/apps/details?id=com.dxcmobile.safepoints";

export const APP_STORE_URL =
  process.env.REACT_APP_APP_STORE_URL ||
  window.env.APP_STORE_URL ||
  "https://apps.apple.com/th/app/safe-points/id6449960669";

export const DEFAULT_ERROR_MESSAGE = "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง";

export const PEOPLE_ROLE =
  process.env.REACT_APP_PEOPLE_ROLE ||
  window.env.PEOPLE_ROLE ||
  "b36f9006-917e-454f-874e-89e38f3be472";

export const USE_GMAP_SDK =
  (process.env.REACT_APP_USE_GMAP_SDK || window.env.USE_GMAP_SDK) === "true";
export const USE_PLACE_API =
  (process.env.REACT_APP_USE_PLACE_API || window.env.USE_PLACE_API) === "true";
export const USE_GEO_API =
  (process.env.REACT_APP_USE_GEO_API || window.env.USE_GEO_API) === "true";
export const GOOGLE_MAPS_API_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
  window.env.GOOGLE_MAPS_API_KEY ||
  "";

export const PRIVACY_TEXT = `ศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติ สำนักงานกิจการยุติธรรม
จัดตั้งขึ้นเมื่อปี พ.ศ ๒๕๖๔ เป็นหน่วยงานในเครือข่ายภาครัฐ
ในความรับผิดชอบของสำนักงานกิจการยุติธรรม กระทรวงยุติธรรม
ได้ให้บริการระบบการพยากรณ์ถานการณ์อาชญากรรม (Safepoints)
และเนื่องด้วยรัฐบาลได้มีนโยบายในการส่งเสริมการยกระดับการให้บริการด้วยระบบดิจิทัล
เพื่อการมุ่งสู่ Thailand ๔.๐ ที่ให้ความสำคัญกับข้อมูลเป็นหลัก
โดยมีการออกกฎหมายใหม่ ได้แก่ พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
พ.ศ. ๒๕๖๒ ที่มีผลบังคับใช้ตั้งแต่วันที่ ๒๘ พฤษภาคม ๒๕๖๒
ในปีงบประมาณ พ.ศ. ๒๕๖๔
ศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติได้ดำเนินงานโครงการพัฒนาศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติเพื่อยกระดับประสิทธิภาพการเข้าถึงกระบวนการยุติธรรม
และการจัดทำนโยบายแนวปฏิบัติการคุ้มครองข้อมูลส่วนบุคคล
เพื่อเตรียมการรองรับตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
เนื่องจากปัจจุบันมีการล่วงละเมิดสิทธิความเป็นส่วนตัวของข้อมูลส่วนบุคคลเป็นจำนวนมากจนสร้างความเดือดร้อน
เสียหายให้แก่เจ้าของข้อมูลส่วนบุคคล
ประกอบกับความก้าวหน้าเทคโนโลยีทำให้การเก็บรวบรวม ใช้
หรือเปิดเผยข้อมูลส่วนบุคคล อันเป็นการล่วงละเมิดทำได้โดยง่าย
จึงจำเป็นที่หน่วยงานของรัฐต้องคุ้มครองข้อมูลต่างๆ ของประชาชน
ให้สอดรับกับข้อกฎหมาย และสร้างความมั่นใจในการให้บริการของภาครัฐ
ภายใต้คุณภาพของการบริการที่เหมาะสมเพื่อให้ศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติสามารถบริหารจัดการทางด้านการรักษาความมั่นคงปลอดภัยด้านสารสนเทศได้อย่างมีประสิทธิภาพ
สอดคล้องตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. ๒๕๖๒
<br />
<br />
ศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติ
ได้จัดทำนโยบายความเป็นส่วนตัวฉบับนี้
เพื่อชี้แจงรายละเอียดเกี่ยวกับการเก็บรวบรวม ใช้
หรือเปิดเผยข้อมูลส่วนบุคคล ระยะเวลาในการเก็บข้อมูล การทำลายข้อมูล
อีกทั้งสิทธิของเจ้าของข้อมูลส่วนบุคคล ในส่วนที่เกี่ยวข้องกับ
<a href="https://www.safepoints.in.th">
  https://www.safepoints.in.th
</a>
และเพื่อปฏิบัติตามหน้าที่ของผู้ควบคุมข้อมูลตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
พ.ศ. 2562 ศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติ
ขอแนะนำให้ท่านโปรดอ่านและทำความเข้าใจนโยบายความเป็นส่วนตัว
(Privacy Notice)
นี้เพื่อรับทราบวัตถุประสงค์ที่ศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติได้เก็บรวบรวม
ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่าน ระยะเวลาในการเก็บข้อมูล
การทำลายข้อมูล ตลอดจนสิทธิของเจ้าของข้อมูลส่วนบุคคล
ซึ่งท่านสามารถศึกษารายละเอียดได้ดังต่อไปนี้
<br />
<br />
<b>1. วัตถุประสงค์ของการเก็บและประมวลผลข้อมูล</b>
<br />
ข้อมูลส่วนบุคคลของท่านจะถูกใช้โดยศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติภายใต้
<a href="https://www.safepoints.in.th">
  https://www.safepoints.in.th
</a>
เพื่อวัตถุประสงค์ดังต่อไปนี้;
<br />
1.1) การลงทะเบียนผู้ใช้งานของระบบการพยากรณ์สถานการณ์อาชญากรรม
(Safepoints)
<br />
1.2) การนำข้อมูลไปประมวลผลในระบบการพยากรณ์สถานการณ์อาชญากรรม
(Safepoints)
<br />
1.3)
การติดต่อสอบถามข้อมูลการให้บริการของศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติการแจ้งปัญหาการใช้งานของระบบการพยากรณ์สถานการณ์อาชญากรรม
(Safepoints) การเก็บรวบรวมสถิติของผู้มาใช้บริการ เพื่อจัดทำรายงาน
<br />
1.4) บันทึกประวัติการใช้งานระบบ เพื่อการตรวจสอบ
และรักษาความมั่นคงปลอดภัยของข้อมูล
<br />
<br />
<b>2. ข้อมูลส่วนบุคคลที่ถูกเก็บและประมวลผล</b>
<br />
ในการประมวลผลเพื่อให้บรรลุวัตถุประสงค์ตามที่ได้กล่าวมาข้างต้น
ทางศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติ จะเก็บ ใช้
และส่งข้อมูลดังต่อไปนี้
<br />
2.1) ชื่อ นามสกุล
<br />
2.2) อีเมล
<br />
2.3) หมายเลขบัตรประชาชน
<br />
2.4) วันเกิด
<br />
2.5) เพศ
<br />
2.6) รหัสอุปกรณ์
<br />
ศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติ ไม่มีความประสงค์
ที่จะเก็บรวบรวมข้อมูลอื่นๆ
นอกเหนือไปจากที่จำเป็นต่อการปฏิบัติตามวัตถุประสงค์การประมวลผลข้อมูล
และไม่มีการใช้ข้อมูลในการติดต่อ
หรือข้อมูลอื่นใดนอกเหนือไปจากข้อมูลส่วนบุคคลที่ได้ระบุไว้แล้วข้างต้น
ถึงแม้ว่าข้อมูลดังกล่าวจะปรากฏอยู่ในอีเมล
หรือเอกสารอื่นใดที่ท่านได้สมัครใจเปิดเผยไว้ต่อศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติ
<br />
<br />
<b>3. แหล่งที่มาของข้อมูลส่วนบุคคล</b>
<br />
ศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติ
ได้รับข้อมูลส่วนบุคคลของท่านโดยวิธีการ
ตามระบุในคู่มือการลงทะเบียนผู้ใช้งานระบบ
โดยรายละเอียดอ้างอิงจากข้อมูลส่วนบุคคลของท่าน สามารถตรวจสอบได้ที่
<a href="https://www.safepoints.in.th/profile">
  https://www.safepoints.in.th/profile
</a>
<br />
<br />
<b>4. การประมวลผลข้อมูลส่วนบุคคล</b>
<br />
ศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติ
มีการประมวลผลข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ตามระบุในข้อ 1
หากท่านมีความประสงค์จะถอนความยินยอมในการประมวลผลดังกล่าว
ท่านสามารถติดต่อระบบการพยากรณ์สถานการณ์อาชญากรรม (Safepoints)
และแจ้งความประสงค์ในการถอนความยินยอม โดยใช้สิทธิได้ตามข้อ 6
<br />
<br />
<b>5. การเก็บรักษาและการโอนข้อมูล</b>
<br />
ศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติ
จะมีการเก็บรักษาข้อมูลของท่าน
ตราบเท่าที่ข้อมูลนั้นยังจำเป็นสำหรับวัตถุประสงค์ของการรวบรวมข้อมูล
และสอดคล้องตามที่กฎหมายกำหนด
โดยศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติ
มีนโยบายในการควบคุมการจัดเก็บและทำลายเอกสารต่างๆ
เมื่อสิ้นสุดระยะเวลาในการจัดเก็บแล้ว
โดยวิธีที่เหมาะสมเพื่อให้ไม่สามารถระบุตัวตนได้
<br />
<br />
ศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติ
อาจมีการแบ่งปันข้อมูลส่วนบุคคลของท่านให้แก่หน่วยงานภาครัฐในเครือข่าย
ที่ตั้งอยู่ในและนอกราชอาณาจักร
เพื่อการปฏิบัติตามฐานสัญญาข้อตกลงในการให้บริการหน่วยงานปลายทางที่ได้รับการโอนข้อมูลส่วนบุคคลจากศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติ
จะต้องอยู่ภายใต้มาตรการด้านการคุ้มครองข้อมูลส่วนบุคคลตามที่กฎหมายกำหนดหรือมีมาตรการด้านการคุ้มครองข้อมูลส่วนบุคคลที่มากกว่า
ซึ่งอาจรวมถึงนโยบายคุ้มครองข้อมูลของหน่วยงาน
สัญญาการประมวลผลข้อมูลและอื่นๆ (ตามแต่ที่กฎหมายกำหนด)
เพื่อให้มั่นใจว่าได้มีการใช้ข้อมูลส่วนบุคคลของท่านอย่างเป็นธรรมและรับผิดชอบตามที่พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
พ.ศ. ๒๕๖๒ กำหนด
<br />
<br />
<b>6. สิทธิของเจ้าของข้อมูล</b>
<br />
ตามที่พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. ๒๕๖๒
ได้กำหนดสิทธิดังต่อไปนี้แก่เจ้าของข้อมูลดังนี้
<br />
<b>6.1) สิทธิในการเข้าถึงข้อมูลส่วนบุคคล</b>:
ท่านมีสิทธิในการได้รับข้อมูลต่างๆ
อันเกี่ยวกับข้อมูลส่วนบุคคลของท่านที่ศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติ
ถือครองอยู่
<br />
<b>6.2) การแก้ไขข้อมูล</b>:
ท่านมีสิทธิในการที่จะร้องขอให้ศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติ
แก้ไขข้อมูลส่วนบุคคลที่ผิดพลาดเกี่ยวกับท่าน
<br />
<b>6.3) การลบข้อมูล</b>:
ท่านอาจร้องขอให้ศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติ
ลบข้อมูลส่วนบุคคลของท่านเมื่อมีสถานการณ์ใดสถานการณ์หนึ่งเกิดขึ้น
<br />
<b>6.4) การระงับการประมวลผลข้อมูลส่วนบุคคล</b>:
ท่านมีสิทธิในการระงับการใช้ข้อมูลส่วนบุคคลของท่าน
<br />
<b>6.5) ปฎิเสธการประมวลผลข้อมูลส่วนบุคคล</b>:
ท่านมีสิทธิในการปฎิเสธการประมวลผลข้อมูลส่วนบุคคลของท่าน
<br />
<b>6.6) การทำให้ข้อมูลอยู่ในรูปแบบที่พกพาได้</b>:
ท่านมีสิทธิในการได้รับข้อมูลส่วนบุคคลของท่านในรูปแบบที่ใช้กันโดยทั่วไป
<br />
<br />
ในการใช้สิทธิของท่าน
หรือหากท่านต้องการสอบถามข้อมูลเพิ่มเติมเกี่ยวกับการใช้ข้อมูลส่วนบุคคลของท่าน
โปรดติดต่อ ศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติ ชั้น 9
ศูนย์ราชการเฉลิมพระเกียรติฯ อาคารรัฐประศาสนภักดี (อาคาร บี)
ถนนแจ้งวัฒนะ หลักสี่ กรุงเทพฯ Tel: +66 2 141-3792 Email:
info@safepoints.in.th
<br />
<br />
<b>7. การรับทราบนโยบายคุ้มครองข้อมูลส่วนบุคคล</b>
<br />
ข้าพเจ้ารับทราบและยินยอมที่จะให้ศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติ
เก็บรวบรวม ใช้ ประมวลผล
และโอนข้อมูลส่วนบุคคลเพื่อให้บรรลุวัตถุประสงค์ตามที่ได้กล่าวข้างต้น`;

export const PRIVACY_TEXT_ENG = `The National Center for Crime Analytics and Forecasting, Office of Justice Affairs, was established in 2021 (B.E. 2564).<br>
It is a government-affiliated agency under the Ministry of Justice responsible for providing crime forecasting services (Safepoints).<br>
In alignment with the government’s policy to enhance digital services as part of the Thailand 4.0 initiative, which emphasizes data-driven development,<br>
new legislation was enacted, including the Personal Data Protection Act, B.E. 2562, which came into effect on May 28, 2019.<br><br>

In the fiscal year 2021 (B.E. 2564), The National Center for Crime Analytics and Forecasting implemented a project to enhance its operational efficiency<br>
to improve access to justice and to formulate policies and practices for personal data protection in preparation for compliance<br>
with the Personal Data Protection Act.<br>
This initiative arose from the increasing infringement on individuals’ data privacy, causing significant distress and damage to personal data owners.<br>
Advances in technology have made the collection, use, or disclosure of personal data easier, often resulting in privacy violations.<br>
Therefore, it is crucial for government agencies to protect citizens’ data in accordance with legal requirements<br>
and to build public confidence in government services.<br>
The Center aims to deliver services with quality standards, ensuring the efficient management of information security,<br>
aligning with the Personal Data Protection Act, B.E. 2562.<br><br>

The National Center for Crime Analytics and Forecasting has established this Privacy Policy to provide details<br>
on the collection, use, and disclosure of personal data, data retention periods, data erasure processes,<br>
and the rights of personal data owners concerning <a href="https://www.safepoints.in.th">https://www.safepoints.in.th</a>.<br>
This policy is in compliance with the obligations of a data controller as stipulated in the Personal Data Protection Act, B.E. 2562.<br>
The National Center for Crime Analytics and Forecasting strongly encourages you to carefully read this Privacy Policy<br>
to be informed about the purposes for which your personal data is collected, used, and disclosed,<br>
the retention periods, data destruction processes, and your rights as a personal data owner.<br>
Detailed information can be found in the following sections.<br><br>

<b>1. Purpose of Data Collection and Processing</b><br>
Your personal data will be used by The National Center for Crime Analytics and Forecasting under<br>
<a href="https://www.safepoints.in.th">https://www.safepoints.in.th</a> for the following purposes:<br>
1.1) To register users for the Crime Forecasting System (Safepoints).<br>
1.2) To process the data within the Crime Forecasting System (Safepoints).<br>
1.3) To facilitate service inquiries related to The National Center for Crime Analytics and Forecasting,<br>
address system issues for Safepoints, and collect user statistics to conduct reports.<br>
1.4) To record system usage history for auditing purposes and to ensure the security of the data.<br><br>

<b>2. Personal Data Collected and Processed</b><br>
To achieve the purposes mentioned above, The National Center for Crime Analytics and Forecasting<br>
will collect, use, and process the following personal data:<br>
2.1) Full name<br>
2.2) Email address<br>
2.3) National ID number<br>
2.4) Date of birth<br>
2.5) Gender<br>
2.6) Device ID<br>
The National Center for Crime Analytics and Forecasting does not intend to collect any data beyond what is necessary.<br>
Personal data not listed above will not be collected, used, or processed, even if provided voluntarily via email<br>
or other documents to the National Center for Crime Analytics and Forecasting.<br><br>

<b>3. Source of Personal Data</b><br>
The National Center for Crime Analytics and Forecasting collects your personal data through methods<br>
specified in the user registration manual.<br>
Details regarding your personal data can be reviewed at <a href="https://www.safepoints.in.th/profile">https://www.safepoints.in.th/profile</a>.<br><br>

<b>4. Processing of Personal Data</b><br>
The National Center for Crime Analytics and Forecasting processes your personal data for the purposes outlined in Section 1.<br>
If you wish to withdraw your consent for such processing, you may contact the Crime Forecasting System (Safepoints)<br>
and declare your intention to withdraw consent.<br>
You can exercise this right as detailed in Section 6.<br><br>

<b>5. Data Retention and Transfer</b><br>
The National Center for Crime Analytics and Forecasting retains your personal data as long as it is necessary<br>
for the purposes for which it was collected and in compliance with legal requirements.<br>
The Center has policies in place to control the storage and secure destruction of records once the retention period ends,<br>
ensuring that the data can no longer be used to identify individuals.<br>
The National Center for Crime Analytics and Forecasting may share your personal data with government agencies within its network,<br>
both within and outside the Kingdom of Thailand, as part of service agreement obligations.<br>
Recipient agencies of personal data transferred by the Center must comply with data protection measures prescribed by law<br>
or implement higher standards of data protection.<br>
These measures may include agency privacy policies, data processing agreements, or other legal frameworks as required by law.<br>
This ensures that your personal data is used fairly and responsibly, in accordance with the Personal Data Protection Act, B.E. 2562.<br><br>

<b>6. Rights of Data Owners</b><br>
According to the Personal Data Protection Act B.E. 2562, the following rights are provided to data owners:<br>
6.1) <b>Right to access</b>: You may have the right to access your personal data held by The National Center for Crime Analytics and Forecasting.<br>
6.2) <b>Right to rectification</b>: You may have the right to request The National Center for Crime Analytics and Forecasting<br>
to rectify your personal data if such personal data is incomplete, incorrect, misleading, or not up-to-date.<br>
6.3) <b>Right to erasure</b>: You may have the right to request The National Center for Crime Analytics and Forecasting<br>
to erase your personal data under certain circumstances.<br>
6.4) <b>Right to suspend the processing</b>: You may have the right to suspend the processing of your personal data.<br>
6.5) <b>Right to object the processing</b>: You may have the right to object to the processing of your personal data.<br>
6.6) <b>Right to data portability</b>: You may have the right to receive your personal data in a commonly used format.<br><br>

To exercise your rights or if you require further information regarding the use of your personal data,<br>
please contact The National Center for Crime Analytics and Forecasting at:<br>
9th Floor, Government Complex (B Building), 120 Chaeng Wattana Road, Thungsong-Hong, Laksi, Bangkok 10210<br>
Tel: +66 2 141-3792<br>
Email: info@safepoints.in.th<br><br>

<b>7. Acknowledgment of the Personal Data Protection Policy</b><br>
I acknowledge and consent to The National Center for Crime Analytics and Forecasting in collecting, using, processing,<br>
and transferring my personal data in order to achieve the purposes mentioned above.<br>`;

export const ACCEPT_TEXT =
  "ข้าพเจ้ารับทราบและยินยอมที่จะให้ศูนย์พยากรณ์สถานการณ์อาชญากรรมแห่งชาติ เก็บรวบรวม ใช้ ประมวลผล และโอนข้อมูลส่วนบุคคลเพื่อให้บรรลุวัตถุประสงค์ตามที่ได้กล่าวข้างต้น";

export const ACCEPT_TEXT_ENG =
  "I acknowledge and consent to the National Crime Situation Forecasting Center collecting, using, processing, and transferring my personal data to achieve the purposes stated above.";

export const TERM_OF_USE = `
<p>เงื่อนไขการใช้บริการ (Term Of Use)</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ldquo;เงื่อนไขการใช้บริการ&rdquo; ที่กำหนดนี้จะครอบคลุมสิทธิและภาระหน้าที่ในการใช้งานเว็บไซต์ แอพพลิเคชั่นมือถือและการบริการ ซึ่งต่อไปจะรวมเรียกว่า &ldquo;บริการ&rdquo; ที่สำนักงานกิจการยุติธรรม ซึ่งต่อไปจะเรียกว่า &ldquo;สำนักงาน&rdquo; จัดให้บริการผ่านทางเครือข่ายอินเทอร์เน็ต โดยเงื่อนไขการใช้บริการถือเป็นข้อตกลงที่มีผลผูกพันระหว่างสำนักงานฯ ซึ่งเป็นเจ้าของกรรมสิทธิและสิทธิในทรัพย์สินทางปัญญาของบริการกับท่าน ดังนั้นสำนักงานฯ จึงขอให้ท่านอ่านและพิจารณาเงื่อนไขการใช้บริการที่กำหนดไว้นี้โดยละเอียด</p>
<p>เมื่อท่านใช้บริการของสำนักงานฯ ย่อมถือว่าท่านยินยอมและยอมรับที่จะผูกพันและปฏิบัติตาม</p>
<ul>
<li>เงื่อนไขการใช้บริการที่กำหนดไว้นี้</li>
<li>นโยบายการคุ้มครองข้อมูลส่วนบุคคลของสำนักงานฯ ซึ่งต่อไปจะเรียกว่า &ldquo;นโยบายการคุ้มครองข้อมูลส่วนบุคคล&rdquo; และ</li>
<li>นโยบายทรัพย์สินทางปัญญาของสำนักงานฯ ซึ่งต่อไปจะเรียกว่า &ldquo;นโยบายทรัพย์สินทางปัญญา&rdquo; ซึ่งทั้งหมดดังกล่าวข้างต้นถือเป็นส่วนหนึ่งของเงื่อนไขการใช้บริการด้วย</li>
</ul>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ถ้าท่านไม่เห็นด้วยกับเงื่อนไขการใช้บริการหรือส่วนใดส่วนหนึ่งของเงื่อนไขการใช้บริการ ท่านจะไม่ได้รับอนุญาตให้ใช้บริการและหากท่านได้มีการใช้บริการไปแล้ว ท่านก็จะต้องระงับการใช้บริการในทันที</p>
<p><strong>&ldquo;</strong><strong>บริการ&rdquo; คืออะไร</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;สำนักงานฯ ได้จัดให้ผู้ใช้บริการสามารถสมัครเข้าร่วม ลงทะเบียนผู้ใช้งานและใช้บริการที่เกี่ยวข้องกับการบริการผ่านทางเว็บไซต์ www.safepoints.in.th และ ซอฟต์แวร์ Safepoints</p>
<p><strong>สิทธิการใช้</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>บริการของสำนักงานฯ</strong>&nbsp;: สำนักงานฯ ได้ให้สิทธิแก่ท่านในการใช้บริการซึ่งรวมถึงโปรแกรมคอมพิวเตอร์ที่เกี่ยวข้องโดยมิใช่วัตถุประสงค์เพื่อการค้า ทั้งนี้ ภายใต้เงื่อนไขการใช้บริการตามที่กำหนดไว้นี้&nbsp;</p>
<p><strong>การใช้บริการ</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ท่านสามารถใช้บริการเพื่อประโยชน์ส่วนบุคคลของท่านเท่านั้น ท่านไม่สามารถใช้บริการโดยมีวัตถุประสงค์เพื่อการค้า ตัวอย่างเช่น ท่านไม่สามารถนำบริการไปเสนอให้บุคคลอื่นใช้ประโยชน์ได้ ท่านไม่สามารถนำบริการไปขายหรือให้เช่าต่อได้ รวมทั้งไม่สามารถนำเสนอบริการต่อสาธารณะโดยผ่านช่องทางการสื่อสารหรือรวมเข้ากับการบริการของท่าน เว้นแต่ท่านจะได้รับอนุญาตเป็นลายลักษณ์อักษรจากสำนักงานฯ ก่อน ท่านไม่สามารถทำสำเนา พิมพ์ บันทึก หรือใช้ข้อมูลจากเว็บไซต์หรือฐานข้อมูลการบริการ เว้นแต่จะเป็นกรณีที่โปรแกรมคอมพิวเตอร์จัดให้ใช้ได้และประโยชน์ส่วนบุคคลของท่านเท่านั้น</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เมื่อมีการใช้บริการหรือใช้ข้อมูลจากฐานข้อมูล ท่านไม่สามารถแยกส่วน (Scraping) ค้นหารูปแบบความสัมพันธ์ของข้อมูล (Data Mining) หาประโยชน์จากข้อมูล (Harvesting) ใช้โปรแกรมคอมพิวเตอร์เพื่อนำข้อมูลออกมาใช้งาน (Screen Scraping) รวบรวมข้อมูล (Data Aggregating) และจัดลำดับข้อมูล (Indexing) นอกจากนี้ท่านตกลงว่า ท่านจะไม่ใช้หุ่นยนต์ โปรแกรมคอมพิวเตอร์สไปเดอร์ (Spider) โปรแกรมคอมพิวเตอร์สแครบเปอร์ (Scraper) หรือวิธีการอัตโนมัติอื่นใดในการเข้าถึงเว็บไซต์หรือฐานข้อมูลเพื่อประโยชน์ใดๆ โดยไม่ได้รับอนุญาตเป็นลายลักษณ์อักษรจากสำนักงานฯ ก่อน</p>
<p>โปรแกรมคอมพิวเตอร์ที่ให้บริการไม่อาจใช้ประโยชน์นอกเหนือจากที่อนุญาตไว้ในเงื่อนไขการใช้บริการนี้</p>
<p><br /> <strong>การลงทะเบียนใช้บริการ</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;การใช้บริการและการเข้าใช้งานเว็บไซต์หรือซอฟต์แวร์ ของสำนักงานฯ ในบางส่วนต้องมีการลงทะเบียนใช้บริการด้วย</p>
<p><strong>การยุติบริการ</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ท่านอาจยุติบริการในเวลาใดก็ได้และไม่จำเป็นต้องมีเหตุผลใดๆ</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;สำนักงานฯ มีสิทธิที่จะขัดขวางการเข้าใช้บริการและตัดการเชื่อมต่อการใช้บริการของท่านได้ไม่ว่าในเวลาใดและไม่ว่าด้วยเหตุผลใดตามที่สำนักงานฯ เห็นสมควรแต่เพียงฝ่ายเดียว นอกจากนี้สำนักงานฯ มีสิทธิที่จะแลกเปลี่ยนข้อมูลการใช้บริการของท่านกับบุคคลภายนอกได้ในกรณีดังต่อไปนี้</p>
<ul>
<li>ถ้าในระหว่างการลงทะเบียนใช้บริการปรากฏว่าท่านตั้งใจที่จะให้ข้อมูลที่ไม่ถูกต้องหรือผิดพลาด</li>
<li>ถ้าท่านมีส่วนร่วมในการกระทำที่ก่อหรืออาจจะก่อให้เกิดอัตรายหรือเสียหายแก่สำนักงานฯ บุคคลภายนอก ซึ่งรวมถึงผู้ใช้บริการรายอื่น ผู้ที่เป็นคู่ค้ากับสำนักงานฯ หรือผู้ที่เป็นพันธมิตรกับสำนักงานฯ</li>
<li>ถ้าท่านใช้หรือพยายามใช้เว็บไซต์หรือบริการในการกระทำผิดกฎหมายหรือเล็งเห็นได้ว่าเป็นการผิดกฎหมาย หรือถ้าท่านใช้เว็บไซต์หรือบริการเพื่อทำให้เกิด สนับสนุน ช่วยเหลือ หรือยุยงให้เกิดการกระทำที่ผิดกฎหมาย</li>
<li>ถ้าท่านละเมิดเงื่อนไขการใช้บริการนี้</li>
<li>ถ้าสำนักงานฯ มีความจำเป็นต้องแลกเปลี่ยนข้อมูลเพื่อให้เป็นไปตามคำสั่งหรือหมายที่ออกโดยองค์กรตุลาการหรือองค์กรบริหารที่มีอำนาจตามกฎหมาย</li>
<li>ถ้าสำนักงานฯ มีความจำเป็นต้องแลกเปลี่ยนข้อมูลเพื่อวัตถุประสงค์ในการตรวจหา ป้องกัน หรือระบุปัญหาทางเทคนิค การละเมิดความปลอดภัย หรือการฟ้องร้องคดีแก่ผู้ต้องสงสัยว่ากระทำความผิดกฎหมายหรือการฉ้อโกง</li>
</ul>
<p>หากเกิดกรณีดังกล่าวขึ้น สำนักงานฯ จะแจ้งให้ท่านทราบถึงการปิดบัญชีผู้ใช้บริการของท่าน</p>
<p><strong>ข้อห้ามในการส่งข้อมูลหรือข้อความ</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ห้ามมิให้ส่งข้อมูลหรือข้อความที่มีลักษณะเชิงพาณิชย์ รวมถึงการโฆษณา เว้นแต่ข้อมูลหรือข้อความนั้นเป็นเรื่องเกี่ยวกับสำนักงานฯ หรือเกี่ยวกับการบริการหรือผลิตภัณฑ์ของสำนักงานฯ และได้รับความเห็นชอบจากสำนักงานฯ แล้ว</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เมื่อท่านส่งข้อมูลหรือข้อความเพื่อเผยแพร่ผ่านทางบริการของสำนักงานฯ ท่านจะต้องมั่นใจว่าข้อมูลหรือข้อความนั้นไม่ละเมิดกฎหมาย นอกจากนี้ท่านจะต้องส่งข้อมูลหรือข้อความที่มีความชัดเจนเท่านั้นและจะต้องไม่ส่งข้อมูลหรือข้อความที่</p>
<ul>
<li>เสี่ยงต่อความมั่นคงปลอดภัยหรือสุขภาพของบุคคล</li>
<li>เป็นข้อมูลส่วนบุคคลหรือเกี่ยวกับการระบุตัวบุคคล ที่อยู่ หรือข้อมูลการติดต่อบุคคล โดยไม่ได้รับความยินยอมให้เปิดเผยข้อมูลส่วนบุคคลดังกล่าวจากบุคคลนั้นก่อน</li>
<li>ผิดกฎหมาย ใส่ร้าย หมิ่นประมาท หรือคุกคามความเป็นส่วนตัวของบุคคลใด</li>
<li>ก่อกวน ก้าวร้าว ขู่เข็ญ หรือหยาบคาย</li>
<li>มีลักษณะเป็นการสนับสนุนการเหยียดหยามหรือแบ่งแยกชนชั้นที่มีต่อเชื้อชาติ ถิ่นกำเนิด ชาติพันธุ์ ชนชาติ ศาสนา เพศ อาชีพ เพศ ความเจ็บป่วย ความสามารถทางกายหรือจิต ความเชื่อ ความคิดเห็นทางการเมือง หรือชนชั้นทางสังคมหรือเศรษฐกิจ</li>
<li>สนับสนุนการกระทำความผิดอาญาหรือก่อให้เกิดความรับผิดทางแพ่ง หรือการฟ้องร้องคดี</li>
<li>สร้างวงจรแชร์ลูกโซ่ จดหมายลูกโซ่ ข้อความเสนอสินค้าหรือโฆษณาที่รบกวนผู้รับ หรือสิ่งอื่นใดที่ต้องห้ามตามกฎหมายหรือเงื่อนไขการใช้บริการนี้</li>
<li>แสดงโดยชัดเจนหรือโดยนัยว่าได้รับการสนับสนุนจากสำนักงานฯ ซึ่งไม่เป็นความจริง</li>
</ul>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;สำนักงานฯ อาจปฏิเสธไม่ให้ท่านใช้บริการหรือลบข้อมูลหรือข้อความที่ท่านส่งหรือนำเสนอได้ หากปรากฏว่าข้อมูลหรือข้อความนั้นละเมิดเงื่อนไขการใช้บริการนี้ หรือปรากฏว่าท่านกระทำหรือละเว้นการกระทำที่ก่อหรืออาจก่อให้เกิดอันตรายแก่การบริการ ผู้ใช้บริการ สำนักงานฯ หรือตัวแทนหรือผู้ที่เกี่ยวข้องกับบริการ ผู้ใช้บริการ หรือสำนักงานฯ ในกรณีนี้สำนักงานฯ อาจใช้มาตรการป้องกันมิให้ท่านเสนอข้อมูลหรือข้อความในบริการของสำนักงานฯ ได้ ข้อกำหนดนี้เป็นสิ่งที่เพิ่มเติมจากสิทธิใดๆ ที่สำนักงานฯ มีอยู่ตามกฎหมาย</p>
<p><br /> <strong>สิทธิในข้อมูลหรือข้อความ</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;สำนักงานฯ อาจตรวจสอบข้อมูลหรือข้อความก่อนหรือหลังการใช้ข้อมูลหรือข้อความนั้นเพื่อป้องกันการใช้งานข้อมูลหรือข้อความที่ไม่เหมาะสมหรือไม่ดีหรือไม่ถูกต้อง และสำนักงานฯ อาจลบข้อมูลหรือข้อความนั้นออกไปก็ได้ โดยปกติสำนักงานฯ จะไม่ใช้สิทธิในการตรวจสอบข้อมูลหรือข้อความ แต่จะดำเนินการในบางกรณีเท่านั้น</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;สำนักงานฯ สงวนสิทธิ์ในการพิจารณาว่าข้อมูลหรือข้อความใดจะถูกนำไปใช้ ระยะเวลาที่นำไปใช้ สถานที่นำไปใช้ รูปแบบ และเรื่องอื่นที่เกี่ยวข้องกับการนำข้อมูลหรือข้อความไปเผยแพร่ในขอบเขตของการบริการ ซึ่งสำนักงานฯ ไม่รับรองว่าข้อมูลหรือข้อความทั้งหมดจะถูกนำไปใช้อย่างแพร่หลายหรือในระยะเวลาที่จำกัด</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อมูลหรือข้อความที่ผู้ใช้บริการส่งหรือนำเสนอมานั้นไม่ถือเป็นความเห็นของสำนักงานฯ โดยสำนักงานฯ ไม่รับรองความสมบูรณ์ ความน่าเชื่อถือ ความถูกต้อง ความชอบด้วยกฎหมาย และความเป็นปัจจุบันของข้อมูลหรือข้อความนั้น</p>
<p><strong>สิทธิในทรัพย์สินทางปัญญา</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;สิทธิในทรัพย์สินทางปัญญาทั้งหมดที่ปรากฏอยู่ในเว็บไซต์ บริการ ซอฟต์แวร์ หรือฐานข้อมูล ซึ่งสิทธิในทรัพย์สินทางปัญญาดังกล่าวจะหมายความรวมถึง ลิขสิทธิ์ เครื่องหมายการค้า การออกแบบทางอุตสาหกรรม สิทธิบัตร และความลับทางการค้า ซึ่งเป็นของสำนักงานฯ หรือที่สำนักงานฯ ได้รับอนุญาตให้ใช้สิทธิแต่เพียงผู้เดียว บริการของสำนักงานฯ จะได้รับความคุ้มครองตามกฎหมายไทยหรือบทกฎหมายอื่นของไทยทั้งในอาญาเขตประเทศไทยและต่างประเทศ</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;การทำสำเนา การแจกจ่าย การแสดงในที่สาธารณะ การนำเสนอต่อสาธารณะโดยเครือข่ายการสื่อสาร การถ่ายทอดสู่สาธารณะ การแก้ไขเปลี่ยนแปลง การปรับปรุง การประมวลผล การพัฒนาจากต้นฉบับ การขาย หรือการให้เช่าที่กระทำต่อบริการไม่ว่าส่วนหนึ่งส่วนใด ลักษณะใด หรือวิธีการใดโดยไม่ได้รับอนุญาตจากสำนักงานเป็นลายลักษณ์อักษรก่อนถือเป็นการต้องห้ามและเป็นการละเมิดสิทธิของสำนักงานฯ นอกจากนี้ชื่อ รูปแบบสัญลักษณ์ เครื่องหมายการค้า หรือเครื่องหมายบริการของสำนักงานฯ ถือเป็นกรรมสิทธิ์และทรัพย์สินทางปัญญาของสำนักงานฯ ซึ่งไม่สามารถนำไปใช้ได้ไม่ว่าในกรณีใดๆ</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;สำนักงานฯ อาจป้องกันสิทธิในบริการของสำนักงานฯ จากการใช้งานโดยผู้ที่ไม่มีสิทธิด้วยวิธีการทางเทคโนโลยี ซึ่งท่านรับรองว่าจะหลีกเลี่ยงการทำให้สำนักงานฯ ด้อยสิทธิไปจากที่กำหนดในเงื่อนไขการใช้บริการหรือโดย กฎหมาย รวมทั้งหลีกเลี่ยงการละเมิดสิทธิในบริการ ซึ่งจะส่งผลให้สำนักงานฯ มีสิทธิยุติหรือเพิกถอนสิทธิในการใช้บริการภายใต้เงื่อนไขการใช้บริการได้ ในกรณีที่เกิดเหตุการณ์ดังกล่าวขึ้น ท่านจะต้องยุติการใช้บริการในทันทีและปฏิบัติตามภาระหน้าที่ที่มีอยู่ต่อสำนักงานฯ ต่อไป</p>
<p><strong>การผิดเงื่อนไขการใช้บริการและการชดใช้ค่าเสียหาย</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;โดยที่ข้อกำหนดนี้ไม่ทำให้สำนักงานฯ เสื่อมสิทธิอื่นใดตามกฎหมาย ท่านตกลงที่จะชดใช้ค่าเสียหายและทำให้สำนักงานฯ ลูกจ้าง เจ้าหน้าที่ ผู้บริหาร และตัวแทนปราศจากความเสียหายอันเนื่องมาจากการที่ท่านละเมิดเงื่อนไขนี้หรือกระทำการใดที่ผิดกฎหมายที่เกี่ยวกับการใช้บริการ การชดใช้ค่าเสียหายดังกล่าวจะครอบคลุมถึงค่าใช้จ่าย การจ่ายเงิน การสูญเสีย การสูญเสียผลประโยชน์ หรือความเสียหายอย่างอื่นไม่ว่าโดยทางตรงหรือทางอ้อมและไม่ว่าจะเป็นความเสียหายที่เป็นตัวเงินหรือไม่เป็นตัวเงินที่เกิดขึ้นแก่สำนักงานฯ ลูกจ้าง เจ้าหน้าที่ ผู้บริหาร และตัวแทน ซึ่งรวมถึงแต่ไม่จำกัดเพียงแค่ค่าใช้จ่ายในทางกฎหมายและการดำเนินคดี</p>
<p><strong>ความเป็นส่วนตัว</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;สำนักงานฯ ย่อมเคารพความเป็นส่วนตัวของท่านในระหว่างการใช้งานโปรแกรมคอมพิวเตอร์โดยสำนักงานฯ จะมีการทำนโยบายการคุ้มครองข้อมูลส่วนบุคคลของโปรแกรมคอมพิวเตอร์ให้เป็นปัจจุบันซึ่งท่านจะสามารถเข้าถึงได้โดยผ่านทางเว็บไซต์ https://www.safepoints.in.th/privacy ซึ่งถือเป็นส่วนหนึ่งของเงื่อนไขการใช้บริการด้วย โดยที่นโยบายการคุ้มครองข้อมูลส่วนบุคคลจะอยู่ภายใต้การทำให้เป็นปัจจุบันในแต่ละช่วงเวลา สำนักงานฯ จึงขอแนะนำให้ท่านตรวจสอบนโยบายการคุ้มครองข้อมูลส่วนบุคคลดังกล่าวเป็นระยะๆ</p>
<p><strong>การแก้ไขเปลี่ยนแปลงการบริการและโปรแกรมคอมพิวเตอร์</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;สำนักงานฯ อาจแก้ไข ปรับปรุง หรือเปลี่ยนแปลงโปรแกรมคอมพิวเตอร์ ลักษณะของการบริการ การเชื่อมต่อและการออกแบบสำหรับผู้ใช้บริการ การขยายขอบเขตและความสามารถในการใช้ประโยชน์ของข้อมูลหรือข้อความ ของบริการและรูปลักษณ์ที่เกี่ยวกับบริการ ไม่ว่าบางส่วนหรือทั้งหมด ทั้งนี้ โดยสำนักงานฯไม่จำเป็นต้องบอกกล่าวให้ผู้ใช้บริการทราบก่อน ซึ่งท่านจะไม่มีสิทธิที่จะกล่าวอ้าง ฟ้องร้อง หรือเรียกร้องใดๆ จากสำนักงานฯ สำหรับการเปลี่ยนแปลง หรือสิ่งที่ผิดพลาดซึ่งเกิดขึ้นจากการเปลี่ยนแปลงดังกล่าว</p>
<p><strong>การยุติการบริการ</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;สำนักงานฯ อาจบอกเลิกเงื่อนไขของการบริการเป็นการชั่วคราวหรือตลอดไปไม่ว่าทั้งหมดหรือแต่บางส่วนและไม่ว่าในเวลาใดตามแต่สำนักงานฯ จะเห็นสมควร</p>
<p><strong>การแก้ไขเปลี่ยนแปลงเงื่อนไขการใช้บริการ</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;สำนักงานฯ อาจแก้ไขเปลี่ยนแปลงเงื่อนไขการใช้บริการเป็นครั้งคราว ถ้ามีการเปลี่ยนแปลงพื้นฐานเพิ่มเติม สำนักงานฯ จะแจ้งโปรแกรมคอมพิวเตอร์ในเวอร์ชั่นใหม่และเว็บไซต์ที่ให้บริการ การใช้บริการของท่านต่อไปหลังจากเงื่อนไข การใช้บริการได้มีการปรับปรุงแก้ไขย่อมมีความหมายว่าท่านได้ยินยอมรับเงื่อนไขการใช้บริการที่แก้ไขเปลี่ยนแปลงนั้นด้วย ถ้าท่านไม่เห็นด้วยกับการแก้ไขเปลี่ยนแปลงเงื่อนไขการใช้บริการ ท่านจะต้องยุติการใช้โปรแกรมคอมพิวเตอร์ และบริการต่อไป</p>
<p><strong>กฎหมายที่ใช้บังคับและเขตอำนาจศาล</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เงื่อนไขการใช้บริการ โปรแกรมคอมพิวเตอร์ และบริการจะบังคับใช้ภายใต้กฎหมายของประเทศไทยโดยไม่อยู่ภายใต้หลักกฎหมายขัดกัน ทั้งนี้ ข้อพิพาท การฟ้องร้อง หรือการโต้เถียงที่เกิดขึ้นเนื่องจากหรือเกี่ยวข้องกับเงื่อนไขการ ใช้บริการ โปรแกรมคอมพิวเตอร์ และบริการ จะอยู่ภายใต้เขตอำนาจศาลที่มีอำนาจในประเทศไทยเท่านั้น</p>
<p><br /> <strong>ความสมบูรณ์ของเงื่อนไขการใช้บริการ</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เงื่อนไขการใช้บริการและนโยบายซึ่งถือเป็นส่วนหนึ่งของเงื่อนไขการใช้บริการ ได้แก่ นโยบายการคุ้มครองข้อมูลส่วนบุคคล และนโยบายทรัพย์สินทางปัญญา จะถือว่าเป็นการก่อตั้งข้อตกลงที่มีความสมบูรณ์ระหว่างท่านและสำนักงานฯ ในเรื่องที่เกี่ยวกับการบริการของสำนักงานฯ</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีที่มีความขัดแย้งระหว่างเงื่อนไขการใช้บริการและสาระสำคัญของเงื่อนไขการใช้บริการที่แจ้งต่อผู้ใช้บริการใช้งาน ให้นำเงื่อนไขการใช้บริการนี้มาใช้บังคับ</p>
<p><strong>การติดต่อสำนักงานฯ</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีที่ท่านมีข้อสงสัยเกี่ยวกับบริการ ท่านอาจติดต่อกับสำนักงานฯ โดยผ่านทาง &nbsp;<br /> support@safepoints.in.th หรือผ่านช่องทางแจ้งปัญหาใน Mobile application ซึ่งสำนักงานฯ จะพยายามอย่างที่สุดที่จะตอบสนองท่านในทันที</p>
`;

export const TERM_OF_USE_ENG = `
<p>Terms of Use</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ldquo;Terms of Use&rdquo; specified herein cover the rights and responsibilities related to the use of the website, mobile applications, and services, hereinafter referred to as &ldquo;Services,&rdquo; provided by the Office of Justice Affairs, hereinafter referred to as &ldquo;the Office,&rdquo; through the internet. These terms constitute a binding agreement between the Office, as the owner of the intellectual property rights of the Services, and you. Therefore, the Office requests that you carefully read and review these Terms of Use.</p>
<p>By using the Services of the Office, you agree and accept to be bound by:</p>
<ul>
<li>These Terms of Use;</li>
<li>The Office’s Privacy Policy, hereinafter referred to as the &ldquo;Privacy Policy;&rdquo; and</li>
<li>The Office’s Intellectual Property Policy, hereinafter referred to as the &ldquo;Intellectual Property Policy,&rdquo; all of which are considered part of these Terms of Use.</li>
</ul>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If you disagree with these Terms of Use or any part thereof, you are not permitted to use the Services. If you have already used the Services, you must cease usage immediately.</p>
<p><strong>&ldquo;Services&rdquo; Defined</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Office provides users with the ability to register, log in, and access related services via the website www.safepoints.in.th and the Safepoints software.</p>
<p><strong>Right to Use</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Office Services</strong>: The Office grants you the right to use its Services, including related software, for non-commercial purposes, subject to the conditions outlined in these Terms of Use.</p>
<p><strong>Using the Services</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You may use the Services for personal purposes only. Commercial use is prohibited. For example, you may not offer the Services for use by others, sell or lease the Services, or publicly display them without prior written consent from the Office. Copying, printing, recording, or using data from the website or database is only allowed for your personal use, as permitted by the software.</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When using the Services or data from the database, you may not perform activities such as scraping, data mining, harvesting, screen scraping, data aggregation, or indexing. You agree not to use bots, spiders, scrapers, or any automated means to access the website or database without prior written consent from the Office.</p>
<p>Software provided as part of the Services must not be used beyond the scope permitted under these Terms of Use.</p>
<p><br /> <strong>Service Registration</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Some parts of the Services, including access to the website or software, require user registration.</p>
<p><strong>Service Termination</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You may terminate the Services at any time without providing a reason.</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Office reserves the right to block access to the Services and disconnect your usage at any time for any reason deemed appropriate by the Office. Additionally, the Office reserves the right to share information regarding your usage of the Services with third parties in the following circumstances:</p>
<ul>
<li>If you intentionally provide incorrect or false information during registration.</li>
<li>If you engage in activities that cause or may cause harm or damage to the Office, third parties, other users, business partners, or affiliates of the Office.</li>
<li>If you use or attempt to use the website or Services for illegal purposes or actions deemed unlawful.</li>
<li>If you violate these Terms of Use.</li>
<li>If required by court orders or directives from authorized legal bodies.</li>
<li>If necessary to detect, prevent, or identify technical issues, security violations, or fraud.</li>
</ul>
<p>In such cases, the Office will notify you of the termination of your account.</p>
<p><strong>Prohibited Information or Messages</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You may not send commercial messages, including advertisements, unless related to the Office or its Services and approved by the Office. When submitting information or messages through the Services, you must ensure compliance with laws and clarity, avoiding:</p>
<ul>
<li>Content that endangers personal safety or health.</li>
<li>Personal data or contact information without prior consent from the individual.</li>
<li>Defamatory, unlawful, or privacy-invading content.</li>
<li>Harassing, aggressive, or offensive content.</li>
<li>Content promoting discrimination based on race, origin, ethnicity, nationality, religion, gender, occupation, illness, or other characteristics.</li>
<li>Encouragement of criminal activity or liability.</li>
<li>Pyramid schemes, spam, or prohibited advertising.</li>
<li>Content falsely endorsed by the Office.</li>
</ul>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Office may deny your use of the Services or remove submitted information or messages violating these Terms of Use. The Office may take preventive measures to restrict the submission of such information or messages.</p>
<p><br /> <strong>Intellectual Property Rights</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The intellectual property rights in the website, Services, software, and database, including copyrights, trademarks, industrial designs, patents, and trade secrets, belong to or are licensed exclusively to the Office. These rights are protected under Thai and international laws.</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Copying, distributing, displaying, modifying, or using the Services without prior written consent is prohibited and considered a violation of the Office’s rights.</p>
<p><strong>Violations and Indemnification</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You agree to indemnify and hold harmless the Office, its employees, officers, and agents from damages resulting from violations of these Terms of Use or related legal actions.</p>
<p><strong>Privacy</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Office respects your privacy and maintains an updated Privacy Policy, accessible at <a href="https://www.safepoints.in.th/privacy">https://www.safepoints.in.th/privacy</a>.</p>
<p><strong>Changes to Services and Software</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Office may modify or update Services, software features, or designs without prior notice.</p>
<p><strong>Governing Law and Jurisdiction</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;These Terms of Use are governed by Thai law. Disputes arising from these Terms will be resolved exclusively within Thai jurisdiction.</p>
<p><br /> <strong>Contacting the Office</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If you have questions regarding the Services, contact the Office via <br /> support@safepoints.in.th or through the mobile application’s help section.</p>
`;
