import { useState, useEffect, useContext } from "react";
import { ModalAlert, RiskReport } from ".";
import { RiskContext } from "../libs/risk";
import { MDBCard, MDBCardText, MDBTooltip } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";

const RiskCard = ({
  date,
  time = null,
  level = null,
  description = null,
  prob,
  showDescription = true,
  width = "20rem",
  maxTime,
  maxLevel,
}) => {
  const { t } = useTranslation();
  const { setRiskReport, setShowModalReport } = useContext(RiskContext);
  const [maxRiskValue, setMaxRiskValue] = useState({
    info: t("no_data"),
    bgColor: "#E0E0E0",
    textColor: "black",
    icon: "question-circle",
  });
  const [riskValue, setRiskValue] = useState({
    info: t("no_data"),
    bgColor: "#E0E0E0",
    textColor: "black",
    icon: "question-circle",
  });
  const isSmallWidth = window.innerWidth < 576;

  const setRisk = (level, setValue) => {
    switch (level) {
      case 1:
        setValue({
          info: t("risk_level_very_low"),
          bgColor: "#00C853",
          textColor: "black",
          icon: "/emoticon1.png",
        });
        break;
      case 2:
        setValue({
          info: t("risk_level_low"),
          bgColor: "#AEEA00",
          textColor: "black",
          icon: "/emoticon2.png",
        });
        break;
      case 3:
        setValue({
          info: t("risk_level_medium"),
          bgColor: "#FFD600",
          textColor: "black",
          icon: "/emoticon3.png",
        });
        break;
      case 4:
        setValue({
          info: t("risk_level_high"),
          bgColor: "#FF6D00",
          textColor: "white",
          icon: "/emoticon4.png",
        });
        break;
      case 5:
        setValue({
          info: t("risk_level_very_high"),
          bgColor: "#D50000",
          textColor: "white",
          icon: "/emoticon5.png",
        });
        break;
      default:
        setValue({
          info: t("no_data"),
          bgColor: "#E0E0E0",
          textColor: "black",
          icon: "/help.png",
        });
    }
  };

  useEffect(() => {
    setRisk(level, setRiskValue);
    setRisk(maxLevel, setMaxRiskValue);
  }, [level, maxLevel, t]);

  const MyRiskCard = ({ onClick }) => {
    return (
      <MDBCard
        style={{
          width,
          backgroundColor: riskValue?.bgColor,
          borderRadius: "0 0 1rem 1rem",
          color: riskValue?.textColor,
          cursor: "pointer",
          padding: "0.5rem",
          height: "180px",
        }}
        onClick={onClick}
      >
        <div className="d-flex flex-column mt-1 mx-2">
          <div className="d-flex justify-content-between">
            <div
              style={{
                color: riskValue?.textColor,
                display: "flex",
                gap: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              {date && <h6>{date}</h6>}
              <h6>|</h6>
              {time && (
                <h6>
                  {time} {t("time_suffix")}
                </h6>
              )}
            </div>
            <img
              src="/info.png"
              alt="info"
              style={{
                width: "1.5rem",
                height: "1.5rem",
                filter:
                  riskValue?.textColor === "black"
                    ? "brightness(0%)"
                    : "brightness(300%)",
              }}
            />
          </div>

          <div className="d-flex align-items-center justify-content-evenly mt-1">
            <div className="d-flex align-items-center justify-content-evenly flex-wrap flex-fill">
              <img
                src={riskValue?.icon}
                alt={riskValue?.info}
                className="mx-2"
                style={{
                  width: "3rem",
                  height: "3rem",
                }}
              />
              <h6 style={{ color: riskValue?.textColor, textAlign: "center" }}>
                {t("risk")}
                <div>{riskValue?.info}</div>
              </h6>
            </div>
            <MDBCardText
              className="mx-2"
              style={{
                color: riskValue?.textColor,
                fontSize: "0.8rem",
                textAlign: "center",
              }}
            >
              {description ?? t("no_data")}
            </MDBCardText>
          </div>
        </div>
      </MDBCard>
    );
  };

  const MyTopRiskCard = () => {
    return (
      <MDBCard
        style={{
          width,
          backgroundColor: maxRiskValue?.bgColor,
          borderRadius: showDescription ? "1rem 1rem 0 0" : "1rem",
          border: !showDescription && "solid black",
          color: maxRiskValue?.textColor,
          padding: showDescription ? "0.5rem" : "0.2rem",
        }}
      >
        <div className="d-flex align-items-center justify-content-evenly mx-1">
          {!showDescription && date && <h6>{date}</h6>}
          <h6>{t("max_risk")}</h6>
          {maxTime && (
            <h6>
              {t("time")} {maxTime} {t("time_suffix")}
            </h6>
          )}

          {showDescription && <div className="vr" />}
          <div className="d-flex align-items-center">
            <img
              src={maxRiskValue?.icon}
              alt={maxRiskValue?.info}
              style={{
                width: "2rem",
                height: "2rem",
              }}
            />
            <h6
              style={{
                color: maxRiskValue?.textColor,
                display: isSmallWidth ? "none" : "block",
              }}
            >
              {maxRiskValue?.info}
            </h6>
          </div>
        </div>
      </MDBCard>
    );
  };

  const WrapToolTip = () => {
    return (
      <MDBTooltip
        title={
          <div className="d-flex flex-column align-items-start">
            <strong>{t("risk_levels")}</strong>
            <strong>{t("risk_levels_description")}</strong>
            <li style={{ color: "#00C853" }}>{t("risk_very_low")}</li>
            <li style={{ color: "#AEEA00" }}>{t("risk_low")}</li>
            <li style={{ color: "#FFD600" }}>{t("risk_medium")}</li>
            <li style={{ color: "#FF6D00" }}>{t("risk_high")}</li>
            <li style={{ color: "#D50000" }}>{t("risk_very_high")}</li>
          </div>
        }
        wrapperProps={{
          color: "link",
          style: { padding: 0, borderRadius: "1rem" },
          onClick: () => {
            setRiskReport({ description, prob, level });
            setShowModalReport(true);
          },
        }}
      >
        <MyTopRiskCard />
        <MyRiskCard />
      </MDBTooltip>
    );
  };

  return (
    <div className="d-flex flex-column" style={{ width }}>
      {!isSmallWidth && showDescription ? (
        <WrapToolTip />
      ) : (
        <div>
          <MyTopRiskCard />
          {showDescription && (
            <MyRiskCard
              onClick={() => {
                setRiskReport({ description, prob, level });
                setShowModalReport(true);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default RiskCard;
