import { useAuth } from "../../auth/AuthContext";
import { RiskInfo, RiskAds, Layout } from "../../components";

const Home = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Layout>
      {isAuthenticated && <RiskInfo />}
      <RiskAds isAuthenticated={isAuthenticated} />
    </Layout>
  );
};

export default Home;
