import React, { useEffect, useState, useRef, useCallback } from "react";
import { MDBIcon } from "mdb-react-ui-kit";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import { RiskCard } from ".";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import "swiper/css";
import "swiper/css/navigation";

const RiskSlider = ({
  data,
  date,
  curDate = dayjs().format("YYYY-MM-DD"),
  time,
}) => {
  const { t } = useTranslation();
  const [slide, setSlide] = useState(0);
  const sliderRef = useRef(null);

  const handleSlideChange = (swiper) => {
    setSlide(swiper.activeIndex);
  };

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
    setSlide((prevSlide) => Math.max(prevSlide - 1, 0));
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
    setSlide((prevSlide) => Math.min(prevSlide + 1, 23));
  }, []);

  const handleSlideTo = useCallback((index) => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideTo(index);
    setSlide(index);
  }, []);

  const bgColor = [
    "#E0E0E0",
    "#00C853",
    "#AEEA00",
    "#FFD600",
    "#FF6D00",
    "#D50000",
  ];

  const maxRisk = data
    ? Object.keys(data).reduce((a, b) =>
        data[a].crime_level > data[b].crime_level ? a : b
      )
    : null;

  useEffect(() => {
    if (curDate === date) {
      setSlide(parseInt(time.slice(0, 2)));
      sliderRef.current.swiper.slideTo(time.slice(0, 2));
    } else {
      sliderRef.current.swiper.slideTo(0);
      setSlide(0);
    }
  }, [curDate, date, time]);

  const Indicators = () => {
    return data ? (
      <div className="bg-white d-flex px-2 rounded-5 my-2 py-1 flex-wrap">
        {Object.keys(data).map((tf, idx) => (
          <div
            key={idx}
            className="align-self-end position-relative d-flex flex-grow-1 flex-shrink-1"
            style={{ minWidth: "0.5rem", margin: "0.05rem" }}
          >
            {idx % 6 === 0 && (
              <div style={{ paddingBottom: "2.5rem" }}>
                <span className="position-absolute">|{tf}</span>
              </div>
            )}
            <div
              onClick={() => {
                setSlide(idx);
                handleSlideTo(idx);
              }}
              style={{
                backgroundColor: bgColor[data[tf].crime_level || 0],
                height: "1rem",
                minWidth: "0.5rem",
                cursor: "pointer",
                flex: "1 1 0",
                alignSelf: "end",
                border: slide === idx ? "0.1rem solid blue" : "none",
              }}
            />
          </div>
        ))}
      </div>
    ) : null;
  };

  return (
    <div>
      <div className="w-100 d-flex align-items-center">
        <MDBIcon
          fas
          icon="angle-left"
          style={{ cursor: "pointer" }}
          onClick={handlePrev}
        />
        <Swiper
          ref={sliderRef}
          slidesPerView={1.5}
          centeredSlides={true}
          spaceBetween={5}
          pagination={{ clickable: true }}
          modules={[Pagination]}
          onSlideChange={handleSlideChange}
          className="mySwiper"
        >
          {data &&
            Object.keys(data).map((tf, idx) => (
              <SwiperSlide key={`slider-${idx}`}>
                <div className="p-2 w-100">
                  <RiskCard
                    date={date}
                    time={tf}
                    width="100%"
                    level={data[tf].crime_level || 0}
                    maxTime={maxRisk}
                    maxLevel={data[maxRisk].crime_level}
                    description={data[tf].description}
                    prob={data[tf].prob}
                    showDescription={true}
                  />
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
        <MDBIcon
          fas
          icon="angle-right"
          style={{ cursor: "pointer" }}
          onClick={handleNext}
        />
      </div>
      <span className="d-flex justify-content-center">
        {t("view_hourly_details")}
      </span>
      <Indicators />
    </div>
  );
};

export default RiskSlider;
