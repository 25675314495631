import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AuthHelper } from "../../libs/auth";
import { ApiHelper } from "../../libs/api";
import { Layout } from "../../components";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBValidation,
} from "mdb-react-ui-kit";
import { useAuth } from "../../auth/AuthContext";
import { useTranslation } from "react-i18next";

const VerifyEmail = () => {
  const { t } = useTranslation();
  const { checkAuth } = useAuth();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await checkAuth();
    navigate("/accept-term");
  };

  useEffect(() => {
    const fetchEmail = async () => {
      try {
        const { data } = await AuthHelper.me();
        setEmail(data?.email || "");
      } catch (error) {
        console.error("Error fetching email:", error);
      }
    };
    fetchEmail();
  }, []);

  return (
    <Layout>
      <MDBContainer className="d-flex align-items-center justify-content-center">
        <MDBCard
          className="m-5"
          style={{ maxWidth: "600px", minWidth: "400px" }}
        >
          <MDBCardBody className="px-5">
            <h2 className="text-uppercase text-center mb-5">
              {t("verify_email_title")}
            </h2>
            <MDBValidation onSubmit={handleSubmit}>
              <p>{t("verify_email_sent")} </p>
              {email && <p>{email}</p>}
              <p>{t("verify_email_status")}</p>
              <MDBBtn type="submit" className="mb-4 w-100" size="lg">
                {t("verify_email_check_status")}
              </MDBBtn>
              <div className="d-flex align-items-center mb-2">
                <span className="small text-muted">
                  {t("verify_email_not_received")}{" "}
                </span>
                <MDBBtn
                  color="link"
                  className="small ms-2"
                  onClick={(e) => {
                    e.preventDefault();
                    ApiHelper.sendVerificationEmail();
                    alert(t("verify_email_resent_alert"));
                  }}
                  style={{ padding: 0 }}
                >
                  {t("verify_email_resend")}
                </MDBBtn>
              </div>
              <p className="small text-muted">{t("verify_email_check_spam")}</p>
              <p className="text-center">
                <Link className="text-secondary" to="/delete-account">
                  {t("delete_account_link")}
                </Link>
              </p>
            </MDBValidation>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </Layout>
  );
};

export default VerifyEmail;
