import { useEffect, useState } from "react";
import { useNavigate, Link, NavLink, useLocation } from "react-router-dom";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarToggler,
  MDBNavbarItem,
  MDBCollapse,
  MDBIcon,
  MDBNavbarNav,
} from "mdb-react-ui-kit";
import { useAuth } from "../auth/AuthContext";
import { useTranslation } from "react-i18next";

const Navbar = ({ admin = false }) => {
  const { isAuthenticated, adminMode, logout, changeAdminMode } = useAuth();
  const [showNav, setShowNav] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("i18nextLng") || "en"
  );

  const handleLogout = async (e) => {
    e.preventDefault();
    await logout();
    setShowNav(false);
    navigate("/");
  };

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "th" : "en";
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("i18nextLng", newLanguage);
    setCurrentLanguage(newLanguage);
    window.location.reload();
  };

  useEffect(() => {
    const allowedAdminPaths = [
      "/admin-report",
      "/admin-alert",
      "/video-help-admin",
      "/profile",
      "/alert-add",
    ];
    const isReportPath = location.pathname.startsWith("/report-detail");
    if (!allowedAdminPaths.includes(location.pathname) && !isReportPath) {
      changeAdminMode(false);
    }
  }, [location]);

  return (
    <>
      <MDBNavbar
        expand="xl"
        light
        bgColor="light"
        style={{ fontFamily: "Kanit-Regular" }}
      >
        <MDBContainer fluid>
          <Link className="navbar-brand" to="/">
            <img
              src="logo192.png"
              height="30"
              alt=""
              loading="lazy"
              style={{ fontFamily: "Kanit-Regular" }}
            />
            {t("safe_points")}
          </Link>
          <MDBNavbarToggler
            type="button"
            data-target="#navbarToggler"
            aria-controls="navbarToggler"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowNav(!showNav)}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>
          <MDBCollapse navbar show={showNav} className="px-1">
            {!adminMode ? (
              <MDBNavbarNav left fullWidth={false} className="mb-2 mb-lg-0">
                <MDBNavbarItem>
                  <NavLink className="nav-link" to="/">
                    {t("home")}
                  </NavLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <NavLink className="nav-link" to="/planning">
                    {t("plan_trip")}
                  </NavLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <NavLink className="nav-link" to="/add-event">
                    {t("report_info")}
                  </NavLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <NavLink className="nav-link" to="/faq">
                    {t("help_center")}
                  </NavLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <NavLink className="nav-link" to="/video-help">
                    {t("video_help")}
                  </NavLink>
                </MDBNavbarItem>
              </MDBNavbarNav>
            ) : (
              <MDBNavbarNav left fullWidth={false} className="mb-2 mb-lg-0">
                <MDBNavbarItem>
                  <NavLink className="nav-link" to="/admin-report">
                    {t("admin_report")}
                  </NavLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <NavLink className="nav-link" to="/admin-alert">
                    {t("admin_alert")}
                  </NavLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <NavLink className="nav-link" to="/video-help-admin">
                    {t("admin_video_help")}
                  </NavLink>
                </MDBNavbarItem>
              </MDBNavbarNav>
            )}
            {isAuthenticated ? (
              <MDBNavbarNav right fullWidth={false} className="mb-2 mb-lg-0">
                <MDBNavbarItem>
                  <NavLink
                    className="nav-link text-danger"
                    as="button"
                    onClick={toggleLanguage}
                  >
                    {currentLanguage === "en" ? "TH" : "EN"}
                  </NavLink>
                </MDBNavbarItem>
                {!admin && (
                  <MDBNavbarItem>
                    <NavLink className="nav-link" to="/notification">
                      {t("notifications")}
                    </NavLink>
                  </MDBNavbarItem>
                )}
                <MDBNavbarItem>
                  <NavLink className="nav-link" to="/profile">
                    {t("profile")}
                  </NavLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <NavLink
                    className="nav-link"
                    onClick={handleLogout}
                    style={{ fontFamily: "Kanit-Regular" }}
                  >
                    {t("logout")}
                  </NavLink>
                </MDBNavbarItem>
              </MDBNavbarNav>
            ) : (
              <MDBNavbarNav right fullWidth={false} className="mb-2 mb-lg-0">
                <MDBNavbarItem>
                  <NavLink
                    className="nav-link text-danger"
                    as="button"
                    onClick={toggleLanguage}
                  >
                    {currentLanguage === "en" ? "TH" : "EN"}
                  </NavLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <NavLink className="nav-link" to="/login">
                    {t("login")}
                  </NavLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <NavLink className="nav-link" to="/register">
                    {t("register")}
                  </NavLink>
                </MDBNavbarItem>
              </MDBNavbarNav>
            )}
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
};

export default Navbar;
