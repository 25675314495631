import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";

const ModalConfirm = ({ show, setShow, confirm }) => {
  const { t } = useTranslation();
  const toggleOpen = () => setShow(!show);

  return (
    <>
      <MDBModal show={show} backdrop={true} staticBackdrop={true}>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{t("modal_confirm_title")}</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>{t("modal_confirm_message")}</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="danger" onClick={confirm}>
                {t("modal_confirm_button_confirm")}
              </MDBBtn>
              <MDBBtn
                className="text-danger"
                color="red-100"
                onClick={toggleOpen}
              >
                {t("modal_confirm_button_cancel")}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default ModalConfirm;
