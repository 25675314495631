import parse from "html-react-parser";
import { MDBContainer, MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { PRIVACY_TEXT, PRIVACY_TEXT_ENG } from "../../libs/constants";
import { Layout } from "../../components";
import { useTranslation } from "react-i18next";

const Privacy = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const privacyContent =
    currentLanguage === "th" ? PRIVACY_TEXT : PRIVACY_TEXT_ENG;

  return (
    <Layout>
      <MDBContainer>
        <MDBCard className="my-4">
          <MDBCardBody>
            <h2 className="text-uppercase text-center">
              {t("privacy_policy")}
            </h2>
            <h2 className="text-uppercase text-center mb-5">
              {t("privacy_policy_notice")}
            </h2>
            {parse(privacyContent)}
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </Layout>
  );
};

export default Privacy;
