import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import {
  MDBContainer,
  MDBAccordion,
  MDBAccordionItem,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBBtn,
} from "mdb-react-ui-kit";
import { Layout } from "../../components";
import Loading from "../../components/Loading";

const adminVideoSections = [
  {
    question: "สำหรับในคอมพิวเตอร์ / โน๊ตบุ๊ค (Admin)",
    videos: [
      {
        videoId: "ve_xmPwQ87U",
        title: "1. การสลับบทบาท",
      },
      { videoId: "Lha5UHsdrCI", title: "2. การตรวจสอบข้อมูลข่าวสาร" },
      { videoId: "iuW2pEvMtK8", title: "3. การส่งแจ้งเตือนเหตุ" },
    ],
  },
  {
    question: "สำหรับในมือถือ (Admin)",
    videos: [
      {
        videoId: "Rjr3MSO1tec",
        title: "1. การสลับบทบาท",
      },
      {
        videoId: "tbDMdrDdoC8",
        title: "2. การตรวจสอบข้อมูลข่าวสาร",
      },
      { videoId: "U2sQzUVKRsM", title: "3. การส่งแจ้งเตือนเหตุ" },
    ],
  },
];

const VideoHelpAdmin = () => {
  const youtubeOptions = {
    width: "100%",
    height: "100%",
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <Layout admin={true}>
      <MDBContainer>
        <h3 className="text-center mb-4 mt-5 text-xl font-bold">
          วิดีโอสอนใช้งานระบบสำหรับผู้ดูแลระบบ
        </h3>

        <MDBAccordion flush>
          {adminVideoSections.map((section, index) => (
            <MDBAccordionItem
              collapseId={`accordion-item-${index}`}
              headerTitle={
                <span className="text-lg font-bold">{section.question}</span>
              }
              key={index}
              className="bg-transparent"
            >
              <MDBCardBody className="bg-transparent">
                <MDBRow>
                  {section.videos.map((video, videoIndex) => (
                    <MDBCol
                      key={videoIndex}
                      lg="4"
                      md="6"
                      sm="12"
                      className="mb-4"
                    >
                      <h5 className="mb-2 text-center text-sm">
                        {video.title}
                      </h5>
                      <MDBCard className="overflow-hidden shadow-none bg-transparent p-0 m-0">
                        <div className="aspect-w-4 aspect-h-3 w-full relative">
                          <YouTube
                            videoId={video.videoId}
                            opts={youtubeOptions}
                            className="absolute inset-0 w-full h-full"
                          />
                        </div>
                      </MDBCard>
                    </MDBCol>
                  ))}
                </MDBRow>
              </MDBCardBody>
            </MDBAccordionItem>
          ))}
        </MDBAccordion>
      </MDBContainer>
    </Layout>
  );
};

export default VideoHelpAdmin;
