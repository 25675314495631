import { useState } from "react";
import { useTranslation } from "react-i18next";
import { API_URL } from "../libs/constants";
import { mappingLevel, mappingTypeValue } from "../libs/mappingValue";

import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import { ModalDetail } from "../components";

const NotificationItem = ({
  title,
  type,
  description,
  image,
  location_title,
  date_created,
  damage_level,
}) => {
  const { t } = useTranslation();
  const daysAgo = Math.floor(
    (new Date() - new Date(date_created)) / (1000 * 3600 * 24)
  );
  const [openModal, setOpenModal] = useState(false);
  const toggleOpen = () => setOpenModal(!openModal);

  return (
    <>
      <MDBRow
        onClick={toggleOpen}
        className="shadow overflow-hidden rounded-6 p-3 bg-white"
        style={{
          cursor: "pointer",
        }}
      >
        <MDBCol md="4" lg="3" size="12">
          <div
            className="w-100 d-flex align-items-center justify-content-center border border-gray-300 rounded"
            style={{
              height: "180px",
              width: "180px",
            }}
          >
            {image ? (
              <img
                src={`${API_URL}/assets/${image}`}
                className="img-fluid w-100 h-100"
                alt={title}
              />
            ) : (
              <MDBIcon fas icon="image" size="4x" />
            )}
          </div>
        </MDBCol>
        <MDBCol md="8" lg="9" size="12">
          <div className="d-flex flex-sm-row flex-column justify-content-between my-3">
            <h4 className="mb-1">{title}</h4>
            <div className="d-flex flex-row">
              {type?.map((accident, index) => {
                const mapValue = mappingTypeValue(accident);
                return (
                  <div
                    key={index}
                    className="bg-black text-white px-2 py-1 me-2 rounded-5 text-truncate"
                  >
                    {mapValue}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="d-flex gap-2 align-items-center">
            <img
              src={`warning-level-${damage_level}.png`}
              alt="damage-level"
              style={{ width: "20px", height: "20px" }}
            />
            <p className="m-0">
              <b>{t("notification_item_damage_level")}: </b>
              {mappingLevel(damage_level)}
            </p>
          </div>
          <p className="mb-1 text-secondary">{description}</p>
          <p className="mb-0">
            <MDBIcon fas icon="location-dot" size="1x" className="pe-2" />
            <b>{t("notification_item_location")}:</b> {location_title}
          </p>
          <p className="mb-0">
            <MDBIcon fas icon="clock" size="1x" className="pe-2" />
            {daysAgo} {t("notification_item_days_ago")}
          </p>
        </MDBCol>
      </MDBRow>
      <ModalDetail
        openModal={openModal}
        toggleOpen={toggleOpen}
        title={title}
        type={type}
        description={description}
        image={image}
        location_title={location_title}
        date_created={date_created}
        damage_level={damage_level}
      />
    </>
  );
};

export default NotificationItem;
