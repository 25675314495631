import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { DEFAULT_ERROR_MESSAGE } from "../../libs/constants";
import { ModalAlert, Layout } from "../../components";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBValidation,
  MDBInput,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import { useAuth } from "../../auth/AuthContext";
import { useTranslation } from "react-i18next";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
  });
  const [modalValue, setModalValue] = useState({
    modalTitle: "",
    modalMessage: "",
    showModal: false,
    onModalClose: () => {},
  });

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = formValue;
    if (email && email.includes("@") && !email.endsWith("@") && password) {
      const { success, error } = await login(email, password);
      if (success) {
        navigate("/");
      } else {
        const message = error?.message?.toLowerCase();
        let errorMsg = DEFAULT_ERROR_MESSAGE;
        if (message && message.includes("invalid user")) {
          errorMsg = t("login_invalid_user");
        } else if (message && message.includes("email")) {
          errorMsg = t("login_invalid_email");
        }
        setModalValue({
          modalTitle: t("error"),
          modalMessage: errorMsg,
          showModal: true,
          onModalClose: () =>
            setModalValue({ ...modalValue, showModal: false }),
        });
      }
    }
  };

  const handleLineLogin = () => {
    navigate("/login-line?platform=web");
  };

  const handleThaiIdLogin = () => {
    navigate("/login-thaid?platform=web");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Layout>
      <MDBContainer
        fluid
        className="d-flex align-items-center justify-content-center min-vh-100"
        style={{ background: "linear-gradient(white, #84BBFF)" }}
      >
        <MDBCard
          className="my-4 mx-3 m-md-5"
          style={{
            flex: 1,
            maxWidth: "500px",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(to right, rgba(132, 187, 255, 0.39) 10%, rgba(174, 209, 251, 0.39) 37%, rgba(195, 219, 249, 0.39) 50%, rgba(132, 187, 255, 0.39) 90%)",
              width: "100%",
              padding: "20px",
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              paddingLeft: "40px",
            }}
          >
            <img
              src="logo512.png"
              alt="logo"
              style={{
                marginRight: "20px",
                height: "75px",
                width: "75px",
              }}
            />
            <div style={{ textAlign: "left", paddingTop: "20px" }}>
              {" "}
              <h2
                className="text-uppercase mb-2"
                style={{
                  fontFamily: "Kanit-SemiBold",
                  fontSize: "2rem",
                }}
              >
                {t("login_title")}
              </h2>
              <h4
                className="mb-4"
                style={{
                  fontFamily: "Kanit-Regular",
                  fontSize: "0.875rem",
                }}
              >
                {t("login_welcome")}
              </h4>
            </div>
          </div>

          <MDBCardBody className="px-4 px-md-5">
            <MDBValidation onSubmit={handleSubmit}>
              <p className="m-0 mb-md-2 text-dark">
                {t("email")} <span style={{ color: "red" }}>*</span>
              </p>
              <MDBInput
                wrapperClass="mb-4"
                size="lg"
                id="input-email"
                type="email"
                name="email"
                value={formValue.email}
                onChange={onChange}
                required
                placeholder={t("login_email_placeholder")}
              />

              <p className="m-0 mb-md-2 text-dark">
                {t("password")} <span style={{ color: "red" }}>*</span>
              </p>
              <div style={{ position: "relative" }}>
                <MDBInput
                  wrapperClass="mb-4"
                  size="lg"
                  id="input-password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formValue.password}
                  onChange={onChange}
                  required
                  placeholder={t("login_password_placeholder")}
                />
                <img
                  src={showPassword ? "eye-hide.png" : "eye.png"}
                  alt={t("password_visibility_toggle")}
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    width: "20px",
                    height: "20px",
                  }}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <div className="d-flex align-items-center">
                  <MDBCheckbox id="rememberMe" style={{ color: "#B62319" }} />
                  <div
                    style={{
                      fontFamily: "Kanit-Regular",
                      marginLeft: "0.5rem",
                    }}
                  >
                    {t("remember_me")}
                  </div>
                </div>
                <Link
                  to="/forgot-password"
                  style={{
                    textDecoration: "underline",
                    fontFamily: "Kanit-Regular",
                  }}
                >
                  {t("forgot_password")}
                </Link>
              </div>
              <MDBBtn
                type="submit"
                className="mb-4 w-100 rounded-6"
                size="lg"
                style={{
                  backgroundColor: "#B62319",
                  borderColor: "#B62319",
                  boxShadow: "none",
                  fontFamily: "Kanit-Regular",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {t("login_button")}
              </MDBBtn>
            </MDBValidation>
            <div
              className="text-center mb-4"
              style={{ fontFamily: "Kanit-Regular" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <hr
                  style={{
                    borderTop: "1px solid #000",
                    width: "45%",
                    margin: "0 10px",
                  }}
                />
                <span>{t("or")}</span>
                <hr
                  style={{
                    borderTop: "1px solid #000",
                    width: "45%",
                    margin: "0 10px",
                  }}
                />
              </div>
            </div>
            <div className="text-center">
              <MDBBtn
                type="button"
                className="mb-4 w-100 rounded-4"
                size="lg"
                style={{
                  backgroundColor: "#06C755",
                  borderColor: "#06C755",
                  boxShadow: "none",
                  fontFamily: "Kanit-Regular",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={handleLineLogin}
              >
                <img
                  src="LineLogo.png"
                  alt="Line"
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "10px",
                  }}
                />
                {t("login_with_line")}
              </MDBBtn>
              <MDBBtn
                type="button"
                className="mb-4 w-100 rounded-4"
                size="lg"
                style={{
                  backgroundColor: "#030650",
                  borderColor: "#030650",
                  boxShadow: "none",
                  fontFamily: "Kanit-Regular",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={handleThaiIdLogin}
              >
                <img
                  src="ThaiDLogo.png"
                  alt="ThaiD"
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "10px",
                  }}
                />
                {t("login_with_thaid")}
              </MDBBtn>
            </div>
            <p
              className="mb-5 pb-lg-2 text-center"
              style={{ color: "#393f81", fontFamily: "Kanit-Regular" }}
            >
              {t("new_user")}{" "}
              <Link
                to="/register"
                style={{
                  textDecoration: "underline",
                  fontFamily: "Kanit-Regular",
                }}
              >
                {t("register_now")}
              </Link>
            </p>
          </MDBCardBody>
        </MDBCard>
        <ModalAlert
          show={modalValue.showModal}
          setShow={(show) => (show ? null : modalValue.onModalClose())}
          title={modalValue.modalTitle}
          onClose={modalValue.onModalClose}
        >
          {modalValue.modalMessage}
        </ModalAlert>
      </MDBContainer>
    </Layout>
  );
};

export default Login;
