import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiHelper } from "../../libs/api";
import { DEFAULT_ERROR_MESSAGE } from "../../libs/constants";
import { ModalAlert, Layout } from "../../components";
import { useTranslation } from "react-i18next";

import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBValidation,
  MDBInput,
  MDBIcon,
} from "mdb-react-ui-kit";
import { useAuth } from "../../auth/AuthContext";

const GENDERS = {
  male: "gender_male",
  female: "gender_female",
  none: "gender_none",
};

const VerifyIdentity = () => {
  const { t } = useTranslation();
  const { userId, userProfileId, checkAuth } = useAuth();
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({
    name: "",
    surname: "",
    nationalId: "",
    laserCode: "",
    birthday: "",
    gender: "",
    verified: false,
  });

  const [modalValue, setModalValue] = useState({
    modalTitle: "",
    modalMessage: "",
    showModal: false,
    onModalClose: () => {},
  });

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const validateName = (name) => {
    const re = /^[ก-๏\s]+$/;
    return re.test(name);
  };

  const validateId = (id) => {
    const re = /[0-9]{13}/;
    return re.test(id);
  };

  const validateLaserCode = (laserCode) => {
    const laserCodeRegex = /^[A-Z]{2}\d{1}-\d{7}-\d{2}$/;
    return laserCodeRegex.test(laserCode);
  };

  const validateByThaid = () => {
    navigate(`/login-ThaiD?platform=web&thaid_userId=${userProfileId}`);
  };

  const saveProfile = async () => {
    const { name, surname, nationalId, birthday, gender, laserCode } =
      formValue;
    if (name && surname && nationalId && birthday && gender && laserCode) {
      if (
        !validateName(name) ||
        !validateName(surname) ||
        !validateId(nationalId) ||
        !validateLaserCode(laserCode)
      ) {
        return { success: false, validate: true };
      }
      const x = ApiHelper.updateUser(userId, {
        first_name: name,
        last_name: surname,
      });
      const y = ApiHelper.updateProfile(userProfileId, {
        national_id: nationalId,
        birthday,
        gender,
      });
      const results = await Promise.all([x, y]);
      if (results[0].success && results[1].success) {
        return { success: true, validate: true };
      } else {
        return { success: false, validate: true };
      }
    }
    return { success: false, validate: false };
  };

  const handleSave = async () => {
    const { success, validate } = await saveProfile();
    if (success) {
      setModalValue({
        modalTitle: t("verify_identity_success"),
        modalMessage: t("profile_saved_success"),
        showModal: validate,
        onModalClose: () => {
          setModalValue({ ...modalValue, showModal: false });
          checkAuth();
          navigate("/");
        },
      });
    } else {
      setModalValue({
        modalTitle: t("error"),
        modalMessage: validate
          ? t("profile_validation_error")
          : t(DEFAULT_ERROR_MESSAGE),
        showModal: validate,
        onModalClose: () => {
          setModalValue({ ...modalValue, showModal: false });
        },
      });
    }
  };

  const handleSubmit = async () => {
    const { success: saveSuccess, validate } = await saveProfile();
    if (saveSuccess) {
      const { laserCode } = formValue;
      const { success, error } = await ApiHelper.verifyIdentity(laserCode);
      if (success) {
        setModalValue({
          modalTitle: t("verify_identity_success"),
          modalMessage: t("identity_verified_success"),
          showModal: true,
          onModalClose: () => {
            setModalValue({ ...modalValue, showModal: false });
            checkAuth();
            navigate("/");
          },
        });
      } else {
        const message = error?.Message?.toLowerCase();
        let errorMsg = t(DEFAULT_ERROR_MESSAGE);
        if (message && message.includes("citizenid")) {
          errorMsg = t("citizen_id_error");
        } else if (message && message.includes("laser")) {
          errorMsg = t("laser_code_error");
        } else if (message && message.includes("not_found_laser_code")) {
          errorMsg = t("not_found_laser_code_error");
        } else if (message && message.includes("data_mismatch")) {
          errorMsg = t("data_mismatch_error");
        } else if (message && message.includes("data_invalid")) {
          errorMsg = t("data_invalid_error");
        }
        setModalValue({
          modalTitle: t("error"),
          modalMessage: errorMsg,
          showModal: true,
          onModalClose: () => {
            setModalValue({ ...modalValue, showModal: false });
          },
        });
      }
    } else {
      setModalValue({
        modalTitle: t("error"),
        modalMessage: validate
          ? t("profile_validation_error")
          : t(DEFAULT_ERROR_MESSAGE),
        showModal: validate,
        onModalClose: () => {
          setModalValue({ ...modalValue, showModal: false });
        },
      });
    }
  };

  return (
    <Layout>
      <MDBContainer className="d-flex align-items-center justify-content-center">
        <MDBCard
          className="m-5 rounded-6"
          style={{ maxWidth: "600px", minWidth: "350px" }}
        >
          <MDBCardBody className="px-3 px-md-5">
            <div className="d-flex align-items-center mb-4">
              <MDBBtn
                onClick={() => navigate(-1)}
                tag="a"
                color="none"
                className="m-2"
              >
                <MDBIcon fas icon="chevron-left" size="lg" />
              </MDBBtn>
              <h2 className="text-uppercase">{t("verify_identity_title")}</h2>
            </div>

            {!formValue.verified && (
              <div className="alert alert-info mb-4 text-center" role="alert">
                {t("identity_verification_notice")}
              </div>
            )}
            <MDBValidation
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <MDBInput
                wrapperClass="mb-4"
                label={t("name_th")}
                size="lg"
                id="input-name"
                type="text"
                name="name"
                value={formValue.name}
                onChange={onChange}
                required
                disabled={formValue.verified}
              />
              <MDBInput
                wrapperClass="mb-4"
                label={t("surname_th")}
                size="lg"
                id="input-surname"
                type="text"
                name="surname"
                value={formValue.surname}
                onChange={onChange}
                required
                disabled={formValue.verified}
              />
              <MDBInput
                wrapperClass="mb-4"
                label={t("id_card_number")}
                size="lg"
                id="input-national-id"
                type="text"
                name="nationalId"
                value={formValue.nationalId}
                onChange={onChange}
                required
                disabled={formValue.verified}
              />
              {!formValue.verified && (
                <MDBInput
                  wrapperClass="mb-4"
                  label={t("laser_code")}
                  size="lg"
                  id="input-laser-code"
                  type="text"
                  name="laserCode"
                  value={formValue.laserCode}
                  onChange={onChange}
                  required
                  disabled={formValue.verified}
                />
              )}
              <MDBInput
                wrapperClass="mb-4"
                label={t("birthday")}
                size="lg"
                id="input-birthday"
                type="date"
                name="birthday"
                value={formValue.birthday}
                onChange={onChange}
                required
                disabled={formValue.verified}
              />
              {formValue.verified ? (
                <MDBInput
                  wrapperClass="mb-4"
                  label={t("gender")}
                  size="lg"
                  id="input-gender"
                  type="text"
                  name="gender"
                  value={t(GENDERS[formValue.gender])}
                  required
                  disabled={formValue.verified}
                />
              ) : (
                <select
                  className="form-select mb-4"
                  aria-label="input-gender"
                  id="input-gender"
                  name="gender"
                  value={formValue.gender}
                  onChange={onChange}
                  required
                >
                  <option value="">{t("select_gender")}</option>
                  {Object.keys(GENDERS).map((key) => (
                    <option key={key} value={key}>
                      {t(GENDERS[key])}
                    </option>
                  ))}
                </select>
              )}
              {!formValue.verified && (
                <MDBContainer>
                  <MDBRow className="gap-4">
                    <MDBCol className="p-0">
                      <MDBBtn
                        type="submit"
                        className="mb-4 w-100"
                        color="secondary"
                        size="lg"
                        onClick={handleSave}
                      >
                        {t("save_information")}
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol className="p-0">
                      <MDBBtn
                        type="submit"
                        className="ml-4 mb-4 w-100"
                        size="lg"
                        onClick={handleSubmit}
                      >
                        {t("verify_identity")}
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow className="gx-4">
                    <MDBBtn
                      type="button"
                      className="mb-4 w-100 rounded-4"
                      size="lg"
                      style={{
                        backgroundColor: "#030650",
                        borderColor: "#030650",
                        boxShadow: "none",
                        fontFamily: "Kanit-Regular",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={validateByThaid}
                    >
                      <img
                        src="ThaiDLogo.png"
                        alt="ThaiD"
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: "10px",
                        }}
                      />
                      {t("verify_with_thaid")}
                    </MDBBtn>
                  </MDBRow>
                </MDBContainer>
              )}
            </MDBValidation>
          </MDBCardBody>
        </MDBCard>
        <ModalAlert
          show={modalValue.showModal}
          setShow={(show) => (show ? null : modalValue.onModalClose())}
          title={modalValue.modalTitle}
          onClose={modalValue.onModalClose}
        >
          {modalValue.modalMessage}
        </ModalAlert>
      </MDBContainer>
    </Layout>
  );
};

export default VerifyIdentity;
